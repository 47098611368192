import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import format from "date-fns/format";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";

import { Button } from "../../../common/button";
import styles from "./info.module.css";
import { ReactComponent as PenIcon } from "../../../../media/icons/edit-pen-white.svg";
import { ReactComponent as ArrowIcon } from "../../../../media/icons/dropdown-arrow.svg";
import { ReactComponent as CloseIcon } from "../../../../media/icons/close-grey.svg";
import { ReactComponent as SearchIcon } from "../../../../media/icons/search.svg";

import { statusesConstant } from "../../../../constants/referralRequestDetail";
import TextArea from "../../../common/textarea";
import { actions as referralRequestDetailActions } from "../../../../actions/referralRequestDetail";
import Input from "../../../common/input";
import PracticesModal from "../../../listingDetail/practicesModal";

const validate = values => {
  const { summary } = values;
  const errors = {};
  const requiredText = "Required";

  if (!summary) {
    errors.summary = requiredText;
  }

  return errors;
};

const Info = ({
  caseData,
  categoriesProfessions,
  onOpenPopupDescription,
  // states,
  isFailedEmailVerification,
  handleOpenContactInfo,
  status,
  practicesList
}) => {
  const { t } = useTranslation();
  const [isMouseInBlock, setIsMouseInBlock] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const [isTouched, setIsTouched] = useState(false);
  const containerRef = useRef();
  const [activePractices, setActivePractices] = useState(false);

  const normalizeProfessions = useMemo(() => {
    if (practicesList?.length) {
      return practicesList.map(item => {
        const parent = item.children.map(itemChildren => {
          const children = itemChildren.children.map(childChildItem => {
            return {
              parent_practice_id: itemChildren.practice_id,
              parent_parent_id: itemChildren.parent_id,
              parent_title: itemChildren.title,
              parent_parent_practice_id: item.practice_id,
              parent_parent_parent_id: item.parent_id,
              parent_parent_title: item.title,
              ...childChildItem
            };
          });

          return {
            parent_practice_id: item.practice_id,
            parent_parent_id: item.parent_id,
            parent_title: item.title,
            ...itemChildren,
            children
          };
        });

        return { ...item, children: parent };
      });
    }

    return [];
  }, [practicesList, practicesList?.length]);

  const dispatch = useDispatch();
  const handleSendReferralsData = useCallback(data => dispatch(referralRequestDetailActions.handleSendReferralsData(data)), [dispatch]);

  const formik = useFormik({
    initialValues: { ...caseData, isEdited: false },
    validate,
    onSubmit: values => {
      handleSendReferralsData(values);
    }
  });

  // , touched,
  const { errors, values, setFieldTouched, setFieldValue } = formik;

  const handleSubmit = () => {
    const { name: full_name, email, country, state, city, zip, phone, practice1, practice2, practice3, lead_id } = values;
    handleSendReferralsData({
      full_name,
      email,
      country,
      state,
      city,
      zip,
      phone,
      practice1,
      practice2,
      practice3,
      lead_id,
      command: "save_general_information"
    });
  };

  const handleBlur = fieldName => {
    setFieldTouched(fieldName, true);
  };

  const handleChange = e => {
    setFieldValue(e.target.name, e.target.value);

    if (!isEdited) {
      setIsEdited(true);
    }
  };

  const handleMouseLeave = () => {
    if (isMouseInBlock) {
      setIsMouseInBlock(false);
      if (isEdited) {
        handleSubmit();
        setIsEdited(false);
      }
    }
  };
  const handleMouseMove = () => {
    if (!isMouseInBlock) {
      setIsMouseInBlock(true);
    }
  };

  const handleTouchStart = () => {
    if (!isTouched) {
      setIsTouched(true);
    }
  };

  const handleRemovePractice = (event, practiceId) => {
    event.stopPropagation();
    event.preventDefault();

    setFieldValue(`practice${practiceId}`, null);

    if (!values.isEdited) {
      setFieldValue("isEdited", true);
    }

    if (!isEdited) {
      setIsEdited(true);
    }
  };

  const handleSelectPractice = practiceId => {
    if (!values.isEdited) {
      setFieldValue("isEdited", true);
    }

    if (!isEdited) {
      setIsEdited(true);
    }

    setActivePractices(false);

    if (!values.practice1) {
      setFieldValue("practice1", practiceId);
      return;
    }

    if (!values.practice2) {
      setFieldValue("practice2", practiceId);
      return;
    }

    if (!values.practice3) {
      setFieldValue("practice3", practiceId);
    }
  };

  useEffect(() => {
    const handleTouch = event => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        if (isTouched) {
          setIsTouched(false);

          if (isEdited) {
            handleSubmit();
            setIsEdited(false);
          }
        }
      }
    };

    document.addEventListener("touchstart", handleTouch, true);

    return () => {
      document.removeEventListener("touchstart", handleTouch, true);
    };
  }, [isEdited]);

  const isShowEditButton = [statusesConstant.pendingReview, statusesConstant.pending].includes(status);

  const practices = useMemo(
    () =>
      categoriesProfessions.practices
        ? categoriesProfessions.practices.filter(
            item => item.practice_id === values.practice1 || item.practice_id === values.practice2 || item.practice_id === values.practice3
          )
        : [],
    [values.practice1, values.practice2, values.practice3, categoriesProfessions]
  );

  const handleOpenModal = () => {
    const { practice1, practice2, practice3 } = values;

    if (!practice1 || !practice2 || !practice3) {
      setActivePractices(true);
    }
  };

  return (
    <>
      <div className={styles.heading}>
        <div className={styles.headingItem}>
          <span className={styles.headingLabel}>request id:</span>
          <p className={styles.headingValue}>{caseData.lead_id}</p>
        </div>
        <div className={styles.headingItem}>
          <span className={styles.headingLabel}>Received on:</span>
          <p className={styles.headingValue}>{format(new Date(caseData.added), "MM/dd/yyyy - h:mm a")}</p>
        </div>
      </div>
      <div
        ref={containerRef}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
        onTouchStart={handleTouchStart}
        className={styles.content}
      >
        <div>
          <div className={styles.contentItem}>
            <h3 className={styles.label}>{t("dashboard_referrals_details_description")}</h3>

            {isShowEditButton && (
              <Button className={styles.editBtn} onClick={onOpenPopupDescription}>
                <PenIcon className={styles.penIcon} />
              </Button>
            )}
            <TextArea onBlur={handleBlur} className={styles.textarea} name="summary" value={values.summary} onChange={handleChange} />
          </div>
        </div>

        <div className={styles.form}>
          <div className={styles.formItem}>
            <label className={styles.formLabel}>{t("dashboard_referrals_details_location")}</label>
            <Input
              error={errors.city}
              type="text"
              className={styles.input}
              value={values.city}
              name="city"
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>

          <div className={styles.formItem}>
            <label className={styles.formLabel}>{t("dashboard_common_field_email")}</label>
            <Input
              error={errors.email}
              type="text"
              className={styles.input}
              value={values.email}
              name="email"
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {isFailedEmailVerification && <p className={styles.errorEmail}>This email address is not verified</p>}
          </div>
        </div>

        <div className={styles.form}>
          <div className={styles.formItem}>
            <label className={styles.formLabel}>full name</label>
            <Input
              error={errors.name}
              type="text"
              className={styles.input}
              value={values.name}
              name="name"
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>

          <div className={styles.formItem}>
            <label className={styles.formLabel}>{t("dashboard_common_field_phonenumber")}</label>
            <Input
              error={errors.phone}
              type="text"
              className={styles.input}
              value={values.phone}
              name="phone"
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>

          {isShowEditButton && (
            <Button className={cn(styles.editBtn, styles.editContactInfo)} onClick={handleOpenContactInfo}>
              <PenIcon className={styles.penIcon} />
            </Button>
          )}
        </div>
        <div className={styles.formItem}>
          <h3 className={styles.formLabel}>{t("dashboard_referrals_details_specialties")}</h3>

          <div
            tabIndex={-1}
            role="button"
            className={cn(styles.practiceDropdown, errors.practice_id ? styles.practiceDropdownError : "", styles.practiceDropdownWidth)}
            onClick={handleOpenModal}
          >
            <SearchIcon className={styles.searchIcon} />
            <span className={styles.practiceContainer}>
              {practices.map((item, index) => {
                return (
                  <button
                    type="button"
                    className={styles.practiceItem}
                    key={item.practice_id}
                    onClick={event => handleRemovePractice(event, index + 1)}
                  >
                    {item.title}
                    <CloseIcon />
                  </button>
                );
              })}
            </span>

            <ArrowIcon />
          </div>
        </div>
        <div className={styles.formItem}>
          {activePractices && (
            <PracticesModal
              handleSelectPractice={handleSelectPractice}
              closeModal={() => setActivePractices(false)}
              profession={{ children: normalizeProfessions }}
            />
          )}
        </div>
      </div>
      {/*
      <div className={styles.flexbox}>
        <div className={styles.flexItem}>
          <h3 className={styles.label}>{t("dashboard_referrals_details_directory")}_1111</h3>
          <a className={styles.profileLink} href={caseData.website_url} target="_blank" rel="noopener noreferrer">
            {getNameWebsiteFromUrl(caseData.website_url)}
          </a>
        </div>
        <div className={styles.flexItem}>
          <h3 className={styles.label}>PROFESSION_222</h3>
          <p className={styles.textContent}>{professions?.title}</p>
        </div>
      </div>

      <hr className={cn(styles.hrFullWidth, styles.mt30)} />
      */}
    </>
  );
};

export default Info;
