import React from "react";
import { Link } from "react-router-dom";

import { Section } from "../section";
import { SectionHeading } from "../sectionHeading";
import styles from "./mainSection.module.css";

export const MainSection = ({ title, linkText, to, icon, children, isEmpty }) => {
  return (
    <Section>
      {!isEmpty && <SectionHeading title={title} linkText={linkText} to={to} icon={icon} />}

      {children}
      {!isEmpty && (
        <Link to={to} className={styles.link}>
          {linkText}
        </Link>
      )}
    </Section>
  );
};
