import React from "react";
import { NavLink } from "react-router-dom";

import styles from "./pageNames.module.css";

const PageNames = () => {
  return (
    <div className={styles.main}>
      <NavLink activeClassName={styles.activeLink} className={styles.link} to="/" exact>
        Feed
      </NavLink>

      <NavLink activeClassName={styles.activeLink} className={styles.link} to="/saved" exact>
        Saved
      </NavLink>
    </div>
  );
};

export default PageNames;
