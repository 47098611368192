import React from "react";
import { Link } from "react-router-dom";
import cn from "classnames";

import styles from "./forbidden.module.css";

const Forbidden = () => {
  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Forbidden</h1>
      <p className={styles.text}>Sorry, but this is a forbidden page</p>
      <Link to="/login" className={cn("button", styles.button)}>
        Login or sign up
      </Link>
    </div>
  );
};

export default Forbidden;
