import React from "react";
import cn from "classnames";

import modalStyles from "../../../../common/modal/modal.module.css";
import { Modal } from "../../../../common/modal";
import styles from "./success.module.css";
import ModalControl from "../../../../modalControl";

export const Success = ({ closeModal, clearAllModals, subTitle, title, message }) => {
  return (
    <Modal closeModal={closeModal} classNameMain={styles.paymentOkMain} clearAllModals={clearAllModals}>
      <section>
        <div className={modalStyles.pointer}>{subTitle}</div>
        <div className={styles.popupHeading}>{title}</div>
      </section>

      <div className={styles.paymentOkWrp}>
        <div className={cn("email-submit-check", styles.check)} />
        {message}
        <br />
        <br />
      </div>
      <ModalControl isClose isHideExitConfirm onExit={closeModal} />
    </Modal>
  );
};
