import React from "react";
import { withRouter } from "react-router-dom";

import ErrorBoundaryComponent from "../../components/errorBoundary";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidUpdate(prevProps) {
    if (this.state.hasError && this.props.location.pathname !== prevProps.location.pathname) {
      window.location.reload();
    }
  }

  componentDidCatch(error, errorInfo) {
    console.error("error", error);
    console.error("errorInfo", errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <ErrorBoundaryComponent />;
    }

    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
