import React from "react";
import Select from "react-select";
import cn from "classnames";

import { customStyles as selectStyles } from "../../../constants/select";
import styles from "./selectInput.module.css";

const SelectInput = ({
  error,
  className,
  classNameError,
  classNameValid,
  classNameInvalid,
  classNameWrap,
  classNamePrefix,
  defaultValue,
  getOptionLabel,
  getOptionValue,
  formatOptionLabel,
  id,
  inputId,
  isInvalid,
  isMulti,
  name,
  noOptionsMessage,
  onChange,
  onBlur,
  options,
  label,
  labelClass,
  placeholder = "Select",
  customStyles,
  value,
  required,
  disabled,
  isAddCustomScrollbar,
  selectRef,
  isNewSelectedColor,
  onInputChange,
  isAutoFocus
}) => {
  const handleChangeInput = event => {
    if (onInputChange) {
      onInputChange(event);
    }
  };

  return (
    <div className={classNameWrap}>
      {label && (
        <label className={cn(styles.label, labelClass)} htmlFor={inputId}>
          {label} {required && <span className={styles.asterisk}>*</span>}
        </label>
      )}
      <Select
        isAddCustomScrollbar={isAddCustomScrollbar}
        classNamePrefix={classNamePrefix}
        className={cn(styles.select, className, { [styles.selectInvalid]: isInvalid }, isInvalid ? classNameInvalid : classNameValid)}
        isDisabled={disabled}
        defaultValue={defaultValue}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        formatOptionLabel={formatOptionLabel}
        id={id}
        inputId={inputId}
        isMulti={isMulti}
        name={name}
        noOptionsMessage={noOptionsMessage}
        onBlur={onBlur}
        onChange={onChange}
        options={options}
        placeholder={placeholder}
        styles={customStyles || selectStyles}
        value={value}
        openMenuOnFocus
        ref={selectRef}
        isNewSelectedColor={isNewSelectedColor}
        onInputChange={handleChangeInput}
        // isSearchable={false}
        autoFocus={isAutoFocus}
      />
      {isInvalid && <div className={cn(styles.error, classNameError)}>{error}</div>}
    </div>
  );
};

export default SelectInput;
