import React from "react";

import styles from "./content.module.css";

const Content = ({ title }) => {
  return (
    <div>
      <h3 className={styles.title}>{title}</h3>
    </div>
  );
};

export default Content;
