import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";

import { OverlayPreloader } from "../../../common/preloader";
import { Modal } from "../../../common/modal";
import { actions as referralRequestDetailActions } from "../../../../actions/referralRequestDetail";
import { RenderQuestions } from "../../components/renderQuestions";
import styles from "./questionOpenToOther.module.css";
import ModalControl from "../../../modalControl";

export const QuestionOpenToOther = ({ leadId, clearAllModals, onClose }) => {
  const { t } = useTranslation();
  const { preloader, case: caseData } = useSelector(state => state.referralRequestDetail);

  const dispatch = useDispatch();
  const updateFollowupQuestion = useCallback(answers => dispatch(referralRequestDetailActions.updateFollowupQuestion(answers, leadId)), [
    dispatch
  ]);

  const {
    answer: answerOpenProfessionals,
    question: questionOpenProfessionals,
    lead_followup_id: leadFollowUpId,
    answer_options: answerOptions
  } = caseData.open_to_other_professionals;

  const normalizeQuestion = [
    {
      answer_type: "checkboxes",
      answer: [answerOpenProfessionals],
      question: questionOpenProfessionals,
      lead_followup_id: leadFollowUpId,
      answer_options: { options: JSON.parse(answerOptions).options }
    }
  ];

  const formAnswers = questionList => {
    return questionList.map(question => {
      return {
        lead_followup_id: question.lead_followup_id,
        answer: question.answer[0],
        label: question.answer[0]
      };
    });
  };

  const formik = useFormik({
    initialValues: formAnswers(normalizeQuestion),
    onSubmit: values => {
      updateFollowupQuestion({ lead_followup_id: values[0].lead_followup_id, answer: values[0].answer });
    }
  });

  const { errors, touched, values } = formik;

  const handleBlur = fieldName => {
    formik.setFieldTouched(fieldName, true);
  };

  return (
    <Modal clearAllModals={clearAllModals} closeModal={onClose}>
      {preloader && <OverlayPreloader overlayClassName="sectionOverlayModal" />}
      <div className={styles.modalWrp}>
        <h2 className={styles.title}>Follow up Questions</h2>
        <hr className={styles.hr} />
        <RenderQuestions
          handleBlur={handleBlur}
          formik={formik}
          questions={normalizeQuestion}
          errors={errors}
          touched={touched}
          values={values}
        />

        <ModalControl onExit={onClose} onConfirm={formik.handleSubmit} isClose>
          {t("dashboard_common_button_save")}
        </ModalControl>
      </div>
    </Modal>
  );
};
