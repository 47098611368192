import React from "react";

import testCompany from "../../../media/images/testCompany.png";
import { ReactComponent as LocationIcon } from "../../../media/icons/location.svg";
import styles from "./aside.module.css";

const Aside = () => {
  return (
    <div className={styles.aside}>
      <h4 className={styles.asideTitle}>Similar Job</h4>
      <div className={styles.jobs}>
        <div className={styles.jobsItem}>
          <span className={styles.jobsLogo}>
            <img src={testCompany} alt="Company" />
          </span>
          <div className={styles.jobsHeading}>
            <h6 className={styles.jobsCompanyName}>Spotify</h6>
            <span className={styles.jobsPoint} />
            <p className={styles.jobsLocation}>
              <LocationIcon />
              United Arab Emirates
            </p>
          </div>
          <h3 className={styles.jobsName}>UI / UX Designer</h3>
          <p className={styles.jobsDescription}>
            We are looking for a UI Designer who would work close with our team at https://kulga.co/ to continue the website design...
          </p>
        </div>
        <div className={styles.jobsItem}>
          <span className={styles.jobsLogo}>
            <img src={testCompany} alt="Company" />
          </span>
          <div className={styles.jobsHeading}>
            <h6 className={styles.jobsCompanyName}>Spotify</h6>
            <span className={styles.jobsPoint} />
            <p className={styles.jobsLocation}>
              <LocationIcon />
              United Arab Emirates
            </p>
          </div>
          <h3 className={styles.jobsName}>UI / UX Designer</h3>
          <p className={styles.jobsDescription}>
            We are looking for a UI Designer who would work close with our team at https://kulga.co/ to continue the website design...
          </p>
        </div>
        <div className={styles.jobsItem}>
          <span className={styles.jobsLogo}>
            <img src={testCompany} alt="Company" />
          </span>
          <div className={styles.jobsHeading}>
            <h6 className={styles.jobsCompanyName}>Spotify</h6>
            <span className={styles.jobsPoint} />
            <p className={styles.jobsLocation}>
              <LocationIcon />
              United Arab Emirates
            </p>
          </div>
          <h3 className={styles.jobsName}>UI / UX Designer</h3>
          <p className={styles.jobsDescription}>
            We are looking for a UI Designer who would work close with our team at https://kulga.co/ to continue the website design...
          </p>
        </div>
      </div>
    </div>
  );
};

export default Aside;
