import React from "react";

import { Login as LoginComponent } from "../../components/login";

const Login = ({ errors, isLoggedIn, history, login, loginMethods, preloaders, user, keycloak }) => {
  return (
    <LoginComponent
      errors={errors}
      isLoggedIn={isLoggedIn}
      history={history}
      login={login}
      loginMethods={loginMethods}
      preloaders={preloaders}
      user={user}
      keycloak={keycloak}
    />
  );
};

export default Login;
