import React from "react";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import { Link } from "react-router-dom";

import { ReactComponent as IconCheck } from "../../../../media/icons/check-green.svg";
import styles from "../../pricing.module.css";

const PricingPlanMonth = ({ active }) => {
  const { t } = useTranslation();

  return (
    <div className={cn(styles.pricingItem, styles.pricingItemBlue)}>
      <h2 className={styles.pricingTitle}>{t("dashboard_pricing_sponsor_title")}</h2>
      <span className={styles.pricingType}>{t("dashboard_pricing_listing")}</span>
      <span className={styles.pricingPrice}>${active ? "20" : "25"}</span>
      <span className={styles.pricingPeriod}>{t("dashboard_pricing_sponsor_subtitle")}</span>

      <div className={styles.pricingIncludes}>
        <span className={styles.pricingSubtitle}>Everything in free, plus:</span>
        <p className={styles.pricingIncludesItem}>
          <IconCheck />
          {t("dashboard_pricing_sponsor_benefits1_bullet1")}
        </p>
        <p className={styles.pricingIncludesItem}>
          <IconCheck />
          {t("dashboard_pricing_sponsor_benefits1_bullet2")}
        </p>
        <p className={styles.pricingIncludesItem}>
          <IconCheck />
          {t("dashboard_pricing_sponsor_benefits1_bullet3")}
        </p>
        <p className={styles.pricingIncludesItem}>
          <IconCheck />
          {t("dashboard_pricing_sponsor_benefits1_bullet4")}
        </p>
      </div>
      <div className={styles.pricingButtonContent}>
        <Link to="/login" className={styles.pricingButton}>
          Become a Member
        </Link>
      </div>
    </div>
  );
};

export default PricingPlanMonth;
