import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { NotificationError } from "../common/notification";
import Owner from "./components/owner";
import styles from "./teams.module.css";
import { actions as teamsActions } from "../../actions/teams";
import { OverlayPreloader } from "../common/preloader";
import Modals from "./components/modals";
import { modalsName } from "../../constants/teams";
import { useDocTitle } from "../../helpers/hooks";
import Tabs from "../account/tabs";
import Content from "./components/content";
import { Button } from "../common/button";
import { ReactComponent as PlusIcon } from "../../media/icons/plus.svg";

const Teams = () => {
  const { owner, preloaders, modals, activeAccess, error } = useSelector(state => state.teams);
  const dispatch = useDispatch();
  const getTeams = useCallback(() => dispatch(teamsActions.getTeams()), [dispatch]);
  const toggleModal = useCallback((modal, isActive) => dispatch(teamsActions.toggleModal(modal, isActive)), [dispatch]);
  const clearAllModals = useCallback(() => dispatch(teamsActions.clearAllModals()), [dispatch]);
  const handleActiveAccess = useCallback(access => dispatch(teamsActions.handleActiveAccess(access)), [dispatch]);
  const inviteAccess = useCallback(data => dispatch(teamsActions.inviteAccess(data)), [dispatch]);
  const acceptAccess = useCallback(data => dispatch(teamsActions.acceptAccess(data)), [dispatch]);
  const updateAccess = useCallback(data => dispatch(teamsActions.updateAccess(data)), [dispatch]);
  const removeAccess = useCallback(data => dispatch(teamsActions.removeAccess(data)), [dispatch]);
  const rejectAccess = useCallback(data => dispatch(teamsActions.rejectAccess(data)), [dispatch]);
  const clearError = useCallback(() => dispatch(teamsActions.clearError()), [dispatch]);
  const { t } = useTranslation();
  const {
    globalInfo: { email_verified: isEmailVerified }
  } = useSelector(state => state.main);
  useDocTitle(t("dashboard_teams_metatitle"));

  const handleOpenModal = modalName => {
    toggleModal(modalName, true);
  };

  const handleOpenAccessModal = (modalName, access) => {
    toggleModal(modalName, true);

    handleActiveAccess(access);
  };

  const handleCloseModal = modalName => {
    toggleModal(modalName, false);
  };

  useEffect(() => {
    if (getTeams) {
      getTeams();
    }
  }, []);

  const handleOpen = () => {
    handleOpenModal(modalsName.invite);
  };

  return (
    <>
      <div className={styles.container}>
        {preloaders.page && <OverlayPreloader overlayClassName="sectionOverlayModal" />}
        {error.isShow && <NotificationError text={error.text} onClose={clearError} />}

        <div className={styles.flex}>
          <Content title={t("dashboard_teams_myteam_title")} />

          <Button className={styles.button} onClick={handleOpen} disabled={!isEmailVerified}>
            <PlusIcon /> {t("dashboard_teams_invitebutton")}
          </Button>
        </div>

        <Tabs />

        <Owner
          onOpenModal={handleOpenModal}
          onCloseModal={handleCloseModal}
          owner={owner}
          handleAccess={access => handleOpenAccessModal(modalsName.updateInvite, access)}
          handleRemove={access => handleOpenAccessModal(modalsName.remove, access)}
          handleAccept={accept => handleOpenAccessModal(modalsName.accept, accept)}
          isEmailVerified={isEmailVerified}
        />
      </div>

      <Modals
        modals={modals}
        inviteAccess={inviteAccess}
        preloaders={preloaders}
        clearAllModals={clearAllModals}
        handleCloseModal={handleCloseModal}
        acceptAccess={acceptAccess}
        activeAccess={activeAccess}
        handleOpenModal={handleOpenModal}
        rejectAccess={rejectAccess}
        removeAccess={removeAccess}
        updateAccess={updateAccess}
      />
    </>
  );
};

export default Teams;
