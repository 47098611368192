import React, { useState } from "react";
import { useSelector } from "react-redux";

import ModalControl from "../../../modalControl";
import TextArea from "../../../common/textarea";
import { Modal } from "../../../common/modal";
import { OverlayPreloader } from "../../../common/preloader";
import styles from "./message.module.css";

export const Message = ({ onClose, sendMatchMessage, leadId, clearAllModals, preloader }) => {
  const { messageModalName } = useSelector(state => state.referralRequestDetail);
  const [message, setMessage] = useState("");

  const handleChange = event => {
    setMessage(event.target.value);
  };

  const handleSend = () => {
    sendMatchMessage(leadId, message);
  };

  return (
    <Modal clearAllModals={clearAllModals} closeModal={onClose}>
      {preloader && <OverlayPreloader />}

      <h3 className={styles.title}>Message {messageModalName}</h3>
      <TextArea
        label={<label className={styles.label}>Your Message</label>}
        onChange={handleChange}
        required
        styleProps={{ wrapper: styles.textAreaContent }}
        value={message}
        className={styles.textArea}
      />
      <span className={styles.description}>
        Please note that this submission does not create an attorney-client communication. Attorney client relationship is formed only when
        attorney formally agrees to represent you. By sending this message you are giving permission to the attorney to contact you.
      </span>

      <ModalControl onConfirm={handleSend} isHideExit confirmDisabled={!message}>
        Send
      </ModalControl>
    </Modal>
  );
};
