import { teams } from "../constants/actionTypes";

const defaultModals = {
  invite: false,
  updateInvite: false,
  remove: false,
  reject: false,
  viewAccess: false,
  removeSuccess: false,
  rejectSuccess: false,
  inviteSuccess: false,
  updateInviteSuccess: false,
  access: false
};

const defaultState = {
  preloaders: {
    page: false,
    remove: false,
    accept: false,
    invite: false,
    reject: false,
    update: false,
    access: false
  },
  error: { isShow: false, text: "" },
  modals: {
    ...defaultModals
  },
  owner: [],
  member: [],
  activeAccess: {},
  currentTeam: { value: "" },
  isLoaded: false
};

export default function(state = defaultState, action) {
  const { payload, type } = action;
  switch (type) {
    case teams.SET_LOADING:
      return {
        ...state,
        preloaders: {
          ...state.preloaders,
          [payload.name]: payload.isLoading
        }
      };

    case teams.GET_TEAMS:
      return {
        ...state,
        preloaders: {
          ...state.preloaders,
          page: false
        },
        member: payload.member,
        owner: payload.owner,
        isLoaded: true
      };

    case teams.GET_TEAMS_MEMBER:
      return {
        ...state,
        preloaders: {
          ...state.preloaders,
          page: false
        },
        member: payload
      };

    case teams.TOGGLE_MODAL:
      return {
        ...state,
        modals: {
          ...state.modals,
          [payload.modal]: payload.isActive
        }
      };

    case teams.CLEAR_ALL_MODALS:
      return {
        ...state,
        modals: {
          ...defaultModals
        }
      };

    case teams.ACTIVE_ACCESS:
      return {
        ...state,
        activeAccess: payload
      };

    case teams.REMOVE_ACCESS:
      // eslint-disable-next-line no-case-declarations
      const updatedOwner = state.owner.filter(item => String(item.team_member_id) !== String(payload));

      return {
        ...state,
        preloaders: {
          ...defaultState.preloaders
        },
        modals: {
          ...state.modals,
          remove: false,
          removeSuccess: true
        },
        owner: updatedOwner
      };

    case teams.ACCEPT_ACCESS:
      return {
        ...state,
        preloaders: {
          ...state.preloaders,
          accept: false
        },
        modals: {
          ...state.modals,
          accept: false
        }
      };

    case teams.INVITE_ACCESS:
      return {
        ...state,
        preloaders: {
          ...state.preloaders,
          invite: false
        },
        modals: {
          ...state.modals,
          invite: false,
          inviteSuccess: true
        }
      };

    case teams.REJECT_ACCESS:
      // eslint-disable-next-line no-case-declarations
      const updatedMember = state.member.filter(item => String(item.team_member_id) !== String(payload));

      return {
        ...state,
        preloaders: {
          ...state.preloaders,
          reject: false
        },
        modals: {
          ...state.modals,
          reject: false,
          rejectSuccess: true
        },
        member: updatedMember
      };

    case teams.UPDATE_ACCESS:
      return {
        ...state,
        preloaders: {
          ...state.preloaders,
          update: false
        },
        modals: {
          ...defaultState.modals,
          updateInvite: false,
          updateInviteSuccess: true
        }
      };

    case teams.CLEAR_ERROR:
      return {
        ...state,
        error: {
          ...state.error,
          isShow: false,
          text: ""
        }
      };

    case teams.REMOVE_ACCESS_FAIL:
    case teams.ACCEPT_ACCESS_FAIL:
    case teams.INVITE_ACCESS_FAIL:
    case teams.REJECT_ACCESS_FAIL:
    case teams.GET_TEAMS_FAIL:
    case teams.UPDATE_ACCESS_FAIL:
      return {
        ...state,
        error: {
          ...state.error,
          isShow: true,
          text: payload
        },
        preloaders: {
          ...defaultState.preloaders
        }
      };

    case teams.SET_TEAM:
      return {
        ...state,
        currentTeam: payload
      };

    default:
      return {
        ...state
      };
  }
}
