import React, { useState, useEffect, useRef } from "react";

import styles from "./counter.module.css";

const Counter = ({ children, setShowTimer, showTimer }) => {
  const timer = 30;
  const [counter, setCounter] = useState(timer);
  const timeout = useRef();

  useEffect(() => {
    if (counter > 0) {
      timeout.current = setTimeout(() => setCounter(counter - 1), 1000);
    } else if (setShowTimer) {
      setShowTimer(false);

      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    }
  }, [counter]); //eslint-disable-line

  useEffect(() => {
    if (showTimer && counter <= 0 && setCounter) {
      setCounter(timer);
    }
  }, [showTimer]); //eslint-disable-line

  useEffect(() => {
    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, []);

  return (
    <div className={styles.counterContent}>
      {counter > 0 ? (
        <div className={styles.counterText}>
          Request a new code
          <span> {`- wait ${counter} second${counter > 1 ? "s" : ""}`}</span>
        </div>
      ) : (
        <>{children}</>
      )}
    </div>
  );
};

export default Counter;
