import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import getYear from "date-fns/getYear";

import LeadsBy from "./components/leadsBy";
// import PerformanceProfile from "./components/performanceProfile";
import PerformanceMembership from "./components/performanceMembership";
import PerformancePracticeArea from "./components/performancePracticeArea";
import { actions as performanceActions } from "../../actions/performance";
import styles from "./performance.module.css";
import { useDocTitle } from "../../helpers/hooks";
import { Notification } from "../common/notification";
import { makePerformanceYearOptions } from "../../helpers/select";
import SubTabs from "../payPerLead/subTabs";
import { actions as payPerLeadActions } from "../../actions/payPerLead";
import SelectTeam from "../selectTeam";

const PerformanceComponent = () => {
  const dispatch = useDispatch();
  const { error, leadsByWeek, leadsByMonth, leadsByYear, leadsByListing, leadsByPracticeArea, preloaders } = useSelector(
    state => state.performance
  );
  const { user } = useSelector(state => state.auth);
  const dateOfCreatedUser = user?.added;
  const getLeads = useCallback(
    ({ dir_id, page, practice_id, tab, sort, search, perPage, teamValue }) =>
      dispatch(payPerLeadActions.getLeads({ dir_id, page, practice_id, tab, sort, search, perPage, team: teamValue })),
    [dispatch]
  );
  const { tabsCounter } = useSelector(state => state.payPerLead);
  const getLeadsByMonth = useCallback(year => dispatch(performanceActions.getLeadsByMonth(year)), [dispatch]);
  const getLeadsByWeek = useCallback(week => dispatch(performanceActions.getLeadsByWeek(week)), [dispatch]);
  const getLeadsByYear = useCallback(year => dispatch(performanceActions.getLeadsByYear(year)), [dispatch]);
  const getLeadsByListing = useCallback(() => dispatch(performanceActions.getLeadsByListing()), [dispatch]);
  const getLeadsByPracticeArea = useCallback(() => dispatch(performanceActions.getLeadsByPracticeArea()), [dispatch]);

  useDocTitle("dashboard_performance_metatitle");

  const currentYear = getYear(new Date());
  const yearOptions = makePerformanceYearOptions(dateOfCreatedUser);
  const defaultYearOption = yearOptions.find(option => currentYear.toString() === option.value);

  const [selectYearByWeek, setSelectYearByWeek] = useState(defaultYearOption);
  const [selectYearByMonth, setSelectYearByMonth] = useState(defaultYearOption);
  const [team, setTeam] = useState({ value: "", label: "Unselect " });
  // const [selectYearByYear, setSelectYearByYear] = useState(defaultYearOption);

  useEffect(() => {
    getLeadsByWeek(selectYearByWeek.value);
  }, [selectYearByWeek.value]); //eslint-disable-line

  useEffect(() => {
    getLeadsByMonth(selectYearByMonth.value);
  }, [selectYearByMonth.value]); //eslint-disable-line

  useEffect(() => {
    getLeadsByYear();
    getLeads({ perPage: 10, teamValue: team, tab: "notpurchased" });
    getLeadsByListing();
    getLeadsByPracticeArea();
  }, []); //eslint-disable-line

  const { t } = useTranslation();

  return (
    <div className={styles.main}>
      <h1 className={styles.title}>Analytics</h1>

      <SelectTeam isShowText handleChange={setTeam} unselectText="My Referrals" />

      <div className={styles.tabs}>
        <SubTabs tabsCounter={tabsCounter} />
      </div>

      {error && <Notification error>{error}</Notification>}
      <LeadsBy
        divIdTooltip="leadsByMonthTooltip"
        divId="leadsByMonth"
        isLoading={preloaders.leadsByMonth}
        setSelectYear={setSelectYearByMonth}
        selectYear={selectYearByMonth}
        leads={leadsByMonth}
        title={t("dashboard_performance_leadsbymonth_chart_title")}
        xDimensionTitle="month"
        dateOfCreatedUser={dateOfCreatedUser}
      />
      <LeadsBy
        divId="leadsByWeek"
        divIdTooltip="leadsByWeekTooltip"
        isLoading={preloaders.leadsByWeek}
        leads={leadsByWeek}
        setSelectYear={setSelectYearByWeek}
        selectYear={selectYearByWeek}
        title={t("dashboard_performance_leadsbyweek_chart_title")}
        xDimensionTitle="day"
        dateOfCreatedUser={dateOfCreatedUser}
      />
      {/*
        [
        {
          month: "2021",
          calls: "14",
          clickcalls: "4",
          referrals: "3",
          messages: "2"
        },
        {
          month: "2022",
          calls: "14",
          clickcalls: "4",
          referrals: "6",
          messages: "5"
        }
        ]
      */}
      <LeadsBy
        isLoading={preloaders.leadsByYear}
        leads={leadsByYear}
        divId="leadsByYear"
        divIdTooltip="leadsByYearTooltip"
        title="Leads by Year"
        xDimensionTitle="year"
        isHideSelect
      />
      {/*
        [{
          title: "Goldman & Associates (Jewishlawyers.net)",
          leads: "14"
        },
        {
          title: "Goldman & Associates (Legalglobal.com)",
          leads: "2"
        }]
        */}
      <PerformanceMembership
        divId="data_viz3"
        divIdTooltip="data_viz3_tooltip"
        isLoading={preloaders.leadsByListing}
        leads={leadsByListing}
      />
      <PerformancePracticeArea
        divId="data_viz2"
        divIdTooltip="data_viz2_tooltip"
        isLoading={preloaders.leadsByPracticeArea}
        leads={leadsByPracticeArea}
      />
      {/*
        <PerformanceProfile leadsByMonth={12} />
     */}
    </div>
  );
};

export default PerformanceComponent;
