import React from "react";

import ProfessionItem from "../professionItem";
import styles from "./professions.module.css";
import { images } from "../../../constants/createProfession";

const Professions = ({ professions, currentProfession }) => {
  const professionsList = professions.map(item => {
    return <ProfessionItem link={item.url} key={item.name} name={item.name} Image={images[currentProfession]} color={item.color} />;
  });

  return <div className={styles.professions}>{professionsList}</div>;
};

export default Professions;
