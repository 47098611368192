export const FILTERS = {
  PRACTICES: "practices",
  CATEGORIES: "categories",
  PROFESSIONS: "professions",
  CATEGORIES_MY_ANSWER: "categoriesMyAnswers"
};

export const QUESTION_TYPES = {
  QUESTIONS: "questions",
  MY_ANSWER: "my-answers"
};

export const TABS = {
  MY_QUESTIONS: "my-questions",
  MY_ANSWER: "my-answers"
};

export const ACTIVE_FILTERS_DEFAULT_VALUE = {
  practices: [],
  categories: [],
  categoriesMyAnswers: [],
  professions: [],
  isFirstRender: true
};

export const NAVIGATIONS = [
  {
    text: "My Questions",
    param: "my-questions"
  },
  {
    text: "My Answers",
    param: "my-answers"
  }
];
