import React from "react";
import { NotificationLeadPage } from "../../components/notificationLeadComponent";

const dataPage = {
  metaTitle: "Lead Expired - More Than 60 Days Old",
  title: "Lead Expired",
  text: "This lead is more than 60 days old and not available for purchase anymore.",
  buttonText: "Login to Heritage Web Dashboard"
};

export const LeadExpired = () => {
  const { metaTitle, title, text, buttonText } = dataPage;

  return <NotificationLeadPage metaTitle={metaTitle} title={title} text={text} buttonText={buttonText} />;
};
