import React, { useCallback, useEffect, useState } from "react";
import cn from "classnames";
import { useHistory, NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import SelectInput from "../../common/selectInput";
import { actions as teamsActions } from "../../../actions/teams";
import styles from "./tabs.module.css";

const Tabs = ({ className }) => {
  const [filterType, setFilterType] = useState({ value: "", label: "" });
  const dispatch = useDispatch();
  const getTeams = useCallback(() => dispatch(teamsActions.getTeams()), [dispatch]);
  const { member, isLoaded } = useSelector(state => state.teams);

  const myAccount = { value: "/account", label: "My Account" };
  const teams = { value: "/teams", label: "My Team" };
  const access = { value: "/access", label: "My Access" };
  const payments = { value: "/payments", label: "Payments" };
  const notifications = { value: "/manage-notification", label: "Notifications" };
  const history = useHistory();

  const filterOptions = [
    myAccount,
    notifications,
    payments,
    teams,
    ...(member?.length || access?.value === history.location.pathname ? [access] : [])
  ];

  const onChangeFilter = value => {
    history.push(value.value);
  };

  useEffect(() => {
    if (filterType.value !== history.location.pathname) {
      const findListingTab = filterOptions.find(item => item.value === history.location.pathname);
      setFilterType(findListingTab);
    }
  }, [history.location.pathname]);

  useEffect(() => {
    if (getTeams && !isLoaded && access.value !== history.location.pathname && teams.value !== history.location.pathname) {
      getTeams();
    }
  }, []);

  return (
    <div className={cn(styles.filter, className)}>
      <div className={styles.filterMobile}>
        <span className={styles.label}>Filter by:</span>
        <SelectInput
          classNameWrap={styles.selectWrapper}
          className={styles.select}
          name="country"
          placeholder="Filter By"
          onChange={value => onChangeFilter(value)}
          options={filterOptions}
          value={filterType}
        />
      </div>

      <div className={styles.filterDesktop}>
        {filterOptions.map(item => {
          return (
            <NavLink to={item.value} key={item.value} activeClassName={styles.filterButtonActive} className={styles.filterButton}>
              {item.label}
            </NavLink>
          );
        })}
      </div>
    </div>
  );
};

export default Tabs;
