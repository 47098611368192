import { inbox, main } from "../constants/actionTypes";
import { sortMessagesByDate, optionsToArray, getMessageByType } from "../helpers";

const defaultState = {
  listingMessages: { read: [], unread: [], oldCount: null, newCount: null },
  filterOptions: [],
  listingMessagesError: null,
  preloaders: { getMessages: true, markAsRead: null, archive: null }
};

export default function(state = defaultState, action) {
  switch (action.type) {
    case inbox.SET_LOADING:
      return {
        ...state,
        preloaders: { ...state.preloaders, [action.payload.section]: action.payload.isSectionLoading }
      };

    case inbox.GET_MESSAGES:
      return {
        ...state,
        preloaders: { ...state.preloaders, getMessages: false },
        listingMessages: {
          ...action.payload.payloadMessage,
          unread: sortMessagesByDate(action.payload.payloadMessage.unread),
          read: sortMessagesByDate(action.payload.payloadMessage.read)
        },
        filterOptions: optionsToArray(action.payload.filterOptions)
      };

    case main.GET_UTILITY_LISTING_FAIL:
      return {
        ...state,
        preloaders: { ...state.preloaders, getMessages: false },
        listingMessagesError: action.payload
      };

    case inbox.CLEAR:
      return {
        ...state,
        listingMessages: { read: [], unread: [], oldCount: null, newCount: null }
      };

    case inbox.ARCHIVE:
      return {
        ...state
      };

    case inbox.MARK_AS_READ: {
      const { messageByType, newMessages } = getMessageByType(state.listingMessages.unread, action.payload);

      return {
        ...state,
        preloaders: { ...state.preloaders, markAsRead: false },
        listingMessages: {
          read: [...state.listingMessages.read, ...messageByType].sort((a, b) => new Date(b.sent) - new Date(a.sent)),
          unread: [...newMessages]
        }
      };
    }

    case inbox.MARK_AS_UNREAD: {
      const { messageByType, newMessages } = getMessageByType(state.listingMessages.read, action.payload);

      return {
        ...state,
        preloaders: { ...state.preloaders, markAsRead: false },
        listingMessages: {
          unread: [...state.listingMessages.unread, ...messageByType].sort((a, b) => new Date(b.sent) - new Date(a.sent)),
          read: [...newMessages]
        }
      };
    }

    default:
      return {
        ...state
      };
  }
}
