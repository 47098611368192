import React from "react";
import { useTranslation } from "react-i18next";

import Info from "../info";
import styles from "./owner.module.css";

const Owner = ({ owner, handleAccess, handleRemove, handleAccept, isEmailVerified }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.content}>
      <Info
        emptyText={t("dashboard_teams_myteam_desc")}
        list={owner}
        title
        isOwner
        buttonText="Update"
        handleRemove={handleRemove}
        handleAccess={handleAccess}
        handleAccept={handleAccept}
        isDisabledActions={!isEmailVerified}
      />
    </div>
  );
};

export default Owner;
