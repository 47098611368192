import React from "react";
import cn from "classnames";

import { RedStar } from "../redStar";
import styles from "./label.module.css";

const Label = ({ htmlFor, children, className, isRequired }) => {
  return (
    <label className={cn(styles.label, className)} htmlFor={htmlFor}>
      {children} {isRequired && <RedStar isActive />}
    </label>
  );
};

export default Label;
