import React from "react";
import { NavLink } from "react-router-dom";
import cn from "classnames";

import { ReactComponent as FacebookIcon } from "../../media/icons/footer/facebook.svg";
import { ReactComponent as LinkedinIcon } from "../../media/icons/footer/linkedin.svg";
import { ReactComponent as TwitterIcon } from "../../media/icons/footer/twitter.svg";
import { ReactComponent as GoogleIcon } from "../../media/icons/footer/google.svg";
import { ReactComponent as RSSIcon } from "../../media/icons/footer/rss.svg";
import instagramIcon from "../../media/icons/footer/instagram.svg";
import { ReactComponent as FooterLogo } from "../../media/icons/newFooterLogo.svg";
import styles from "./footer.module.css";
import MailerLiteForm from "../mailerLiteForm";
import { socialName } from "../../constants/main";

const Footer = ({ footerInfo, socialLinks }) => {
  const sliceFooter = () => {
    const midIndex = Math.ceil(footerInfo.length / 2);
    const firstHalfFooter = footerInfo.slice(0, midIndex);
    const secondHalfFooter = footerInfo.slice(midIndex);

    return [firstHalfFooter, secondHalfFooter];
  };

  const [firstHalfFooter, secondHalfFooter] = sliceFooter();

  const getFullYear = () => new Date().getFullYear();

  const renderFooter = footerLinks => {
    return footerLinks.map(item => (
      <React.Fragment key={`${item.url}-${item.title}}`}>
        {item.url.includes("https") || item.url.includes("www.") ? (
          // eslint-disable-next-line
          <a href={item.url} target="_blank" className={styles.navItem} rel="noopener noreferrer">
            {item.title}
          </a>
        ) : (
          // eslint-disable-next-line
          <NavLink href={item.url} to={item.url} className={styles.navItem}>
            {item.title}
          </NavLink>
        )}
      </React.Fragment>
    ));
  };

  const renderSocialsImage = type => {
    if (type === socialName.facebook || type === socialName.facebookGroup) {
      return <FacebookIcon />;
    }

    if (type === socialName.linkedin || type === socialName.linkedinGroup) {
      return <LinkedinIcon />;
    }

    if (type === socialName.twitter) {
      return <TwitterIcon />;
    }

    if (type === socialName.google) {
      return <GoogleIcon />;
    }
    if (type === socialName.rss) {
      return <RSSIcon />;
    }

    if (type === socialName.instagram) {
      return <img src={instagramIcon} alt="" />;
    }

    return <></>;
  };

  const renderSocials = () => {
    return (
      <>
        {socialLinks
          .filter(item => Boolean(item.social_url))
          .map(item => {
            return (
              <a key={item.social_name} className={styles.link} href={item.social_url} rel="noopener noreferrer" target="_blank">
                {renderSocialsImage(item.social_name)}
              </a>
            );
          })}
      </>
    );
  };

  return (
    <footer className={styles.footer}>
      <div className={styles.content}>
        <div className={styles.logoContent}>
          <div>
            <div>
              <FooterLogo className={styles.logo} />
              <h2 className={styles.footerDescription}>Stay connected to your communities</h2>
            </div>

            <div className={cn(styles.socials, styles.socialsTablet)}>{renderSocials()}</div>
          </div>

          <div className={cn(styles.form, styles.formTablet)}>
            <MailerLiteForm id="mailerLiteForm1" />
          </div>
        </div>

        <nav className={styles.nav}>
          <div className={styles.navContent}>
            <span className={styles.navItemHeading}>Heritage Web</span>
            {renderFooter(firstHalfFooter)}
          </div>
          <div className={styles.navContent}>
            <span className={styles.navItemHeading}>Find</span>
            {renderFooter(secondHalfFooter)}
          </div>
        </nav>
      </div>

      <div className={cn(styles.form, styles.formMobile)}>
        <MailerLiteForm id="mailerLiteForm2" />
      </div>
      <div className={styles.bottomContent}>
        <div className={cn(styles.socials, styles.socialsMobileAndDesktop)}>{renderSocials()}</div>
        <p className={styles.copy}>
          Copyright ©{" "}
          <a href="https://heritageweb.com/" target="_blank" rel="noopener noreferrer">
            Heritage Web LLC
          </a>{" "}
          2011-{`${getFullYear()}. `}
          <br />
          All Rights Reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
