import React from "react";
import { NavLink } from "react-router-dom";

import styles from "./tabs.module.css";

const Tabs = () => {
  return (
    <div className={styles.tabs}>
      <NavLink className={styles.tab} to="/" exact activeClassName={styles.tabsActive}>
        My Feed
      </NavLink>
      <NavLink className={styles.tab} to="/saved" exact activeClassName={styles.tabsActive}>
        Saved
      </NavLink>
    </div>
  );
};

export default Tabs;
