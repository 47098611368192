import React from "react";
import cn from "classnames";

import styles from "./input.module.css";

const Input = ({
  autoComplete,
  error,
  className,
  classNameError,
  classNameValid,
  classNameInvalid,
  classNameWrap,
  disabled,
  id,
  isInvalid,
  name,
  maxLength,
  onChange,
  onBlur,
  label,
  labelClass,
  placeholder,
  required,
  type = "text",
  value,
  icon,
  isAutoFocus,
  onClick,
  inputRef
}) => {
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <div className={classNameWrap}>
      {label && (
        <label className={cn(styles.label, labelClass)} htmlFor={id}>
          {label} {required && <span className={styles.asterisk}>*</span>}
        </label>
      )}
      <input
        autoComplete={autoComplete}
        className={cn(styles.input, className, { [styles.invalid]: isInvalid }, isInvalid ? classNameInvalid : classNameValid)}
        disabled={disabled}
        id={id}
        name={name}
        maxLength={maxLength}
        value={value}
        onBlur={onBlur}
        onChange={onChange}
        placeholder={placeholder}
        type={type}
        /* eslint-disable-next-line jsx-a11y/no-autofocus */
        autoFocus={isAutoFocus}
        ref={inputRef}
        onClick={handleClick}
      />
      {icon}
      {isInvalid && <div className={cn(styles.error, classNameError)}>{error}</div>}
    </div>
  );
};

export default Input;
