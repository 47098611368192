import React from "react";
import format from "date-fns/format";
import cn from "classnames";
import { intervalToDuration } from "date-fns";

import { ReactComponent as TicketIcon } from "../../../media/icons/ticket.svg";
import { ReactComponent as ClockIcon } from "../../../media/icons/clockThin.svg";
import { createImageDeliveryUrl, priceFormatterCurrency } from "../../../helpers";
// import testCompany from "../../../media/images/eventsTestBanner.jpg";
import { Button } from "../../common/button";
import Location from "../location";
import styles from "./eventContent.module.css";
import { ReactComponent as SaveIcon } from "../../../media/icons/heart.svg";
import { ReactComponent as ShareIcon } from "../../../media/icons/share.svg";
import { ReactComponent as FlashIcon } from "../../../media/icons/flash.svg";

const EventContent = ({ listing, isShowFavorites, removeFavorite, addToFavorite }) => {
  const {
    // event_audiences,
    event_tickets: eventTickets = [],
    event_schedules = [],
    description = "description",
    attorney = "attorney",
    lawfirms = [],
    listings_image_name: listingsImageName,
    cover_image_url: socialImage,
    practices,
    languages,
    bookmarked: isFavorite,
    listing_id
  } = listing;
  // const eventAudiences = event_audiences.length ? event_audiences[0] : {};
  const eventSchedules = event_schedules.length ? event_schedules[0] : {};
  const address = lawfirms.length ? lawfirms[0] : {};
  const duration =
    eventSchedules.start_date_time && eventSchedules.end_date_time
      ? intervalToDuration({ start: new Date(eventSchedules.start_date_time), end: new Date(eventSchedules.end_date_time) })
      : "";

  return (
    <div>
      {socialImage && (
        <div className={styles.banner}>
          <img src={createImageDeliveryUrl(socialImage)} alt="cover" />
        </div>
      )}

      <div className={styles.main}>
        <div className={cn(styles.event, socialImage ? styles.eventPosition : "")}>
          <span className={styles.eventLogo}>{listingsImageName && <img src={listingsImageName} alt="event" />}</span>
          <h3 className={styles.eventName}>{attorney}</h3>

          <div className={styles.eventHeading}>
            <p className={styles.eventCompanyName}>
              {eventSchedules.start_date_time && format(new Date(eventSchedules.start_date_time), "MMM do yyyy, h:mm a")} -{" "}
              {eventSchedules.end_date_time && format(new Date(eventSchedules.end_date_time), "MMM do yyyy, h:mm a")} (
              {eventSchedules.timezone})
            </p>
          </div>
        </div>

        <div className={styles.tags}>
          {duration.hours || duration.minutes ? (
            <div className={styles.tag}>
              <ClockIcon />
              Event duration
              <span>
                {duration.hours ? `${duration.hours}h ` : ""} {duration.minutes ? `${duration.minutes} min` : ""}
              </span>
            </div>
          ) : (
            ""
          )}

          {eventTickets.length ? (
            <>
              {eventTickets.map(item => {
                return (
                  <div className={styles.tag} key={`${item.name}-${item.price}`}>
                    <TicketIcon />
                    {item.name}:<span>{priceFormatterCurrency(item.currency).format(item.price)}</span>
                  </div>
                );
              })}
            </>
          ) : null}
        </div>

        <div className={styles.actions}>
          <Button className={styles.apply}>
            <FlashIcon />
            Buy ticket
          </Button>

          <div className={styles.favorites}>
            {isShowFavorites ? (
              <>
                {isFavorite ? (
                  <Button className={cn(styles.favoritesButton, styles.favoritesButtonActive)} onClick={() => removeFavorite(listing_id)}>
                    <span>Unsave</span>
                    <SaveIcon />
                  </Button>
                ) : (
                  <Button className={styles.favoritesButton} onClick={() => addToFavorite(listing_id)}>
                    <span>Save</span>
                    <SaveIcon />
                  </Button>
                )}
              </>
            ) : null}

            <Button className={styles.favoritesButton}>
              <span>Share</span>
              <ShareIcon />
            </Button>
          </div>
        </div>

        <div className={styles.content}>
          <h6 className={styles.descriptionTitle}>Event details</h6>
          <p className={styles.descriptionText}>{description}</p>
        </div>

        {practices.length ? (
          <div className={styles.content}>
            <h6 className={styles.descriptionTitle}>Community Affiliation</h6>

            <div className={styles.tags}>
              {practices.map(item => {
                return (
                  <span className={styles.tag} key={item._practice}>
                    {item._practice}
                  </span>
                );
              })}
            </div>
          </div>
        ) : null}

        {languages.length ? (
          <div className={styles.content}>
            <h6 className={styles.descriptionTitle}>Community Affiliation</h6>

            <div className={styles.tags}>
              {languages.map(item => {
                return (
                  <span className={styles.tag} key={`${item.language_title}-${item.prkey}`}>
                    {item.language_title}
                  </span>
                );
              })}
            </div>
          </div>
        ) : null}
        <Location address={address} />
      </div>
    </div>
  );
};

export default EventContent;
