import React from "react";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import format from "date-fns/format";
import { useDispatch } from "react-redux";

import { ReactComponent as FavoriteIcon } from "../../../../media/icons/unsave.svg";
import locationIcon from "../../../../media/icons/location.svg";
import calendarIcon from "../../../../media/icons/calendar.svg";
import { actions as questionsActions } from "../../../../actions/questions";
import { Button } from "../../../common/button";
import styles from "./question.module.css";

const areEqual = (prevProps, nextProps) => {
  return (
    nextProps.question.question_id === prevProps.question.question_id &&
    nextProps.question.saved === prevProps.question.saved &&
    nextProps.practices.length === prevProps.practices.length &&
    nextProps.question.practice1 === prevProps.question.practice1 &&
    nextProps.question.practice2 === prevProps.question.practice2 &&
    nextProps.question.practice3 === prevProps.question.practice3
  );
};

export const Question = React.memo(({ question, practices }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const saveQuestion = questionId => dispatch(questionsActions.saveSingleQuestion(questionId));
  const unSaveQuestion = questionId => dispatch(questionsActions.unSaveSingleQuestion(questionId));

  const {
    added,
    city,
    name,
    practice1,
    practice2,
    practice3,
    saved,
    state,
    summary,
    title,
    website_url: website,
    question_id: questionId
  } = question;
  const practiseArea1 = practices.find(({ practice_id: id }) => id === practice1);
  const practiseArea2 = practices.find(({ practice_id: id }) => id === practice2);
  const practiseArea3 = practices.find(({ practice_id: id }) => id === practice3);

  return (
    <div className={styles.question}>
      <div className={styles.heading}>
        <p className={styles.name}>{name}</p>

        <div className={styles.buttonSave}>
          {+saved ? (
            <Button className={cn(styles.iconButtonFilled, "button--clear")} onClick={() => unSaveQuestion(questionId)}>
              <div className={styles.iconFilled} />
              <FavoriteIcon />
            </Button>
          ) : (
            <Button className={cn(styles.iconButton, "button--clear")} onClick={() => saveQuestion(questionId)}>
              <div className={styles.iconFill} />
              <FavoriteIcon />
            </Button>
          )}
        </div>
      </div>
      <div className={cn("row", styles.subHeading)}>
        <p className={cn(styles.company, "col-xl-4")}>{website && <>({website.match(/[a-zA-Z0-9\-.]+\.[a-zA-Z]{2,3}/)})</>}</p>
        <div className={cn(styles.cityWrap, "col-md-8")}>
          {city && (
            <div className={styles.city}>
              <img className={styles.icon} src={locationIcon} alt="" />
              {city}, {state}
            </div>
          )}
          {added && (
            <div className={styles.time}>
              <img className={styles.icon} src={calendarIcon} alt="" />
              {format(new Date(added.split(" ").join("T")), "MMM do yyyy, h:mm a")}
            </div>
          )}
        </div>
      </div>
      <div className={cn(styles.line, styles.mb25)} />
      {title && <div className={styles.title}>{title}</div>}
      {summary && <div className={styles.text}>{summary}</div>}
      <div className={styles.line} />
      <div className={styles.subInfo}>
        {city && (
          <div className={styles.city}>
            {city}, {state}
          </div>
        )}
        {added && <div className={styles.time}> {format(new Date(added.split(" ").join("T")), "MMM do yyyy, h:mm a")}</div>}
      </div>
      {(practiseArea1 || practiseArea2 || practiseArea3) && (
        <>
          <div className={cn(styles.line, styles.mb25)} />
          <div className={styles.control}>
            <p className={styles.areas}>{t("dashboard_common_practiceareas")}</p>
            {practiseArea1 && <p className={styles.business}>{practiseArea1.title}</p>}
            {practiseArea2 && <p className={styles.business}>{practiseArea2.title}</p>}
            {practiseArea3 && <p className={styles.business}>{practiseArea3.title}</p>}
          </div>
        </>
      )}
    </div>
  );
}, areEqual);
