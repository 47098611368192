import React from "react";
import { useTranslation } from "react-i18next";

import { Value } from "../value";
import styles from "./practices.module.css";
import { Label } from "../label";

export const Practices = ({ practicesTitle }) => {
  const { t } = useTranslation();

  return (
    <>
      <Label>{t("dashboard_single_lead_practices")}</Label>

      <div className={styles.practices}>
        {practicesTitle.map(item => {
          return <Value key={item}>{item}</Value>;
        })}
      </div>
    </>
  );
};
