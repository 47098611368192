import React from "react";

import Questions from "../questions";
import styles from "./followUpQuestions.module.css";

const QuestionsForm = ({ leadId }) => {
  return (
    <div className={styles.content}>
      <Questions leadId={leadId} />
    </div>
  );
};

export default QuestionsForm;
