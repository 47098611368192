import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Modal } from "../common/modal";
import { actions as referralRequestsActions } from "../../actions/referralRequests";
import { modals } from "../../constants/referralRequests";
import { OverlayPreloader } from "../common/preloader";
import ModalControl from "../modalControl";
import styles from "./importing.module.css";

export const Importing = () => {
  const { t } = useTranslation();
  const { importReady = {}, preloaders } = useSelector(state => state.referralRequests);

  const { requests, messages, questions } = importReady;
  const dispatch = useDispatch();
  const clearAllModals = useCallback(() => dispatch(referralRequestsActions.clearAllModals()), [dispatch]);
  const toggleActivityOfModal = useCallback(
    (modal, isActive) =>
      dispatch(
        referralRequestsActions.toggleActivityOfModal({
          modal,
          isActive
        })
      ),
    [dispatch]
  );

  const getImport = useCallback(() => dispatch(referralRequestsActions.getImport()), [dispatch]);

  return (
    <Modal clearAllModals={clearAllModals} closeModal={() => toggleActivityOfModal(modals.importing, false)} classNameMain={styles.modal}>
      <div className={styles.wrapper}>
        {preloaders.preloaderCaseImport && <OverlayPreloader />}
        <h3 className={styles.modalTitle}>{t("dashboard_referrals_import_top_title")}</h3>
        <h1 className={styles.title}>{t("dashboard_referrals_import_confirmation_h1")}</h1>
        <hr className={styles.hr} />
        <div className={styles.group}>
          <div className={styles.itemGroup}>
            <p className={styles.total}>{messages}</p>
            <p className={styles.subTitle}>{t("dashboard_referrals_import_confirmation_messages")}</p>
          </div>
          <div className={styles.itemGroup}>
            <p className={styles.total}>{requests}</p>
            <p className={styles.subTitle}>{t("dashboard_referrals_import_confirmation_referrals")}</p>
          </div>
          <div className={styles.itemGroup}>
            <p className={styles.total}>{questions}</p>
            <p className={styles.subTitle}>Question to import</p>
          </div>
        </div>
      </div>

      <ModalControl onConfirm={getImport} isHideExit>
        {t("dashboard_referrals_import_confirmation_import_button")}
      </ModalControl>
    </Modal>
  );
};
