import React from "react";
import { Link } from "react-router-dom";

import styles from "./sidebarTile.module.css";

export const SidebarTile = ({ title, text, linkName, linkUrl, img, isLink, isButton, onClick }) => {
  const renderButton = () => {
    if (isLink) {
      return (
        <a href={linkUrl} className={styles.tileLink} target="_blank" rel="noopener noreferrer">
          {linkName}
        </a>
      );
    }

    if (isButton) {
      return (
        <button type="button" className={styles.tileLink} onClick={onClick}>
          {linkName}
        </button>
      );
    }

    return (
      <Link to={linkUrl} className={styles.tileLink}>
        {linkName}
      </Link>
    );
  };

  return (
    <div className={styles.tile}>
      {img && (
        <div className={styles.imgContent}>
          <img src={img} alt="" />
        </div>
      )}
      <div className={styles.tileContent}>
        <h3 className={styles.tileTitle}>{title}</h3>
        <p className={styles.tileText}>{text}</p>
        {renderButton()}
      </div>
    </div>
  );
};
