import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import { Modal } from "../../../common/modal";
import { modals as modalsConstant } from "../../../../constants/payPerLeadDetail";
import { actions as paymentActions } from "../../../../actions/payment";
import { actions as payPerLeadDetailActions } from "../../../../actions/payPerLeadDetail";
import { apiKey } from "../../../../constants/stripe";
import EditCard from "../../../common/payment/editCard";

const stripePromise = loadStripe(apiKey);

export const EditCardComponent = () => {
  const dispatch = useDispatch();
  const toggleModal = useCallback((modal, isActive) => dispatch(payPerLeadDetailActions.toggleModal(modal, isActive)), [dispatch]);
  const clearAllModals = useCallback(() => dispatch(payPerLeadDetailActions.clearAllModals()), [dispatch]);
  const updateCard = useCallback(card => dispatch(paymentActions.updateCard(card)), [dispatch]);
  const { modals } = useSelector(state => state.payPerLeadDetail);
  const payment = useSelector(state => state.payment);
  const { countries } = useSelector(state => state.main);
  const { preloaders: paymentPreloaders, success: paymentSuccess, selectedCard } = payment;

  const closeModal = () => {
    toggleModal(modalsConstant.editCard, false);
  };

  if (!modals.editCard) {
    return null;
  }

  return (
    <Elements stripe={stripePromise}>
      <Modal className="zIndex4" closeModal={closeModal} clearAllModals={clearAllModals}>
        <EditCard
          closeModal={closeModal}
          countries={countries}
          currentCardDetails={selectedCard}
          paymentPreloaders={paymentPreloaders}
          paymentSuccess={paymentSuccess}
          updateCard={updateCard}
        />
      </Modal>
    </Elements>
  );
};
