import React from "react";
import cn from "classnames";
import { useTranslation } from "react-i18next";

import styles from "./productActions.module.css";

const ProductActions = ({
  isBottomPosition,
  isNotFilledAllFields,
  publishListing,
  setShowRequiredText,
  listingId,
  isDraft,
  showRequiredText,
  resetDraft,
  parentId
}) => {
  const bottomPositionClassName = isBottomPosition ? styles.productActionsBottom : "";
  const { t } = useTranslation();

  const renderPublishButton = () => {
    if (!isDraft) {
      return (
        <button type="button" className={styles.primary} disabled>
          {t("publishdraft")}
        </button>
      );
    }

    if (isNotFilledAllFields) {
      return (
        <button type="button" className={styles.primary} onClick={() => setShowRequiredText(true)}>
          {t("publishdraft")}
        </button>
      );
    }

    return (
      <button type="button" className={styles.primary} onClick={() => publishListing(listingId)}>
        {t("publishdraft")}
      </button>
    );
  };

  const handleRemoveDraft = () => {
    resetDraft(listingId);
  };

  return (
    <>
      <div className={cn(styles.productActions, bottomPositionClassName)}>
        {Boolean(parentId) && isDraft ? (
          <button type="button" className={styles.secondary} onClick={handleRemoveDraft}>
            {t("removedraft")}
          </button>
        ) : null}
        {renderPublishButton()}
      </div>
      {isNotFilledAllFields && showRequiredText && <p className={styles.validationError}>Please add required fields before submitting</p>}
    </>
  );
};

export default ProductActions;
