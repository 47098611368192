import React from "react";
import { useTranslation } from "react-i18next";

import { Success } from "../success";

export const DeleteSuccess = ({ closeModal, clearAllModals }) => {
  const { t } = useTranslation();

  return (
    <Success
      closeModal={closeModal}
      clearAllModals={clearAllModals}
      subTitle={t("dashboard_listings_table_delete")}
      title="Listing Deleted"
      message="You listing has been successfully deleted"
    />
  );
};
