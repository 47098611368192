import React, { useState } from "react";
import cn from "classnames";

import { useTranslation } from "react-i18next";
import { ReactComponent as Dots } from "../../../../media/icons/dots_vertical.svg";
import { ReactComponent as Notification } from "../../../../media/icons/notification.svg";
import { ReactComponent as ArchiveIcon } from "../../../../media/icons/delete.svg";
import styles from "./actions.module.css";

const Actions = ({ isArchived, activityArchive, handleUnread }) => {
  const [active, setActive] = useState(false);
  const { t } = useTranslation();

  const activeClass = active ? styles.moreActive : "";

  const toggleActive = () => {
    setActive(prev => !prev);
  };

  return (
    <div className={styles.main}>
      <button type="button" className={cn(styles.more, activeClass)} onClick={toggleActive}>
        <Dots />
      </button>

      {active && (
        <div className={styles.actions}>
          <button type="button" className={styles.actionsButton} onClick={handleUnread}>
            <Notification />
            {t("dashboard_clientview_inbox_unread")}
          </button>
          <button type="button" className={styles.actionsButton} onClick={activityArchive}>
            <ArchiveIcon />
            {isArchived ? "Un archive" : "Archive"}
          </button>
        </div>
      )}
    </div>
  );
};

export default Actions;
