import { searchResult } from "../constants/actionTypes";

const defaultState = {
  preloaders: {
    page: false
  },
  articles: [],
  events: [],
  freeListings: [],
  jobs: [],
  questions: [],
  sponsorListings: [],
  featuredPractices: []
};

export default function(state = defaultState, action) {
  const { type, payload } = action;
  switch (type) {
    case searchResult.SET_LOADING:
      return {
        ...state,
        preloaders: {
          ...state.preloaders,
          [payload.section]: payload.isLoading
        }
      };

    case searchResult.GET_SEARCH_RESULT:
      return {
        ...state,
        preloaders: { ...state.preloaders, page: false },
        articles: payload.articles,
        events: payload.events,
        freeListings: payload.free,
        jobs: payload.jobs,
        questions: payload.questions,
        sponsorListings: payload.sponsor,
        featuredPractices: payload.featuredPractices
      };

    case searchResult.GET_SEARCH_RESULT_FAIL:
      return {
        ...state,
        preloaders: { ...state.preloaders, page: false },
        articles: [],
        events: [],
        freeListings: [],
        jobs: [],
        questions: [],
        sponsorListings: [],
        featuredPractices: []
      };

    default:
      return {
        ...state
      };
  }
}
