import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import format from "date-fns/format";
import { useFormik } from "formik";

import { OverlayPreloader } from "../../../common/preloader";
import { Modal } from "../../../common/modal";
import { normalizeFollowupQuestionOptions } from "../../../../helpers";
import { actions as referralRequestDetailActions } from "../../../../actions/referralRequestDetail";
import { RenderQuestions } from "../../components/renderQuestions";
import styles from "./questions.module.css";
import ModalControl from "../../../modalControl";

export const Questions = ({ clearAllModals, onClose, leadId }) => {
  const { followups: questions, preloader } = useSelector(state => state.referralRequestDetail);

  const noAnsweredQuestion = questions.filter(item => item.answer.length === 0 || (item.answer.length === 1 && item.answer[0] === ""));
  const dispatch = useDispatch();
  const updateFollowupQuestions = useCallback(answers => dispatch(referralRequestDetailActions.updateFollowupQuestions(answers)), [
    dispatch
  ]);

  const formAnswers = questionList => {
    return questionList.map(question => {
      let label = "Select";
      const answerOptions = normalizeFollowupQuestionOptions(question);
      const date = new Date();

      const answerDate = question.answer_type === "date" && question.answer ? new Date(question.answer) : new Date();

      if (question.answer) {
        label = answerOptions.find(({ answer }) => answer === question.answer)?.label || "Select";
      }

      switch (question.answer_type) {
        case "checkboxes":
        case "select": {
          return {
            lead_followup_id: question.lead_followup_id,
            answer: Array.isArray(question.answer) ? question.answer[0] : question.answer || "",
            label: Array.isArray(question.answer) ? question.answer[0] : label,
            answer_options: { options: answerOptions }
          };
        }

        case "date": {
          return {
            lead_followup_id: question.lead_followup_id,
            answer: answerDate || date
          };
        }

        case "text": {
          return {
            lead_followup_id: question.lead_followup_id,
            answer: Array.isArray(question.answer) && question.answer?.length ? question.answer[0] : ""
          };
        }

        default: {
          return {
            lead_followup_id: question.lead_followup_id,
            answer: question.answer || ""
          };
        }
      }
    });
  };

  const validate = values => {
    const errors = {};
    const requiredText = "Required";

    values.forEach((answer, index) => {
      const question = noAnsweredQuestion[index];

      if (!!question?.is_required && !answer?.answer) {
        errors[index] = requiredText;
      }
    });

    return errors;
  };

  const formik = useFormik({
    initialValues: formAnswers(questions),
    validate,
    onSubmit: values => {
      const valuesToSend = values.map(({ answer, lead_followup_id }) => {
        if (answer instanceof Date) {
          return {
            answer: answer && format(answer, "yyyy-MM-dd"),
            lead_followup_id: parseInt(lead_followup_id, 10)
          };
        }
        return { answer, lead_followup_id: parseInt(lead_followup_id, 10) };
      });

      const normalizeAnswers = valuesToSend
        .map(item => ({ [item.lead_followup_id]: item.answer }))
        .reduce((total, current) => {
          return { ...total, ...current };
        }, {});

      updateFollowupQuestions({ lead_id: leadId, followups: normalizeAnswers });
    }
  });

  const { errors, touched, values } = formik;

  const handleBlur = fieldName => {
    formik.setFieldTouched(fieldName, true);
  };

  const normalizeQuestions = questions.map(item => {
    return {
      ...item,
      answer_options: typeof item.answer_options === "string" ? JSON.parse(item.answer_options) : item.answer_options
    };
  });

  return (
    <Modal clearAllModals={clearAllModals} closeModal={onClose}>
      {preloader && <OverlayPreloader overlayClassName="sectionOverlayModal" />}
      <div className={styles.modalWrp}>
        <h2 className={styles.title}>Follow up Questions</h2>
        <hr className={styles.hr} />
        <RenderQuestions
          handleBlur={handleBlur}
          formik={formik}
          questions={normalizeQuestions}
          errors={errors}
          touched={touched}
          values={values}
        />

        <ModalControl onExit={onClose} onConfirm={formik.handleSubmit} isClose>
          Save
        </ModalControl>
      </div>
    </Modal>
  );
};
