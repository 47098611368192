import React, { useEffect, useRef, useState } from "react";
import cn from "classnames";
import { useTranslation } from "react-i18next";

import Dropdown from "../dropdown";
import styles from "./bio.module.css";
import { useValidationError } from "../../../helpers/hooks";

const Bio = ({ showRequiredText, validation, description, submitChanges, isJobListing, isEvents }) => {
  if (Object.keys(validation).length && !validation.enabled) {
    return null;
  }

  const [isMouseInBlock, setIsMouseInBlock] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const [isTouched, setIsTouched] = useState(false);

  const { t } = useTranslation();
  const [value, setValue] = useState("");
  const isRequired = validation.required;
  const isEmptyDescription = !description.length;
  const isRequiredText = validation.required && isEmptyDescription && showRequiredText;
  const title = isJobListing ? "Job Description" : `${validation?.custom_name || "Bio"}`;
  const errors = validate(value);
  const containerRef = useRef();

  const handleSubmit = () => {
    submitChanges({ description: value }, "description", "save_description");
  };

  useValidationError(Object.values(errors).some(item => Boolean(item)) ? { bioHasLinks: "Error" } : "isRemove", "bioHasLinks");

  const handleChange = e => {
    setValue(e.target.value);

    if (!isEdited) {
      setIsEdited(true);
    }
  };

  useEffect(() => {
    if (description) {
      setValue(description);
    }
  }, []);

  const handleTouchStart = () => {
    if (!isTouched) {
      setIsTouched(true);
    }
  };

  const handleMouseMove = () => {
    if (!isMouseInBlock) {
      setIsMouseInBlock(true);
    }
  };

  const handleMouseLeave = () => {
    if (isMouseInBlock && !errors.bioHasLinks) {
      setIsMouseInBlock(false);
      if (isEdited) {
        handleSubmit();
        setIsEdited(false);
      }
    }
  };

  const renderPlaceholder = () => {
    if (isEvents) {
      return "Enter the event description here";
    }

    if (isJobListing) {
      return "Enter the job description here";
    }

    return "Any details about your experience, occupation etc.";
  };

  useEffect(() => {
    const handleTouch = event => {
      if (containerRef.current && !containerRef.current.contains(event.target) && !errors.bioHasLinks) {
        if (isTouched) {
          setIsTouched(false);

          if (isEdited) {
            handleSubmit();
            setIsEdited(false);
          }
        }
      }
    };

    document.addEventListener("touchstart", handleTouch, true);

    return () => {
      document.removeEventListener("touchstart", handleTouch, true);
    };
  }, [isEdited]);

  return (
    <div ref={containerRef}>
      <Dropdown
        description={validation.custom_description}
        isRequired={isRequired}
        isRequiredText={isRequiredText}
        title={title}
        handleMouseMove={handleMouseMove}
        handleMouseLeave={handleMouseLeave}
        handleTouchStart={handleTouchStart}
      >
        <textarea
          name="bio"
          id="bio"
          placeholder={renderPlaceholder()}
          className={cn(styles.textarea, errors.bioHasLinks ? styles.textareaError : "")}
          value={value}
          onChange={handleChange}
        />

        <span className={cn(styles.info, errors.bioHasLinks ? styles.infoActive : "")}>
          {t("dashboard_single_listing_popup_biography_html")}
        </span>
      </Dropdown>
    </div>
  );
};

const validate = values => {
  const errors = {};

  // eslint-disable-next-line max-len
  const URLRegex = /(([a-z]+:\/\/)?(([a-z0-9-]+\.)+([a-z]{2}|aero|arpa|biz|com|coop|edu|gov|info|int|jobs|mil|museum|name|nato|net|org|pro|travel|local|internal))(:[0-9]{1,5})?(\/[a-z0-9_\-.~]+)*(\/([a-z0-9_\-.]*)(\?[a-z0-9+_\-.%=&amp;]*)?)?(#[a-zA-Z0-9!$&'()*+.=-_~:@'/?]*)?)(\s+|$)/;
  if (values.match(URLRegex)) {
    errors.bioHasLinks = true;
  }

  return errors;
};
export default Bio;
