import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import PaymentHistory from "./paymentHistory";
import PaymentMethods from "./paymentMethods";
import { actions as paymentActions } from "../../actions/payment";
import styles from "./account.module.css";
import { OverlayPreloader } from "../common/preloader";
import SelectTeam from "../selectTeam";
import Tabs from "../account/tabs";

const Payments = ({ countries, keycloak }) => {
  const dispatch = useDispatch();
  const addCard = useCallback((cardData, isFirst) => dispatch(paymentActions.addCard(cardData, isFirst)), [dispatch]);
  const clearTokenError = useCallback(() => dispatch(paymentActions.clearTokenError()), [dispatch]);
  const deleteCard = useCallback(cardToDelete => dispatch(paymentActions.deleteCard(cardToDelete)), [dispatch]);
  const getPaymentMethods = (page, perPage, team) => dispatch(paymentActions.getPaymentMethods(page, perPage, team));
  const removeAddCardSuccess = () => dispatch(paymentActions.removeAddCardSuccess());
  const getPaymentHistory = (page, perPage, team) => dispatch(paymentActions.getPaymentHistory(page, perPage, team));
  const getPdfFile = url => dispatch(paymentActions.getPdfFile(url));
  const selectCard = useCallback(card => dispatch(paymentActions.selectCard(card)), [dispatch]);
  const setErrorToken = useCallback(err => dispatch(paymentActions.setErrorToken(err)), [dispatch]);
  const updateCard = useCallback(card => dispatch(paymentActions.updateCard(card)), [dispatch]);
  const clearSuccess = useCallback(() => dispatch(paymentActions.clearSuccess()), [dispatch]);
  const assignCardToSubscription = useCallback(data => dispatch(paymentActions.assignCardToSubscription(data)), [dispatch]);
  const { payment } = useSelector(state => state);
  const [team, setTeam] = useState({ value: "", label: "Unselect " });

  const {
    defaultSource,
    selectedCard,
    paymentMethods,
    subscriptionPagination,
    subscriptions,
    expiredSubscriptions,
    preloaders: paymentPreloaders,
    success: paymentSuccess,
    paymentHistory,
    paymentHistoryPagination,
    paymentError,
    error: errorToken
  } = payment;

  const handleGetPaymentMethodsTeam = currentTeam => {
    getPaymentMethods(0, 20, currentTeam);
    getPaymentHistory(0, 20, currentTeam);
    setTeam(currentTeam);
  };

  return (
    <div className={styles.wrapper}>
      <h1 className={styles.mainTitle}>Payments</h1>
      {paymentPreloaders.paymentHistory && <OverlayPreloader />}

      <div className={styles.flex}>
        <Tabs className={styles.tabs} />

        <SelectTeam
          className={styles.team}
          text="View payments of"
          isShowText
          handleChange={handleGetPaymentMethodsTeam}
          unselectText="My Payments"
        />
      </div>

      <PaymentMethods
        addCard={addCard}
        countries={countries}
        clearTokenError={clearTokenError}
        deleteCard={deleteCard}
        defaultSource={defaultSource}
        errorToken={errorToken}
        paymentPreloaders={paymentPreloaders}
        paymentSuccess={paymentSuccess}
        paymentMethods={paymentMethods}
        subscriptions={subscriptions}
        expiredSubscriptions={expiredSubscriptions}
        subscriptionPagination={subscriptionPagination}
        paymentError={paymentError?.error}
        selectedCard={selectedCard}
        selectCard={selectCard}
        setErrorToken={setErrorToken}
        updateCard={updateCard}
        getPaymentMethods={getPaymentMethods}
        assignCardToSubscription={assignCardToSubscription}
        removeAddCardSuccess={removeAddCardSuccess}
        clearSuccess={clearSuccess}
      >
        <PaymentHistory
          paymentHistory={paymentHistory}
          getPaymentHistory={getPaymentHistory}
          pagination={paymentHistoryPagination}
          preloaders={paymentPreloaders}
          team={team}
          setTeam={setTeam}
          keycloak={keycloak}
          getPdfFile={getPdfFile}
        />
      </PaymentMethods>
    </div>
  );
};

export default Payments;
