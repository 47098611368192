import React from "react";
import cn from "classnames";

import { ReactComponent as LocationIcon } from "../../../media/icons/location.svg";
import { ReactComponent as DeskIcon } from "../../../media/icons/desk.svg";
import { Button } from "../../common/button";
import Company from "../company";
import styles from "./jobContent.module.css";
// import { priceFormatter } from "../../../helpers";
import { ReactComponent as SaveIcon } from "../../../media/icons/heart.svg";
import { ReactComponent as ShareIcon } from "../../../media/icons/share.svg";

const workType = {
  1: "In-Person",
  2: "Hybrid",
  3: "Remote"
};

const jobType = {
  1: "Full Time",
  2: "Part Time",
  3: "Contract",
  4: "Temporary",
  5: "Volunteer",
  6: "Internship"
};

const JobContent = ({ listing, isShowFavorites, removeFavorite, addToFavorite }) => {
  const {
    description = "description",
    attorney = "name",
    jobs = [],
    practices = [],
    tags = [],
    listings_image_name: listingsImageName,
    lawfirms = [],
    languages,
    bookmarked: isFavorite,
    listing_id
  } = listing;
  const currentJob = jobs?.length ? jobs[0] : {};
  const workplaces = currentJob.workplace_type_id ? JSON.parse(currentJob.workplace_type_id) : [];
  const jobTypes = currentJob.type_id ? JSON.parse(currentJob.type_id) : [];
  const address = lawfirms?.length ? lawfirms[0] : {};

  return (
    <>
      <div>
        <div className={styles.main}>
          <div className={styles.job}>
            <span className={styles.jobLogo}>{listingsImageName ? <img src={listingsImageName} alt="Job" /> : null}</span>
            <h3 className={styles.jobName}>{attorney}</h3>

            <div className={styles.jobHeading}>
              <h6 className={styles.jobCompanyName}>_Spotify</h6>
              <span className={styles.jobPoint} />
              <p className={styles.jobLocation}>
                <LocationIcon />
                {address.country || "__USA"}
              </p>
              <span className={styles.jobPoint} />
              <p className={styles.jobCompanyName}>_Posted 2 hours ago</p>
            </div>
            <div className={styles.jobActions}>
              <Button className={styles.jobApply}>
                <DeskIcon />
                Apply now
              </Button>

              <div className={styles.favorites}>
                {isShowFavorites ? (
                  <>
                    {isFavorite ? (
                      <Button
                        className={cn(styles.favoritesButton, styles.favoritesButtonActive)}
                        onClick={() => removeFavorite(listing_id)}
                      >
                        <span>Unsave</span>
                        <SaveIcon />
                      </Button>
                    ) : (
                      <Button className={styles.favoritesButton} onClick={() => addToFavorite(listing_id)}>
                        <span>Save</span>
                        <SaveIcon />
                      </Button>
                    )}
                  </>
                ) : null}
                <Button className={styles.favoritesButton}>
                  <span>Share</span>
                  <ShareIcon />
                </Button>
              </div>
            </div>
          </div>
          <div className={styles.info}>
            <div className={styles.tags}>
              {workplaces?.length ? (
                <>
                  {workplaces.map(item => (
                    <>
                      <span className={styles.tagsItem}>{workType[item]}</span>
                    </>
                  ))}
                </>
              ) : (
                <>
                  <span className={styles.tagsItem}>tag</span>
                </>
              )}

              {jobTypes?.length ? (
                <>
                  {jobTypes.map(item => (
                    <>
                      <span className={styles.tagsItem}>{jobType[item]}</span>
                    </>
                  ))}
                </>
              ) : (
                <>
                  <span className={styles.tagsItem}>workspace</span>
                </>
              )}

              {currentJob.compensation ? <span className={styles.tagsItem}>{currentJob.compensation}</span> : null}
            </div>
          </div>
          {description && (
            <div className={styles.description}>
              <h6 className={styles.descriptionTitle}>Job Description</h6>
              <p className={styles.descriptionText}>{description}</p>
            </div>
          )}

          <div className={styles.industry}>
            <h6 className={styles.industryTitle}>Industry</h6>
            <div className={styles.tags}>
              {practices.length ? (
                <>
                  {practices.map((item, index) => {
                    return (
                      <>
                        {/* eslint-disable-next-line */}
                        <span key={`${item.practice_id}-${index}`} className={styles.tagsItem}>
                          {item._practice}
                        </span>
                      </>
                    );
                  })}
                </>
              ) : (
                <span className={styles.tagsItem}>tag Industry</span>
              )}
            </div>
          </div>

          <div className={styles.skills}>
            <h6 className={styles.skillsTitle}>Skills</h6>
            <div className={styles.tags}>
              {tags.length ? (
                <>
                  {tags.map(item => {
                    return (
                      <span key={item.tag} className={styles.tagsItem}>
                        {item.tag}
                      </span>
                    );
                  })}
                </>
              ) : (
                <span className={styles.tagsItem}>tag Skills</span>
              )}
            </div>
          </div>

          <div className={styles.industry}>
            <h6 className={styles.skillsTitle}>Languages</h6>
            <div className={styles.tags}>
              {languages.length ? (
                <>
                  {languages.map(item => {
                    return (
                      <span key={item.language_title} className={styles.tagsItem}>
                        {item.language_title}
                      </span>
                    );
                  })}
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <Company currentJob={currentJob} />
    </>
  );
};

export default JobContent;
