import React from "react";
import { useTranslation } from "react-i18next";

import { Label } from "../label";
import { Value } from "../value";
import { Hr } from "../hr";
import styles from "./description.module.css";

const areEqual = (prevProps, nextProps) => {
  return nextProps.summary === prevProps.summary;
};

export const Description = React.memo(({ summary }) => {
  const { t } = useTranslation();

  return (
    <div>
      <Label>{t("dashboard_single_lead_description")}</Label>
      <Value className={styles.description}>{summary}</Value>
      <Hr />
    </div>
  );
}, areEqual);
