import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { actions as signUpActions } from "../../actions/signup";
import RegistrationComponent from "../../components/registration";

const RegistrationContainer = () => {
  const dispatch = useDispatch();

  const signUp = useCallback(data => dispatch(signUpActions.signUp(data, "Dashboard Default Page")), [dispatch]);
  const { error, success, loading } = useSelector(state => state.signUp);
  const history = useHistory();

  return <RegistrationComponent error={error} signUp={signUp} success={success} loading={loading} history={history} />;
};

export default RegistrationContainer;
