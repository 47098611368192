import React from "react";
import { useTranslation } from "react-i18next";

import selectIcon from "../../../../media/icons/dropdown-arrow.svg";
import styles from "./select.module.css";

export const Select = ({ onChangeSelect }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.select}>
      <select className={styles.answersFilter} onChange={onChangeSelect}>
        <option value={false}>
          {t("dashboard_qa_all_filterby")}: {t("dashboard_qa_single_latest")}
        </option>
        <option value>
          {t("dashboard_qa_all_filterby")}: {t("dashboard_qa_single_newest")}
        </option>
      </select>
      <img className={styles.selectIcon} src={selectIcon} alt="" />
    </div>
  );
};
