import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Coupon } from "../coupon";
import { actions as payPerLeadDetailActions } from "../../../actions/payPerLeadDetail";
import styles from "./coupon.module.css";

export const CouponCode = ({ leadId, handleClickNext, price }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [couponText, setCouponText] = useState("");

  const checkCoupon = useCallback(coupon => dispatch(payPerLeadDetailActions.checkCoupon(coupon)), [dispatch]);

  const {
    coupon: { code, price: updatedPrice, couponError }
  } = useSelector(state => state.payPerLeadDetail);

  const handleCheckCoupon = () => {
    checkCoupon({ leadId, couponCode: couponText });
  };

  return (
    <Coupon
      checkCoupon={handleCheckCoupon}
      className={styles.couponContent}
      coupon={couponText}
      setCoupon={setCouponText}
      handleClickNext={handleClickNext}
      updatedPrice={updatedPrice}
      code={code}
      price={price}
    >
      {couponError && <p className={styles.couponError}>{t("dashboard_common_payments_couponerror")}</p>}

      {code && (
        <div className={styles.coupon}>
          <p className={styles.text}>
            Coupon code <span className={styles.code}>{code}</span> has been applied
          </p>
        </div>
      )}
    </Coupon>
  );
};
