import React from "react";
import cn from "classnames";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import styles from "./datePickerInput.module.css";

const DatePickerInput = ({
  error,
  classNameError,
  classNameWrap,
  disabled,
  disableFuture,
  id,
  format,
  isInvalid,
  inputVariant = "outlined",
  InputAdornmentProps,
  name,
  onError,
  onChange,
  onAccept,
  onBlur,
  label,
  labelClass,
  placeholder,
  required,
  variant = "inline",
  value,
  views,
  isGrey,
  isDisableMaxDay
}) => {
  const greyStyles = isGrey ? styles.greyDatePicker : "";

  return (
    <div className={classNameWrap}>
      {label && (
        <label className={cn(styles.label, labelClass)} htmlFor={id}>
          {label} {required && <span className={styles.asterisk}>*</span>}
        </label>
      )}

      <DatePicker
        className={cn(styles.datePicker, greyStyles)}
        name={name}
        id={id}
        error={false}
        disabled={disabled}
        disableFuture={disableFuture}
        helperText={null}
        variant={variant}
        inputVariant={inputVariant}
        format={format}
        value={value}
        InputAdornmentProps={InputAdornmentProps}
        onAccept={onAccept}
        onBlur={onBlur}
        onChange={onChange}
        onError={onError}
        placeholder={placeholder}
        views={views}
        autoOk
        maxDate={isDisableMaxDay ? "" : new Date()}
      />
      {isInvalid && <div className={cn(styles.error, classNameError)}>{error}</div>}
    </div>
  );
};

export default DatePickerInput;
