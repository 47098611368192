import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector, shallowEqual } from "react-redux";

import { compareMsg } from "../../../helpers";
import { MainSection } from "../mainSection";
import Message from "../message/message";
import messageIcon from "../../../media/icons/header/messages.svg";

export const Messages = React.memo(() => {
  const { t } = useTranslation();

  const { dashboardData } = useSelector(state => state.home, shallowEqual);

  if (!dashboardData?.listing_messages?.length) {
    return null;
  }

  const messagesList = dashboardData?.listing_messages
    ?.sort(compareMsg)
    .map(message => (
      <Message data={message} key={`message-${message.attorney}-${message.listing_message_id}-${message.fullName}`} isHomePage />
    ));

  return (
    <MainSection
      icon={messageIcon}
      isActive={!dashboardData?.listing_messages?.length}
      title={t("dashboard_home_inbox_block_title")}
      linkText={t("dashboard_home_viewall")}
      to="/inbox"
    >
      <div>{dashboardData?.listing_messages?.length ? messagesList : null}</div>
    </MainSection>
  );
});
