import React, { useState, useEffect } from "react";
import cn from "classnames";
import { useTranslation } from "react-i18next";

import { Radio } from "../radio";
import { renderBrandIcon } from "../../../helpers";
import styles from "../../payments/paymentMethods/paymentMethods.module.css";

export const CardList = ({ cardIconClass, defaultSource, paymentMethods, selectCard, selectedCard }) => {
  const [checkedRadio, setCheckedRadio] = useState(null);
  const { t } = useTranslation();

  const handleSelect = e => {
    selectCard(paymentMethods.find(({ id }) => id === e.target.value));
    setCheckedRadio(e.target.value);
  };

  useEffect(() => {
    if (selectedCard?.id && !checkedRadio) {
      setCheckedRadio(selectedCard.id);
      selectCard(paymentMethods.find(({ id }) => id === selectedCard.id));
    }
    if (defaultSource?.id && !checkedRadio) {
      setCheckedRadio(defaultSource.id);
      selectCard(paymentMethods.find(({ id }) => id === defaultSource.id));
    }
  }, [checkedRadio, selectedCard, setCheckedRadio, defaultSource, selectCard, paymentMethods]);

  return (
    <React.Fragment>
      <div className={styles.cardsLayout}>
        {paymentMethods.map(({ id, brand, last4 }) => {
          const isDefault = id === defaultSource?.id;

          return (
            <div className={styles.cardsLayoutItem} key={id}>
              <Radio
                className={styles.cardItemLabel}
                id={id}
                name="select_card"
                value={id}
                checked={checkedRadio === id}
                onChange={event => handleSelect(event)}
              >
                <span className={cn(styles.cardBrandImg, cardIconClass)}>
                  <img src={renderBrandIcon(brand)} alt="" />
                </span>
                <span className={cn(styles.numberWrap, checkedRadio === id ? styles.defaultNumber : null)}>
                  <p className={styles.layoutNumber}>**** - **** - **** - {last4}</p>
                  {isDefault && <p className={styles.layoutMain}>{t("dashboard_payment_methods_default_card")}</p>}
                </span>
              </Radio>
            </div>
          );
        })}
      </div>
    </React.Fragment>
  );
};
