import React from "react";
import format from "date-fns/format";
import { useTranslation } from "react-i18next";

import { ReactComponent as CameraIcon } from "../../../../media/icons/photo-listing.svg";
import { Modal } from "../../../common/modal";
import styles from "./remove.module.css";
import { OverlayPreloader } from "../../../common/preloader";
import ModalControl from "../../../modalControl";

const Remove = ({ clearAllModals, onClose, active, title = "Remove Access", onSubmit, loading }) => {
  const { t } = useTranslation();

  const { name_l, name_f, sent, member_name = "", team_member_id } = active;

  const handleSubmit = () => {
    onSubmit({ team_member_id });
  };

  return (
    <Modal clearAllModals={clearAllModals} closeModal={onClose}>
      {loading && <OverlayPreloader overlayClassName="sectionOverlayModal" />}

      <span className={styles.label}>{t("dashboard_teams_myteam_title")}</span>
      <h3 className={styles.title}>{title}</h3>

      <span className={styles.description}>{t("dashboard_teams_remove_desc")}</span>

      <div className={styles.user}>
        <div className={styles.userPhoto}>
          <CameraIcon />
        </div>
        <div className={styles.userInfo}>
          <span className={styles.userName}>{name_f || name_l ? `${name_f} ${name_l}` : member_name}</span>
          <span className={styles.userData}>Member since {format(new Date(sent), "MM/dd/yyyy")}</span>
        </div>
      </div>

      <ModalControl onExit={onClose} confirmationType="submit" onConfirm={handleSubmit}>
        {t("dashboard_teams_remove_title")}
      </ModalControl>
    </Modal>
  );
};

export default Remove;
