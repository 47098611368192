import React from "react";
import { useTranslation } from "react-i18next";

import { Modal } from "../common/modal";
import successIcon from "../../media/icons/success.svg";
import modalStyles from "../common/modal/modal.module.css";
import styles from "./verificationSuccess.module.css";
import ModalControl from "../modalControl";

export const VerificationSuccessModal = ({ clearAllModals }) => {
  const { t } = useTranslation();

  return (
    <Modal closeModal={clearAllModals} clearAllModals={clearAllModals} classNameMain={styles.modal}>
      <div className={modalStyles.form}>
        <section>
          <div className={modalStyles.pointer}>{t("dashboard_home_verifyemail_modal_name")}</div>
          <div className={modalStyles.popupHeading}>{t("dashboard_home_verifyemail_modal_success_title")}</div>
        </section>

        <section className={styles.content}>
          <img src={successIcon} alt="success" className={styles.icon} />
          <p className={styles.message}>{t("dashboard_home_verifyemail_modal_success")}</p>
        </section>

        <ModalControl onExit={clearAllModals} isHideExitConfirm isClose />
      </div>
    </Modal>
  );
};
