export const preloaders = {
  page: "page"
};

export const statuses = {
  active: "Active",
  draft: "Draft",
  pending: "Pending Review",
  pendingPayment: "Pending Payment",
  neverPublished: "Never Published",
  rejected: "Rejected",
  paused: "Paused",
  expired: "Expired"
};

export const daysWithNumbers = {
  1: "First",
  2: "Second",
  3: "Third",
  4: "Fourth",
  5: "Fifth",
  6: "Sixth",
  7: "Seventh",
  8: "Eighth",
  9: "Ninth",
  10: "Tenth",
  11: "Eleventh",
  12: "Twelfth",
  13: "Thirteenth",
  14: "Fourteenth",
  15: "Fifteenth",
  16: "Sixteenth",
  17: "Seventeenth",
  18: "Eighteenth",
  19: "Nineteenth",
  20: "Twentieth",
  21: "Twenty-First",
  22: "Twenty-Second",
  23: "Twenty-Third",
  24: "Twenty-Fourth",
  25: "Twenty-Fifth",
  26: "Twenty-Sixth",
  27: "Twenty-Seventh",
  28: "Twenty-Eighth",
  29: "Twenty-Ninth",
  30: "Thirtieth",
  31: "Thirty-First"
};

export const daysOfWeek = {
  0: "Sunday",
  1: "Monday",
  2: "Tuesday",
  3: "Wednesday",
  4: "Thursday",
  5: "Friday",
  6: "Saturday"
};
