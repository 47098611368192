import React, { useState, useEffect } from "react";
import cn from "classnames";
import { useTranslation } from "react-i18next";

import Search from "../search";
import styles from "./heading.module.css";

const Heading = ({ getCityStateZip, cityStateZipList, professions, getPractices, practices }) => {
  const [isActiveDropdown, setIsActiveDropdown] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState({});
  const { t } = useTranslation();

  const handleSetDropdown = item => {
    setActiveDropdown(item);
    setIsActiveDropdown(false);
    getPractices(item.profession_id);
  };

  const dropdownList = professions.map(item => {
    return (
      <button
        key={item.title}
        type="button"
        className={cn(styles.dropdownItem, activeDropdown === item.title ? styles.dropdownItemActive : "")}
        onClick={() => handleSetDropdown(item)}
      >
        {item.title}
      </button>
    );
  });

  const toggleActiveDropdown = () => {
    setIsActiveDropdown(prev => !prev);
  };

  useEffect(() => {
    if (professions.length && !activeDropdown.title) {
      const findLawyer = professions.find(item => item.title === "Lawyers");
      setActiveDropdown(findLawyer);
    }
  }, [professions.length]);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.search}>
          <h1 className={styles.title}>{t("dashboard_hp_h1")}</h1>
          {professions.length ? (
            <button
              type="button"
              className={cn(styles.searchDropdown, isActiveDropdown ? styles.searchDropdownActive : "")}
              onClick={toggleActiveDropdown}
            >
              {activeDropdown.title}
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                <path d="M8 12L16 20L24 12" stroke="#AC4B77" strokeWidth="2.66667" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </button>
          ) : null}

          {isActiveDropdown && <div className={styles.dropdown}>{dropdownList}</div>}
        </div>
        <p className={styles.description}>{t("dashboard_hp_desc")}</p>
        <Search
          getCityStateZip={getCityStateZip}
          cityStateZipList={cityStateZipList}
          practices={practices}
          activeDropdown={activeDropdown}
        />
      </div>
    </div>
  );
};

export default Heading;
