import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import cn from "classnames";

import { DetailNavigation } from "./popups/detailNavigation";
import PageNames from "./pageNames";
import { Notification } from "./notification";
import { Hamburger } from "./hamburger";
import { Avatar } from "./avatar";
import { actions as mainActions } from "../../actions/main";
import styles from "./header.module.css";

const Header = ({ keycloak }) => {
  const location = useLocation();

  const [popup, setPopup] = useState(null);
  const dispatch = useDispatch();
  const { isActiveMobileMenu } = useSelector(state => state.main);
  const isListingDetailPage = ["/my-jobs", "/my-events", "/my-organizations", "/profile"].some(item => location.pathname.includes(item));

  const toggleMobileMenu = useCallback(isActive => dispatch(mainActions.toggleMobileMenu(isActive)), [dispatch]);

  const handleOpenNewListing = () => {
    toggleMobileMenu(!isActiveMobileMenu);
  };

  useEffect(() => {
    setPopup(false);
  }, [location.search]);

  const togglePopup = name => {
    if (name === popup) {
      setPopup(false);
    } else {
      setPopup(name);
    }
  };

  return (
    <div className="header-background">
      <header className={cn("header header--global", isListingDetailPage ? styles.header : "")}>
        <Hamburger toggleDropdown={handleOpenNewListing} activeMenu={isActiveMobileMenu} />
        <PageNames />
        <div className="header__actions">
          <Notification onCloseHandler={() => togglePopup("notification")} popup={popup} />
          <div className="relative">
            <Avatar togglePopup={togglePopup} />
            {popup === "links" && <DetailNavigation onCloseHandler={() => togglePopup("links")} keycloak={keycloak} />}
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
