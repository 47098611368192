import React from "react";
import cn from "classnames";
import { useTranslation } from "react-i18next";

import Checkbox from "../../../common/checkbox";
import { OverlayPreloader } from "../../../common/preloader";
import { filterTypes } from "../../../../constants/listings";
import styles from "./filter.module.css";

const Filter = ({
  setIsDraft,
  setActiveFilter,
  loading,
  listActiveDirectories,
  setListActiveDirectories,
  filters,
  listActiveListingType,
  setListActiveListingType,
  listActiveSponsorOptions,
  setListActiveSponsorOptions,
  appliedCount,
  isDesktop,
  filterType,
  getPositions,
  listActiveChange,
  setListActiveChange,
  listActiveStatus,
  setListActiveStatus,
  isDraft
}) => {
  const {
    directories,
    freesponsor: sponsorOptions,
    // professiongroups: listingTypes,
    change_status: changeStatus,
    publish_status: publishStatus
    // publish_status2: publishStatus2
  } = filters;

  const { t } = useTranslation();

  const isEmptyFilters =
    listActiveChange.length ||
    listActiveStatus.length ||
    listActiveDirectories.length ||
    listActiveListingType.length ||
    listActiveSponsorOptions.length ||
    isDraft;

  const resetFilters = () => {
    setListActiveDirectories([]);
    setListActiveListingType([]);
    setListActiveSponsorOptions([]);
    setListActiveStatus([]);
    setListActiveChange([]);
    setIsDraft(false);
  };

  /*
  
  const getListingType = name => {
    return listActiveListingType.includes(name);
  };

    const onListingType = name => {
    if (getListingType(name)) {
      const newOptions = listActiveListingType.filter(item => item !== name);
      setListActiveListingType(newOptions);
    } else {
      setListActiveListingType([...listActiveListingType, name]);
    }
  };
  
    const getAllListingType = () => {
      return listActiveListingType && listActiveListingType.length === listingTypes.length;
    };

    const handleAllListingType = () => {
      if (getAllListingType()) {
        setListActiveListingType([]);
      } else {
        const ids = listingTypes.map(item => item.profession_group_id);
  
        setListActiveListingType(ids);
      }
    };
    
    
  const listingTypesList = listingTypes?.map(({ profession_group_id, title }) => {
    return (
      <div key={`${profession_group_id}-${title}`}>
        <Checkbox
          big
          className={styles.checkboxLabel}
          id={title}
          checked={getListingType(profession_group_id)}
          onChange={() => onListingType(profession_group_id)}
        >
          {title}
        </Checkbox>
      </div>
    );
  });

  */

  const handleAllListActiveChange = () => {
    if (getAllListingChangeStatus()) {
      setListActiveChange([]);
      setIsDraft(false);
    } else {
      const ids = changeStatus.map(item => item.id);

      setListActiveChange(ids);
      setIsDraft(true);
    }
  };

  const getActiveNetwork = name => {
    return listActiveDirectories.includes(name);
  };

  const onCheckNetwork = name => {
    if (getActiveNetwork(name)) {
      const newOptions = listActiveDirectories.filter(item => item !== name);
      setListActiveDirectories(newOptions);
    } else {
      setListActiveDirectories([...listActiveDirectories, name]);
    }
  };

  const getAllActiveNetwork = () => {
    return listActiveDirectories && listActiveDirectories.length === directories.length;
  };

  const handleAllNetwork = () => {
    if (getAllActiveNetwork()) {
      setListActiveDirectories([]);
    } else {
      const ids = directories.map(item => item.dir_id);

      setListActiveDirectories(ids);
    }
  };

  const directoriesList = directories?.map(({ dir_id, title }) => {
    return (
      <div key={`${dir_id}-${title}`}>
        <Checkbox
          id={title}
          checked={getActiveNetwork(dir_id)}
          onChange={() => onCheckNetwork(dir_id)}
          big
          className={styles.checkboxLabel}
          key={dir_id}
        >
          {title}
        </Checkbox>
      </div>
    );
  });

  const getSponsorOption = name => {
    return listActiveSponsorOptions.includes(name);
  };

  const onSponsorOption = name => {
    if (getSponsorOption(name)) {
      const newOptions = listActiveSponsorOptions.filter(item => item !== name);
      setListActiveSponsorOptions(newOptions);
    } else {
      setListActiveSponsorOptions([...listActiveSponsorOptions, name]);
    }
  };

  const getAllSponsorOption = () => {
    return listActiveSponsorOptions && listActiveSponsorOptions.length === sponsorOptions.length;
  };

  const handleAllSponsorNetwork = () => {
    if (getAllSponsorOption()) {
      setListActiveSponsorOptions([]);
    } else {
      setListActiveSponsorOptions(sponsorOptions);
    }
  };

  const sponsorOptionsList = sponsorOptions?.map(title => {
    return (
      <div key={title}>
        <Checkbox
          id={title.id}
          checked={getSponsorOption(title.id)}
          onChange={() => onSponsorOption(title.id)}
          big
          className={cn(styles.checkboxLabel, styles.checkboxLabelCapitalize)}
        >
          {t(title.title)}
        </Checkbox>
      </div>
    );
  });

  /*
  const renderListingType = () => {
    return (
      <>
        {!isDesktop && <h6 className={styles.categoriesSubTitle}>Listing Type</h6>}

        {listingTypesList}

        <div key="profession_group_id">
          <Checkbox big className={styles.checkboxLabel} id="all" checked={getAllListingType()} onChange={handleAllListingType}>
            All
          </Checkbox>
        </div>
      </>
    );
  };
  */

  const renderListingDirectories = () => {
    return (
      <>
        {!isDesktop && <h6 className={styles.categoriesSubTitle}>Directory</h6>}

        {directoriesList}

        <div key="profession_group_id">
          <Checkbox
            id="profession_group_id"
            checked={getAllActiveNetwork()}
            onChange={handleAllNetwork}
            big
            className={styles.checkboxLabel}
          >
            {t("dashboard_common_all")}
          </Checkbox>
        </div>
      </>
    );
  };

  const renderSponsorOptionsList = () => {
    return (
      <>
        {!isDesktop && <h6 className={styles.categoriesSubTitle}>Option</h6>}

        <div key="sponsorOptionsList">
          {sponsorOptionsList}

          <Checkbox
            id="sponsorOptionsList"
            checked={getAllSponsorOption()}
            onChange={handleAllSponsorNetwork}
            big
            className={styles.checkboxLabel}
          >
            {t("dashboard_common_all")}
          </Checkbox>
        </div>
      </>
    );
  };

  const renderChangeStatus = () => {
    return (
      <>
        {!isDesktop && <h6 className={styles.categoriesSubTitle}>Changes</h6>}

        {listingChangeStatusList}

        <div key="is_draft">
          <Checkbox big className={styles.checkboxLabel} id="is_draft" checked={isDraft} onChange={() => setIsDraft(prev => !prev)}>
            {t("dashboard_listings_changesindraft")}
          </Checkbox>
        </div>

        <div key="profession_group_id">
          <Checkbox
            big
            className={styles.checkboxLabel}
            id="all"
            checked={getAllListingChangeStatus()}
            onChange={handleAllListActiveChange}
          >
            {t("dashboard_common_all")}
          </Checkbox>
        </div>
      </>
    );
  };

  const getListingChangeStatus = name => {
    return listActiveChange.includes(name);
  };

  const onListingChangeStatus = name => {
    if (getListingChangeStatus(name)) {
      const newOptions = listActiveChange.filter(item => item !== name);
      setListActiveChange(newOptions);
    } else {
      setListActiveChange([...listActiveChange, name]);
    }
  };

  const getAllListingChangeStatus = () => {
    return listActiveChange && listActiveChange.length === changeStatus.length && isDraft;
  };

  const listingChangeStatusList = changeStatus?.map(item => {
    return (
      <div key={item}>
        <Checkbox
          big
          className={styles.checkboxLabel}
          id={item.id}
          checked={getListingChangeStatus(item.id)}
          onChange={() => onListingChangeStatus(item.id)}
        >
          {t(item.title)}
        </Checkbox>
      </div>
    );
  });

  const renderPublishStatus = () => {
    return (
      <>
        {!isDesktop && <h6 className={styles.categoriesSubTitle}>Status22</h6>}

        {listingPublishStatus}

        <div key="profession_group_id">
          <Checkbox big className={styles.checkboxLabel} id="all" checked={getAllListingPublishStatus()} onChange={handleAllPublishStatus}>
            {t("dashboard_common_all")}
          </Checkbox>
        </div>
      </>
    );
  };

  const handleAllPublishStatus = () => {
    if (getAllListingPublishStatus()) {
      setListActiveStatus([]);
    } else {
      const ids = publishStatus.map(item => item.id);

      setListActiveStatus(ids);
    }
  };

  const getListingPublishStatus = name => {
    return listActiveStatus.includes(name);
  };

  const onListingPublishStatus = name => {
    if (getListingPublishStatus(name)) {
      const newOptions = listActiveStatus.filter(item => item !== name);
      setListActiveStatus(newOptions);
    } else {
      setListActiveStatus([...listActiveStatus, name]);
    }
  };

  const getAllListingPublishStatus = () => {
    return listActiveStatus && listActiveStatus.length === publishStatus.length;
  };

  const listingPublishStatus = publishStatus?.map(item => {
    return (
      <div key={item}>
        <Checkbox
          big
          className={styles.checkboxLabel}
          id={item.id}
          checked={getListingPublishStatus(item.id)}
          onChange={() => onListingPublishStatus(item.id)}
        >
          {t(item.title)}
        </Checkbox>
      </div>
    );
  });

  const getPositionLeft = () => {
    const { directories: directoriesPosition, status, changes, option } = getPositions();
    if (isDesktop) {
      if (filterTypes.directory === filterType) {
        return directoriesPosition;
      }

      if (filterTypes.status === filterType) {
        return `${status}px`;
      }

      if (filterTypes.changes === filterType) {
        return `${changes}px`;
      }

      if (filterTypes.option === filterType) {
        return `${option}px`;
      }
    }
    return "";
  };

  const renderAllCategories = () => {
    if (isDesktop) {
      if (filterTypes.directory === filterType) {
        return <>{renderListingDirectories()}</>;
      }

      if (filterTypes.status === filterType) {
        return <>{renderPublishStatus()}</>;
      }

      if (filterTypes.changes === filterType) {
        return <>{renderChangeStatus()}</>;
      }

      if (filterTypes.option === filterType) {
        return <>{renderSponsorOptionsList()}</>;
      }
    }

    return (
      <>
        {/*
        {renderListingType()}
        */}
        {renderListingDirectories()}

        {renderChangeStatus()}

        {renderPublishStatus()}

        {renderSponsorOptionsList()}
      </>
    );
  };

  const statusActive = filterTypes.status === filterType ? styles.filterContentStatus : "";
  const changesActive = filterTypes.changes === filterType ? styles.filterContentChanges : "";
  const optionActive = filterTypes.option === filterType ? styles.filterContentOption : "";

  return (
    <div
      className={cn(styles.filter, statusActive, changesActive, optionActive)}
      style={{ left: isDesktop ? getPositionLeft() || 0 : "0" }}
    >
      {loading && <OverlayPreloader />}
      <div className={styles.filterContent}>
        {!isDesktop && (
          <div className={styles.filterHeading}>
            <h4 className={styles.filterTitle}>Filters</h4>
            <button type="button" className={styles.filterClose} onClick={() => setActiveFilter(false)}>
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                <path d="M13 1L1 13" stroke="#1C1D40" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M1 1L13 13" stroke="#1C1D40" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </button>
          </div>
        )}
        <div className={styles.filterContainer}>
          <div>{renderAllCategories()}</div>
        </div>
        <div className={styles.filterActions}>
          {isEmptyFilters && (
            <button type="button" className={styles.filterResetAll} onClick={resetFilters}>
              {t("dashboard_common_reset")}
            </button>
          )}
          <button type="button" className={styles.filterApply} onClick={() => setActiveFilter(false)} disabled={!isEmptyFilters}>
            {t("dashboard_common_apply")} <>{appliedCount ? <>({appliedCount})</> : ""}</>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Filter;
