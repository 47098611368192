import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import cn from "classnames";
import { useHistory, Link } from "react-router-dom";
import qs from "qs";
import queryString from "query-string";

import { useTranslation } from "react-i18next";
import { subTabLeads } from "../../../constants/referralRequests";
import { actions as payPerLeadActions } from "../../../actions/payPerLead";
import styles from "./tabs.module.css";

const SubTabs = ({ tabsCounter }) => {
  const dispatch = useDispatch();
  const { activeSubTab } = useSelector(state => state.payPerLead);
  const { t } = useTranslation();

  const history = useHistory();
  const searchParams = queryString.parse(history.location.search);
  const setActiveSubTab = useCallback(tab => dispatch(payPerLeadActions.setActiveSubTab(tab)), [dispatch]);

  const handleSetActiveSubTab = subTab => {
    const params = qs.stringify({ ...searchParams, tab: subTab });

    setActiveSubTab(subTab);
    history.push(`/paid-leads?${params}`);
  };

  useEffect(() => {
    if (history.location.pathname === "/analytics") {
      setActiveSubTab(subTabLeads.analytics);
    } else {
      setActiveSubTab("");
    }
  }, []);

  return (
    <div className={styles.content}>
      {tabsCounter.purchased || tabsCounter.hidden || tabsCounter.new ? (
        <>
          <button
            type="button"
            className={cn(styles.tab, activeSubTab === subTabLeads.new ? styles.tabActive : "")}
            onClick={() => handleSetActiveSubTab(subTabLeads.new)}
          >
            New {tabsCounter.new ? <>{`(${tabsCounter.new})`}</> : ""}
          </button>

          <button
            type="button"
            className={cn(styles.tab, activeSubTab === subTabLeads.purchased ? styles.tabActive : "")}
            onClick={() => handleSetActiveSubTab(subTabLeads.purchased)}
          >
            {t("dashboard_ppl_purchased_leads")} {tabsCounter.purchased ? <>{`(${tabsCounter.purchased})`}</> : ""}
          </button>
          <button
            type="button"
            className={cn(styles.tab, activeSubTab === subTabLeads.hidden ? styles.tabActive : "")}
            onClick={() => handleSetActiveSubTab(subTabLeads.hidden)}
          >
            {t("dashboard_ppl_hidden_leads")} {tabsCounter.hidden ? <>{`(${tabsCounter.hidden})`}</> : ""}
          </button>
        </>
      ) : null}
      <Link
        to="/analytics"
        className={cn(styles.tab, activeSubTab === subTabLeads.analytics ? styles.tabActive : "")}
        onClick={() => handleSetActiveSubTab(subTabLeads.analytics)}
      >
        Analytics
      </Link>
    </div>
  );
};

export default SubTabs;
