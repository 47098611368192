import React from "react";

import { Modal } from "../../../common/modal";
import { ReactComponent as IconCheck } from "../../../../media/icons/success.svg";
import styles from "./successMessage.module.css";
import ModalControl from "../../../modalControl";

export const SuccessMessage = ({ onClose, clearAllModals }) => {
  return (
    <Modal clearAllModals={clearAllModals} closeModal={onClose}>
      <div className={styles.flex}>
        <h3 className={styles.title}>Your message has been sent</h3>
        <IconCheck className={styles.icon} />
      </div>
      <span className={styles.description}>You can expect a reply within 24 hours. </span>

      <ModalControl onExit={onClose} isHideExitConfirm isClose />
    </Modal>
  );
};
