import React, { useState, useEffect } from "react";
import cn from "classnames";

import arrow from "../../../media/icons/dropdown-arrow.svg";
import Checkbox from "../checkbox";
import styles from "./dropdown.module.css";

const Dropdown = ({
  children,
  checked,
  checkClass,
  className,
  id,
  handleCheck,
  hasCheckBox,
  mainClass,
  propsHandleToggle,
  propsIsOpen,
  renderHidden,
  title,
  titleClass
}) => {
  const [isOpen, toggleOpen] = useState(propsIsOpen);

  useEffect(() => {
    if (propsIsOpen) {
      toggleOpen(true);
    } else if (propsIsOpen === false) {
      toggleOpen(false);
    }
  }, [propsIsOpen]);

  const handleToggle = () => {
    if (propsHandleToggle) {
      propsHandleToggle();
    } else {
      toggleOpen(!isOpen);
    }
  };

  const renderChildren = () => {
    if (renderHidden) {
      return <div className={cn({ [styles.hidden]: !isOpen })}>{children}</div>;
    }

    return isOpen && children;
  };

  return (
    <div className={cn("dropdown", className)}>
      <div role="button" tabIndex="0" onClick={handleToggle} className={cn("dropdown__heading", mainClass)}>
        <div className={titleClass}>
          {hasCheckBox && <Checkbox checked={checked} className={cn(styles.checkbox, checkClass)} id={id} onChange={handleCheck} />}
          <div className="dropdown__title button--clear">{title}</div>
        </div>

        <button type="button" className="button--clear" onClick={handleToggle}>
          <img className={cn({ "dropdown-arrow": !isOpen, "dropdown-arrow--up": isOpen })} src={arrow} alt="" />
        </button>
      </div>
      {renderChildren()}
    </div>
  );
};

export default Dropdown;
