import React from "react";
import cn from "classnames";

import { ReactComponent as IconClass } from "../../../media/icons/information-tooltip.svg";
import styles from "./tooltip.module.css";

export const Tooltip = ({ arrowClass, className, children, classText, iconClass }) => {
  return (
    <div className={cn(styles.tooltip, className)}>
      <IconClass className={cn(styles.icon, iconClass)} />
      <span className={cn(classText, arrowClass ? styles.contentMiddle : styles.content)}>{children}</span>
    </div>
  );
};
