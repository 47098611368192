import React from "react";
import { useTranslation } from "react-i18next";

import { Button } from "../../../common/button";
import { ReactComponent as PenIcon } from "../../../../media/icons/edit-pen-white.svg";
import { statusesConstant } from "../../../../constants/referralRequestDetail";
import styles from "./openToOtherProfessionals.module.css";

const OpenToOtherProfessionals = ({ openToOtherProfessionals, status, onOpenModal }) => {
  const { t } = useTranslation();
  const isShowEditButton = [statusesConstant.pendingReview, statusesConstant.pending].includes(status);

  if (!openToOtherProfessionals?.question) {
    return null;
  }

  return (
    <>
      <div className={styles.content}>
        <div>
          <p className={styles.question}>{openToOtherProfessionals.question}</p>

          <div className={styles.answerBox}>
            <span className={styles.myAnswer}>{t("dashboard_referrals_details_my_answer")}:</span>
            <span className={styles.answerTextSimple}>{openToOtherProfessionals.answer}</span>
          </div>
        </div>

        {isShowEditButton && (
          <Button className={styles.editBtn} onClick={onOpenModal}>
            <PenIcon className={styles.penIcon} />
          </Button>
        )}
      </div>
      <hr className={styles.hr} />
    </>
  );
};

export default OpenToOtherProfessionals;
