import React from "react";
import { ReactComponent as SuccessIcon } from "../../../../media/icons/success.svg";

import { Modal } from "../../../common/modal";
import styles from "./success.module.css";
import ModalControl from "../../../modalControl";

const Success = ({
  clearAllModals,
  onClose,
  text = "You’ve successfully removed your access from you Joe Jameson’s team",
  title = "Remove Access"
}) => {
  return (
    <Modal clearAllModals={clearAllModals} closeModal={onClose}>
      <span className={styles.label}>my team</span>
      <h3 className={styles.title}>{title}</h3>

      <div className={styles.successIcon}>
        <SuccessIcon />
      </div>
      <span className={styles.confirmation}>{text}</span>

      <ModalControl onExit={onClose} isHideExitConfirm isClose />
    </Modal>
  );
};

export default Success;
