import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import styles from "./header.module.css";
import mainLogo from "../../../../media/icons/logo.svg";

export const Header = ({ link, login }) => {
  const { t } = useTranslation();

  return (
    <header className={styles.header}>
      <div className={styles.content}>
        <img src={mainLogo} alt="" />
        {link && (
          <Link className={styles.link} to="/">
            {t("dashboard_common_button_exit_continue_later")}
          </Link>
        )}
        {login && (
          <Link className={styles.link} to="/login">
            {t("dashboard_login_button")}
          </Link>
        )}
      </div>
    </header>
  );
};
