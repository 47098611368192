import React from "react";
import { useDispatch, useSelector } from "react-redux";

import emptyNotification from "../../../../../media/icons/empty-notifications.svg";
import { NotificationItem } from "../notificationItem";
import { actions as mainActions } from "../../../../../actions/main";
import styles from "./notificationList.module.css";

export const NotificationList = () => {
  const dispatch = useDispatch();
  const setViewNotification = ids => dispatch(mainActions.setViewNotification(ids));
  const setUnViewNotification = ids => dispatch(mainActions.setUnViewNotification(ids));
  const { notifications } = useSelector(state => state.main);

  if (!notifications.length) {
    return (
      <div className={styles.empty}>
        <img src={emptyNotification} alt="no_messages" />
      </div>
    );
  }

  return notifications.map((item, index) => {
    return (
      <NotificationItem
        // eslint-disable-next-line react/no-array-index-key
        key={index.toString()}
        index={index}
        setViewNotification={setViewNotification}
        setUnViewNotification={setUnViewNotification}
        {...item}
      />
    );
  });
};
