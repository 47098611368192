import React, { useState } from "react";

import styles from "./gallery.module.css";
import testBanner from "../../../media/images/eventsTestBanner.jpg";
import AllPhotos from "../allPhotos";

const Gallery = () => {
  const [showAllPhoto, setShowAllPhoto] = useState(false);

  const handleOpenAllPhotos = () => {
    setShowAllPhoto(true);
  };

  const handleCloseAllPhotos = () => {
    setShowAllPhoto(false);
  };

  return (
    <>
      {showAllPhoto ? <AllPhotos closeModal={handleCloseAllPhotos} /> : null}
      <div className={styles.content}>
        <div className={styles.gallery}>
          <div className={styles.galleryLeft}>
            <img src={testBanner} alt="cover" />
          </div>

          <div className={styles.galleryRight}>
            <span>
              <img src={testBanner} alt="cover" />
            </span>
            <span>
              <img src={testBanner} alt="cover" />
            </span>
            <span>
              <img src={testBanner} alt="cover" />
            </span>
            <span>
              <img src={testBanner} alt="cover" />
            </span>
          </div>
          <button type="button" className={styles.allPhotos} onClick={handleOpenAllPhotos}>
            See All Photos
          </button>
        </div>
      </div>
    </>
  );
};

export default Gallery;
