import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import DOMPurify from "dompurify";

import "./index.css";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import settingsIcon from "../../media/icons/settings.svg";
import timeIcon from "../../media/icons/time.svg";
import { Preloader } from "../common/preloader";
import { actions as mainActions } from "../../actions/main";
import { useDocTitle } from "../../helpers/hooks";

const Notifications = () => {
  const { t } = useTranslation();
  useDocTitle("dashboard_notifications_main_metatitle");

  const dispatch = useDispatch();
  const setViewNotification = useCallback(ids => dispatch(mainActions.setViewNotification(ids)), [dispatch]);

  const main = useSelector(state => state.main);
  const {
    preloaders: { getGlobalInfo: loading },
    notifications
  } = main;

  const handleView = ids => {
    setViewNotification(ids);
  };

  if (loading) {
    return <Preloader />;
  }

  const renderNotifications = notifications.map(item => {
    const route = item.type === "10" ? `/paid-leads/${item.record_id}?purchased=${item.purchased ? "1" : "0"}` : item.url;

    return (
      <div key={`${item.record_id?.toString()}-${item.type_title}`} className="notification">
        <div className="notification__title-flex">
          <p className="notification__title">{item.type_title}</p>
          <div className="notification__time">
            <img className="time-icon" src={timeIcon} alt="" />
            <time className="notification__time-text">{formatDistanceToNow(new Date(item.added))}</time>
          </div>
        </div>
        <div className="notification__main-flex">
          {/* eslint-disable-next-line react/no-danger */}
          <pre className="notification__text" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.message) }} />
          <Link onClick={() => handleView(item.notification_id)} to={route} className="button button--view text-center">
            View
          </Link>
        </div>
      </div>
    );
  });

  return (
    <div className="wrapper">
      <div className="notification-page">
        <div className="title--notification title">
          <p className="title__text">{t("dashboard_notifications_main_h1")}</p>
          <Link to="/manage-notification" className="setting-button">
            <img className="title__setting-icon" src={settingsIcon} alt="" />
          </Link>
        </div>
        {renderNotifications}
      </div>
    </div>
  );
};

export default Notifications;
