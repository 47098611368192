import React from "react";

import communities from "../../../media/images/communities/communities.png";
import styles from "./heading.module.css";
import { Button } from "../../common/button";

const Heading = ({ currentProfession }) => {
  return (
    <div className={styles.content}>
      <div className={styles.leftSide}>
        <h1 className={styles.title}>{`${currentProfession} Communities`}</h1>
        <p className={styles.description}>Publish your free listing on one or more of the directories below</p>
        <Button type="submit" className={styles.button}>
          Create New Account
        </Button>
      </div>
      <div className={styles.rightSide}>
        <img className={styles.communitiesImage} src={communities} alt="" />
      </div>
    </div>
  );
};

export default Heading;
