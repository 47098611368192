import queryString from "query-string";
import { payPerLead } from "../constants/actionTypes";

const defaultState = {
  filters: [],
  leads: [],
  loading: false,
  pagination: {
    currentPage: null,
    currentPageNum: null,
    lastPage: null,
    lastPageNum: null,
    perPage: 0
  },
  tabsCounter: {
    new: 0,
    purchased: 0,
    hidden: 0
  },
  activeTab: "",
  activeSubTab: ""
};

export default function(state = defaultState, action) {
  switch (action.type) {
    case payPerLead.SET_LOADING:
      return {
        ...state,
        loading: action.payload
      };

    case payPerLead.SET_TAB:
      return {
        ...state,
        activeTab: action.payload.tab,
        activeSubTab: action.payload.subTab || ""
      };

    case payPerLead.SET_SUB_TAB:
      return {
        ...state,
        activeSubTab: action.payload
      };

    case payPerLead.GET_LEADS: {
      const lastPageNum = parseInt(queryString.parse(queryString.extract(action.payload.lastPage)).page, 10);
      const currentPageNum = parseInt(queryString.parse(queryString.extract(action.payload.currentPage)).page, 10);

      return {
        ...state,
        leads: action.payload.leads,
        filters: action.payload.filters,
        loading: false,
        pagination: {
          currentPage: action.payload.currentPage,
          currentPageNum,
          lastPage: action.payload.lastPage,
          lastPageNum,
          perPage: action.payload.perPage
        },
        tabsCounter: { ...action.payload.tabsCounter }
      };
    }

    case payPerLead.GET_LEADS_FAIL:
      return {
        ...state,
        loading: false
      };

    case payPerLead.SET_LEADS_CLEAR:
      return {
        ...state,
        leads: [],
        filters: [],
        tabsCounter: {
          new: 0,
          purchased: 0,
          hidden: 0
        }
      };

    case payPerLead.SET_LEAD_NO_INTEREST: {
      const filteredLeads = state.leads.map(item => {
        if (item.lead_id === action.payload) {
          return { ...item, hide: "1" };
        }
        return item;
      });
      return {
        ...state,
        leads: [...filteredLeads],
        loading: false
      };
    }

    case payPerLead.SET_LEAD_INTEREST: {
      const filteredLeads = state.leads.filter(item => item.lead_id !== action.payload);

      return {
        ...state,
        leads: filteredLeads,
        loading: false
      };
    }

    default:
      return {
        ...state
      };
  }
}
