import axios from "axios";
import queryString from "query-string";
import { payPerLead } from "../constants/actionTypes";

export const actions = {
  getLeads({ dir_id, page = "0", practice_id, tab, sort, search, perPage, team = {} }) {
    return (dispatch, getState) => {
      const {
        teams: { currentTeam }
      } = getState();
      const teamUrl = currentTeam?.value || team.value ? "/team" : "";
      const userIdParams = currentTeam?.value || team.value ? { user_id: currentTeam.value || team.value } : {};

      dispatch(this.setLoading(true));
      const perPageValue = perPage ? { per_page: perPage } : {};
      const searchValue = search ? { search } : {};

      const filterParams = queryString.stringify(
        { practice_id, dir_id, sort, page, ...userIdParams, ...perPageValue, ...searchValue },
        { arrayFormat: "index" }
      );

      axios({
        method: "get",
        responseType: "json",
        url: `/members/json-api${teamUrl}/lead/${tab}${filterParams ? `?${filterParams}` : ""}`,
        params: { page, tab },
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
        }
      })
        .then(res => {
          dispatch({
            type: payPerLead.GET_LEADS,
            payload: {
              leads: res.data.leads,
              filters: res.data.filters,
              lastPage: res.data.last_page,
              currentPage: res.data.current_page,
              perPage: res.data.per_page,
              tabsCounter: { new: res.data.notpurchased, purchased: res.data.purchased, hidden: res.data.hidden }
            }
          });
        })
        .catch(error => {
          console.error("getLeads", error);

          dispatch({
            type: payPerLead.GET_LEADS_FAIL,
            payload: error.response
          });
        });
    };
  },

  setLoading(isLoading) {
    return {
      type: payPerLead.SET_LOADING,
      payload: isLoading
    };
  },

  setLeadsClear() {
    return {
      type: payPerLead.SET_LEADS_CLEAR
    };
  },

  setNoInterest(leadId, userId) {
    return async (dispatch, getState) => {
      const {
        teams: { currentTeam }
      } = getState();
      const teamUrl = currentTeam?.value || userId ? "/team" : "";
      const userIdParams = currentTeam?.value || userId ? { user_id: currentTeam.value || userId } : {};

      dispatch(this.setLoading(true));

      axios({
        method: "post",
        responseType: "json",
        url: `/members/json-api${teamUrl}/lead/hide`,
        headers: {
          "Content-Type": "application/json"
        },
        data: {
          lead_id: leadId,
          ...userIdParams
        }
      })
        .then(() => {
          dispatch({
            type: payPerLead.SET_LEAD_NO_INTEREST,
            payload: leadId
          });
        })
        .catch(() => {
          dispatch(this.setLoading(false));
        });
    };
  },

  setInterest(leadId, userId) {
    return async (dispatch, getState) => {
      const {
        teams: { currentTeam }
      } = getState();
      const teamUrl = currentTeam?.value || userId ? "/team" : "";
      const userIdParams = currentTeam?.value || userId ? { user_id: currentTeam.value || userId } : {};

      dispatch(this.setLoading(true));
      axios({
        method: "post",
        responseType: "json",
        url: `/members/json-api${teamUrl}/lead/unhide`,
        headers: {
          "Content-Type": "application/json"
        },
        data: {
          lead_id: leadId,
          ...userIdParams
        }
      })
        .then(() => {
          dispatch({
            type: payPerLead.SET_LEAD_INTEREST,
            payload: leadId
          });
        })
        .catch(() => {
          dispatch(this.setLoading(false));
        });
    };
  },

  setActiveTab(tab, subTab) {
    return {
      type: payPerLead.SET_TAB,
      payload: { tab, subTab }
    };
  },

  setActiveSubTab(tab) {
    return {
      type: payPerLead.SET_SUB_TAB,
      payload: tab
    };
  }
};
