import React, { useEffect, useRef } from "react";

const MailerLiteForm = ({ id }) => {
  const myElementRef = useRef(null);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      const element = document.getElementById(id);
      if (element) {
        element.style.display = "block";
        myElementRef.current.appendChild(element);
      }
    }, 2000);

    return () => clearTimeout(timeoutId);
  }, []);

  return <div ref={myElementRef} />;
};

export default MailerLiteForm;
