import React, { useState, useEffect } from "react";
import cn from "classnames";
import { formatDistanceToNow } from "date-fns";

import styles from "./profileListing.module.css";
import { Button } from "../../common/button";
import { ReactComponent as SaveIcon } from "../../../media/icons/heart.svg";
import { ReactComponent as PhoneIcon } from "../../../media/icons/phone.svg";

const ProfileListing = ({ isExpand, list, addToFavorite, removeFavorite }) => {
  return (
    <div className={cn(styles.flex, isExpand ? styles.flexExpand : "")}>
      {list.map(item => {
        return <Profile isExpand={isExpand} item={item} addToFavorite={addToFavorite} removeFavorite={removeFavorite} key={item.title} />;
      })}
    </div>
  );
};

const Profile = ({ item, isExpand, removeFavorite, addToFavorite }) => {
  const [isFavorite, setIsFavorite] = useState(false);

  const toggleFavorite = listingId => {
    if (isFavorite) {
      removeFavorite(listingId);
      setIsFavorite(false);
    } else {
      addToFavorite(listingId);
      setIsFavorite(true);
    }
  };

  useEffect(() => {
    if (item.bookmarked === "true") {
      setIsFavorite(true);
    }
  }, []);

  return (
    <div className={cn(styles.tile, isExpand ? styles.tileExpand : "")} key={item.title}>
      <div className={styles.favorites}>
        <Button
          className={cn(styles.favoritesButton, isFavorite ? styles.favoritesButtonActive : "")}
          onClick={() => toggleFavorite(item.listing_id)}
        >
          <SaveIcon />
        </Button>
      </div>

      <div className={styles.heading}>
        <div className={styles.tileImage}>
          <img src={item.listings_image_name} alt="Profile banner" />
        </div>
        <div className={styles.headingContent}>
          <div className={styles.headingFlex}>
            <h6 className={styles.tileTitle}>{item.attorney}</h6>
            {item.is_premium && <span className={cn(styles.sponsor, styles.sponsorTablet)}>Sponsor</span>}
          </div>
          <p className={styles.tilePosition}>{item.position}</p>
          {item.is_premium && <span className={cn(styles.sponsor, styles.sponsorMobile)}>Sponsor</span>}

          <div className={styles.tileInfoTablet}>
            <p className={styles.practices}>{item.practices}</p>

            <div className={styles.tileFlex}>
              <p className={styles.experience}>{item.experience}</p>
              <a href="tel:1212121" className={styles.phone}>
                <PhoneIcon /> {item.phone}
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.tileInfoMobile}>
        <p className={styles.practices}>__practices{item.practices}</p>

        <div className={styles.tileFlex}>
          {item.added && <p className={styles.experience}>{formatDistanceToNow(new Date(item.added))} Licensed</p>}
          <a href="tel:1212121" className={styles.phone}>
            <PhoneIcon /> __phone{item.phone}
          </a>
        </div>
      </div>

      <p className={styles.tileDescription}>{item.description}</p>
    </div>
  );
};

export default ProfileListing;
