export const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#FF4042", "#FF00C6", "#54FF00", "#FF0013"];

export const mainColors = [
  { background: "#DA4655", color: "#FFF" },
  { background: "#EAEAEA", color: "#2C2D64" },
  { background: "#91B848", color: "#FFF" },
  { background: "#AC4B77", color: "#FFF" },
  { background: "#EAEAEA", color: "#2C2D64" },
  { background: "#F7BF44", color: "#FFF" },
  { background: "#E16D4F", color: "#FFF" },
  { background: "#EAEAEA", color: "#2C2D64" },
  { background: "#5BAFD7", color: "#FFF" },
  { background: "#2C2D64", color: "#FFF" },
  { background: "#EAEAEA", color: "#2C2D64" }
];
