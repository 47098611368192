import React from "react";
import { format, formatDistanceToNow } from "date-fns";
import cn from "classnames";
import DOMPurify from "dompurify";

import { getFormattedDateTimeZone } from "../../../../../helpers";
import timeIcon from "../../../../../media/icons/time.svg";
import { parseDate } from "../../../../../helpers/parseDate";
import { Button } from "../../../../common/button";
import styles from "./notification.module.css";

export const NotificationItem = ({
  is_viewed,
  message,
  added,
  notification_id,
  setViewNotification,
  setUnViewNotification,
  index,
  type_title
}) => {
  const addedDate = format(getFormattedDateTimeZone(added), "yyyy-MM-dd' 'HH:mm:ss");
  const notificationViewed = Boolean(String(is_viewed) === "1");

  const onToggleView = () => {
    if (notificationViewed) {
      setUnViewNotification([notification_id], index);
    } else {
      setViewNotification([notification_id], index);
    }
  };

  return (
    <div className={cn(styles.popupItem, !notificationViewed && styles.new)}>
      <div className="d-flex justify-content-between">
        <p className={styles.title}>{type_title}</p>
      </div>
      {/* eslint-disable-next-line react/no-danger */}
      <pre dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(message) }} />
      <div className="d-flex justify-content-between">
        <div>
          <img className={styles.timeIcon} src={timeIcon} alt="" />
          <time className={styles.timeText}>{formatDistanceToNow(new Date(parseDate(addedDate)))}</time>
        </div>
        <Button onClick={onToggleView} className={cn("button--clear", styles.readMark)}>
          {notificationViewed ? "Mark as Unread" : "Mark as Read"}
        </Button>
      </div>
    </div>
  );
};
