import React from "react";
import format from "date-fns/format";
import { useTranslation } from "react-i18next";

import { Hr } from "../hr";
import { Label } from "../label";
import { Value } from "../value";
import styles from "./userInfo.module.css";

const areEqual = (prevProps, nextProps) => {
  return nextProps.name === prevProps.name && nextProps.email === prevProps.email && nextProps.phone === prevProps.phone;
};

export const UserInfo = React.memo(({ name, email, phone, added, leadId }) => {
  const { t } = useTranslation();

  return (
    <>
      <Hr />

      <div className={styles.userInfo}>
        <div className={styles.infoPanel}>
          <Label>FULL NAME</Label>

          <Value>{name}</Value>
        </div>
        <div className={styles.infoPanel}>
          <Label>{t("dashboard_single_lead_received")}</Label>

          <Value>{added && format(new Date(added.split(" ").join("T")), "MM/dd/yyyy - h:mm a")}</Value>
        </div>

        <div className={styles.infoPanel}>
          <Label isUpperCase>E-MAIL ADDRESS</Label>

          <Value>{email}</Value>
        </div>

        <div className={styles.infoPanel}>
          <Label>{t("dashboard_single_lead_id")}</Label>

          <Value>{leadId}</Value>
        </div>

        <div className={styles.infoPanel}>
          <Label isUpperCase>{t("phone")}</Label>

          <Value>{phone}</Value>
        </div>

        {/*
        <Socials linkedinUrl={linkedin_url} facebookUrl={facebook_url} twitterUrl={twitter_url}/>
      */}
      </div>
      <Hr />
    </>
  );
}, areEqual);
