import React from "react";

// import testCompany from "../../../media/images/testCompany.png";
import styles from "./associations.module.css";

const Associations = ({ associations }) => {
  return (
    <div className={styles.content}>
      <h3 className={styles.title}>Associations</h3>
      <div>
        {associations.map(item => {
          return (
            <div className={styles.tile} key={`${item.association_joinyear}-${item.association_name}`}>
              <div className={styles.flex}>
                {/*
                <span className={styles.tileLogo}>
                  <img src={testCompany} alt="" />
                </span>
                */}
                <div>
                  <h6 className={styles.tileTitle}>
                    {item.association_name} {item.association_position}
                  </h6>

                  <p className={styles.tileDescription}>Member since {item.association_joinyear}</p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Associations;
