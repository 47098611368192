import { useSelector } from "react-redux";
import React, { useState } from "react";
import cn from "classnames";

import { ReactComponent as DropdownIcon } from "../../../../media/icons/dropdown-arrow.svg";
import Checkbox from "../../../common/checkbox";
import { FILTERS } from "../../../../constants/questionsAnswers";
import styles from "./professions.module.css";

const areEqual = (prevProps, nextProps) => {
  return nextProps.activeFilters.professions.length === prevProps.activeFilters.professions.length;
};

export const Professions = React.memo(({ activeFilters, handleFilter }) => {
  const questions = useSelector(state => state.questions);
  const { professions } = questions;
  const [isActiveDropdown, setIsActiveDropdown] = useState(false);

  if (!professions?.length) {
    return null;
  }

  const toggleDropdown = () => {
    setIsActiveDropdown(prev => !prev);
  };

  return (
    <div className={styles.content}>
      <button type="button" className={styles.dropdownButton} onClick={toggleDropdown}>
        Professions
        <DropdownIcon className={cn(styles.icon, isActiveDropdown ? styles.iconActive : "")} />
      </button>

      {isActiveDropdown && (
        <div className={styles.dropdownContent}>
          {professions.map(({ profession_id: id, title }) => (
            <span key={id}>
              <Checkbox
                checked={activeFilters.professions.includes(id)}
                className={styles.checkbox}
                id={title}
                name={title}
                onChange={() => handleFilter(FILTERS.PROFESSIONS, id)}
                isNewStyles
                big
              >
                {title}
              </Checkbox>
            </span>
          ))}
        </div>
      )}
    </div>
  );
}, areEqual);
