import React from "react";
import cn from "classnames";

import styles from "./value.module.css";

export const Value = ({ children, isLink, href, className }) => {
  const isStars = children && String(children).includes("*");

  if (isLink) {
    return (
      <a className={cn(styles.value, className)} href={href} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    );
  }
  return <>{isStars ? <p className={styles.stars}>********************</p> : <p className={cn(styles.value, className)}>{children}</p>}</>;
};
