import { performance } from "../constants/actionTypes";

import { concatSimilarArray } from "../helpers";

const defaultState = {
  leadsByWeek: [],
  leadsByYear: [],
  leadsByMonth: [],
  leadsByListing: [],
  leadsByPracticeArea: [],
  error: null,
  preloaders: { leadsByMonth: true, leadsByYear: true, leadsByWeek: true, leadsByListing: true, leadsByPracticeArea: true }
};

export default function(state = defaultState, action) {
  switch (action.type) {
    case performance.SET_LOADING:
      return {
        ...state,
        preloaders: { ...state.preloaders, [action.payload.section]: action.payload.isSectionLoading }
      };

    case performance.GET_LEADS_BY_MONTH_SUCCESS:
      return {
        ...state,
        leadsByMonth: action.payload,
        preloaders: { ...state.preloaders, leadsByMonth: false }
      };

    case performance.GET_LEADS_BY_YEAR_SUCCESS:
      return {
        ...state,
        leadsByYear: action.payload,
        preloaders: { ...state.preloaders, leadsByYear: false }
      };

    case performance.GET_LEADS_BY_WEEK_SUCCESS:
      return {
        ...state,
        leadsByWeek: action.payload,
        preloaders: { ...state.preloaders, leadsByWeek: false }
      };

    case performance.GET_LEADS_BY_LISTING_SUCCESS:
      return {
        ...state,
        leadsByListing: concatSimilarArray(action.payload),
        preloaders: { ...state.preloaders, leadsByListing: false }
      };

    case performance.GET_LEADS_BY_PRACTICE_AREA_SUCCESS:
      return {
        ...state,
        leadsByPracticeArea: concatSimilarArray(action.payload),
        preloaders: { ...state.preloaders, leadsByPracticeArea: false }
      };

    case performance.GET_ERROR_DATA_FROM_SERVER:
      return {
        ...state,
        error: action.payload,
        preloaders: { ...state.preloaders, leadsByMonth: false, leadsByYear: false, leadsByWeek: false }
      };

    default:
      return {
        ...state
      };
  }
}
