export const getPracticeAreas = (idArrays = [], practices = []) => {
  const finalPracticeAreas = [];
  idArrays.forEach(item => {
    // eslint-disable-next-line radix
    if (parseInt(item) >= 1 && practices) {
      const practice = practices.find(({ id, practice_id }) => id === item || practice_id === item);
      if (practice) {
        finalPracticeAreas.push(practice.title);
      }
    }
  });

  return finalPracticeAreas;
};
