import { auth } from "../constants/actionTypes";

const defaultState = {
  errors: {
    login: null,
    resetPassword: null,
    setNewPassword: null,
    verificationCode: null,
    isNotExistEmail: false,
    failLogin: ""
  },
  isKeycloack: true,
  errorResetPassword: "",
  googleAuthId: null,
  googleAuthResponse: null,
  loginMethods: null,
  code: "",
  preloaders: {
    login: null,
    loginMethods: null,
    resetPassword: null,
    setNewPassword: null,
    verificationCode: null
  },
  success: {
    login: null,
    resetPassword: null,
    setNewPassword: null,
    verificationCode: null
  },
  user: null
};

export default function(state = defaultState, action) {
  const { payload, type } = action;
  switch (type) {
    case auth.SET_LOADING:
      return {
        ...state,
        preloaders: { ...state.preloaders, [payload.section]: payload.isSectionLoading },
        errorResetPassword: ""
      };

    case auth.SET_SUCCESS:
      return {
        ...state,
        success: { ...state.success, [payload.action]: payload.isActionSuccessful }
      };

    case auth.SET_ERRORS:
      return {
        ...state,
        errors: { ...state.errors, [payload.action]: payload.error }
      };

    case auth.RESET_PASSWORD:
      return {
        ...state,
        preloaders: {
          ...state.preloaders,
          resetPassword: false
        },
        errors: { ...defaultState.errors }
      };

    case auth.SET_NEW_PASSWORD:
      return {
        ...state,
        preloaders: {
          ...state.preloaders,
          setNewPassword: false
        },
        errors: { ...defaultState.errors }
      };

    case auth.SET_NEW_PASSWORD_FAIL:
      return {
        ...state,
        preloaders: {
          ...state.preloaders,
          setNewPassword: false
        },
        errors: { ...state.errors, setNewPassword: payload.error },
        errorResetPassword: payload.errorResetPassword
      };

    case auth.VERIFICATION_CODE_FAIL:
      return {
        ...state,
        preloaders: {
          ...state.preloaders,
          verificationCode: false
        },
        errors: { ...state.errors, verificationCode: payload }
      };

    case auth.VERIFICATION_CODE:
      return {
        ...state,
        code: payload.code,
        preloaders: {
          ...state.preloaders,
          verificationCode: false
        },
        errors: { ...defaultState.errors }
      };

    case auth.RESET_PASSWORD_FAIL:
      return {
        ...state,
        preloaders: {
          ...state.preloaders,
          resetPassword: false
        },
        errors: { ...state.errors, resetPassword: payload }
      };

    case auth.RESET_PASSWORD_FAIL_NOT_EXIST:
      return {
        ...state,
        preloaders: {
          ...state.preloaders,
          resetPassword: false
        },
        errors: { ...state.errors, isNotExistEmail: true }
      };

    case auth.LOGIN_SUCCESS:
      return {
        ...state,
        user: { ...payload },
        preloaders: { ...state.preloaders, login: false }
      };
    case auth.LOGIN_FAIL:
      return {
        ...state,
        errors: { ...state.errors, login: payload },
        preloaders: { ...state.preloaders, login: false }
      };

    case auth.SET_LOGIN_METHODS:
      return {
        ...state,
        loginMethods: payload
      };

    case auth.SET_GOOGLE_AUTH_ID:
      return {
        ...state,
        googleAuthId: payload
      };

    case auth.KEYCLOACK_FAIL:
      return {
        ...state,
        isKeycloack: false,
        preloaders: {
          ...state.preloaders,
          login: false
        }
      };

    case auth.SET_GOOGLE_AUTH_RESPONSE:
      return {
        ...state,
        googleAuthResponse: payload
      };

    default:
      return {
        ...state
      };
  }
}
