import axios from "axios";
import { referralRequestDetail } from "../constants/actionTypes";
import { actions as referralRequestsActions } from "./referralRequests";

export const actions = {
  getCase(id) {
    return dispatch => {
      dispatch(this.setLoading(true));
      axios
        .get(`members/json-api/case/case?lead_id=${id}`)
        .then(({ data }) => {
          dispatch(this.getMatches(id));
          dispatch(this.getProfessions());
          dispatch({
            type: referralRequestDetail.GET_CASE_SUCCESS,
            payload: {
              lead: data.lead,
              isFailedEmailVerification: data.lead.email_confirmed === "0",
              followups: data.followups,
              matches: data.matches,
              history: data.history,
              newsletters: data.newsletters
            }
          });
        })
        .catch(err => dispatch({ type: referralRequestDetail.GET_CASE_FAIL, payload: err }));
    };
  },

  getMatches(id) {
    return dispatch => {
      dispatch(this.setLoading(true));
      axios
        .get(`members/api-v2/referrals?command=matches&lead_id=${id}`)
        .then(() => {
          // eslint-disable-next-line
          // console.log("data", data);
        })
        .catch(err => dispatch({ type: referralRequestDetail.GET_CASE_FAIL, payload: err }));
    };
  },

  getPractices() {
    return dispatch => {
      dispatch(this.setLoading(true));
      axios({
        method: "get",
        responseType: "json",
        url: "members/json-api/case/directories-professions-practices",
        headers: {
          "Content-Type": "application/json"
        }
      })
        .then(res => {
          dispatch({
            type: referralRequestDetail.GET_CASE_PRACTICES,
            payload: res.data
          });
        })
        .catch(error => {
          console.error("getPractices", error);
        });
    };
  },
  setLoading(status) {
    return {
      type: referralRequestDetail.SET_CASE_PRELOADER,
      payload: status
    };
  },

  handleRemove(leadId, history, isLead, searchParams) {
    return dispatch => {
      dispatch(this.setLoading(true));

      axios({
        method: "DELETE",
        url: `members/json-api/case/case?lead_id=${leadId}`,
        headers: {
          "Content-Type": "application/json"
        }
      })
        .then(() => {
          if (isLead) {
            dispatch(referralRequestsActions.getCases(searchParams?.page));
          } else {
            history.push("/paid-leads?page=0");
          }
          dispatch(this.setLoading(false));
        })
        .catch(() => {
          dispatch(this.setLoading(false));
        });
    };
  },

  sendMatchMessage(leadId, message) {
    return dispatch => {
      dispatch(this.setLoading(true));

      axios({
        method: "post",
        url: "members/api-v2/referrals",
        headers: {
          "Content-Type": "application/json"
        },
        data: {
          command: "save_message",
          lead_id: leadId,
          message
        }
      })
        .then(() => {
          dispatch({
            type: referralRequestDetail.SEND_MATCH_MESSAGE
          });
        })
        .catch(() => {
          dispatch(this.setLoading(false));
        });
    };
  },

  changeCaseData(data) {
    return dispatch => {
      dispatch(this.setLoading(true));
      axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}/members/json-api/case/case`,
        data: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json"
        }
      })
        .then(() => {
          dispatch({
            type: referralRequestDetail.SUBMIT_CASE_DATA_SUCCESS,
            payload: data
          });
        })
        .catch(error => {
          console.error("changeCaseData", error);
          dispatch({
            type: referralRequestDetail.SUBMIT_CASE_DATA_FAIL,
            payload: error?.response?.data?.error || "Something went wrong"
          });
        });
    };
  },

  handleSendReferralsData(data) {
    return dispatch => {
      // dispatch(this.setLoading(true));
      axios({
        method: "post",
        url: "/members/api-v2/referrals",
        data: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json"
        }
      })
        .then(() => {
          /*
          dispatch({
            type: referralRequestDetail.SUBMIT_CASE_DATA_SUCCESS,
            payload: data
          });
          */
        })
        .catch(error => {
          console.error("changeCaseData", error);
          dispatch({
            type: referralRequestDetail.SUBMIT_CASE_DATA_FAIL,
            payload: error?.response?.data?.error || "Something went wrong"
          });
        });
    };
  },

  changeContactInfo(data) {
    return dispatch => {
      dispatch(this.setLoading(true));
      axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}/members/json-api/lead/update`,
        data: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json"
        }
      })
        .then(result => {
          if (result.data.ok) {
            dispatch({
              type: referralRequestDetail.CHANGE_CONTACT_INFO,
              payload: data
            });
          }
        })
        .catch(error => {
          dispatch({
            type: referralRequestDetail.CHANGE_CONTACT_INFO_FAIL,
            payload: error?.response?.data?.error || "Something went wrong"
          });
        });
    };
  },
  updateFollowupQuestions(answers) {
    return dispatch => {
      dispatch(this.setLoading(true));

      axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}/members/json-api/case/followups`,
        data: JSON.stringify(answers),
        headers: {
          "Content-Type": "application/json"
        }
      })
        .then(() => {
          dispatch({
            type: referralRequestDetail.UPDATED_FOLLOWUPS
          });
          dispatch(this.getCase(answers.lead_id));
        })
        .catch(() => {
          console.error("updateFollowupQuestions error");
          dispatch(this.setLoading(false));
        });
    };
  },

  updateFollowupQuestion(answers, leadId) {
    return dispatch => {
      dispatch(this.setLoading(true));

      axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}/members/json-api/case/followup`,
        data: JSON.stringify(answers),
        headers: {
          "Content-Type": "application/json"
        }
      })
        .then(() => {
          dispatch({
            type: referralRequestDetail.UPDATED_FOLLOWUPS
          });
          dispatch(this.getCase(answers.lead_id || leadId));
        })
        .catch(() => {
          console.error("updateFollowupQuestions error");
          dispatch(this.setLoading(false));
        });
    };
  },

  getProfessions() {
    return dispatch => {
      axios({
        method: "get",
        responseType: "json",
        url: "members/api-v2/professions?command=tree"
      })
        .then(res => {
          dispatch({
            type: referralRequestDetail.GET_PRACTICES,
            payload: res.data.data.find(item => item.title === "Profiles").children
          });
        })
        .catch(error => {
          dispatch({
            type: referralRequestDetail.GET_PRACTICES_FAIL,
            payload: error.response.data.error
          });
        });
    };
  },

  toggleModal(modal, isActive) {
    return {
      type: referralRequestDetail.TOGGLE_ACTIVITY_OF_MODAL,
      payload: {
        modal,
        isActive
      }
    };
  },

  toggleMessageModal(isActive, name) {
    return {
      type: referralRequestDetail.TOGGLE_ACTIVITY_MESSAGE_MODAL,
      payload: {
        isActive,
        name
      }
    };
  },

  clearAllModals() {
    return {
      type: referralRequestDetail.CLEAR_ALL_MODALS
    };
  },

  setContactInfoData(data) {
    return {
      type: referralRequestDetail.SET_CONTACT_INFO_DATA,
      payload: data
    };
  }
};
