import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import format from "date-fns/format";

import { Call } from "./call";
import { Pagination } from "../common/pagination";
import { formatDuration, paginate } from "../../helpers";
import { Preloader } from "../common/preloader";
import { Notification } from "../common/notification";
import emptyCall from "../../media/icons/empty-call.svg";
import { actions } from "../../actions/callHistory";
import { useDocTitle } from "../../helpers/hooks";
import styles from "./callHistory.module.css";

export const CallHistoryComponent = () => {
  const { t } = useTranslation();
  useDocTitle("dashboard_callhistory_page_metatitle");

  const dispatch = useDispatch();
  const getCallHistory = useCallback(() => dispatch(actions.getCallHistory()), [dispatch]);
  const setCallHistoryClean = useCallback(() => dispatch(actions.setCallHistoryClean()), [dispatch]);
  const callHistory = useSelector(state => state.callHistory);
  const { calls, callHistoryError, preloader } = callHistory;
  const [callsList, setCallList] = useState(calls);

  useEffect(() => {
    setCallList(calls);
  }, [calls]);

  useEffect(() => {
    window.history.replaceState(null, null, "?page=0");
    getCallHistory();
    return () => {
      setCallHistoryClean();
    };
  }, []);

  const searchParams = queryString.parse(window.location.search);
  const callsToRender = paginate(callsList, 25, searchParams.page);

  const callHistoryDesktop = callsToRender?.map(
    ({ from_number, date_start, duration, status, name_first, name_last, listing_url }, index) => {
      let directoryName = "";
      if (listing_url) {
        const { host } = new URL(listing_url);
        const hostUrl = host.indexOf("www.") === 0 ? host.slice(4) : host;
        directoryName = hostUrl.charAt(0).toUpperCase() + hostUrl.substr(1);
      }

      let colorStatus;

      switch (status.toLowerCase()) {
        case "completed":
          colorStatus = styles.completed;
          break;
        case "no-answer":
          colorStatus = styles.noAnswered;
          break;
        case "blocked":
          colorStatus = styles.blocked;
        // eslint-disable-next-line no-fallthrough
        default:
          colorStatus = null;
      }
      return (
        // eslint-disable-next-line react/no-array-index-key
        <tr className={styles.tableRow} key={`${name_first}-${index.toString()}`}>
          <td className={styles.td}>
            <a href={`tel:${from_number}`}>{from_number}</a>
          </td>
          <td className={styles.td}>
            {name_first} {name_last}
          </td>
          <td className={styles.td}>
            <time>{date_start && format(new Date(date_start.split(" ").join("T")), "yyyy/MM/dd, h:mm a")}</time>
          </td>
          <td className={styles.td}>
            <span className={cn(styles.indicator, colorStatus)}>{status}</span>
          </td>
          <td className={styles.td}>{formatDuration(duration)}</td>
          <td className={styles.td}>
            <a href={listing_url} target="_blank" rel="noopener noreferrer">
              {directoryName}
            </a>
          </td>
        </tr>
      );
    }
  );

  const callHistoryMobile = callsToRender?.map(({ from_number, attorney, date_start, status, duration, listing_url }, index) => {
    return (
      // eslint-disable-next-line react/no-array-index-key
      <React.Fragment key={`${attorney}-${index.toString()}`}>
        <Call
          from_number={from_number}
          attorney={attorney}
          date_start={date_start}
          status={status}
          duration={duration}
          listing_url={listing_url}
        />
      </React.Fragment>
    );
  });

  const makePaginationLinks = () => {
    const navLinks = [];
    const numberOfPages = callsList.length / 25;
    for (let i = 0; i <= numberOfPages; i++) {
      navLinks.push({ pathname: "/call-history", search: queryString.stringify({ ...searchParams, page: i }), number: i });
    }
    return navLinks;
  };
  const paginationLinks = makePaginationLinks();

  return (
    <div className={cn("wrapper", styles.wrapper)}>
      <div className="row mb20">
        <div className="col-md-9 col-xl-10">
          <h1 className="mb0">{t("dashboard_call_history_h1")}</h1>
        </div>
      </div>
      {!calls?.length && !preloader && (
        <div className={styles.emptyMobile}>
          <img src={emptyCall} alt="no_messages" />
        </div>
      )}

      <div className={styles.callHistoryMobile}>
        {callHistoryMobile}
        {preloader && <Preloader />}
      </div>

      {callHistoryError && <Notification error>Error from server</Notification>}

      <table className={styles.table}>
        <thead className={styles.heading}>
          <tr>
            <th className={styles.th}>{t("dashboard_call_history_phone")}</th>
            <th className={styles.th}>{t("dashboard_call_history_name")}</th>
            <th className={styles.th}>{t("dashboard_call_history_datetime")}</th>
            <th className={styles.th}>{t("dashboard_call_history_status")}</th>
            <th className={styles.th}>{t("dashboard_call_history_duration")}</th>
            <th className={styles.th}>{t("dashboard_call_history_source")}</th>
          </tr>
        </thead>
        <tbody>
          {preloader && (
            <tr>
              <th>
                <Preloader />
              </th>
            </tr>
          )}

          {callHistoryDesktop}
        </tbody>
      </table>
      {!calls?.length && !preloader && (
        <div className={styles.desktopEmpty}>
          <img src={emptyCall} alt="no_messages" />
        </div>
      )}

      <Pagination currentPage={parseInt(searchParams.page, 10)} links={paginationLinks} lastPage={paginationLinks.length - 1} />
    </div>
  );
};
