import React from "react";

import { Modal } from "../../../common/modal";
import { OverlayPreloader } from "../../../common/preloader";
import { ReactComponent as RemoveIcon } from "../../../../media/icons/remove.svg";
import styles from "./followingModal.module.css";

const FollowingModal = ({ onClose, preloader, followings, removeFollowing }) => {
  return (
    <Modal clearAllModals={onClose} closeModal={onClose} classNameMain={styles.modal}>
      {preloader && <OverlayPreloader />}

      <h3 className={styles.title}>Following</h3>

      {followings.map(item => {
        return (
          <div className={styles.followingItem} key={item.name}>
            <span>{item.name}</span>
            <button type="button" className={styles.removeButton} onClick={() => removeFollowing(item.user_following_id)}>
              <RemoveIcon />
            </button>
          </div>
        );
      })}

      <button className={styles.close} type="button" onClick={onClose}>
        Close
      </button>
    </Modal>
  );
};

export default FollowingModal;
