import React from "react";
import { useTranslation } from "react-i18next";

import { Textarea } from "../textarea";
import styles from "./replyBox.module.css";

export const ReplyBox = React.memo(({ submitFormAnswer }) => {
  const { t } = useTranslation();

  const onEnterPress = (e, callback) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      callback();
    }
  };

  return (
    <>
      <div className="down-line" />
      <div className={styles.replyBox}>
        <p className={styles.replyTitle}>Your reply</p>
        <Textarea
          buttonText={t("dashboard_qa_single_submitreply")}
          className={styles.textarea}
          onSubmit={submitFormAnswer}
          onKeyDown={onEnterPress}
          reply
        />
      </div>
      <div className="down-line" />
    </>
  );
});
