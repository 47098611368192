import React, { useCallback, useEffect } from "react";
import cn from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import { useTranslation } from "react-i18next";
import { CardList } from "../../../common/payment/cardList";
import { SelectedCardBlock } from "../../../common/payment/selectedCardBlock";
import { Modal } from "../../../common/modal";
import modalStyles from "../../../common/modal/modal.module.css";
import { Button } from "../../../common/button";
import { OverlayPreloader } from "../../../common/preloader";
import { modals as modalsConstant } from "../../../../constants/payPerLeadDetail";
import { actions as paymentActions } from "../../../../actions/payment";
import { actions as payPerLeadDetailActions } from "../../../../actions/payPerLeadDetail";
import { apiKey } from "../../../../constants/stripe";
import styles from "./payment.module.css";
import { CouponCode } from "../../couponCode";
import ModalControl from "../../../modalControl";

const stripePromise = loadStripe(apiKey);

export const Payment = ({ leadId, price }) => {
  const dispatch = useDispatch();
  const deleteCard = useCallback(cardToDelete => dispatch(paymentActions.deleteCard(cardToDelete)), [dispatch]);
  const getPaymentMethods = useCallback(() => dispatch(paymentActions.getPaymentMethods()), [dispatch]);
  const selectCard = useCallback(card => dispatch(paymentActions.selectCard(card)), [dispatch]);
  const updateDefaultSource = useCallback(cardId => dispatch(paymentActions.updateDefaultSource(cardId)), [dispatch]);
  const toggleModal = useCallback((modal, isActive) => dispatch(payPerLeadDetailActions.toggleModal(modal, isActive)), [dispatch]);
  const clearAllModals = useCallback(() => dispatch(payPerLeadDetailActions.clearAllModals()), [dispatch]);

  const payment = useSelector(state => state.payment);
  const { defaultSource, preloaders: paymentPreloaders, paymentMethods, selectedCard } = payment;
  const { loading, coupon } = useSelector(state => state.payPerLeadDetail);
  const { t } = useTranslation();

  const closeModal = () => {
    toggleModal(modalsConstant.payment, false);
  };

  const openModal = modal => {
    toggleModal(modal, true);
  };

  const handleClickNext = () => {
    onConfirm();
  };

  const handleEditCard = modal => {
    closeModal();
    openModal(modal);
  };

  const isDisabledButton = () => {
    return paymentPreloaders.paymentMethods || !selectedCard;
  };

  const onConfirm = () => {
    closeModal();
    openModal(modalsConstant.confirm);
  };

  useEffect(() => {
    if (!paymentMethods?.length) {
      getPaymentMethods();
    }
  }, [getPaymentMethods]); //eslint-disable-line

  return (
    <Elements stripe={stripePromise}>
      <Modal closeModal={closeModal} clearAllModals={clearAllModals}>
        <div className={modalStyles.form}>
          <section>
            <div className={modalStyles.pointer}>PURCHASE LEAD</div>
            <div className={modalStyles.popupHeading}>Payment Method & Pay</div>

            {paymentPreloaders.chargeForMembership && (
              <OverlayPreloader overlayClassName="sectionOverlayModal zIndex4" spinnerClassName={`zIndex4 ${modalStyles.spinnerOverlay}`} />
            )}

            {(paymentPreloaders.paymentMethods ||
              paymentPreloaders.updateCard ||
              paymentPreloaders.deleteCard ||
              coupon.preloader ||
              loading) && <OverlayPreloader overlayClassName="sectionOverlayModal zIndex4" spinnerClassName="zIndex4" />}

            {paymentMethods && (
              <>
                <span className={modalStyles.border} />
                <SelectedCardBlock
                  selectedCard={selectedCard}
                  defaultSource={defaultSource}
                  updateDefaultSource={updateDefaultSource}
                  deleteCard={deleteCard}
                  openModal={handleEditCard}
                />
                <CardList
                  defaultSource={defaultSource}
                  paymentMethods={paymentMethods}
                  selectedCard={selectedCard}
                  selectCard={selectCard}
                />
              </>
            )}

            <Button
              onClick={() => openModal(modalsConstant.addCard)}
              className={cn(modalStyles.buttonNewCard, paymentPreloaders.paymentMethods ? "button--outline" : null)}
            >
              + {t("dashboard_payments_addcardbutton")}
            </Button>
          </section>

          <CouponCode leadId={leadId} handleClickNext={handleClickNext} price={price} />

          <ModalControl onExit={closeModal} onConfirm={onConfirm} confirmClassName={isDisabledButton() ? styles.disabled : ""}>
            {t("dashboard_common_button_next")}
          </ModalControl>
        </div>
      </Modal>
    </Elements>
  );
};
