export const preloaderNames = {
  leadsByYear: "leadsByYear",
  leadsByListing: "leadsByListing",
  leadsByMonth: "leadsByMonth",
  leadsByWeek: "leadsByWeek",
  allLeads: "allLeads",
  trafficHistory: "trafficHistory",
  trafficSource: "trafficSource",
  geoChart: "geoChart"
};
