import React from "react";

import { Answer } from "../answer";
import { Comment } from "../comment";

export const Answers = ({ answers }) => {
  return (
    <>
      {answers.map(answer => {
        return (
          <Answer
            key={answer.answer_id}
            {...answer}
            commentsRender={
              answer.comments ? answer.comments.map(comment => <Comment key={comment.answer_comment_id} {...comment} />) : null
            }
          />
        );
      })}
    </>
  );
};
