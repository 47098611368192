import React from "react";
import { ReactComponent as CheckIcon } from "../../../media/icons/create-new-account-profession/check-cycle.svg";

import styles from "./freePlan.module.css";

const listings = [
  "Bio",
  "Licenses",
  "Pictures",
  "Contact info",
  "Insurances",
  "Social media profiles",
  "Specialties",
  "Languages",
  "Answer Q&As",
  "Website",
  "Associations",
  "Email notifications"
];

const FreePlan = () => {
  const listingsList = listings.map(name => {
    return (
      <div key={name} className={styles.listing}>
        <CheckIcon />
        <span className={styles.listingName}>{name}</span>
      </div>
    );
  });
  return (
    <div className={styles.plan}>
      <div className={styles.content}>
        <div>
          <h2 className={styles.name}>Forever Free Listing</h2>
          <p className={styles.description}>In one or more directories</p>
        </div>

        <div className={styles.priceContent}>
          <span className={styles.priceNumber}>$0</span>
          <span className={styles.priceDescription}>
            No credit cards <br /> required
          </span>
        </div>
      </div>
      <div className={styles.listingDetail}>
        <h5 className={styles.listingType}>LISTING DETAILS</h5>
        <div className={styles.listings}>{listingsList}</div>
      </div>
    </div>
  );
};

export default FreePlan;
