import React from "react";

import styles from "./locationsProfile.module.css";
import { ReactComponent as LocationIcon } from "../../../media/icons/location.svg";
import { ReactComponent as PhoneIcon } from "../../../media/icons/phone-filled.svg";

const LocationsProfile = ({ locations, website, headline }) => {
  const week = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

  return (
    <div className={styles.content}>
      <h3 className={styles.title}>Location(s)</h3>

      <h5 content={styles.name}>{headline}</h5>

      <div className={styles.flex}>
        {locations.map((item, index) => {
          return (
            <div className={styles.location}>
              <div>
                <h6 className={styles.locationName}>{index === 0 ? "Primary Office Location" : `Office Location ${index + 1}`}</h6>
                <p className={styles.locationAddress}>
                  <LocationIcon />
                  {item.street}
                  {item.city ? `, ${item.city}` : ""}
                  {item.state ? `, ${item.state} ` : ""}
                  {item.zip}
                </p>
                <div className={styles.locationPhone}>
                  <PhoneIcon />
                  <div>
                    {item.phone ? <span>{item.phone}</span> : null}
                    {item.fax ? <span>{item.fax}</span> : null}
                  </div>
                </div>
              </div>
              {item?.subs?.hours ? (
                <div className={styles.days}>
                  <h6 className={styles.daysTitle}>Hours of Operation</h6>
                  {Object.keys(item.subs.hours).map(hoursItem => {
                    return (
                      <div className={styles.dayItem}>
                        <span className={styles.weekDay}>{week[hoursItem]}</span>

                        <div>
                          {item.subs.hours[hoursItem].map(dayItem => {
                            return (
                              <span className={styles.workingHours}>
                                {dayItem.start} - {dayItem.end}
                              </span>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : null}
            </div>
          );
        })}
      </div>

      <h6 className={styles.websiteTitle}>Website</h6>
      {website && (
        <a href={website} className={styles.website}>
          {website}
        </a>
      )}
    </div>
  );
};

export default LocationsProfile;
