import React from "react";
import cn from "classnames";
import { useTranslation } from "react-i18next";

import { ReactComponent as IconYelp } from "../../../../media/icons/features/yelp.svg";
import { ReactComponent as IconLinkedin } from "../../../../media/icons/features/linkedin.svg";
import { ReactComponent as IconZapier } from "../../../../media/icons/features/zapier.svg";
import { ReactComponent as IconTrilio } from "../../../../media/icons/features/twilio.svg";

import styles from "./integrations.module.css";

const Integrations = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>{t("dashboard_features_integrations_title")}</h2>
      <div className={styles.integrations}>
        <div className={cn(styles.integrationsItem, styles.integrationsItemTrilio)} key="triluo">
          <IconTrilio />
        </div>
        <div className={cn(styles.integrationsItem, styles.integrationsItemYelp)} key="yelp">
          <IconYelp />
        </div>
        <div className={cn(styles.integrationsItem, styles.integrationsItemLinkedin)} key="linnked">
          <IconLinkedin />
        </div>
        <div className={cn(styles.integrationsItem, styles.integrationsItemZipier)} key="zipier">
          <IconZapier />
        </div>
      </div>
    </div>
  );
};

export default Integrations;
