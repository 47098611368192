import React from "react";
import cn from "classnames";
import format from "date-fns/format";
import { useTranslation } from "react-i18next";

import { formatDuration } from "../../../helpers";
import styles from "./call.module.css";

export const Call = ({ caller_name, className, date_start, duration, from_number, listing_url, status }) => {
  const hostUrl = listing_url ? new URL(listing_url).host : "";
  const { t } = useTranslation();

  const renderStatus = () => {
    const lowerCaseStatus = status.toLowerCase();
    if (lowerCaseStatus === "completed") {
      return { colorStatus: styles.completed, textStatus: t("dashboard_call_history_status_answered") };
    }

    if (lowerCaseStatus === "no-answer") {
      return { colorStatus: styles.noAnswered, textStatus: t("dashboard_call_history_status_missed") };
    }

    if (lowerCaseStatus === "blocked") {
      return { colorStatus: styles.blocked, textStatus: t("dashboard_call_history_status_blocked") };
    }

    return { colorStatus: "", textStatus: "" };
  };

  const { colorStatus, textStatus } = renderStatus();

  return (
    <div className={cn(styles.call, className)}>
      <div className={cn(styles.col6, styles.colSm4)}>
        <div className={styles.number}>
          <div className={styles.label}>PHONE NUMBER</div>
          <a href={`tel:${from_number}`} className={cn(styles.info, styles.active)}>
            {from_number}
          </a>
        </div>
        <div className={styles.date}>
          <span className={styles.label}>DATE & TIME</span>
          <p className={styles.info}>{date_start && format(new Date(date_start.split(" ").join("T")), "yyyy/MM/dd, h:mm a")}</p>
        </div>
      </div>
      <div className={cn(styles.col6, styles.colSm4)}>
        <div className={styles.source}>
          <span className={styles.label}>SOURCE</span>
          <p className={styles.info}>
            <a href={listing_url} target="_blank" rel="noopener noreferrer">
              {hostUrl.indexOf("www.") === 0 ? hostUrl.slice(4) : hostUrl}
            </a>
          </p>
        </div>
        <div className={styles.id}>
          <span className={styles.label}>ID</span>
          <p className={styles.info}>{caller_name}</p>
        </div>
      </div>
      <div className={cn(styles.col12, styles.colSm4)}>
        <div className={styles.duration}>
          <span className={styles.label}>DURATION</span>
          <p className={styles.info}>{formatDuration(duration)}</p>
        </div>
        <div className={styles.wrap}>
          <p className={styles.info2}>{formatDuration(duration)}</p>
          <span className={cn(styles.indicator, colorStatus)}>{textStatus}</span>
        </div>
      </div>
    </div>
  );
};
