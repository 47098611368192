import React, { useEffect } from "react";
import cn from "classnames";
import getMonth from "date-fns/getMonth";
import getYear from "date-fns/getYear";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";

import Checkbox from "../checkbox";
import { OverlayPreloader } from "../preloader";
import Input from "../input";
import SelectInput from "../selectInput";
import { renderBrandIcon } from "../../../helpers";
import ModalControl from "../../modalControl";
import styles from "../../payments/paymentMethods/paymentMethods.module.css";

const validate = ({ address_zip, address_country, name_f, name_l, expiredDate }) => {
  const errors = {};

  const validDateError = "Please enter a valid date";
  const validFutureText = "Please enter a future date";
  const requiredText = "Required";
  const month = expiredDate.slice(0, 2);
  const year = `20${expiredDate.slice(3, 5)}`;

  if (month || year) {
    const date = new Date();
    const yearNum = parseInt(year, 10);
    const monthNum = parseInt(month, 10);

    if (Number.isNaN(monthNum) || monthNum <= 0 || monthNum > 12) {
      errors.expiredDate = validDateError;
    } else if (yearNum === getYear(date)) {
      if (parseInt(month, 10) <= getMonth(date)) {
        errors.expiredDate = validFutureText;
      }
    }

    if (Number.isNaN(yearNum) || yearNum - getYear(date) > 50) {
      errors.expiredDate = validDateError;
    } else if (yearNum < getYear(date)) {
      errors.expiredDate = validFutureText;
    }
  }

  if (!name_f) {
    errors.name_f = requiredText;
  }
  if (!name_l) {
    errors.name_l = requiredText;
  }
  if (!month || !year) {
    errors.expiredDate = requiredText;
  }

  if (!address_zip) {
    errors.address_zip = requiredText;
  }
  if (!address_country) {
    errors.address_country = requiredText;
  }

  return errors;
};

const EditCard = ({ closeModal, countries, currentCardDetails, paymentSuccess, paymentPreloaders, updateCard }) => {
  const formik = useFormik({
    initialValues: {
      ...currentCardDetails,
      address_country: countries?.find(country => country.alpha2 === currentCardDetails.address_country) || "",
      name_f: currentCardDetails.name.split(" ")[0],
      name_l: currentCardDetails.name.split(" ")[1],
      expiredDate: `${
        currentCardDetails.exp_month < 10 ? `0${Number(currentCardDetails.exp_month)}` : currentCardDetails.exp_month
      }/${String(currentCardDetails.exp_year).slice(2, 4)}`
    },
    validate,
    onSubmit: ({ address_zip, address_country, default_source, name_f, name_l, expiredDate }) => {
      const month = expiredDate.slice(0, 2);
      const year = `20${expiredDate.slice(3, 5)}`;
      const formattedCardData = {
        address_zip,
        address_country: address_country.alpha2,
        exp_month: month,
        exp_year: year,
        default_source,
        name: `${name_f} ${name_l}`
      };

      updateCard({ ...currentCardDetails, ...formattedCardData });
    }
  });
  const { t } = useTranslation();

  const { address_zip, address_country, default_source: isDefault, name_f, name_l, expiredDate } = formik.values;

  useEffect(() => {
    if (paymentSuccess.editCard || paymentSuccess.updateCard) {
      closeModal();
    }
  }, [paymentSuccess.editCard, paymentSuccess.updateCard, closeModal]);

  const handleBlur = inputName => {
    formik.setFieldTouched(inputName, true);
  };

  const handleChange = e => {
    formik.setFieldValue(e.target.name, e.target.value);
  };

  const handleChangeExpiredDate = event => {
    const { value, name } = event.target;
    const firstPart = value.slice(0, 2);
    const secondPart = value.slice(2, 4);
    let newValue = "";

    const isExpiredDate = /^[0-9]*\/?[0-9]*$/.test(value);

    if (isExpiredDate) {
      if (value.length === 3) {
        if (value.includes("/")) {
          newValue = firstPart;
          formik.setFieldValue(name, newValue);
        } else {
          newValue = `${firstPart}/${secondPart}`;
          formik.setFieldValue(name, newValue);
        }
      } else {
        formik.setFieldValue(name, value);
      }
    }
  };

  const handleSelectChange = (newValue, actionMeta) => {
    const fieldName = actionMeta.name;
    formik.setFieldValue(fieldName, newValue);
  };

  return (
    <form className="modalForm">
      <fieldset disabled={paymentPreloaders.updateCard}>
        {paymentPreloaders.updateCard && <OverlayPreloader overlayClassName="sectionOverlayModal" />}
        <p className={styles.cardSubtitle}>{t("dashboard_pause_modal_payment_method_title")}</p>
        <h1 className={cn("title", styles.cardNewTitle)}>{t("dashboard_payment_edit_card")}</h1>
        <div className="down-line mb25" />

        <div className={styles.flexWrap}>
          <Input
            classNameError={styles.errorText}
            classNameWrap={styles.inputWrap}
            error={formik.errors.name_f}
            isInvalid={formik.errors.name_f && formik.touched.name_f}
            id="name_f"
            label={t("dashboard_payment_first_name")}
            onBlur={() => handleBlur("name_f")}
            onChange={handleChange}
            name="name_f"
            required
            value={name_f}
          />
          <Input
            classNameError={styles.errorText}
            classNameWrap={styles.inputWrap}
            error={formik.errors.name_l}
            isInvalid={formik.errors.name_l && formik.touched.name_l}
            id="name_l"
            label={t("dashboard_payment_last_name")}
            onBlur={() => handleBlur("name_l")}
            onChange={handleChange}
            name="name_l"
            required
            value={name_l}
          />
        </div>

        <div className={styles.flexWrapFake}>
          <Input
            classNameWrap={styles.inputWrap}
            id="fake_card"
            label="Card Number"
            name="fake_card"
            value={`****-****-****-${currentCardDetails.last4}`}
            disabled
          />
          <span className={styles.fakeBrandCardIcon}>
            <img src={renderBrandIcon(currentCardDetails.brand)} alt="" />
          </span>
        </div>

        <div className={styles.flexWrap}>
          <Input
            classNameError={styles.errorText}
            classNameWrap={styles.inputWrap}
            error={formik.errors.expiredDate}
            isInvalid={formik.errors.expiredDate && formik.touched.expiredDate}
            id="expiredDate"
            label={t("dashboard_payment_expiration_date")}
            onBlur={() => handleBlur("expiredDate")}
            onChange={handleChangeExpiredDate}
            maxLength="5"
            name="expiredDate"
            placeholder="MM/YY"
            required
            value={expiredDate}
          />
          <Input
            classNameError={styles.errorText}
            classNameWrap={styles.inputWrap}
            error={formik.errors.address_zip}
            isInvalid={formik.errors.address_zip && formik.touched.address_zip}
            id="address_zip"
            label={t("dashboard_payment_zip_code")}
            onBlur={() => handleBlur("address_zip")}
            onChange={handleChange}
            maxLength="5"
            name="address_zip"
            required
            value={address_zip}
          />
        </div>

        <div className={styles.flexWrap}>
          <SelectInput
            classNameError={styles.errorText}
            classNameWrap={styles.inputWrap}
            error={formik.errors.address_country}
            isInvalid={formik.errors.address_country && formik.touched.address_country}
            getOptionLabel={country => country.name}
            label={t("dashboard_payment_country")}
            required
            name="address_country"
            onChange={handleSelectChange}
            onBlur={() => handleBlur("address_country")}
            options={countries}
            value={address_country}
          />
        </div>

        <Checkbox
          className={styles.checkLabel}
          htmlFor="default-edit"
          checked={isDefault}
          onChange={() => formik.setFieldValue("default_source", !isDefault)}
          disabled={currentCardDetails.default_source}
        >
          {currentCardDetails.default_source
            ? t("dashboard_payment_methods_default_card")
            : t("dashboard_payment_methods_set_default_card")}
        </Checkbox>
      </fieldset>

      <ModalControl
        onExit={closeModal}
        confirmationType="submit"
        onConfirm={formik.handleSubmit}
        confirmDisabled={paymentPreloaders.updateCard}
        exitDisabled={paymentPreloaders.updateCard}
        confirmClassName={paymentPreloaders.updateCard ? "button--outline" : null}
      >
        {t("dashboard_common_savechanges")}
      </ModalControl>
    </form>
  );
};

export default EditCard;
