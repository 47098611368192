import { ReactComponent as AccountantsIcon } from "../media/icons/communities/accountants.svg";
import { ReactComponent as AgentsIcon } from "../media/icons/communities/agents.svg";
import { ReactComponent as ArtistsIcon } from "../media/icons/communities/artists.svg";
import { ReactComponent as BabysittersIcon } from "../media/icons/communities/babysitters.svg";
import { ReactComponent as DJsIcon } from "../media/icons/communities/djs.svg";
import { ReactComponent as DoctorsIcon } from "../media/icons/communities/doctors.svg";
import { ReactComponent as NursesIcon } from "../media/icons/communities/nurses.svg";
import { ReactComponent as HousekeepersIcon } from "../media/icons/communities/housekeepers.svg";
import { ReactComponent as LawyersIcon } from "../media/icons/communities/lawyers.svg";
import { ReactComponent as OrganizationsIcon } from "../media/icons/communities/organizations.svg";
import { ReactComponent as TherapistsIcon } from "../media/icons/communities/therapists.svg";
import { ReactComponent as TutorsIcon } from "../media/icons/communities/tutors.svg";

import { ReactComponent as AfghanIcon } from "../media/icons/communities/afghan.svg";
import { ReactComponent as AlbanianIcon } from "../media/icons/communities/albanian.svg";
import { ReactComponent as ArmenianIcon } from "../media/icons/communities/armenian.svg";
import { ReactComponent as AustrianIcon } from "../media/icons/communities/austrian.svg";
import { ReactComponent as AzeriIcon } from "../media/icons/communities/azeri.svg";
import { ReactComponent as BrazilianIcon } from "../media/icons/communities/brazilian.svg";
import { ReactComponent as ChineseIcon } from "../media/icons/communities/chinese.svg";
import { ReactComponent as CroatiaIcon } from "../media/icons/communities/croatia.svg";
import { ReactComponent as CzechIcon } from "../media/icons/communities/czech.svg";
import { ReactComponent as FrenchIcon } from "../media/icons/communities/french.svg";
import { ReactComponent as IranianIcon } from "../media/icons/communities/iranian.svg";
import { ReactComponent as PolishIcon } from "../media/icons/communities/polish.svg";

export const professionalDirectories = [
  { name: "Accountants", Icon: AccountantsIcon, link: "" },
  { name: "Agents", Icon: AgentsIcon, link: "" },
  { name: "Artists", Icon: ArtistsIcon, link: "" },
  { name: "Babysitters", Icon: BabysittersIcon, link: "" },
  { name: "DJs", Icon: DJsIcon, link: "" },
  { name: "Doctors", Icon: DoctorsIcon, link: "" },
  { name: "Nurses", Icon: NursesIcon, link: "" },
  { name: "Housekeepers", Icon: HousekeepersIcon, link: "" },
  { name: "Lawyers", Icon: LawyersIcon, link: "" },
  { name: "Organizations", Icon: OrganizationsIcon, link: "" },
  { name: "Therapists", Icon: TherapistsIcon, link: "" },
  { name: "Tutors", Icon: TutorsIcon, link: "" }
];

export const professionalDirectoriesObject = {
  Accountants: { name: "Accountants", Icon: AccountantsIcon, link: "" },
  Agents: { name: "Agents", Icon: AgentsIcon, link: "" },
  Artists: { name: "Artists", Icon: ArtistsIcon, link: "" },
  Babysitters: { name: "Babysitters", Icon: BabysittersIcon, link: "" },
  DJs: { name: "DJs", Icon: DJsIcon, link: "" },
  Doctors: { name: "Doctors", Icon: DoctorsIcon, link: "" },
  Nurses: { name: "Nurses", Icon: NursesIcon, link: "" },
  Housekeepers: { name: "Housekeepers", Icon: HousekeepersIcon, link: "" },
  Lawyers: { name: "Lawyers", Icon: LawyersIcon, link: "" },
  Organizations: { name: "Organizations", Icon: OrganizationsIcon, link: "" },
  Therapists: { name: "Therapists", Icon: TherapistsIcon, link: "" },
  Tutors: { name: "Tutors", Icon: TutorsIcon, link: "" }
};

export const professionalCommunities = [
  { name: "Afghan", Icon: AfghanIcon, link: "" },
  { name: "Albanian", Icon: AlbanianIcon, link: "" },
  { name: "Armenian", Icon: ArmenianIcon, link: "" },
  { name: "Austrian", Icon: AustrianIcon, link: "" },
  { name: "Azeri", Icon: AzeriIcon, link: "" },
  { name: "Brazilian", Icon: BrazilianIcon, link: "" },
  { name: "Chinese", Icon: ChineseIcon, link: "" },
  { name: "Croatia", Icon: CroatiaIcon, link: "" },
  { name: "Czech", Icon: CzechIcon, link: "" },
  { name: "French", Icon: FrenchIcon, link: "" },
  { name: "Iranian", Icon: IranianIcon, link: "" },
  { name: "Polish", Icon: PolishIcon, link: "" }
];
