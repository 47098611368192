import React from "react";
import { Link } from "react-router-dom";

import { ReactComponent as EmptyIcon } from "../../../media/icons/empty-listings.svg";
import styles from "./emptyState.module.css";

const EmptyState = () => {
  return (
    <div className={styles.wrapper}>
      <EmptyIcon className={styles.icon} />

      <Link className={styles.button} to="/landing">
        Home
      </Link>
    </div>
  );
};

export default EmptyState;
