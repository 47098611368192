import React, { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import Footer from "../footer";
import PublicHeader from "../publicHeader";
import EmptyState from "../publicListing/emptyState";

import Aside from "../publicOrganizations/profileAside";
import About from "../publicOrganizations/about";
import Schedule from "../publicOrganizations/schedule";
import Heading from "../publicOrganizations/headingProfile";
// import TimeZone from "../publicOrganizations/timeZone";
import Location from "../publicOrganizations/locationsProfile";
import Tags from "../publicOrganizations/tags";
import Video from "../publicOrganizations/video";
import Languages from "../publicOrganizations/languages";
import Socials from "../publicOrganizations/socials";
import Licenses from "../publicOrganizations/licenses";
import Insurances from "../publicOrganizations/insurances";
import Educations from "../publicOrganizations/educations";
import Associations from "../publicOrganizations/associations";
import { actions } from "../../actions/publicListings";
import { OverlayPreloader } from "../common/preloader";
import styles from "./publicProfile.module.css";
import { actions as profileActions } from "../../actions/account/profile";

const PublicProfile = ({ listingId }) => {
  const dispatch = useDispatch();
  const getListing = useCallback(id => dispatch(actions.getListing(id)), [dispatch]);
  const addToFavorite = useCallback(id => dispatch(actions.addToFavorite(id)), [dispatch]);
  const removeFavorite = useCallback(id => dispatch(actions.removeFavorite(id)), [dispatch]);
  const getProfile = useCallback(() => dispatch(profileActions.getProfile()), [dispatch]);
  const { preloaders, listing } = useSelector(state => state.publicListing);
  const { account } = useSelector(state => state);
  const { profile } = account;

  const {
    description,
    bookmeetings,
    insurances,
    lawfirms: locations,
    practices,
    languages,
    licenses,
    socials,
    attorney,
    consultation,
    primary_image_url: coverImage,
    education,
    associations,
    website,
    phone,
    bookmarked: isFavorite,
    listing_id,
    cover_image_url: logo,
    title: headline,
    tags,
    intro_video: introVideo
  } = listing;
  const videoId = useMemo(() => {
    if (introVideo) {
      const { searchParams } = new URL(introVideo);
      return introVideo && searchParams ? searchParams.get("v") : "";
    }

    return "";
  }, []);

  useEffect(() => {
    getListing(listingId);
  }, [listingId]);

  useEffect(() => {
    if (!profile.user_id) {
      getProfile();
    }
  }, [profile.user_id]);

  return (
    <>
      {preloaders.page && <OverlayPreloader />}

      <PublicHeader />

      <div className={styles.main}>
        <div className={styles.container}>
          {!listing.listing_id ? (
            <EmptyState />
          ) : (
            <>
              <div className={styles.content}>
                <Link className={styles.back} to="/profile">
                  <span className={styles.backIcon}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="18" viewBox="0 0 10 18" fill="none">
                      <path
                        d="M8.39262 17.1673C8.23826 17.1667 8.08556 17.1354 7.94337 17.0754C7.80119 17.0153 7.67233 16.9276 7.56428 16.8173L0.657617 9.87568C0.440324 9.65709 0.318359 9.3614 0.318359 9.05318C0.318359 8.74496 0.440324 8.44927 0.657617 8.23068L7.56428 1.31235C7.67306 1.20357 7.8022 1.11728 7.94433 1.05841C8.08645 0.999539 8.23878 0.969238 8.39262 0.969238C8.54645 0.969238 8.69878 0.999539 8.84091 1.05841C8.98303 1.11728 9.11217 1.20357 9.22095 1.31235C9.32973 1.42112 9.41602 1.55026 9.47489 1.69239C9.53376 1.83451 9.56406 1.98684 9.56406 2.14068C9.56406 2.29451 9.53376 2.44684 9.47489 2.58897C9.41602 2.7311 9.32973 2.86023 9.22095 2.96901L3.16595 9.00068L9.25595 15.0907C9.45701 15.2479 9.60095 15.4668 9.66572 15.7136C9.73048 15.9605 9.7125 16.2218 9.61451 16.4575C9.51653 16.6932 9.34396 16.8903 9.12326 17.0185C8.90255 17.1467 8.64589 17.199 8.39262 17.1673Z"
                        fill="black"
                      />
                    </svg>
                  </span>
                  Edit Profile
                </Link>
                <Heading
                  name={attorney}
                  logo={logo}
                  consultation={consultation}
                  phone={phone}
                  addToFavorite={() => addToFavorite(listing_id)}
                  removeFavorite={() => removeFavorite(listing_id)}
                  isShowFavorites={profile.user_id}
                  isFavorite={isFavorite}
                  coverImage={coverImage}
                  headline={headline}
                />

                <About description={description} />

                {bookmeetings ? <Schedule bookmeetings={bookmeetings} /> : null}
                {/*
                <TimeZone />
                */}
                {practices.length ? <Tags title="Industries" tags={practices.map(item => item._practice)} /> : null}

                {insurances.length ? <Insurances insurances={insurances} /> : null}

                {licenses.length ? <Licenses licenses={licenses} /> : null}

                {languages.length ? (
                  <Languages languages={languages.sort((a, b) => new Date(b.language_title) - new Date(a.language_title))} />
                ) : null}

                {practices.length ? <Tags title="Skills" tags={tags.map(item => item.tag)} /> : null}

                {education.length ? <Educations educations={education} /> : null}

                {associations.length ? <Associations associations={associations} /> : null}

                <Location headline={headline} locations={locations} website={website} />

                {videoId && <Video videoId={videoId} />}
                {socials.length ? <Socials socials={socials} /> : null}
              </div>
              <Aside />
            </>
          )}
        </div>
      </div>

      <Footer />
    </>
  );
};

export default PublicProfile;
