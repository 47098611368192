import React from "react";
import cn from "classnames";
import { useTranslation } from "react-i18next";

import { replacingSpaceUnderscore } from "../../../helpers";
import styles from "./status.module.css";

export const Status = ({ caseItem }) => {
  const { t } = useTranslation();
  const { status, matches } = caseItem;

  const renderStatus = () => {
    switch (replacingSpaceUnderscore(t(status))) {
      case "match": {
        if (Number(matches) === 1) {
          return (
            <span className={cn(styles[replacingSpaceUnderscore(t(status))], styles.status)}>
              ({matches}) {t(status)}
            </span>
          );
        }
        return <span className={cn(styles[replacingSpaceUnderscore(t(status))], styles.status)}>{t(status)}</span>;
      }
      case "matches": {
        if (matches > 1) {
          return (
            <span className={cn(styles[replacingSpaceUnderscore(t(status))], styles.status)}>
              ({matches}) {t(status)}
            </span>
          );
        }
        return <span className={cn(styles[replacingSpaceUnderscore(t(status))], styles.status)}>{t(status)}</span>;
      }
      default:
        return <span className={cn(styles[replacingSpaceUnderscore(t(status))], styles.status)}>{t(status)}</span>;
    }
  };

  return renderStatus();
};
