import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import { apiKey } from "../../../../../constants/stripe";
import { modals as modalsConstant } from "../../../../../constants/listings";
import { Modal } from "../../../../common/modal";
import { AddCard } from "../../../../common/payment/addCard";

const stripePromise = loadStripe(apiKey);

export const AddCardComponent = ({
  setErrorToken,
  paymentPreloaders,
  paymentSuccess,
  closeModal,
  clearAllModals,
  errorAddCard,
  addCard,
  clearTokenError,
  countries,
  errorToken,
  paymentMethods,
  modals
}) => {
  const handleClose = () => {
    closeModal(modalsConstant.addCard);
  };

  return (
    <Elements stripe={stripePromise}>
      <React.Fragment>
        {modals.addCard && (
          <Modal className="zIndex4" closeModal={handleClose} clearAllModals={clearAllModals}>
            <AddCard
              errorAddCard={errorAddCard}
              addCard={addCard}
              clearTokenError={clearTokenError}
              countries={countries}
              closeModal={() => closeModal(modalsConstant.addCard)}
              errorToken={errorToken}
              isFirst={!paymentMethods?.length}
              setErrorToken={setErrorToken}
              paymentPreloaders={paymentPreloaders}
              paymentSuccess={paymentSuccess}
            />
          </Modal>
        )}
      </React.Fragment>
    </Elements>
  );
};
