import React, { useRef, useState } from "react";
import cn from "classnames";

import { ReactComponent as CameraIcon } from "../../../media/icons/photo-listing.svg";
import { ReactComponent as CloseIcon } from "../../../media/icons/close-cross.svg";
import { ReactComponent as PencilIcon } from "../../../media/icons/edit-pencil-small.svg";
import styles from "./detailImages.module.css";

const DetailImages = ({ id, onUpload, validation, images, deleteImage }) => {
  if (Object.keys(validation).length && !validation.enabled) {
    return null;
  }
  const defaultImages = [1, 2, 3, 4, 5];

  const renderImages = [...images, ...defaultImages].slice(0, 5).map((item, index) => (
    /* eslint-disable-next-line */
    <DetailImagesItem key={`image-${index}`} index={index} id={id} onUpload={onUpload} image={item} deleteImage={deleteImage} />
  ));
  return (
    <div>
      <h3 className={styles.titleContent}>Detail Image</h3>
      <p className={styles.descriptionContent}>Create image at 1800 x 300 px. Keep images under 3MB and JPEG if possible.</p>
      <div className={styles.flex}>{renderImages}</div>
    </div>
  );
};

const DetailImagesItem = ({ id, onUpload, image, index, deleteImage }) => {
  const input = useRef();
  const [showError, setShowError] = useState(false);

  const handleFileInput = e => {
    e.persist();
    const { files } = e.target;

    if (files[0].size >= 5000000) {
      setShowError(true);
    } else {
      handleUploadImage(files[0]);

      if (showError) {
        setShowError(false);
      }
    }
  };

  const handleUploadImage = files => {
    if (files) {
      const formData = new FormData();

      formData.append("listing_id", id);
      formData.append("image_body", files);
      formData.append("command", "additional_image");
      formData.append("is_default", "0");

      onUpload(formData);
    }
  };

  const handleDragOver = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragLeave = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  const onDrop = e => {
    e.preventDefault();

    if (e.dataTransfer) {
      if (e.dataTransfer.items) {
        if (e.dataTransfer.items[0].kind === "file") {
          const file = e.dataTransfer.items[0].getAsFile();

          handleUploadImage(file);
        }
      } else {
        handleUploadImage(e.dataTransfer.files[0]);
      }
    }
  };

  const handleOpenFile = () => {
    input.current.click();
  };

  const handleDeleteImage = event => {
    event.stopPropagation();
    deleteImage(id, image.image_id);
  };

  return (
    <>
      {image.src ? (
        <div className={styles.container}>
          <span className={styles.image} style={{ backgroundImage: `url(${image.src})` }} />
          <div className={cn(styles.content, styles.contentActive)}>
            <button type="button" className={styles.photoCamera} onClick={handleDeleteImage}>
              <CloseIcon className={styles.closeIcon} />
            </button>
          </div>
        </div>
      ) : (
        <>
          <div
            tabIndex={-1}
            role="button"
            className={styles.container}
            onDrop={onDrop}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onClick={handleOpenFile}
          >
            <div className={styles.content}>
              <button type="button" className={styles.photoCamera}>
                <CameraIcon className={styles.cameraIcon} />

                <span className={cn(styles.edit, styles.editCover)}>
                  <PencilIcon />
                </span>
              </button>
              <h6 className={styles.title}>Click or Drop Image</h6>
            </div>
            {showError && <span className={styles.error}>Maximum limit 5 MB.</span>}
          </div>
        </>
      )}

      <input
        ref={input}
        accept=".png, .jpg, .jpeg, .webp, .gif"
        onChange={e => handleFileInput(e)}
        name="upload"
        className={styles.fileInput}
        type="file"
        id={`upload-${index}`}
      />
    </>
  );
};

export default DetailImages;
