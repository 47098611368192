import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import cn from "classnames";

import { PopupActions } from "../../../common/popupActions";
import actionStyles from "../../../common/popupActions/popupActions.module.css";
import { Button } from "../../../common/button";
import styles from "./remove.module.css";
import { ReactComponent as CloseIcon } from "../../../../media/icons/close-cross-thin.svg";

export const Remove = ({ handleRemove, isLeadPage }) => {
  const [actionPrompt, setActionPrompt] = useState(false);
  const { t } = useTranslation();
  const ref = useRef();

  const closePrompt = () => {
    setActionPrompt(prev => !prev);
  };

  const confirmDelete = () => {
    handleRemove();
    closePrompt();
  };

  const handleClickOutside = event => {
    if (ref.current && !ref.current.contains(event.target) && actionPrompt) {
      setActionPrompt(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className={styles.content}>
      <p className={styles.text}>
        You can edit and update your request during the review process. To edit <br /> your request, click and update each section.
      </p>
      <PopupActions
        promptRoot={isLeadPage ? <CloseIcon className={styles.iconHide} /> : <>{t("dashboard_referrals_details_delete_anchor")}</>}
        setActionPrompt={closePrompt}
        promptRootClass={cn(styles.removeButton, isLeadPage ? styles.removeButtonLead : "")}
        promptClass={styles.prompt}
        close={actionPrompt}
      >
        <React.Fragment>
          <p className={actionStyles.warning}>{t("dashboard_referrals_details_delete_confirm")}</p>
          <div className={actionStyles.promptWrap}>
            <Button className={actionStyles.cancel} onClick={closePrompt}>
              {t("dashboard_common_button_cancel")}
            </Button>
            <Button className={actionStyles.confirm} onClick={confirmDelete}>
              {t("dashboard_common_button_yes")}
            </Button>
          </div>
        </React.Fragment>
      </PopupActions>
    </div>
  );
};
