import React, { useState } from "react";
import { Link } from "react-router-dom";

/*
// import map from "../../../media/images/searchResult/testMap.jpg";
import { ReactComponent as LinkIcon } from "../../../media/icons/link.svg";
*/

import styles from "./sidebar.module.css";
import Checkbox from "../../common/checkbox";
import Select from "../../common/selectInput";
// import { getCountryNameFromCode, renderSelectCountries } from "../../../helpers/select";
// import { customStyles as selectStyles } from "../../../constants/select";
/*

const practicesMarks = [
  {
    name: "Appeals",
    url: "/"
  },
  {
    name: "Bankruptcy and Debt",
    url: "/"
  },
  {
    name: "Business Law",
    url: "/"
  },
  {
    name: "Civil Rights",
    url: "/"
  },
  {
    name: "Criminal Law",
    url: "/"
  },
  {
    name: "DUI and DWI",
    url: "/"
  },
  {
    name: "Divorce",
    url: "/"
  },
  {
    name: "Family Law",
    url: "/"
  },
  {
    name: "Immigration",
    url: "/"
  },
  {
    name: "Insurance",
    url: "/"
  },
  {
    name: "Labor and Employment",
    url: "/"
  },
  {
    name: "Medical Malpractice",
    url: "/"
  },
  {
    name: "Personal Injury",
    url: "/"
  }
];

const statesMarks = [
  {
    name: "Arizona",
    url: "/"
  },
  {
    name: "Colorado",
    url: "/"
  },
  {
    name: "California",
    url: "/"
  },
  {
    name: "Georgia",
    url: "/"
  },
  {
    name: "Illinois",
    url: "/"
  },
  {
    name: "Maryland",
    url: "/"
  },
  {
    name: "Criminal Law",
    url: "/"
  },
  {
    name: "DUI and DWI",
    url: "/"
  },
  {
    name: "Divorce",
    url: "/"
  },
  {
    name: "Labor and Employment",
    url: "/"
  },
  {
    name: "Medical Malpractice",
    url: "/"
  },
  {
    name: "Personal Injury",
    url: "/"
  }
];
*/

const Sidebar = ({ featuredPractices }) => {
  const [freeConsultation, setFreeConsultation] = useState(false);
  return (
    <aside className={styles.aside}>
      {/*
      <div className={styles.mapImage}>
        <img src={map} alt="" />
      </div>
      */}
      <h3 className={styles.title}>Popular Practice Areas</h3>
      <div className={styles.marks}>
        {featuredPractices?.map(item => {
          return (
            <Link to={`/search-result?practice_id=${item.id}`} className={styles.marksItem} key={`${item.title}-practices`}>
              {item.title}
            </Link>
          );
        })}
      </div>

      <div className={styles.filterContent}>
        <h3 className={styles.title}>Free consultation</h3>

        <Checkbox
          checked={freeConsultation}
          className={styles.checkbox}
          id="consultation"
          name="consultation"
          onChange={() => setFreeConsultation(prev => !prev)}
          isNewStyles
        >
          Free consultation
        </Checkbox>
      </div>

      <div className={styles.filterContent}>
        <h3 className={styles.title}>Filter by languages</h3>

        <Select
          // error={errors.country}
          // isInvalid={errors.country && touched.country}
          // getOptionLabel={option => getCountryNameFromCode(countries, option)}
          // getOptionValue={option => option}
          inputId="languages"
          name="country"
          // options={renderSelectCountries(countries, false)}
          // onBlur={() => handleBlur("country")}
          // handleChange={handleSelectChange}
          // styles={selectStyles}
          required
          // value={[values.country]}/z
          classNameWrap={styles.select}
          placeholder="Languages"
        />
      </div>

      {/*
      <h3 className={styles.title}>States</h3>
      <div className={styles.marks}>
        {statesMarks.map((item, index) => {
          return (
            // eslint-disable-next-line
            <a href="/" className={styles.marksItem} key={`${item.name}-${index}-states`}>
              {item.name}
            </a>
          );
        })}
      </div>

      <h3 className={styles.title}>Legal and government links</h3>
      <p className={styles.description}>
        Since you are looking for an African American / black lawyer in California, USA you might find the following legal and government
        links useful:
      </p>
      <div className={styles.links}>
        <a href="/" className={styles.linksItem}>
          <LinkIcon />
          Texas Legislature
        </a>
        <a href="/" className={styles.linksItem}>
          <LinkIcon />
          Texas State Bar Association
        </a>
        <a href="/" className={styles.linksItem}>
          <LinkIcon />
          Library of Congress - Texas Laws
        </a>
      </div>

      <p className={styles.description}>
        All Arab lawyers in USA and other lawyers in USA who provide translators or offer specialized legal services to the Arab community
        are encouraged to request a free directory listing on our site.
      </p>
      <h2 className={styles.subTitle}>Publish Your Free Listing</h2>

      <Link to="/login" className={styles.linkFilled}>
        Publish your Free Listing
      </Link>
      */}
    </aside>
  );
};

export default Sidebar;
