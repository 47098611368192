import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enUS from "./lang/enUS.json";

const i18NextOptions = {
  resources: {
    en: enUS
  },
  lng: "en",

  keySeparator: false, // we do not use keys in form messages.welcome

  interpolation: {
    escapeValue: false // react already safes from xss
  }
};

i18n.use(initReactI18next).init(i18NextOptions);
