import React from "react";
import queryString from "query-string";

import { Pagination } from "../../common/pagination";
import styles from "./leadAndReferralsPagination.module.css";

const LeadAndReferralsPagination = ({
  setPerPage,
  setPerPageLead,
  getCases,
  activeSort,
  listActiveNetwork,
  listActivePractice,
  searchParams,
  searchReferrals,
  history,
  pagination,
  paginationReferrals,
  leads,
  referralsList,
  isReferrals
}) => {
  const handlePerPageReferrals = perPage => {
    setPerPage(perPage);
    history.push({ pathname: "/referrals", search: queryString.stringify({ ...searchParams, page: 0 }), number: 0 });

    getCases({
      sort: activeSort?.value,
      dirId: listActiveNetwork,
      practiceId: listActivePractice,
      type: searchParams.tab,
      page: 0,
      search: searchReferrals,
      perPage,
      isSelectedFilter: true
    });
  };

  const handlePerPageLead = perPage => {
    setPerPageLead(perPage);

    history.push(`/paid-leads?page=0&tab=${searchParams.tab}`);
  };

  const makePaginationLinks = () => {
    const navLinks = [];
    const numberOfPages = pagination.lastPageNum || 0;

    for (let i = 0; i <= numberOfPages; i++) {
      navLinks.push({ pathname: "/paid-leads", search: queryString.stringify({ ...searchParams, page: i }), number: i });
    }

    return navLinks;
  };

  const makeReferralsPaginationLinks = () => {
    const navLinks = [];
    const numberOfPages = paginationReferrals.lastPageNum || 0;

    for (let i = 0; i <= numberOfPages; i++) {
      navLinks.push({ pathname: "/referrals", search: queryString.stringify({ ...searchParams, page: i }), number: i });
    }

    return navLinks;
  };

  const isFirstPage = paginationReferrals.currentPageNum === 0 && paginationReferrals.lastPageNum === 0;

  const isShowPaginationLeadPagination =
    (pagination.lastPageNum !== 0 && !!leads.length && leads.length >= pagination.perPage) || Number(searchParams.page) >= 1;

  const isShowPagination =
    (!!referralsList.length && referralsList.length >= paginationReferrals.perPage && !isFirstPage) ||
    (Number(searchParams.page) >= 1 && !isFirstPage) ||
    paginationReferrals.perPage > 10;

  return (
    <>
      {isShowPaginationLeadPagination && !isReferrals && (
        <Pagination
          perPage={pagination.perPage}
          handlePerPage={handlePerPageLead}
          links={makePaginationLinks()}
          currentPage={parseInt(searchParams.page, 10)}
          lastPage={pagination.lastPageNum}
        />
      )}

      {isShowPagination && isReferrals && (
        <Pagination
          currentPage={parseInt(searchParams.page, 10) || 0}
          links={makeReferralsPaginationLinks()}
          lastPage={paginationReferrals.lastPageNum}
          perPage={paginationReferrals.perPage}
          handlePerPage={handlePerPageReferrals}
          className={styles.pagination}
        />
      )}
    </>
  );
};

export default LeadAndReferralsPagination;
