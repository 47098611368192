import React from "react";
import { useTranslation } from "react-i18next";
import format from "date-fns/format";

import { Modal } from "../../../../common/modal";
import modalStyles from "../../../../common/modal/modal.module.css";
import { getInitialsListing } from "../../../../../helpers";
import { OverlayPreloader } from "../../../../common/preloader";
import styles from "./downgradePeriod.module.css";
import { Button } from "../../../../common/button";
import ModalControl from "../../../../modalControl";

export const DowngradePeriod = ({ closeModal, clearAllModals, itemToEdit, unDowngradeMembership, downgradeMembershipHow, preloaders }) => {
  const { t } = useTranslation();
  const {
    listing_id: listingId,
    product_name: productName,
    attorney,
    _canceled_to_date: canceledDate,
    uncancel_ids: unCancelIds
  } = itemToEdit;

  const onExit = () => {
    closeModal();
  };

  const onCancel = () => {
    unDowngradeMembership(listingId);
  };

  const onDowngrade = () => {
    downgradeMembershipHow(listingId);
  };

  return (
    <Modal closeModal={closeModal} classNameMain={styles.paymentOkMain} clearAllModals={clearAllModals}>
      {preloaders && <OverlayPreloader overlayClassName="sectionOverlayModal zIndex4" spinnerClassName="zIndex4" />}
      <section>
        <div className={modalStyles.pointer}>{t("dashboard_listing_downgrade_period_title")}</div>
        <div className={styles.popupHeading}>{t("dashboard_listing_downgrade_period_title")}</div>
      </section>

      <div className={styles.description}>{t("dashboard_listing_downgrade_period_desc")}</div>
      {canceledDate && (
        <p className={styles.deleteDescription}>
          Scheduled to be downgraded to a free listing on {format(new Date(canceledDate), "MM/dd/yyyy")}
        </p>
      )}
      <div className={styles.listing}>
        <div className={styles.listingInitials}>{getInitialsListing(itemToEdit)}</div>
        <div>
          <p className={styles.listingType}>{productName}</p>
          {attorney && <p className={styles.listingName}>{attorney}</p>}
        </div>
      </div>
      <div className={styles.buttonControls}>
        {unCancelIds && (
          <Button onClick={onCancel} className={styles.cancelDowngrade}>
            {t("dashboard_listing_downgrade_period_cancel_button")}
          </Button>
        )}
        <Button onClick={onDowngrade} className={styles.downgradeNow}>
          {t("dashboard_listing_downgrade_period_now_button")}
        </Button>
      </div>
      <ModalControl onExit={onExit} isHideExitConfirm />
    </Modal>
  );
};
