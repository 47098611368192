import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { modals as modalsConstant } from "../../constants/listings";
import { Button } from "../common/button";
import styles from "./verificationBanner.module.css";
import { actions as listingActions } from "../../actions/listing";

const areEqual = (prevProps, nextProps) => {
  return nextProps.openModal === prevProps.openModal;
};

export const VerificationBanner = React.memo(() => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { verifyCodeCheck } = useSelector(state => state.main.verifyEmailCode);
  const { profile } = useSelector(state => state.account);
  const toggleActivityOfModal = (modal, isActive) => dispatch(listingActions.toggleActivityOfModal(modal, isActive));
  const isEmailVerified = profile?.email_verified === "true";

  const openModal = modalName => {
    toggleActivityOfModal(modalName, true);
  };

  if (!profile?.email_verified || isEmailVerified || verifyCodeCheck) {
    return null;
  }

  return (
    <div className={styles.banner}>
      <div className={styles.bannerText}>{t("dashboard_home_verifyemail_banner_title")}</div>
      <Button onClick={() => openModal(modalsConstant.verification)} className={styles.button}>
        {t("dashboard_home_verifyemail_banner_sendcode")}
      </Button>
    </div>
  );
}, areEqual);
