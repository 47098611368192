import React from "react";

import styles from "../paymentMethods/paymentMethods.module.css";
import stripeLogo from "../../../media/icons/stripe-logo.svg";

const StripeInfo = () => {
  return (
    <div className={styles.flex}>
      <img className={styles.stripeIcon} src={stripeLogo} alt="stripe" />
      <p className={styles.stripeDisclaimer}>
        Stripe is certified to PCI Service Provider Level 1, the most stringent level of certification. Your billing information is not
        saved on our servers and is securely processed by Stripe
      </p>
    </div>
  );
};

export default StripeInfo;
