import React from "react";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import { differenceInCalendarDays } from "date-fns";

import { Status } from "../../listings/components/status";
import { MainSection } from "../mainSection";
import { ReactComponent as PremiumBadge } from "../../../media/icons/premium-badge.svg";
import messageIcon from "../../../media/icons/header/messages.svg";
import { modals as modalsConstant, statuses } from "../../../constants/listings";
import { ControlMenu } from "../../listings/components/controlMenu";
import { Avatar } from "../../listings/components/avatar";
import { OverlayPreloader } from "../../common/preloader";
import { DowngradePeriod } from "../../listings/components/downgradePeriod";
import styles from "./listings.module.css";

export const Listings = ({ listings, openModal, listingPreloaders }) => {
  const { t } = useTranslation();

  const isEmpty = !listings?.length;

  return (
    <MainSection
      title={t("dashboard_home_listing_block_title")}
      linkText={t("dashboard_home_viewall")}
      to="/listings?page=0"
      icon={messageIcon}
      isEmpty={isEmpty}
    >
      {(listingPreloaders.deleteMembership || listingPreloaders.membership) && <OverlayPreloader />}
      {!isEmpty && (
        <div className={cn("card", styles.wrap)}>
          {listings.map(
            (
              {
                attorney,
                is_premium,
                listing_id,
                membership,
                listing_url: listingUrl,
                paused,
                listings_image_name: listingsImageName,
                _canceled_to_date: canceledDate,
                change_status: changeStatus,
                publish_status: publishStatus,
                _profession: listingType
              },
              index
            ) => {
              const isPremium = is_premium === "true" || is_premium === true;
              const isActiveStatus = changeStatus === statuses.active;
              const activeListingsClassName = isActiveStatus ? styles.listingActive : "";
              const sponsorListingsClassName = isPremium && isActiveStatus ? styles.listingActive : "";
              const isDowngradePeriod = (canceledDate ? differenceInCalendarDays(new Date(canceledDate), new Date()) : 0) >= 1;

              return (
                <div className={cn(styles.listing, activeListingsClassName, sponsorListingsClassName)} key={listing_id + index.toString()}>
                  <div className={styles.lawyerInfo}>
                    <Avatar
                      attorneyName={attorney}
                      imageName={listingsImageName}
                      isActiveStatus={isActiveStatus}
                      className={styles.avatar}
                      listingId={listing_id}
                    />
                    <div className={styles.infoText}>
                      <h4 className={styles.category}>
                        {membership} {isPremium && <PremiumBadge className={styles.badgeIcon} />}
                      </h4>
                      <h3 className={styles.name}>{attorney}</h3>
                      <Status
                        status={publishStatus}
                        listingUrl={listingUrl}
                        reviewStatus={changeStatus}
                        isActiveStatus={changeStatus === statuses.active}
                        isHomePage
                        isHideDetailDescription
                        handleRenew={() => openModal(modalsConstant.upgrade, index)}
                      />

                      {isDowngradePeriod && (
                        <DowngradePeriod time={canceledDate} handleDowngrade={() => openModal(modalsConstant.downgradePeriod, index)} />
                      )}
                    </div>
                  </div>
                  <ControlMenu
                    className={styles.actions}
                    promptClassName={styles.promptClassName}
                    paused={paused}
                    status={changeStatus}
                    listing_id={listing_id}
                    isSponsor={isPremium}
                    openModal={openModal}
                    canceledDate={canceledDate}
                    index={index}
                    handleUpgrade={() => openModal(modalsConstant.upgrade, index)}
                    listingType={listingType}
                  />
                </div>
              );
            }
          )}
        </div>
      )}
    </MainSection>
  );
};
