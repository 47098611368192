import React from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import format from "date-fns/format";
import { useTranslation } from "react-i18next";

import locationIcon from "../../../media/icons/location.svg";
import calendarIcon from "../../../media/icons/calendar.svg";
import qIcon from "../../../media/icons/q.svg";
import styles from "./questionBox.module.css";

export const QuestionBox = ({ questionId, added, answerTimes, city, state, title, summary, tab }) => {
  const { t } = useTranslation();
  const titleConcat = title?.length >= 180 ? title.substring(0, 180).concat("...") : title;
  const summaryConcat = summary?.length >= 180 ? summary.substring(0, 180).concat("...") : summary;

  return (
    <div className={styles.box}>
      <img className={styles.questionQA} src={qIcon} alt="" />
      <div className={styles.content}>
        <p className={styles.title}>{titleConcat}</p>
        <p className={styles.answerDescription}>{summaryConcat}</p>
        <Link to={`/question-answer/${questionId}?tab=${tab}`} className={cn(styles.linkToPage, styles.showTablet)}>
          {t("dashboard_common_readmore")}
        </Link>
      </div>

      <div className={styles.container}>
        <div className={styles.answerCount}>
          ({answerTimes}) {answerTimes === 1 ? <>{t("dashboard_home_answer")}</> : "answers"}
        </div>
        <div className={styles.dateFlex}>
          <div className={styles.city}>
            <img className={styles.infoIcon} src={locationIcon} alt="" />
            {city ? `${city}, ` : ""} {state}
          </div>
          <div className={styles.date}>
            <img className={styles.infoIcon} src={calendarIcon} alt="" />
            {added && format(new Date(added.split(" ").join("T")), "MMM do yyyy, h:mm a")}
          </div>
        </div>
      </div>
      <Link to={`/question-answer/${questionId}?tab=${tab}`} className={cn(styles.linkToPage, styles.hideTablet)}>
        {t("dashboard_common_readmore")}
      </Link>
    </div>
  );
};
