import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Success } from "../success";

export const PaymentSuccess = ({ closeModal, clearAllModals, clearSavedPaymentData, clearPaymentState, isPendingPayment, isExpired }) => {
  const { t } = useTranslation();

  useEffect(() => {
    return () => {
      clearPaymentState();
      clearSavedPaymentData();
    };
  }, []); // eslint-disable-line

  const renderTitle = () => {
    if (isExpired) {
      return "RENEW SUBSCRIPTION";
    }
    if (isPendingPayment) {
      return t("pendingpayment");
    }

    return t("dashboard_listings_modal_upgrade_top_title");
  };

  const renderText = () => {
    if (isExpired) {
      return t("dashboard_listings_modal_renew_success_description");
    }

    if (isPendingPayment) {
      return "You have completed your payment";
    }

    return "You have successfully upgraded your listing";
  };

  return (
    <Success
      closeModal={closeModal}
      clearAllModals={clearAllModals}
      subTitle={renderTitle()}
      title="Payment Successful"
      message={renderText()}
    />
  );
};
