import React from "react";
import { useTranslation } from "react-i18next";

import Info from "../info";

const Access = ({ member, handleAccess, handleRemove, handleAccept }) => {
  const { t } = useTranslation();

  return (
    <div>
      <Info
        list={member}
        buttonText="View Access"
        emptyText={t("dashboard_teams_myaccess_desc")}
        handleAccess={handleAccess}
        handleRemove={handleRemove}
        handleAccept={handleAccept}
      />
    </div>
  );
};

export default Access;
