import React from "react";
import cn from "classnames";
import { useSelector } from "react-redux";

import { Button } from "../../common/button";
import { ReactComponent as NotificationsIcon } from "../../../media/icons/notifications.svg";
import { PopupNotification } from "../popups/notification";
import styles from "./notification.module.css";

export const Notification = ({ onCloseHandler, popup }) => {
  const {
    globalInfo: { unread_notifications }
  } = useSelector(state => state.main);

  return (
    <div className={cn(styles.notificationWrap, Number(unread_notifications) > 0 ? styles.counterNotification : null)}>
      <Button className={cn("button--clear", styles.popup)} onClick={onCloseHandler}>
        <NotificationsIcon />
      </Button>
      {popup === "notification" && <PopupNotification onCloseHandler={onCloseHandler} />}
    </div>
  );
};
