import React, { useState } from "react";
import cn from "classnames";

import { ReactComponent as DropdownIcon } from "../../../media/icons/listing-dropdown-arrow.svg";
import styles from "./dropdown.module.css";

const Dropdown = ({ children, title }) => {
  const [isActive, setIsActive] = useState(false);
  const bodyClassName = isActive ? styles.bodyActive : "";
  const iconClassName = isActive ? styles.iconActive : "";

  const toggleDropDown = () => {
    setIsActive(prev => !prev);
  };

  return (
    <div className={styles.dropdown}>
      <div className={styles.head} tabIndex={-1} role="button" onClick={toggleDropDown}>
        <div>
          <h2 className={styles.title}>{title}</h2>
        </div>
        <DropdownIcon className={cn(styles.icon, iconClassName)} />
      </div>
      {isActive && <div className={cn(styles.body, bodyClassName)}>{children}</div>}
    </div>
  );
};

export default Dropdown;
