import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { actions as signUpActions } from "../../actions/signup";
import Heading from "./heading";
import Professions from "./professions";
import ProfessionsCommunities from "../communities/components/professions";
// import DifferentProfession from "./differentProfession";
import FreePlan from "./freePlan";
import SponsorPlan from "./sponsorPlan";
import Banner from "./banner";
import styles from "./professionCommunities.module.css";
import { OverlayPreloader } from "../common/preloader";
import { useDocTitle } from "../../helpers/hooks";
import { professionNames } from "../../constants/createProfession";
import PublicHeader from "../publicHeader";
import Footer from "../footer";
import { professionalDirectoriesObject } from "../../constants/communitiesPage";

const ProfessionCommunities = () => {
  const dispatch = useDispatch();

  const history = useHistory();
  const currentProfession = history.location.pathname
    .replace("-communities", "")
    .replace("/", "")
    .toLowerCase();
  const getPublicProfessions = useCallback(
    (profession, isNotLoadDetail) => dispatch(signUpActions.getPublicProfessions(profession, isNotLoadDetail)),
    [dispatch]
  );
  const cleanProfessional = useCallback(() => dispatch(signUpActions.cleanProfessional()), [dispatch]);
  const { professionsLoading, professions, otherProfessions } = useSelector(state => state.signUp);

  const capitalizeFirstLetter = string => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const renderProfessionName = professionName => {
    if (professionName === professionNames.djs) {
      return "DJs";
    }

    return capitalizeFirstLetter(professionName);
  };

  useDocTitle(`${renderProfessionName(currentProfession)} Communities - Heritage Web Dashboard`);

  useEffect(() => {
    if (window.scrollY) {
      window.scroll(0, 0);
    }

    if (!professions.length) {
      getPublicProfessions(currentProfession, professions.length);
    }

    return () => {
      cleanProfessional();
    };
  }, []); // eslint-disable-line

  useEffect(() => {
    window.scroll(0, 0);
  }, [window.scrollY]); // eslint-disable-line

  const normalizeProfessionalList = otherProfessions
    .filter(item => professionalDirectoriesObject[item.title])
    .map(item => {
      const objectItem = professionalDirectoriesObject[item.title];

      return { ...item, name: item.title, Icon: objectItem.Icon, link: `/${item.title.toLowerCase()}-communities` };
    });
  return (
    <>
      <PublicHeader />

      {professionsLoading && <OverlayPreloader overlayClassName="sectionOverlay" />}

      {/*
      <RegistrationComponent
        error={error}
        signUp={signUp}
        success={success}
        loading={loading}
        history={history}
        title={t("dashboard_newaccount_landing_page_caption")}
      />
      */}

      <section className={styles.wrapper}>
        <Heading currentProfession={currentProfession} />
        <Professions professions={professions} currentProfession={currentProfession} />
        {/*
        <DifferentProfession professions={otherProfessions} currentProfession={currentProfession} cleanProfessional={cleanProfessional} />
        */}
        <h2 className={styles.title}>Select a Different Profession</h2>

        <ProfessionsCommunities professionalList={normalizeProfessionalList} onClick={cleanProfessional} isLink />

        <FreePlan />
        <SponsorPlan />
      </section>
      <Banner />
      <Footer />
    </>
  );
};

export default ProfessionCommunities;
