import React, { useCallback } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { useDispatch, useSelector } from "react-redux";
import { loadStripe } from "@stripe/stripe-js";

import { apiKey } from "../../../constants/stripe";
import { Modal } from "../../common/modal";
import { modals as modalsConstant } from "../../../constants/listings";
import { actions as paymentActions } from "../../../actions/payment";
import { AddCard } from "../../common/payment/addCard";
import EditCard from "../../common/payment/editCard";

const stripePromise = loadStripe(apiKey);

export const PaymentStripe = ({ countries, closeModal, clearAllModals }) => {
  const dispatch = useDispatch();

  const addCard = useCallback((cardData, isFirst) => dispatch(paymentActions.addCard(cardData, isFirst)), [dispatch]);
  const clearTokenError = useCallback(() => () => dispatch(paymentActions.clearTokenError()), [dispatch]);
  const setErrorToken = useCallback(err => dispatch(paymentActions.setErrorToken(err)), [dispatch]);
  const updateCard = useCallback(card => dispatch(paymentActions.updateCard(card)), [dispatch]);

  const {
    preloaders: paymentPreloaders,
    success: paymentSuccess,
    paymentMethods,
    selectedCard,
    error: { stripeTokenError: errorToken, addCard: errorAddCard }
  } = useSelector(state => state.payment);
  const { modals } = useSelector(state => state.listing);

  return (
    <Elements stripe={stripePromise}>
      <React.Fragment>
        {modals.addCard && (
          <Modal className="zIndex4" closeModal={() => closeModal(modalsConstant.addCard)} clearAllModals={clearAllModals}>
            <AddCard
              errorAddCard={errorAddCard}
              addCard={addCard}
              clearTokenError={clearTokenError}
              countries={countries}
              closeModal={() => closeModal(modalsConstant.addCard)}
              errorToken={errorToken}
              isFirst={!paymentMethods?.length}
              setErrorToken={setErrorToken}
              paymentPreloaders={paymentPreloaders}
              paymentSuccess={paymentSuccess}
            />
          </Modal>
        )}

        {selectedCard && modals.editCard && (
          <Modal className="zIndex4" closeModal={() => closeModal(modalsConstant.editCard)} clearAllModals={clearAllModals}>
            <EditCard
              closeModal={() => closeModal(modalsConstant.editCard)}
              countries={countries}
              currentCardDetails={selectedCard}
              paymentPreloaders={paymentPreloaders}
              paymentSuccess={paymentSuccess}
              updateCard={updateCard}
            />
          </Modal>
        )}
      </React.Fragment>
    </Elements>
  );
};
