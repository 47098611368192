import React, { useCallback } from "react";
import { useDispatch } from "react-redux";

import { ReactComponent as EmptyIcon } from "../../../../media/icons/new-empty-listing.svg";
import { actions as mainActions } from "../../../../actions/main";
import styles from "./emptyState.module.css";
import { listingProfessionType } from "../../../../constants/listings";

export const EmptyState = ({ isOrganizations, isAllListings, isJobs, isEvents }) => {
  const dispatch = useDispatch();

  const toggleNewListing = useCallback((isActive, listingType) => dispatch(mainActions.toggleNewListing(isActive, listingType)), [
    dispatch
  ]);

  const handleOpenNewListing = listingType => {
    toggleNewListing(true, listingType);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.iconContainer}>
        <EmptyIcon className={styles.icon} />
      </div>

      <h2 className={styles.title}>Publish your first free listing</h2>

      <div>
        {isAllListings || isOrganizations ? (
          <button type="button" onClick={() => handleOpenNewListing(listingProfessionType.organizations)} className={styles.button}>
            + Business / Organization Listing
          </button>
        ) : null}
        {isAllListings || isEvents ? (
          <button type="button" onClick={() => handleOpenNewListing(listingProfessionType.events)} className={styles.button}>
            + Event Listing
          </button>
        ) : null}

        {isAllListings || isJobs ? (
          <button type="button" onClick={() => handleOpenNewListing(listingProfessionType.jobs)} className={styles.button}>
            + Job Listing
          </button>
        ) : null}
      </div>
    </div>
  );
};
