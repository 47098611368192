import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import format from "date-fns/format";
import { useTranslation } from "react-i18next";

import { AddNotifications } from "../../addNotifications";
import { DeleteNotification } from "../deleteNotification";
import { NotificationSuccess } from "../../notificationSuccess";
import { DeleteNotificationSuccess } from "./deleteNotificationSuccess";
import { actions as listingActions } from "../../../actions/listing";
import { modals as modalsConstant } from "../../../constants/listings";

export const Notifications = ({ clearAllModals, modals, memberData, notificationModalData }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleNotificationEmail = useCallback(email => dispatch(listingActions.handleNotificationEmail(email)), [dispatch]);
  const handleVerifyEmail = useCallback(code => dispatch(listingActions.handleVerifyEmail(code)), [dispatch]);
  const handleNotificationPhone = useCallback(phone => dispatch(listingActions.handleNotificationPhone(phone)), [dispatch]);
  const handleVerifyPhone = useCallback(code => dispatch(listingActions.handleVerifyPhone(code)), [dispatch]);
  const submitPhoneEmail = useCallback((params, type) => dispatch(listingActions.submitPhoneEmail(params, true, type)), [dispatch]);
  const { notificationEmail, notificationPhone } = useSelector(state => state.listing);

  const handleRemoveVerifiedPhone = () => {
    const phoneNumbers = memberData.verified_phones.map(item =>
      item.phone === notificationModalData ? { ...item, date_removed: format(new Date(), "yyyy-MM-dd") } : item
    );

    submitPhoneEmail(
      {
        listing_id: memberData.listing_id,
        verifiedemails: memberData.verified_emails,
        verifiedphones: phoneNumbers,
        leademail_primary_only: memberData.leademail_primary_only
      },
      modalsConstant.deletePhoneSuccess
    );
  };

  const handleRemoveVerifiedEmail = () => {
    const leadsEmail = memberData.verified_emails.map(item =>
      item.email === notificationModalData ? { ...item, date_removed: format(new Date(), "yyyy-MM-dd") } : item
    );

    submitPhoneEmail(
      {
        listing_id: memberData.listing_id,
        verifiedemails: leadsEmail,
        verifiedphones: memberData.verified_phones,
        leademail_primary_only: memberData.leademail_primary_only
      },
      modalsConstant.deleteEmailSuccess
    );
  };

  const {
    preloaders: { notifications }
  } = useSelector(state => state.listing);

  return (
    <>
      {modals.deleteEmail && (
        <DeleteNotification
          closeModal={clearAllModals}
          clearAllModals={clearAllModals}
          subtitle="DELETE NOTIFICATION"
          title="Delete Listing Notification"
          text="Please confirm deleting the email address below from this listing’s notification recipients."
          value={t("dashboard_listing_notifications_emailinput_title")}
          keyValue={notificationModalData}
          listing="Listing"
          listingType={memberData.product_name}
          listingName={memberData.attorney}
          handleConfirm={handleRemoveVerifiedEmail}
          preloaders={notifications}
        />
      )}

      {modals.deletePhone && (
        <DeleteNotification
          closeModal={clearAllModals}
          clearAllModals={clearAllModals}
          subtitle="DELETE NOTIFICATION"
          title="Delete Listing Notification"
          text="Please confirm deleting the phone number below from this listing’s notification recipients."
          value={t("dashboard_listing_notifications_phoneinput_title")}
          keyValue={notificationModalData}
          listing="Listing"
          listingType={memberData.product_name}
          listingName={memberData.attorney}
          handleConfirm={handleRemoveVerifiedPhone}
          preloaders={notifications}
        />
      )}

      {modals.deletePhoneSuccess && (
        <DeleteNotificationSuccess
          closeModal={clearAllModals}
          clearAllModals={clearAllModals}
          subtitle="DELETE NOTIFICATION"
          title="Notification Deleted"
          text="Your notification has been successfully deleted."
        />
      )}

      {modals.deleteEmailSuccess && (
        <DeleteNotificationSuccess
          closeModal={clearAllModals}
          clearAllModals={clearAllModals}
          subtitle="DELETE NOTIFICATION"
          title="Notification Deleted"
          text="Your notification has been successfully deleted."
        />
      )}

      {modals.addEmailSuccess && (
        <NotificationSuccess
          closeModal={clearAllModals}
          clearAllModals={clearAllModals}
          subtitle="ADDITIONAL EMAIL NOTIFICATIONS"
          title="Successful Verification"
          text="You have successfully verified and added your email address"
        />
      )}

      {modals.addPhoneSuccess && (
        <NotificationSuccess
          closeModal={clearAllModals}
          clearAllModals={clearAllModals}
          subtitle="ADDITIONAL PHONE NOTIFICATIONS"
          title={t("dashboard_account_phone_verify_success_title")}
          text={t("dashboard_account_phone_verify_success_msg")}
        />
      )}

      {modals.addEmail && (
        <AddNotifications
          closeModal={clearAllModals}
          clearAllModals={clearAllModals}
          subTitle="ADDITIONAL EMAIL NOTIFICATIONS"
          title={t("dashboard_listing_notifications_email_title")}
          text={t("dashboard_listing_notifications_email_description")}
          label={t("dashboard_listing_notifications_emailinput_title")}
          buttonText={t("dashboard_home_verifyemail_modal_sendcode")}
          buttonTextSent={t("dashboard_common_button_emailsent")}
          handleNotify={handleNotificationEmail}
          handleVerify={handleVerifyEmail}
          preloaders={notifications}
          isError={notificationEmail.isError}
          isErrorVerification={notificationEmail.isErrorVerification}
          activeValue={notificationEmail.email}
          isEmail
        />
      )}

      {modals.addPhone && (
        <AddNotifications
          closeModal={clearAllModals}
          clearAllModals={clearAllModals}
          subTitle="ADDITIONAL PHONE NOTIFICATIONS"
          title={t("dashboard_listing_notifications_phone_title")}
          text={t("dashboard_listing_notifications_phone_description")}
          label={t("dashboard_listing_notifications_phoneinput_title")}
          buttonTextSent="Code sent"
          buttonText="Send Verification Code"
          handleNotify={handleNotificationPhone}
          handleVerify={handleVerifyPhone}
          preloaders={notifications}
          isError={notificationPhone.isError}
          activeValue={notificationPhone.phone}
          isErrorVerification={notificationPhone.isErrorVerification}
        />
      )}
    </>
  );
};
