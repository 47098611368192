import { intervalToDuration, formatDuration } from "date-fns";

export const parseDate = str => {
  const [year, month, date, hours, minutes, seconds] = str
    .match(/(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})/)
    .slice(1)
    .map(n => parseInt(n, 10));
  return new Date(year, month - 1, date, hours, minutes, seconds);
};

export const parseDateToYearMonthDay = date => {
  const duration = intervalToDuration({
    start: new Date(date),
    end: new Date()
  });

  const units = ["years", "months", "weeks", "days"];
  const nonzero = Object.entries(duration)
    // eslint-disable-next-line
    .filter(([_, value]) => value || 0 > 0)
    // eslint-disable-next-line
    .map(([unit, _]) => unit);

  return `${formatDuration(duration, {
    format: units.filter(i => new Set(nonzero).has(i)).slice(0, 3),
    delimiter: ", "
  })} ago`;
};
