import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import { useTranslation } from "react-i18next";

import { tabs } from "../../constants/referralRequests";
import { listingType } from "../../constants/listings";
import { actions as payPerLeadActions } from "../../actions/payPerLead";
import { actions as listingActions } from "../../actions/listing";
import { actions as referralRequestsActions } from "../../actions/referralRequests";
import { getQueryParams } from "../../helpers";

export const useLoadAllUseEffects = ({
  setListActiveNetwork,
  setListActivePractice,
  listActiveNetwork,
  setLeadsList,
  // setSearch,
  setNotification,
  setError,
  listActivePractice,
  filtrationByDate,
  setReferralsList,
  activeSort,
  searchValue,
  perPage: perPageValue,
  perPageLead,
  team
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { location } = history;
  const getLeads = useCallback(
    ({ dir_id, page, practice_id, tab, sort, search, perPage, teamValue }) =>
      dispatch(payPerLeadActions.getLeads({ dir_id, page, practice_id, tab, sort, search, perPage, team: teamValue })),
    [dispatch]
  );
  const { updateReferralRequest, cases, preloaders: preloadersReferrals, isShowReferralsTab } = useSelector(
    state => state.referralRequests
  );

  const setLeadsClear = useCallback(() => dispatch(payPerLeadActions.setLeadsClear()), [dispatch]);
  const setMembershipClear = useCallback(() => dispatch(listingActions.setMembershipClear()), [dispatch]);
  const getPPLListings = useCallback(() => dispatch(listingActions.getExistingMemberships(listingType.ppl)), [dispatch]);
  const searchParams = queryString.parse(location.search);
  const { leads, pagination, loading } = useSelector(state => state.payPerLead);
  const { success: listingSuccess, error: listingError } = useSelector(state => state.listing);
  const getCases = useCallback(data => dispatch(referralRequestsActions.getCases(data)), [dispatch]);
  const setClearReferrals = useCallback(() => dispatch(referralRequestsActions.setClearReferrals()), [dispatch]);
  const { t } = useTranslation();
  const isReferrals = searchParams.tab?.includes("referrals");
  const queryParams = useMemo(() => getQueryParams(history.location.search), [history.location.search]);

  useEffect(() => {
    if (searchParams && !searchParams?.tab) {
      history.push(
        `/paid-leads?page=0${queryParams.user_id ? `&user_id=${queryParams.user_id}` : ""}&tab=${
          isShowReferralsTab ? "referrals" : "notpurchased"
        }`
      );
    }
  }, [location]); // eslint-disable-line

  useEffect(() => {
    if (pagination.currentPageNum !== null && !isReferrals && !searchParams?.tab) {
      history.push(`/paid-leads?page=${pagination.currentPageNum}&tab=notpurchased`);
    }
  }, [pagination.currentPageNum]); // eslint-disable-line

  useEffect(() => {
    if (searchParams && searchParams.tab) {
      setListActiveNetwork([]);
      setListActivePractice([]);
      // history.push(`/paid-leads?page=0&tab=${searchParams.tab}`);
    }
  }, [searchParams.tab]); // eslint-disable-line

  useEffect(() => {
    document.title = t("dashboard_ppl_metatitle");
    getPPLListings();

    return () => {
      setLeadsClear();
      setClearReferrals();
      setMembershipClear();
    };
  }, []); // eslint-disable-line

  useEffect(() => {
    if (leads) {
      setLeadsList(leads);
    }
  }, [leads]); // eslint-disable-line

  useEffect(() => {
    if (cases) {
      setReferralsList(cases);
    }
  }, [cases]); // eslint-disable-line

  useEffect(() => {
    if (searchParams.tab && !loading && !isReferrals) {
      getLeads({
        dir_id: listActiveNetwork,
        page: searchParams.page,
        practice_id: listActivePractice,
        tab: searchParams.tab,
        sort: filtrationByDate?.value,
        search: searchValue,
        perPage: perPageLead,
        teamValue: team
      });
    }

    if (isReferrals && !preloadersReferrals.preloaderCase) {
      getCases({
        team,
        dirId: listActiveNetwork,
        practiceId: listActivePractice,
        type: searchParams.tab,
        page: searchParams.page,
        sort: activeSort?.value,
        perPage: perPageValue,
        isSelectedFilter: Boolean(listActiveNetwork?.length || listActivePractice?.length || activeSort?.value || Number(searchParams.page))
      });

      if (team.value) {
        getLeads({
          dir_id: listActiveNetwork,
          page: searchParams.page,
          practice_id: listActivePractice,
          tab: tabs.notpurchased,
          sort: filtrationByDate?.value,
          search: searchValue,
          perPage: perPageLead,
          teamValue: team
        });
      }
    }
  }, [listActivePractice, listActiveNetwork, searchParams.page, searchParams.tab, filtrationByDate?.value, searchValue, perPageLead, team]); // eslint-disable-line

  useEffect(() => {
    if (isReferrals) {
      document.title = t("dashboard_referrals_all_meta_title");
    }
  }, [updateReferralRequest, searchParams.tab, searchParams.page]); // eslint-disable-line

  useEffect(() => {
    if (listingSuccess?.deleteMembership) {
      setNotification("Pay Per Lead Subscription Paused");
      setTimeout(() => setNotification(null), 7000);
    }
  }, [listingSuccess]); // eslint-disable-line

  useEffect(() => {
    if (listingError?.deleteMembership) {
      setError("Error Deleting Item");
      setTimeout(() => setError(null), 7000);
    }
  }, [listingError]); // eslint-disable-line
};
