import React from "react";
import DOMPurify from "dompurify";

import image from "../../../media/images/articleDefault.jpg";
import styles from "./articles.module.css";

const Articles = ({ articles }) => {
  const renderArticles = articles.map(item => {
    const { title, author, path, html } = item;
    return (
      <div className={styles.item} key={title}>
        <div className={styles.itemImage}>
          <img src={image} alt="Article default" />
        </div>
        <div className={styles.itemContent}>
          <a href={path} className={styles.itemName}>
            {title}
          </a>
          {/* eslint-disable-next-line react/no-danger */}
          <p className={styles.itemDescription} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(html) }} />
          <span className={styles.itemAuthor}>by {author}</span>
        </div>
      </div>
    );
  });
  return (
    <div className={styles.content}>
      <h4 className={styles.title}>Latest Articles</h4>
      <div>{renderArticles}</div>
    </div>
  );
};

export default Articles;
