import React from "react";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";

import Input from "../../../common/input";
import { Modal } from "../../../common/modal";
import { email as emailRegex } from "../../../../constants/regex";
import { OverlayPreloader } from "../../../common/preloader";
import ModalControl from "../../../modalControl";
import styles from "./changeEmail.module.css";

const ChangeEmail = ({
  email = "",
  clearAllModals,
  closeModal,
  preloaders,
  verificationError,
  sendEmailCode,
  saveNewEmailPassword,
  cleanNewEmailPassword
}) => {
  const { t } = useTranslation();

  const validate = values => {
    const { email: emailField } = values;
    const errors = {};

    const requiredText = t("dashboard_common_required");
    const invalidUrlText = t("dashboard_form_validations_invalid_email");

    if (!emailField) {
      errors.email = requiredText;
    }

    if (emailField && !emailField.match(emailRegex)) {
      errors.email = invalidUrlText;
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      email
    },
    onSubmit: () => {
      saveNewEmailPassword(formik.values.email);
      sendEmailCode(formik.values.email, () => formik.setFieldValue("email", ""));
    },
    validate
  });

  const { values, errors, touched, handleSubmit, setFieldTouched } = formik;

  const handleChange = e => {
    formik.setFieldValue(e.target.name, e.target.value);
  };

  const handleBlur = e => {
    setFieldTouched(e.target.name, true);
  };

  const handleClose = () => {
    cleanNewEmailPassword();
    closeModal();
  };

  return (
    <Modal classNameMain={styles.modal} clearAllModals={clearAllModals} closeModal={handleClose}>
      {preloaders.verifications && <OverlayPreloader />}

      <h2 className={styles.subTitle}>ACCOUNT SETTINGS</h2>
      <h1 className={styles.title}>Change Email</h1>
      <form onSubmit={handleSubmit}>
        <Input
          classNameWrap={styles.modalInput}
          error={errors.email}
          id="email"
          isInvalid={errors.email && touched.email}
          name="email"
          label="New Email Address"
          onBlur={handleBlur}
          onChange={handleChange}
          required
          value={values.email}
        />
      </form>
      {verificationError.changeEmail && <div className={styles.error}>Could you please check your email?</div>}

      <ModalControl onExit={handleClose} onConfirm={handleSubmit} isClose>
        {t("dashboard_common_button_next")}
      </ModalControl>
    </Modal>
  );
};

export default ChangeEmail;
