import React from "react";
import { useTranslation } from "react-i18next";

import { Success } from "../success";

export const PauseSuccess = ({ closeModal, clearAllModals }) => {
  const { t } = useTranslation();

  return (
    <Success
      closeModal={closeModal}
      clearAllModals={clearAllModals}
      subTitle={t("dashboard_single_listing_pause")}
      title={t("dashboard_pause_modal_success_h1")}
      message={t("dashboard_pause_modal_success_description")}
    />
  );
};
