import React from "react";
import cn from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { ReactComponent as EditIcon } from "../../../../../media/icons/edit-listing-icon.svg";
import { Button } from "../../../../common/button";
import { actions as mainActions } from "../../../../../actions/main";
import styles from "./heading.module.css";

export const Heading = ({ onClose }) => {
  const dispatch = useDispatch();
  const setViewAllNotification = () => dispatch(mainActions.setViewAllNotification());
  const { notifications } = useSelector(state => state.main);
  const {
    globalInfo: { unread_notifications }
  } = useSelector(state => state.main);

  const isShowMarkAssRead = Number(unread_notifications) > 0 && notifications.length;

  return (
    <div className={styles.popupHeading}>
      <div>
        <p className={styles.name}>Notifications</p>
        {isShowMarkAssRead && (
          <Button onClick={setViewAllNotification} className={cn(styles.subtitle, "button--clear")}>
            Mark All as Read
          </Button>
        )}
      </div>
      <Link to="/manage-notification" className={styles.edit} onClick={onClose}>
        <EditIcon />
      </Link>
    </div>
  );
};
