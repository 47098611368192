import React, { useEffect } from "react";
import cn from "classnames";
import { useTranslation } from "react-i18next";

import { CardList } from "../../../../common/payment/cardList";
import { SelectedCardBlock } from "../../../../common/payment/selectedCardBlock";
import { Modal } from "../../../../common/modal";
import modalStyles from "../../../../common/modal/modal.module.css";
import { Button } from "../../../../common/button";
import { modals as modalsConstant } from "../../../../../constants/listings";
import ModalControl from "../../../../modalControl";
import { OverlayPreloader } from "../../../../common/preloader";

export const Payment = ({
  deleteCard,
  defaultSource,
  getPaymentMethods,
  paymentMethods,
  selectCard,
  selectedCard,
  paymentPreloaders,
  updateDefaultSource,
  clearAllModals,
  openModal,
  savedPaymentData,
  clearSavedPaymentData,
  isPendingPayment,
  isExpired
}) => {
  const { t } = useTranslation();
  const handleCloseModal = () => {
    clearSavedPaymentData();
    clearAllModals();
  };

  const handleClose = () => {
    clearAllModals();
  };

  const onExit = () => {
    handleClose();
    openModal(modalsConstant.upgrade);
  };

  const onConfirm = () => {
    handleClose();
    openModal(modalsConstant.confirm);
  };

  useEffect(() => {
    if (!savedPaymentData.isActive || !selectedCard) {
      getPaymentMethods();
    }
  }, [getPaymentMethods, savedPaymentData.isActive, selectedCard]); // eslint-disable-line

  const renderTitle = () => {
    if (isExpired) {
      return "RENEW SUBSCRIPTION";
    }

    if (isPendingPayment) {
      return t("pendingpayment");
    }

    return t("dashboard_listings_modal_upgrade_top_title");
  };

  return (
    <Modal closeModal={handleCloseModal} clearAllModals={clearAllModals}>
      <form className={modalStyles.form}>
        <section>
          <div className={modalStyles.pointer}>{renderTitle()}</div>
          <div className={modalStyles.popupHeading}>{t("dashboard_listings_modal_upgrade_payment_h1")}</div>

          {paymentPreloaders.chargeForMembership && (
            <OverlayPreloader overlayClassName="sectionOverlayModal zIndex4" spinnerClassName={`zIndex4 ${modalStyles.spinnerOverlay}`} />
          )}
          {(paymentPreloaders.paymentMethods || paymentPreloaders.updateCard || paymentPreloaders.deleteCard) && (
            <OverlayPreloader overlayClassName="sectionOverlayModal zIndex4" spinnerClassName="zIndex4" />
          )}

          {paymentMethods?.length ? (
            <>
              <span className={modalStyles.border} />

              <SelectedCardBlock
                selectedCard={selectedCard}
                defaultSource={defaultSource}
                updateDefaultSource={updateDefaultSource}
                deleteCard={deleteCard}
                openModal={openModal}
              />

              <CardList defaultSource={defaultSource} paymentMethods={paymentMethods} selectedCard={selectedCard} selectCard={selectCard} />
            </>
          ) : null}

          <Button
            onClick={() => {
              openModal(modalsConstant.addCard);
            }}
            className={cn(modalStyles.buttonNewCard, paymentPreloaders.paymentMethods ? "button--outline" : null)}
          >
            + {t("dashboard_payments_addcardbutton")}
          </Button>
        </section>

        <ModalControl
          isExit
          onExit={onExit}
          onConfirm={onConfirm}
          confirmClassName={paymentPreloaders.paymentMethods || (!selectedCard && modalStyles.disabledButton)}
        >
          {t("dashboard_common_button_next")}
        </ModalControl>
      </form>
    </Modal>
  );
};
