export const modals = {
  addCard: "addCard",
  editCard: "editCard",
  success: "success",
  payment: "payment",
  confirm: "confirm"
};

export const discountTypeName = {
  number: "number",
  percent: "percent"
};
