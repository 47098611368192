import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { actions as mainActions } from "../../../actions/main";
import { Notification } from "../notification";

export const PaymentError = () => {
  const { paymentError } = useSelector(state => state.main);

  const dispatch = useDispatch();
  const togglePaymentError = useCallback((isActive, errorMessage) => dispatch(mainActions.togglePaymentError(isActive, errorMessage)), [
    dispatch
  ]);

  const messageError = paymentError.errorMessage || "Something went wrong!";

  if (!paymentError.isActive) {
    return null;
  }

  return (
    <Notification error close={() => togglePaymentError(false, "")}>
      {messageError}
    </Notification>
  );
};
