import React from "react";

import ListingContent from "../listingContent";
import JobsListing from "../jobsListing";

const Jobs = ({ isExpand, onClick, isActive, handleClickAll, list, addToFavorite, removeFavorite, count }) => {
  return (
    <>
      {list?.length ? (
        <>
          {isExpand ? (
            <>{list?.length ? <JobsListing isExpand list={list} /> : null}</>
          ) : (
            <ListingContent title="Jobs" count={count || ""} onClick={onClick} isActive={isActive} handleClickAll={handleClickAll}>
              {list?.length ? <JobsListing removeFavorite={removeFavorite} addToFavorite={addToFavorite} list={list} /> : null}
            </ListingContent>
          )}
        </>
      ) : null}
    </>
  );
};

export default Jobs;
