import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import modalStyles from "../../../common/modal/modal.module.css";
import { Modal } from "../../../common/modal";
import { actions as payPerLeadDetailActions } from "../../../../actions/payPerLeadDetail";
import { modals as modalsConstant } from "../../../../constants/payPerLeadDetail";
import { actions as paymentActions } from "../../../../actions/payment";
import { ReactComponent as SuccessIcon } from "../../../../media/icons/success.svg";
import styles from "./success.module.css";
import { OverlayPreloader } from "../../../common/preloader";
import ModalControl from "../../../modalControl";

export const Success = ({ leadId, price }) => {
  const dispatch = useDispatch();
  const toggleModal = useCallback((modal, isActive) => dispatch(payPerLeadDetailActions.toggleModal(modal, isActive)), [dispatch]);
  const clearAllModals = useCallback(() => dispatch(payPerLeadDetailActions.clearAllModals()), [dispatch]);
  const clearPaymentState = useCallback(() => dispatch(paymentActions.clearState()), [dispatch]);
  const { modals, loading } = useSelector(state => state.payPerLeadDetail);
  const getLead = useCallback((type, leadIds) => dispatch(payPerLeadDetailActions.getLead(type, leadIds)), [dispatch]);

  const closeModal = () => {
    toggleModal(modalsConstant.success, false);
  };

  useEffect(() => {
    if (modals.success) {
      getLead("purchased", leadId);
    }
  }, [getLead, leadId, modals.success]);

  useEffect(() => {
    return () => {
      clearPaymentState();
    };
  }, []); // eslint-disable-line

  return (
    <Modal next modals={modals} closeModal={closeModal} clearAllModals={clearAllModals}>
      {loading && <OverlayPreloader overlayClassName="sectionOverlayModal zIndex4" spinnerClassName="zIndex4" />}
      <h4 className={modalStyles.pointer}>PURCHASE LEAD</h4>
      <h2 className={styles.title}>Purchase Confirmed</h2>
      <hr className={styles.hr} />

      <div className={styles.content}>
        <SuccessIcon className={styles.icon} />
        <div className={styles.detail}>{`You've successfully purchased lead #${leadId} for ${price}`}</div>
      </div>

      <ModalControl onExit={clearAllModals} isHideExitConfirm isClose />
    </Modal>
  );
};
