import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { NotificationError } from "../common/notification";
import Access from "../teams/components/access";
import { actions as teamsActions } from "../../actions/teams";
import { OverlayPreloader } from "../common/preloader";
import { modalsName } from "../../constants/teams";
import { useDocTitle } from "../../helpers/hooks";
import Tabs from "../account/tabs";
import Content from "../teams/components/content";
import Modals from "../teams/components/modals";
import styles from "./access.module.css";

const MyAccess = () => {
  const { member, preloaders, modals, activeAccess, error } = useSelector(state => state.teams);
  const dispatch = useDispatch();
  const getTeams = useCallback(() => dispatch(teamsActions.getTeams()), [dispatch]);
  const toggleModal = useCallback((modal, isActive) => dispatch(teamsActions.toggleModal(modal, isActive)), [dispatch]);
  const clearAllModals = useCallback(() => dispatch(teamsActions.clearAllModals()), [dispatch]);
  const handleActiveAccess = useCallback(access => dispatch(teamsActions.handleActiveAccess(access)), [dispatch]);
  const inviteAccess = useCallback(data => dispatch(teamsActions.inviteAccess(data)), [dispatch]);
  const acceptAccess = useCallback(data => dispatch(teamsActions.acceptAccess(data)), [dispatch]);
  const updateAccess = useCallback(data => dispatch(teamsActions.updateAccess(data)), [dispatch]);
  const removeAccess = useCallback(data => dispatch(teamsActions.removeAccess(data)), [dispatch]);
  const rejectAccess = useCallback(data => dispatch(teamsActions.rejectAccess(data)), [dispatch]);
  const clearError = useCallback(() => dispatch(teamsActions.clearError()), [dispatch]);
  const { t } = useTranslation();

  useDocTitle("My Access - Heritage Web");

  const handleOpenModal = modalName => {
    toggleModal(modalName, true);
  };

  const handleOpenAccessModal = (modalName, access) => {
    toggleModal(modalName, true);

    handleActiveAccess(access);
  };

  const handleCloseModal = modalName => {
    toggleModal(modalName, false);
  };

  useEffect(() => {
    if (getTeams) {
      getTeams();
    }
  }, []);

  return (
    <>
      <div className={styles.container}>
        {preloaders.page && <OverlayPreloader overlayClassName="sectionOverlayModal" />}
        {error.isShow && <NotificationError text={error.text} onClose={clearError} />}

        <div className={styles.flex}>
          <Content title={t("dashboard_teams_myaccess_title")} />
        </div>

        <Tabs />

        <Access
          handleAccept={accept => handleOpenAccessModal(modalsName.accept, accept)}
          handleAccess={access => handleOpenAccessModal(modalsName.viewAccess, access)}
          handleRemove={access => handleOpenAccessModal(modalsName.reject, access)}
          handleCloseModal={handleCloseModal}
          handleOpenModal={handleOpenModal}
          member={member}
        />
      </div>

      <Modals
        modals={modals}
        inviteAccess={inviteAccess}
        preloaders={preloaders}
        clearAllModals={clearAllModals}
        handleCloseModal={handleCloseModal}
        acceptAccess={acceptAccess}
        activeAccess={activeAccess}
        handleOpenModal={handleOpenModal}
        rejectAccess={rejectAccess}
        removeAccess={removeAccess}
        updateAccess={updateAccess}
      />
    </>
  );
};

export default MyAccess;
