import axios from "axios";
import qs from "qs";
import { account } from "../../constants/actionTypes";
import { actions as accountActions } from "../account";

export const actions = {
  getNotificationSettings() {
    return dispatch => {
      dispatch(accountActions.setLoading("notificationSettings", true));
      axios
        .all([
          axios({
            method: "get",
            responseType: "json",
            url: "/members/json-api/notification/index"
          }),
          axios({
            method: "get",
            responseType: "json",
            url: "/members/json-api/notification/newsletters-current"
          }),
          axios({
            method: "get",
            responseType: "json",
            url: "/members/json-api/notification/listing"
          })
        ])
        .then(
          axios.spread((notificationsRes, newslettersRes, listingNotifications) => {
            const leadNewsletters = newslettersRes.data.filter(newsletter => newsletter.title.indexOf("Lead") !== -1);
            const filledListings = listingNotifications.data?.listings?.length ? listingNotifications.data.listings : [];

            dispatch({
              type: account.GET_NOTIFICATION_SETTINGS,
              payload: {
                notifications: notificationsRes.data,
                newsletters: leadNewsletters,
                listingNotifications: { global: listingNotifications.data.global, listings: filledListings }
              }
            });
          })
        )
        .catch(error => {
          dispatch({
            type: account.GET_NOTIFICATION_SETTINGS_FAIL,
            payload: error
          });
        });
    };
  },

  saveNotificationList(notifications) {
    return dispatch => {
      dispatch(accountActions.setLoading("notifications", true));
      axios({
        method: "post",
        url: "/members/json-api/notification/update",
        data: qs.stringify(notifications),
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
        }
      })
        .then(() => {
          dispatch({
            type: account.UPDATE_NOTIFICATIONS_SUCCESS,
            payload: true
          });

          setTimeout(
            () =>
              dispatch({
                type: account.UPDATE_NOTIFICATIONS_SUCCESS,
                payload: null
              }),
            6000
          );
        })
        .catch(() => {
          dispatch(accountActions.setLoading("notifications", false));
        });
    };
  },

  saveNewsletterList(newsletters) {
    return dispatch => {
      dispatch(accountActions.setLoading("newsletters", true));
      axios({
        method: "post",
        url: "/members/json-api/notification/update-newsletters",
        data: qs.stringify(newsletters),
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
        }
      })
        .then(() => {
          dispatch({
            type: account.UPDATE_NEWSLETTERS_SUCCESS,
            payload: true
          });

          setTimeout(
            () =>
              dispatch({
                type: account.UPDATE_NEWSLETTERS_SUCCESS,
                payload: null
              }),
            6000
          );
        })
        .catch(() => {
          dispatch(accountActions.setLoading("newsletters", false));
        });
    };
  }
};
