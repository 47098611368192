import React, { useState } from "react";
import { useFormik } from "formik";
import cn from "classnames";
import { useTranslation } from "react-i18next";

import { Button } from "../common/button";
import Input from "../common/input";
import { Modal } from "../common/modal";
import { OverlayPreloader } from "../common/preloader";
import { email as emailRegex } from "../../constants/regex";
import Counter from "../counter";
import ModalControl from "../modalControl";
import styles from "./addNotifications.module.css";

export const AddNotifications = ({
  closeModal,
  clearAllModals,
  subTitle,
  title,
  text,
  label,
  buttonText,
  handleNotify,
  handleVerify,
  preloaders,
  isErrorVerification,
  isEmail,
  buttonTextSent
}) => {
  const [activeVerifyCode, setActiveVerifyCode] = useState(false);
  const [showTimer, setShowTimer] = useState(false);
  const { t } = useTranslation();
  const errorInput = isErrorVerification ? styles.inputError : "";

  const validate = ({ code, value }) => {
    const errors = {};

    if (!value) {
      errors.value = t("dashboard_common_required");
    }

    if (isEmail && value && !value.match(emailRegex)) {
      errors.value = t("dashboard_form_validations_invalid_email");
    }

    if (!code) {
      errors.code = t("dashboard_common_required");
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      code: "",
      value: ""
    },
    initialStatus: {
      code: "",
      value: ""
    },
    validate,
    onSubmit: () => {
      handleSubmit();
    }
  });

  const disableSendButton = !formik.values.value || activeVerifyCode ? styles.disableButton : "";

  const handleSubmit = () => {
    if (formik.values.value && !formik.values.code) {
      handleNotify(formik.values.value);
      setActiveVerifyCode(true);
    }

    if (formik.values.value && formik.values.code && activeVerifyCode) {
      handleVerify(formik.values.code);
    }
  };

  const handleChange = e => {
    formik.setFieldValue(e.target.name, e.target.value);
    if (formik.status?.[e.target.name]) {
      formik.setStatus({ ...formik.status, [e.target.name]: "" });
    }
  };

  const handleSendCode = () => {
    if (!formik.errors.value) {
      handleNotify(formik.values.value);
      setActiveVerifyCode(true);
    }
  };

  const handleResendCode = () => {
    if (!formik.errors.value) {
      handleNotify(formik.values.value);
      setShowTimer(true);
    }
  };

  const onHandleVerify = () => {
    if (!formik.errors.code) {
      handleVerify(formik.values.code, formik.values.value);
    }
  };

  return (
    <Modal closeModal={closeModal} clearAllModals={clearAllModals} classNameMain={styles.modal}>
      <form className={styles.form} onSubmit={formik.handleSubmit}>
        {preloaders && <OverlayPreloader overlayClassName="sectionOverlayModal" />}
        <section>
          <div className={styles.pointer}>{subTitle}</div>
          <div className={styles.popupHeading}>{title}</div>
        </section>
        <div className={styles.descText}>{text}</div>
        <div className={styles.content}>
          <Input
            classNameWrap={styles.codeInput}
            error={formik.status.value || formik.errors.value}
            id="value"
            isInvalid={(formik.status.value || formik.errors.value) && formik.touched.value}
            name="value"
            onBlur={() => formik.setFieldTouched("value", true)}
            label={label}
            onChange={handleChange}
            value={formik.values.value}
            disabled={activeVerifyCode}
          />
          <Button className={cn(styles.verifyEmail, disableSendButton)} onClick={handleSendCode}>
            {activeVerifyCode && buttonTextSent ? <>{buttonTextSent}</> : <> {buttonText}</>}
          </Button>
          {activeVerifyCode && (
            <Counter setShowTimer={setShowTimer} showTimer={showTimer}>
              <Button className={styles.resendBtn} clearStyles onClick={handleResendCode}>
                {t("dashboard_home_verifyemail_modal_resendbtn")}
              </Button>
            </Counter>
          )}
        </div>
        <Input
          classNameWrap={styles.codeInput}
          className={errorInput}
          error={formik.status.code || formik.errors.code}
          id="code"
          isInvalid={(formik.status.code || formik.errors.code) && formik.touched.code}
          name="code"
          onBlur={() => formik.setFieldTouched("code", true)}
          label={t("dashboard_account_phone_change_codetitle")}
          onChange={handleChange}
          value={formik.values.code}
        />
        {isErrorVerification && <span className={styles.inputErrorText}>The code is incorrect</span>}
        <Button
          className={cn(styles.verifyBtn, !formik.values.code && styles.verifyBtnDisabled)}
          disabled={!formik.values.code}
          onClick={onHandleVerify}
        >
          {t("dashboard_account_phone_change_verifybutton")}
        </Button>

        <ModalControl onExit={closeModal} isHideExitConfirm isClose />
      </form>
    </Modal>
  );
};
