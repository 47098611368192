export const customStyles = {
  menuList: (provided, state) => {
    if (state.selectProps.isAddCustomScrollbar && state.children.length > 8) {
      return {
        ...provided,
        overflowY: "auto",
        height: "250px",
        lineHeight: "1em",
        "&::-webkit-scrollbar": {
          "-webkitAppearance": "none"
        },
        "&::-webkit-scrollbar:vertical": {
          width: "11px"
        },
        "&::-webkit-scrollbar:horizontal": {
          width: "11px"
        },
        "&::-webkit-scrollbar-thumb": {
          borderRadius: "8px",
          border: "2px solid white",
          backgroundColor: "rgba(0, 0, 0, .5)"
        }
      };
    }
    return {
      ...provided
    };
  },
  singleValue: (provided, state) => {
    return {
      ...provided,
      color:
        state?.hasValue &&
        state?.children &&
        !String(state.children)
          .toLowerCase()
          .includes("select")
          ? "#333"
          : "#abafb9"
    };
  },
  option: (provided, state) => {
    return {
      ...provided,
      backgroundColor: state.selectProps.isNewSelectedColor && state.isFocused ? "#F8F8FA" : state.isFocused ? "#F2FAFD" : "#ffffff",
      color: "#353546",
      padding: state.data.isAddBorder ? "8px 20px 20px" : "8px 20px",
      borderBottom: state.data.isAddBorder ? "1px solid #EAEAEA" : "",
      marginBottom: state.data.isAddBorder ? "20px" : ""
    };
  },
  indicatorSeparator: () => ({ display: "none" }),
  valueContainer: provided => {
    return {
      ...provided,
      padding: "2px 8px 2px 10px"
    };
  }
};

export const customStylesMulti = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#F2FAFD" : "#ffffff",
    color: "#353546",
    padding: "8px 20px"
  }),
  placeholder: provided => ({
    ...provided,
    color: "#abafb9"
  }),
  indicatorSeparator: () => ({ display: "none" }),
  valueContainer: provided => ({
    ...provided,
    padding: "0 20px 20px"
  }),
  multiValue: provided => ({
    ...provided,
    background: "#F2FAFD",
    margin: "20px 20px 0 0",
    borderRadius: "4px",
    padding: "8px 12px",
    display: "flex",
    alignItems: "center"
  }),
  multiValueRemove: provided => ({
    ...provided,
    color: "#ABAFB9",
    width: "25px",
    height: "25px",
    paddingLeft: "5px",
    "& svg": {
      width: "100%",
      height: "100%"
    }
  })
};

export const customStylesMultiMobile = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#F2FAFD" : "#ffffff",
    color: "#353546"
  }),
  indicatorSeparator: () => ({ display: "none" }),
  clearIndicator: provided => ({ ...provided, paddingLeft: "0px", paddingRight: "0px" }),
  dropdownIndicator: provided => ({ ...provided, paddingLeft: "0px" }),
  valueContainer: provided => ({
    ...provided,
    minHeight: "50px",
    height: "50px",
    overflowY: "auto"
  }),
  multiValue: provided => ({
    ...provided,
    background: "#F2FAFD",
    borderRadius: "4px",
    display: "flex",
    alignItems: "center"
  }),
  multiValueRemove: provided => ({
    ...provided,
    color: "#ABAFB9",
    width: "25px",
    height: "25px",
    paddingLeft: "5px",
    "& svg": {
      width: "100%",
      height: "100%"
    }
  })
};
