import cn from "classnames";
import React from "react";

import styles from "./textarea.module.css";

const TextArea = ({
  classNameWrap,
  className,
  error,
  disabled,
  id,
  isInvalid,
  name,
  maxLength,
  onChange,
  onBlur,
  label,
  labelClass,
  placeholder,
  required,
  styleProps,
  value
}) => {
  return (
    <div className={cn(styleProps?.wrapper, classNameWrap)}>
      {label && (
        <label className={cn(styles.label, labelClass)} htmlFor={id}>
          {label} {required && <span className={styles.asterisk}>*</span>}
        </label>
      )}
      <textarea
        className={cn(
          styles.input,
          styleProps?.inputBase,
          className,
          { [styles.invalid]: isInvalid },
          isInvalid ? styleProps?.inputInvalid : styleProps?.inputValid
        )}
        disabled={disabled}
        id={id}
        name={name}
        maxLength={maxLength}
        value={value}
        onBlur={onBlur}
        onChange={onChange}
        placeholder={placeholder}
      />
      {isInvalid && <div className={cn(styles.error, styleProps?.error)}>{error}</div>}
    </div>
  );
};

export default TextArea;
