import React, { useCallback } from "react";
import { useDispatch } from "react-redux";

import { actions as payPerLeadActions } from "../../../actions/payPerLead";
import ListItem from "../listItem";
import { getPracticeAreas } from "../../../helpers/getPracticeAreas";
import styles from "./list.module.css";
import { ReactComponent as EmptyIcon } from "../../../media/icons/new-empty-listing.svg";

const List = ({ referralsList, leadsList, isReferrals, practices, handleRemoveReferrals, team }) => {
  const dispatch = useDispatch();
  const setNoInterest = useCallback(id => dispatch(payPerLeadActions.setNoInterest(id, team?.value)), [dispatch]);
  const setInterest = useCallback(id => dispatch(payPerLeadActions.setInterest(id, team?.value)), [dispatch]);
  const activeList = isReferrals ? referralsList : leadsList;

  const renderLeads = activeList?.map(item => {
    const {
      price,
      added,
      city,
      title,
      lead_id,
      state,
      practice1,
      practice2,
      practice3,
      purchased,
      summary,
      hide,
      isPurchasedByTheSameProfessional,
      _followups,
      status,
      matches
    } = item;
    const practicesTitle = getPracticeAreas([practice1, practice2, practice3], practices);

    return (
      <ListItem
        key={`newLead-${lead_id.toString()}-${isReferrals}`}
        price={price}
        added={added}
        city={city}
        state={state}
        title={title}
        leadId={lead_id}
        practicesTitle={practicesTitle}
        setNoInterest={setNoInterest}
        setInterest={setInterest}
        purchased={purchased}
        summary={summary}
        isFollowupQuestions={Boolean(_followups)}
        hide={hide}
        isPurchasedByTheSameProfessional={isPurchasedByTheSameProfessional}
        handleRemoveReferrals={handleRemoveReferrals}
        isReferral={isReferrals}
        lead={item}
        matches={matches}
        status={status}
        team={team}
      />
    );
  });

  return (
    <div>
      {activeList?.length ? (
        <>{renderLeads}</>
      ) : (
        <div>
          <div className={styles.iconContainer}>
            <EmptyIcon className={styles.icon} />
          </div>

          <h2 className={styles.emptyText}>You haven`t requested any referrals</h2>
        </div>
      )}
    </div>
  );
};
export default List;
