import React from "react";
import cn from "classnames";

import { ReactComponent as CameraIcon } from "../../../media/icons/photo-listing.svg";
import styles from "./renderPhoto.module.css";

const RenderPhoto = ({ defaultImage, children, className, isSmall, text, classNameIcon }) => {
  const photoContainerSmall = isSmall ? styles.photoContainerSmall : "";

  const renderPhoto = () => {
    if (!defaultImage) {
      return (
        <div className={cn(styles.photo, styles.photoDefault)}>
          {text && <span className={styles.text}>{text}</span>}
          <CameraIcon className={cn(styles.cameraIcon, classNameIcon)} />
        </div>
      );
    }

    return <img src={defaultImage} alt="" className={styles.photo} />;
  };

  return (
    <div className={cn(styles.photoContainer, photoContainerSmall, className)}>
      {renderPhoto()}
      {children && <>{children}</>}
    </div>
  );
};

export default RenderPhoto;
