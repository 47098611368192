export const eventName = {
  viewContent: "View Content",
  completeRegistration: "Complete Registration",
  initiateCheckout: "Initiate checkout",
  addToCart: "Add to Cart",
  startTrial: "Start Trial",
  purchase: "Purchase",
  addPaymentInfo: "Add Payment Info"
};

export const eventNameDataLayer = {
  signUp: "sign_up",
  listingCreated: "listing_created",
  listingUpgrade: "listing_upgrade",
  leadSale: "lead_sale"
};
