import { account } from "../constants/actionTypes";

export const actions = {
  setLoading(section, isSectionLoading) {
    return {
      type: account.SET_LOADING,
      payload: { section, isSectionLoading }
    };
  }
};
