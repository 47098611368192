import React, { useState, useMemo, useEffect } from "react";
import cn from "classnames";

import Select from "../select";
import { customStyles as selectStyles } from "../../../constants/select";
import { ReactComponent as Arrow } from "../../../media/icons/arrow-grey.svg";
import Checkbox from "../../common/checkbox";
import { statuses } from "../../../constants/listingDetail";
import styles from "./productActions.module.css";

const PublishedDirectories = ({
  directoriesAvailability,
  handleAutoSaveListing,
  publishStatus,
  listingUrl,
  paused,
  pauseListing,
  unPauseListing,
  listingId
}) => {
  const [isActiveDropdown, setIsActiveDropdown] = useState(false);
  const [directories, setDirectories] = useState([]);
  const [pauseValue, setPauseValue] = useState({ id: "", title: "" });
  const isNeverPublished = publishStatus === statuses.neverPublished;
  const isActiveStatus = publishStatus === statuses.active;

  const unpublished = { id: "1", title: "Unpublished" };
  const published = { id: "0", title: "Published" };

  const options = [unpublished, published];

  const listingUrlName = useMemo(() => {
    const parts = listingUrl.split("/");

    return parts[parts.length - 1];
  }, [listingUrl]);

  const toggleDropDown = () => {
    setIsActiveDropdown(prev => !prev);
  };

  const normalizeDomains = url => {
    const domain = new URL(url).hostname.toLowerCase().replace("staging.", "");
    return domain.startsWith("www.") ? domain.slice(4) : domain;
  };

  const handleChange = value => {
    setPauseValue(value);

    if (String(value.id) === "1") {
      pauseListing(listingId, false);
    } else {
      unPauseListing(listingId, false);
    }
  };

  const handleChangeCheckbox = name => {
    const isIncludes = directories.includes(name);
    const newDirectories = isIncludes ? directories.filter(item => item !== name) : [...directories, name];
    setDirectories(newDirectories);

    handleSave(newDirectories);
  };

  const handleSave = activeIds => {
    const params = directoriesAvailability.map(item => {
      if (activeIds.includes(item.dir_id)) {
        return {
          dir_id: item.dir_id,
          publish: 1
        };
      }
      return {
        dir_id: item.dir_id,
        publish: 0
      };
    });

    handleAutoSaveListing({ directories_availability: params }, "directories_availability", "save_directory_availability");
  };

  useEffect(() => {
    if (!pauseValue.id) {
      if (String(paused) === "1") {
        setPauseValue(unpublished);
      } else {
        setPauseValue(published);
      }
    }
  }, [paused]);

  useEffect(() => {
    if (!directories.length) {
      const activeDirectories = directoriesAvailability.filter(item => item.publish).map(item => item.dir_id);
      setDirectories(activeDirectories);
    }
  }, []);

  return (
    <>
      <div className={styles.container}>
        {isNeverPublished ? (
          <div className={styles.flex}>
            <span className={styles.status}>Status:</span>
            <div className={styles.neverPublish}>
              <span className={styles.dotRed} />
              Never Published Before
            </div>
          </div>
        ) : (
          <>
            <div className={styles.content}>
              <div className={styles.flex}>
                <span className={styles.status}>Status:</span>
                <Select
                  getOptionLabel={option => option.title}
                  getOptionValue={option => option.title}
                  inputId="status"
                  name="status"
                  value={pauseValue}
                  options={options}
                  styles={selectStyles}
                  required
                  classNameWrap={cn(styles.selectWrap, String(paused) === "0" ? styles.selectWrapActive : "")}
                  handleChange={handleChange}
                />
              </div>
            </div>
            {String(paused) !== "1" && directoriesAvailability.length ? (
              <div className={styles.content}>
                <button type="button" className={styles.published} onClick={toggleDropDown}>
                  Published on <Arrow className={cn(styles.arrowIcon, isActiveDropdown ? styles.arrowDown : "")} />
                </button>
                {isActiveStatus && (
                  <div className={cn(styles.directories, isActiveDropdown ? styles.directoriesActive : "")}>
                    {directoriesAvailability.map((item, index) => {
                      return (
                        <Checkbox
                          // eslint-disable-next-line
                          key={`${item.dir_id}-${index}`}
                          isNewStyles
                          checked={directories.includes(item.dir_id)}
                          className={styles.checkbox}
                          id={item.dir_id}
                          name={item.dir_id}
                          onChange={() => handleChangeCheckbox(item.dir_id)}
                        >
                          <a
                            rel="noopener noreferrer"
                            className={styles.directoriesName}
                            href={`${item.directory_url}/${listingUrlName}`}
                            target="_blank"
                          >
                            {normalizeDomains(item.directory_url)}
                          </a>
                        </Checkbox>
                      );
                    })}
                  </div>
                )}
              </div>
            ) : null}
          </>
        )}
      </div>
    </>
  );
};

export default PublishedDirectories;
