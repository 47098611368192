import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Modal } from "../../common/modal";
import CardList from "../cardList";
import ModalControl from "../../modalControl";
import { OverlayPreloader } from "../../common/preloader";
import styles from "./updatePaymentMethod.module.css";

const UpdatePaymentMethod = ({
  closeModal,
  clearAllModals,
  defaultSource,
  paymentMethods,
  setModal,
  paymentSubscription,
  loading,
  assignCardToSubscription
}) => {
  const [checkedRadio, setCheckedRadio] = useState("");
  const { t } = useTranslation();

  const handleSelect = event => {
    setCheckedRadio(event.target.value);
  };

  useEffect(() => {
    const findActiveCard = paymentMethods.find(item => item.id === paymentSubscription.card_token);
    if (findActiveCard) {
      setCheckedRadio(findActiveCard.id);
    }
  }, []); // eslint-disable-line

  const onConfirm = () => {
    const data = {
      card_id: checkedRadio,
      invoice_id: paymentSubscription.invoice_id
    };
    assignCardToSubscription(data);
  };

  return (
    <Modal closeModal={closeModal} clearAllModals={clearAllModals}>
      {loading && <OverlayPreloader overlayClassName="sectionOverlayModal zIndex4" spinnerClassName="zIndex4" />}

      <h3 className={styles.subTitle}>{t("dashboard_payments_paymentmethod")}</h3>
      <h2 className={styles.title}>{t("dashboard_payments_updatemethodtitle")}</h2>
      <CardList
        defaultSource={defaultSource}
        paymentMethods={paymentMethods}
        checkedRadio={checkedRadio}
        handleSelect={handleSelect}
        setModal={setModal}
        isUpdatePaymentMethod
        updatedPaymentMethodText={t("dashboard_payment_methods_subscription_default")}
        activeSubscriptionCard={paymentSubscription.card_token}
        isNotShowCantDeleteText
      />
      <ModalControl onExit={closeModal} className={styles.modalControl} onConfirm={onConfirm}>
        {t("dashboard_common_savechanges")}
      </ModalControl>
    </Modal>
  );
};

export default UpdatePaymentMethod;
