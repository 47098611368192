import React from "react";
import { Link } from "react-router-dom";
import DOMPurify from "dompurify";
import cn from "classnames";
import format from "date-fns/format";
import { useTranslation } from "react-i18next";

import styles from "./message.module.css";

const Message = ({ data, isHomePage }) => {
  const { t } = useTranslation();
  const { attorney, listing_message_id: listingMessageId, membership, name_full: fullName, sent, text } = data;
  const homePageFullName = isHomePage ? styles.homePageFullName : "";
  const homePageHeading = isHomePage ? styles.homePageHeading : "";
  const homePageCard = isHomePage ? styles.homePageCard : "";
  const homePageLink = isHomePage ? styles.homePageLink : "";
  const description = text.length >= 180 ? DOMPurify.sanitize(text.substring(0, 180).concat("...")) : DOMPurify.sanitize(text);

  return (
    <div className={cn("card inbox-message__card", styles.card, homePageCard)}>
      <div className={cn(styles.heading, homePageHeading)}>
        <p className={cn(styles.name, homePageFullName)}>{fullName}</p>
        <p className={cn(styles.partner, "d-none d-md-block")}>{`${attorney} (${membership})`}</p>

        {!isHomePage && <p className={styles.time}>{sent && format(new Date(sent.split(" ").join("T")), "MMM do yyyy")}</p>}
      </div>
      <div className={styles.main}>
        {text?.length && (
          <div className={styles.textWrap}>
            {/* eslint-disable-next-line react/no-danger */}
            <pre className={styles.text} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(description) }} />
          </div>
        )}

        <p className={cn(styles.partner, "d-block d-md-none")}>{`${attorney} (${membership})`}</p>
        <div className={styles.more}>
          <Link className={cn(styles.link, homePageLink)} to={`/inbox?messageId=${listingMessageId}`}>
            {t("dashboard_common_readmore")}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Message;
