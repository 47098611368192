import React from "react";
import { useTranslation } from "react-i18next";

import styles from "./feature.module.css";
import referralsMobile from "../../../../media/images/features/referralsMobile.png";
import referralsDesktop from "../../../../media/images/features/referralsDesktop.png";
import jobsDesktop from "../../../../media/images/features/jobsDesktop.png";
import eventsDesktop from "../../../../media/images/features/eventsDesktop.png";
import listingsMobile from "../../../../media/images/features/listingsMobile.png";
import listingsDesktop from "../../../../media/images/features/listingsDesktop.png";
import inboxMobile from "../../../../media/images/features/inboxMobile.png";
import inboxDesktop from "../../../../media/images/features/inboxDesktop.png";
import qaMobile from "../../../../media/images/features/qaMobile.png";
import qaDesktop from "../../../../media/images/features/qaDesktop.png";

const Feature = () => {
  const { t } = useTranslation();

  const list = [
    {
      title: t("dashboard_features_referrals_title1"),
      name: t("dashboard_features_referrals_title2"),
      text: t("dashboard_features_referrals_desc"),
      mobileImage: referralsMobile,
      desktopImage: referralsDesktop
    },
    {
      title: t("dashboard_features_inbox_title1"),
      name: t("dashboard_features_inbox_title2"),
      text: t("dashboard_features_inbox_desc"),
      mobileImage: inboxMobile,
      desktopImage: inboxDesktop
    },
    {
      title: t("dashboard_features_qa_title1"),
      name: t("dashboard_features_qa_title2"),
      text: t("dashboard_features_qa_desc"),
      mobileImage: qaMobile,
      desktopImage: qaDesktop
    },
    {
      title: t("dashboard_features_events_title1"),
      name: t("dashboard_features_events_title2"),
      text: t("dashboard_features_events_desc"),
      mobileImage: eventsDesktop,
      desktopImage: eventsDesktop
    },
    {
      title: t("dashboard_features_jobs_title1"),
      name: t("dashboard_features_jobs_title2"),
      text: t("dashboard_features_jobs_desc"),
      mobileImage: jobsDesktop,
      desktopImage: jobsDesktop
    },
    {
      title: t("dashboard_features_listings_title1"),
      name: t("dashboard_features_listings_title2"),
      text: t("dashboard_features_listings_desc"),
      mobileImage: listingsMobile,
      desktopImage: listingsDesktop
    }
  ];

  return (
    <>
      {list.map(item => (
        <div key={item.title} className={styles.feature}>
          <div className={styles.featureLeftSide}>
            <h3 className={styles.featureTitle}>{item.title}</h3>
            <h2 className={styles.featureName}>{item.name}</h2>
            <p className={styles.featureText}>{item.text}</p>
          </div>
          <div className={styles.featureRight}>
            <img className={styles.featureImageMobile} src={item.mobileImage} alt="" />
            <img className={styles.featureImageDesktop} src={item.desktopImage} alt="" />
          </div>
        </div>
      ))}
    </>
  );
};

export default Feature;
