import React from "react";

import testCompany from "../../../media/images/eventsTestBanner.jpg";
import { ReactComponent as LocationIcon } from "../../../media/icons/location.svg";
import { ReactComponent as CalendarIcon } from "../../../media/icons/calendar.svg";

import styles from "./aside.module.css";

const Aside = () => {
  return (
    <div className={styles.aside}>
      <h4 className={styles.asideTitle}>Similar Events</h4>
      <div className={styles.events}>
        <div className={styles.eventsItem}>
          <span className={styles.eventsLogo}>
            <img src={testCompany} alt="Company" />
          </span>
          <h3 className={styles.eventsName}>ISTQB® Foundation Exam and Training </h3>
          <div className={styles.eventsHeading}>
            <span className={styles.eventsPoint} />
            <p className={styles.eventsLocation}>
              <CalendarIcon />
              ISTQB® Foundation Exam and Training
            </p>
          </div>
          <div className={styles.eventsHeading}>
            <span className={styles.eventsPoint} />
            <p className={styles.eventsLocation}>
              <LocationIcon />
              United Arab Emirates
            </p>
          </div>
        </div>
        <div className={styles.eventsItem}>
          <span className={styles.eventsLogo}>
            <img src={testCompany} alt="Company" />
          </span>
          <h3 className={styles.eventsName}>ISTQB® Foundation Exam and Training </h3>
          <div className={styles.eventsHeading}>
            <span className={styles.eventsPoint} />
            <p className={styles.eventsLocation}>
              <CalendarIcon />
              ISTQB® Foundation Exam and Training
            </p>
          </div>
          <div className={styles.eventsHeading}>
            <span className={styles.eventsPoint} />
            <p className={styles.eventsLocation}>
              <LocationIcon />
              United Arab Emirates
            </p>
          </div>
        </div>
        <div className={styles.eventsItem}>
          <span className={styles.eventsLogo}>
            <img src={testCompany} alt="Company" />
          </span>
          <h3 className={styles.eventsName}>ISTQB® Foundation Exam and Training </h3>
          <div className={styles.eventsHeading}>
            <span className={styles.eventsPoint} />
            <p className={styles.eventsLocation}>
              <CalendarIcon />
              ISTQB® Foundation Exam and Training
            </p>
          </div>
          <div className={styles.eventsHeading}>
            <span className={styles.eventsPoint} />
            <p className={styles.eventsLocation}>
              <LocationIcon />
              United Arab Emirates
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Aside;
