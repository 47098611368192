import React, { useEffect, useRef } from "react";

import MessageThreadItem from "../messageThreadItem";
import styles from "./messageThread.module.css";

const MessageThread = ({ messageDetailMessages, userInitials }) => {
  const messageThreadElement = useRef();

  const messagesList = messageDetailMessages
    ?.filter(item => String(item.is_followup) === "0")
    ?.map(item => {
      return (
        <MessageThreadItem
          key={`${item.text}-${item.sent}`}
          initials={item.client_initials}
          userInitials={userInitials}
          message={item.text}
          date={item.sent}
          isClient={String(item.is_client) === "1"}
          image={item.lawyer_image}
        />
      );
    });

  useEffect(() => {
    const messageThreadHeight = messageThreadElement.current.clientHeight;
    const messageThreadScrollHeight = messageThreadElement.current.scrollHeight;

    if (messageThreadScrollHeight > messageThreadHeight && messageThreadElement.current) {
      messageThreadElement.current.scrollTo({ top: messageThreadScrollHeight, left: 0, behavior: "auto" });
    }
  }, [messageDetailMessages?.length]); // eslint-disable-line

  return (
    <div className={styles.messageThread} ref={messageThreadElement}>
      {messagesList}
    </div>
  );
};

export default MessageThread;
