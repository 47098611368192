import React, { useEffect, useState } from "react";
import cn from "classnames";
import format from "date-fns/format";
import queryString from "query-string";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import emptyState from "../../../media/icons/payment-history-empty.svg";
import { Pagination } from "../../common/pagination";
import { useDocTitle } from "../../../helpers/hooks";
import styles from "./paymentHistory.module.css";

const PaymentHistory = ({ paymentHistory, getPaymentHistory, pagination, team, getPdfFile }) => {
  const { t } = useTranslation();
  const [perPage, setPerPage] = useState("");

  const history = useHistory();
  const searchParams = queryString.parse(history.location.search);
  const numRegex = /^[0-9]+$/g;
  const perPagePagination = pagination.perPage || 0;

  useEffect(() => {
    if (Number(pagination.currentPageNum) !== Number(searchParams.page)) {
      getPaymentHistory(searchParams.page?.match(numRegex) ? searchParams.page : "0", perPage || perPagePagination, team);
    }
  }, [searchParams.page]); // eslint-disable-line

  useEffect(() => {
    if (pagination.currentPageNum !== null && pagination.currentPageNum !== undefined && !searchParams.page) {
      history.push(`/payments?page=${pagination.currentPageNum}`);
    }
  }, [pagination.currentPageNum]); // eslint-disable-line

  const handlePerPage = paginationValue => {
    getPaymentHistory(0, paginationValue, team);
    history.push("/payments?page=0");
    setPerPage(paginationValue);
  };
  /*
  todo: remove
  const createPdfLink = pdfLink => {
    const adminLink = `${process.env.REACT_APP_API_URL}/members${pdfLink}`;

    const headers = {
      token: `${keycloak.token}`
    };

    const encodedHeaders = Object.entries(headers)
      .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
      .join("&");

    const urlWithHeaders = `${adminLink}&${encodedHeaders}`;

    window.open(urlWithHeaders, "_blank");
  };
*/

  useDocTitle(t("dashboard_payment_history_metatitle"));

  const renderPaymentHistory = () => {
    return paymentHistory.map(({ currency, amount, dattm, membership, pdf_link, invoice_id }, index) => {
      return (
        <div className={styles.tableRow} key={(invoice_id * amount + index).toString()}>
          <div className={cn(styles.tableCell, styles.tableBorder)}>
            <span className={styles.paymentTitle}>{t("dashboard_common_date")}</span>
            {format(new Date(dattm), "MMM do yyyy")}
          </div>

          <div className={cn(styles.tableCell, styles.tableBorder)}>
            <span className={styles.paymentTitle}>{t("dashboard_payments_service")}</span>
            {membership}
          </div>
          <div className={cn(styles.tableCell, styles.paymentAmount, styles.tableBorder)}>
            <span className={styles.paymentTitle}>{t("dashboard_payments_amount")}</span>

            <span>
              {currency}
              {amount}
            </span>
          </div>
          <button
            type="button"
            className={cn(styles.tableCell, styles.tableBorder, styles.active, styles.link)}
            onClick={() => getPdfFile(pdf_link)}
          >
            <span className={styles.paymentTitle}>{t("dashboard_payments_invoice")}</span>
            {t("dashboard_payments_pdfinvoice")}
          </button>
        </div>
      );
    });
  };

  const makePaginationLinks = () => {
    const navLinks = [];
    const numberOfPages = pagination.lastPageNum || 0;

    for (let i = 0; i <= numberOfPages; i++) {
      navLinks.push({ pathname: "/payments", search: queryString.stringify({ page: i }), number: i });
    }

    return navLinks;
  };

  const isShowPagination =
    (pagination.lastPageNum !== 0 && !!paymentHistory?.length && paymentHistory?.length >= perPagePagination) ||
    Number(searchParams.page) >= 1;

  const renderPagination = () => {
    if (isShowPagination) {
      return (
        <Pagination
          links={makePaginationLinks()}
          currentPage={parseInt(searchParams.page, 10)}
          lastPage={pagination.lastPageNum}
          perPage={pagination.perPage}
          handlePerPage={handlePerPage}
        />
      );
    }
    return null;
  };
  return (
    <div className={styles.billingWrapper}>
      <div className={styles.billings}>
        {!paymentHistory ||
          (paymentHistory.length === 0 && (
            <section className={styles.emptyState}>
              <img src={emptyState} alt="no prior payments" />
              <p>{t("dashboard_payments_history_msg")}</p>
            </section>
          ))}

        {paymentHistory && paymentHistory.length !== 0 && (
          <>
            <div className={styles.payment}>
              <div className={styles.tableHead}>
                <div className={cn(styles.tableCell, styles.tableCellHead)}>{t("dashboard_common_date")}</div>
                <div className={cn(styles.tableCell, styles.tableCellHead)}>{t("dashboard_payments_service")}</div>
                <div className={cn(styles.tableCell, styles.tableCellHead)}>{t("dashboard_payments_amount")}</div>
                <div className={cn(styles.tableCell, styles.tableCellHead)}>{t("dashboard_payments_invoice")}</div>
              </div>
              {renderPaymentHistory()}
            </div>
            {renderPagination()}
          </>
        )}
      </div>
    </div>
  );
};

export default PaymentHistory;
