import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";

import { ReactComponent as CameraIcon } from "../../../../media/icons/photo-listing.svg";
import styles from "../../listings.module.css";

export const Avatar = ({ attorneyName, imageName, isActiveStatus, className, listingId, listingPathname }) => {
  const activeStyles = isActiveStatus ? styles.personalAvatarActive : "";
  const activeStylesObject = isActiveStatus ? styles.personalAvatarObjectActive : "";

  return (
    <Link to={`${listingPathname}/${listingId}`}>
      {imageName ? (
        <img src={imageName} alt={attorneyName} className={cn(styles.personalAvatar, activeStylesObject, className)} />
      ) : (
        <span className={cn(styles.personalAvatar, styles.initials, activeStyles, className)}>
          <CameraIcon />
        </span>
      )}
    </Link>
  );
};
