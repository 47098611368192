import React from "react";
import cn from "classnames";

import format from "date-fns/format";
import styles from "./messageThreadItem.module.css";

const MessageThreadItem = ({ message, date, image, initials, isClient, userInitials }) => {
  const messageThreadItemClient = !isClient ? styles.messageThreadItemClient : "";

  const renderInitials = () => {
    if (isClient) {
      return (
        <div className={styles.initialsContent}>
          <span className={styles.initials}>{initials}</span>
        </div>
      );
    }

    return (
      <>
        {image ? (
          <img src={image} className={styles.image} alt={initials} />
        ) : (
          <div className={styles.initialsContent}>
            <span className={styles.initials}>{userInitials}</span>
          </div>
        )}
      </>
    );
  };

  return (
    <div className={cn(styles.messageThreadItem, messageThreadItemClient)}>
      <>{renderInitials()}</>
      <div className={styles.message}>{message}</div>
      <span className={styles.date}>{format(new Date(date), "MMM do yyyy, h:mm a")}</span>
    </div>
  );
};

export default MessageThreadItem;
