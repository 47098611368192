import React, { useEffect } from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";
// import getYear from "date-fns/getYear";
import styles from "./leadsBy.module.css";
import "../../styles.css";
import { customStyles as selectStyles } from "../../../../constants/select";
import { Preloader } from "../../../common/preloader";
import { makePerformanceYearOptions } from "../../../../helpers/select";

function p(x1, y1) {
  return `${x1} ${y1} `;
}

function rectangle(x, y, w, h, r1, r2, r3, r4) {
  let strPath = `M${p(x + r1, y)}`; // A
  strPath += `L${p(x + w - r2, y)}Q${p(x + w, y)}${p(x + w, y + r2)}`; // B
  strPath += `L${p(x + w, y + h - r3)}Q${p(x + w, y + h)}${p(x + w - r3, y + h)}`; // C
  strPath += `L${p(x + r4, y + h)}Q${p(x, y + h)}${p(x, y + h - r4)}`; // D
  strPath += `L${p(x, y + r1)}Q${p(x, y)}${p(x + r1, y)}`; // A
  strPath += "Z";
  return strPath;
}

function topRoundedRect(x, y, width, height, radius) {
  if (height < radius) {
    return rectangle(x, y, width, height, height, height, 0, 0);
  }
  return rectangle(x, y, width, height, radius, radius, 0, 0);
}

const LeadsBy = ({
  leads,
  selectYear,
  setSelectYear,
  divId,
  divIdTooltip,
  title,
  xDimensionTitle,
  isLoading = false,
  isHideSelect,
  dateOfCreatedUser
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    const { d3 } = window;
    const svg_width = 1300;
    const svg_height = 500;
    // set the dimensions and margins
    const margin = { top: 60, right: 30, bottom: 20, left: 50 };
    const width = svg_width - margin.left - margin.right;
    const height = svg_height - margin.top - margin.bottom;

    // canvas for drawing
    d3.select(`#${divId}`)
      .selectAll("svg")
      .remove();

    const svg = d3
      .select(`#${divId}`)
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    const data = leads;
    /** column key in data and column title as value */
    const dataColumnTitlesOriginal =
      divId === "leadsByMonth"
        ? {
            calls: t("dashboard_performance_leadsbymonth_chart_phonecalls"),
            messages: t("dashboard_performance_leadsbymonth_chart_inbox"),
            clickcalls: t("dashboard_performance_leadsbymonth_chart_clickcalls"),
            referrals: t("dashboard_performance_leadsbymonth_chart_ppl")
          }
        : {
            calls: t("dashboard_performance_leadsbyweek_chart_phonecalls"),
            messages: t("dashboard_performance_leadsbyweek_chart_inbox"),
            clickcalls: t("dashboard_performance_leadsbyweek_chart_clickcalls"),
            referrals: t("dashboard_performance_leadsbyweek_chart_ppl")
          };
    const dataColumnTitlesTooltip =
      divId === "leadsByMonth"
        ? {
            calls: t("dashboard_performance_leadsbymonth_chart_phonecalls_i"),
            messages: t("dashboard_performance_leadsbymonth_chart_inbox_i"),
            clickcalls: t("dashboard_performance_leadsbymonth_chart_clickcalls_i"),
            referrals: t("dashboard_performance_leadsbymonth_chart_ppl_i")
          }
        : {
            calls: t("dashboard_performance_leadsbyweek_chart_phonecalls_i"),
            messages: t("dashboard_performance_leadsbyweek_chart_inbox_i"),
            clickcalls: t("dashboard_performance_leadsbyweek_chart_clickcalls_i"),
            referrals: t("dashboard_performance_leadsbyweek_chart_ppl_i")
          };
    const dataColumnTitles = { ...dataColumnTitlesOriginal };

    const dataColumnColorsOriginal = {
      calls: "#91B848",
      messages: "#E16D4F",
      clickcalls: "#5BAFD7",
      referrals: "#AC4B77"
    };
    const dataColumnColors = { ...dataColumnColorsOriginal };

    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const column in Object.create(dataColumnTitles)) {
      let columnHasNoValues = true;
      let index = 0;
      while (columnHasNoValues && index < data.length) {
        columnHasNoValues = Number(data[index++][column]) === 0;
      }
      if (columnHasNoValues) {
        delete dataColumnTitles[column];
        delete dataColumnColors[column];
      }
    }
    data.columns = [xDimensionTitle, ...Object.keys(dataColumnTitles)];
    /** keys of data */
    const columnColors = Object.values(dataColumnColors);

    // List of subgroups = header of the csv files = soil condition here
    const dataKeys = Object.keys(dataColumnTitles);

    // List of groups = species here = value of the first column called group -> I show them on the X axis
    const groups = d3.map(data, d => d[xDimensionTitle]).keys();

    // activate tooltip
    const tooltip = d3.select(`#${divIdTooltip}`);
    const divTooltipLegendId = `${divIdTooltip}Legend`;

    const tooltipLegend = d3.select(`#${divTooltipLegendId}`);

    /** unique id generator  */
    const idGenerator = (suffix, index) => `${divId}_${suffix}${index}_`;

    const pathToImageIcon = "/images/d3-leads-legend-info.png";

    // Add Y axis
    let minY = Number.MAX_SAFE_INTEGER;
    let maxY = Number.MIN_SAFE_INTEGER;

    data.columns.slice(1).forEach(each_column => {
      const column_values = data.map(x => parseFloat(x[each_column]));
      const column_min_value = d3.min(column_values);
      if (minY > column_min_value) {
        minY = column_min_value;
      }
      const column_max_value = d3.max(column_values);
      if (maxY < column_max_value) {
        maxY = column_max_value;
      }
    });
    // increasing AxisY for empty space on the top
    if (maxY < 5) {
      // if (maxY <= 1) {
      //   maxY = 2;
      // } else {
      maxY += 3;
      // }
    } else {
      maxY += maxY * 0.2;
    }
    // configuration for empty data
    if (dataKeys.length === 0) {
      minY = 0;
      maxY = 10;
    }
    const y = d3
      .scaleLinear()
      .domain([minY, maxY])
      .range([height, 0]);

    // legend Axis Y
    const axisY = d3.axisLeft(y);
    if (maxY < 10) {
      axisY.ticks(maxY);
      // axisY.tickValues(Array.from(Array(maxY + 1).keys()));
      axisY.tickFormat(d3.format("0"));
    }
    svg
      .append("g")
      .call(axisY)
      .call(g => g.select(".domain").remove()) // feature  D3
      .call(g =>
        g
          .selectAll("line")
          .attr("stroke-dasharray", "4 4")
          .attr("width", "1px")
          .attr("color", "#EAEAEA")
          .attr("x1", 0)
          .attr("x2", width)
      );
    // Axis X
    const x = d3
      .scaleBand()
      .domain(groups)
      .range([0, width])
      .padding([0.15]);
    const axisBottom = svg
      .append("g")
      .attr("transform", `translate(0,${height})`)
      .call(d3.axisBottom(x).tickSize(0));

    axisBottom
      .selectAll(".tick text")
      .attr("class", "svg_diagram_leads_xaxis")
      .attr("y", 5)
      .attr("x", -x.bandwidth() / 12);
    axisBottom.selectAll(".domain").attr("stroke", "#EAEAEA"); // feature  D3

    // subgroup position
    const xSubgroup = d3
      .scaleBand()
      .domain(dataKeys)
      .range([0, x.bandwidth()])
      .padding([0.05]);

    // color palette = one color per subgroup
    const color = d3
      .scaleOrdinal()
      .domain(dataKeys)
      .range(columnColors);

    const legendYShift = 30;
    const legendRectangle = 20;
    // title for table
    svg
      .append("text")
      .attr("x", -20)
      .attr("y", -legendYShift)
      .attr("class", "svg_diagram_title")
      .text(title);

    const legendTitleWidth = title.length * 10;

    // legend
    // legend text
    // subgroup position
    const colorLegend = d3
      .scaleOrdinal()
      .domain(Object.keys(dataColumnTitlesOriginal))
      .range(Object.values(dataColumnColorsOriginal));

    const xLegendPosition = d3
      .scaleBand()
      .domain(Object.keys(dataColumnTitlesOriginal))
      .range([0, width - legendTitleWidth])
      .padding([0.05]);

    svg
      .append("g")
      .selectAll("g")
      // Enter in data = loop group per group
      .data(Object.keys(dataColumnTitlesOriginal))
      .enter()
      .append("text")
      .text(data_item => dataColumnTitlesOriginal[data_item])
      .attr("x", data_item => legendTitleWidth + xLegendPosition(data_item) + legendRectangle * 1.5)
      .attr("y", -legendYShift)
      .attr("class", "svg_diagram_legend_element")
      .attr("id", (data_item, index) => idGenerator("legend", index));

    // legend information icon
    const information_icon_width = 16;
    svg
      .append("g")
      .selectAll("g")
      // Enter in data = loop group per group
      .data(Object.keys(dataColumnTitlesTooltip))
      .enter()
      .append("svg:image")
      // .append("rect")
      .attr("y", -legendYShift - information_icon_width + (legendRectangle - information_icon_width))
      .attr("x", (data_item, index) => {
        const svgElementBox = document.getElementById(idGenerator("legend", index)).getBBox();
        return svgElementBox.x + svgElementBox.width + information_icon_width / 2;
      })
      .attr("width", information_icon_width)
      .attr("height", information_icon_width)
      .attr("xlink:href", process.env.REACT_APP_API_URL + pathToImageIcon)
      .on("mouseover", data_item => {
        const selected_element_value = dataColumnTitlesTooltip[data_item];
        // css: tooltipLegendD3
        const positionX = d3.event.layerX - 290 / 2;
        // css: tooltipLegendD3:after
        // const positionY = d3.event.layerY + 20;
        tooltipLegend
          .html(`<div class="linkPanelD3">  <div class="tooltipLegendD3Text">${selected_element_value}</div> </div>`)
          .style("left", `${positionX}px`)
          .style("top", "30px")
          .style("display", "block")
          .style("position", "absolute");
      })
      .on("mouseout", () => tooltipLegend.style("display", "none"));
    // legend rectangle
    svg
      .append("g")
      .selectAll("g")
      // Enter in data = loop group per group
      .data(Object.keys(dataColumnTitlesOriginal))
      .enter()
      .append("rect")
      .attr("x", data_item => legendTitleWidth + xLegendPosition(data_item))
      .attr("y", -legendYShift - legendRectangle * 0.8)
      .attr("rx", 6)
      .attr("ry", 6)
      .attr("width", legendRectangle)
      .attr("height", legendRectangle)
      .attr("fill", data_item => colorLegend(data_item));

    const chartRectangleRaduis = 5;
    const spaceBetweenChartsInPercentage = 25;

    // data visualization
    svg
      .append("g")
      .selectAll("g")
      // Enter in data = loop group per group
      .data(data)
      .enter()
      .append("g")
      .attr("transform", d => `translate(${x(d[xDimensionTitle])}, 0)`)
      .selectAll("rect")
      .data(data_item =>
        dataKeys.map(key => {
          return { key, value: [data_item[key], data_item] };
        })
      )
      .enter()
      // .append("rect")
      .append("path")
      .attr("d", data_item => {
        const dataKey = data_item.key;
        const dataValue = data_item.value[0];
        const rectangleWidth = xSubgroup.bandwidth() - (spaceBetweenChartsInPercentage / 100) * xSubgroup.bandwidth();
        const xPositionInsideGroup = xSubgroup(dataKey);
        return topRoundedRect(xPositionInsideGroup, y(dataValue), rectangleWidth, height - y(dataValue), chartRectangleRaduis);
      })
      .attr("fill", data_item => color(data_item.key))
      .on("mouseover", data_item => {
        // const svg_node = document.getElementById(divId).getBoundingClientRect();
        const fill_color = color(data_item.key);
        const selected_element_value = data_item.value[0];
        const selected_element_title = dataColumnTitles[data_item.key];
        const positionX = d3.event.layerX - 70; // d3.event.clientX - svg_node.left - 50;
        // const positionY = y(data_item.value) - margin.bottom - 80;
        tooltip
          .html(
            `
            <div class="linkPanelD3">   
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill=${fill_color}>
                  <rect width="20" height="20" rx="6" fill=${fill_color}/>
              </svg>                        
              <p class="tooltipTextD3">${selected_element_title}</p>
            </div>
            <p class="name">${selected_element_value}</p>
              `
          )
          .style("left", `${positionX}px`)
          .style("top", "30px")
          .style("display", "block")
          .style("position", "absolute");
      })
      .on("mousemove", data_item => {
        const positionX = d3.event.layerX - 90; // d3.event.clientX - svg_node.left - 50;
        const positionY = y(data_item.value[0]) - margin.bottom - 15;
        tooltip
          .style("left", `${positionX}px`)
          .style("top", `${positionY}px`)
          .style("display", "block");
      })
      .on("mouseout", () => tooltip.style("display", "none"));
  }, [leads]); // eslint-disable-line

  return (
    <div className={styles.wrapper}>
      {isLoading && <Preloader />}
      {!isHideSelect && (
        <div className="row">
          <div className={styles.select}>
            <Select
              options={makePerformanceYearOptions(dateOfCreatedUser)}
              value={selectYear}
              onChange={value => {
                setSelectYear(value);
              }}
              className="selectBox"
              styles={selectStyles}
            />
          </div>
        </div>
      )}

      <div className="relative">
        <div id={`${divIdTooltip}Legend`} className="tooltipLegendD3" />
      </div>
      <div id={divId} />
      <div id={divIdTooltip} className="tooltipD3" />
    </div>
  );
};

export default LeadsBy;
