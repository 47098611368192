import React, { useState, useEffect, useRef } from "react";
import cn from "classnames";
import Calendar from "react-calendar";
import InputMask from "react-input-mask";
import "react-calendar/dist/Calendar.css";
import format from "date-fns/format";

import { ReactComponent as CalendarIcon } from "../../../media/icons/calendar.svg";
import styles from "./calendarWithInputMask.module.css";

const CalendarWithInputMask = ({
  placeholder,
  error,
  isInvalid,
  value,
  onChange,
  handleSubmit,
  handleChangeInput,
  mask = "99/99/9999",
  isYear,
  classNameError,
  minDate,
  defaultMaxDate,
  isHideFeatureDate
}) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [isCalendarVisible, setIsCalendarVisible] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const calendarRef = useRef();
  const isFutureDate = date => date > new Date();

  const handleDateChange = date => {
    setSelectedDate(date);
    setInputValue(formatDate(date));
    setIsCalendarVisible(false);
    onChange(formatDate(date));

    setTimeout(() => {
      if (handleSubmit) {
        handleSubmit();
      }
    }, 100);
  };

  const formatDate = date => {
    if (!date) return "";

    if (mask.length === 4) {
      const dateYear = date.length === 4 ? date : new Date(String(date)).getFullYear();
      // eslint-disable-next-line
      console.log("date", date, new Date(String(date)).getFullYear());
      return dateYear;
    }

    const day = date
      .getDate()
      .toString()
      .padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = new Date(date).getFullYear();
    return `${month}/${day}/${year}`;
  };

  const parseDateFromInput = valueDate => {
    if (mask.length === 4) {
      return valueDate;
    }

    const [month, day, year] = valueDate.split("/");
    const date = new Date(year, month - 1, day);
    return !Number.isNaN(date.getTime()) ? date : null;
  };

  const toggleActive = () => {
    setIsCalendarVisible(prev => !prev);
  };

  useEffect(() => {
    if (!inputValue && value) {
      setInputValue(formatDate(value));
    }

    if (inputValue && value && inputValue !== value) {
      setInputValue(formatDate(value));
    }
  }, [value]);

  const getMaxDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); // January is 0
    const yyyy = today.getFullYear();

    return mask.length === 4 ? yyyy : `${mm}/${dd}/${yyyy}`;
  };

  const handleInputChange = e => {
    const newValue = e.target.value;
    if (newValue.length === 4 && mask.length === 4) {
      const inputDate = new Date(newValue);
      const maxDate = new Date();

      if (inputDate <= maxDate) {
        setInputValue(newValue);
        handleChangeInput(newValue);
      }
    } else if (newValue.length === 10 && mask.length === 10) {
      const inputDate = new Date(newValue);
      const maxDate = new Date(getMaxDate());

      if (inputDate <= maxDate) {
        setInputValue(newValue);
        handleChangeInput(newValue);
      }
    } else {
      setInputValue(newValue);
    }
  };

  const handleClickOutside = event => {
    if (calendarRef.current && !calendarRef.current.contains(event.target)) {
      setIsCalendarVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const customNavigationLabel = ({ date, view }) => {
    const year = date.getFullYear();
    const currentYear = new Date().getFullYear();

    if (year === 2021 && view === "decade") {
      return `2021 – ${currentYear}`;
    }

    const previousDecadeStart = Math.floor((year - 1) / 10) * 10;
    return `${previousDecadeStart + 1} – ${previousDecadeStart + 10}`;
  };

  return (
    <div className={styles.parent}>
      <InputMask
        mask={mask}
        value={inputValue}
        onChange={handleInputChange}
        onBlur={() => {
          const date = parseDateFromInput(inputValue);
          if (date) {
            setSelectedDate(date);
            onChange(date);
          }
        }}
        placeholder={placeholder}
      >
        {inputProps => (
          <div className={styles.inputParent}>
            <CalendarIcon className={styles.calendarIcon} onClick={toggleActive} />
            <input className={cn(styles.input, isInvalid ? styles.inputInvalid : "")} {...inputProps} />
          </div>
        )}
      </InputMask>

      {isCalendarVisible && (
        <div className={styles.calendar} ref={calendarRef}>
          {isYear ? (
            <Calendar
              defaultView="decade"
              maxDate={defaultMaxDate}
              onClickYear={handleDateChange}
              value={selectedDate ? format(new Date(selectedDate).toLocaleDateString("en-US"), "yyyy") : selectedDate}
              format="9999"
              minDetail="decade"
              maxDetail="decade"
              minDate={minDate}
              tileClassName={({ date }) => (isFutureDate(date) && isHideFeatureDate ? styles.hide : "")}
              navigationLabel={customNavigationLabel}
            />
          ) : (
            <Calendar maxDate={defaultMaxDate} minDate={minDate} onChange={handleDateChange} value={selectedDate} />
          )}
        </div>
      )}
      {isInvalid && <div className={cn(styles.error, classNameError)}>{error}</div>}
    </div>
  );
};

export default CalendarWithInputMask;
