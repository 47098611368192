import React from "react";
import cn from "classnames";

import styles from "./radio.module.css";

export const Radio = ({
  isNewStyles,
  className,
  children,
  checked,
  id,
  iconClass,
  mainClass,
  name,
  onChange,
  refRadio,
  required,
  value
}) => {
  return (
    <div className={cn(mainClass, isNewStyles ? styles.newRadio : "")}>
      <label className={cn(styles.radio, className)}>
        <input
          required={required}
          className={styles.input}
          type="radio"
          id={id}
          name={name}
          value={value}
          checked={checked}
          onChange={onChange}
          ref={refRadio || null}
        />
        <i className={cn(styles.icon, iconClass)} />
        {children}
      </label>
    </div>
  );
};
