import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import axios from "axios";

import Dropdown from "../dropdown";
import RemoveButton from "../removeButton";
import Select from "../select";
import { getLanguageById } from "../../../helpers/select";
import DropdownActions from "../dropdownActions";
import styles from "./languages.module.css";
import { useValidationError } from "../../../helpers/hooks";

const Languages = ({ languagesList, selectedLanguages, submitChanges, validation, showRequiredText, isEvents }) => {
  if (Object.keys(validation).length && !validation.enabled) {
    return null;
  }
  const [cancelTokenSource, setCancelTokenSource] = useState(null);

  const cancelTokens = new Set();

  const cancelAllRequests = () => {
    if (cancelTokenSource) {
      cancelTokenSource.cancel("Operation canceled by the user.");
    }
  };

  const refButtonPersonal = useRef();

  const isRequiredText = validation.required && !selectedLanguages?.length && showRequiredText;
  const [isMouseInBlock, setIsMouseInBlock] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const [selectedPersonalLanguages, setSelectedPersonalLanguages] = useState([]);
  const [mainErrors, setMainErrors] = useState({});

  const personalLanguages = useMemo(() => selectedLanguages.filter(item => !["Office Staff", "translator"].includes(item.type)), [
    selectedLanguages.length
  ]);

  const handleSetSelectedPersonalLanguage = () => {
    if (refButtonPersonal.current && selectedPersonalLanguages.filter(item => item.isNew).length) {
      refButtonPersonal.current.click();
    }

    cancelAllRequests();

    if (!Object.values(mainErrors).filter(item => Boolean(item)).length) {
      const updatedList = selectedPersonalLanguages.filter(item => Object.values(item).some(listItem => listItem));

      if (selectedPersonalLanguages.length === updatedList.length) {
        setSelectedPersonalLanguages([...selectedPersonalLanguages, { language_id: "", skill: "", type: "lawyer", isNew: true }]);
      }
    }
  };

  const normalizeLanguages = useMemo(() => {
    const selectedLanguagesIds = [...selectedLanguages, ...selectedPersonalLanguages].map(item => String(item.language_id));

    return languagesList.filter(item => !selectedLanguagesIds.includes(String(item.id)));
  }, [languagesList.length, selectedPersonalLanguages.length]);

  useEffect(() => {
    if (!selectedPersonalLanguages.length) {
      const sortLanguage = personalLanguages
        .map(item => {
          return {
            ...item,
            title: languagesList.find(languageItem => String(languageItem.id) === String(item.language_id)).title
          };
        })
        .sort((a, b) => (a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1));

      setSelectedPersonalLanguages(sortLanguage);
    } else {
      const sortLanguage = personalLanguages
        .map(item => {
          return {
            ...item,
            title: languagesList.find(languageItem => String(languageItem.id) === String(item.language_id)).title
          };
        })
        .sort((a, b) => (a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1));

      const newList = sortLanguage.map((item, index) => {
        return sortLanguage[index] ? sortLanguage[index] : item;
      });
      setSelectedPersonalLanguages(newList);
    }
  }, [selectedLanguages.length]);

  const isRequired = Object.keys(validation).length ? validation.required : true;
  const isNotRequired = !Object.keys(validation).length;

  const handleSubmit = (data, isNew, index) => {
    if (isNew) {
      const newLanguages = selectedPersonalLanguages.map((item, currentIndex) => {
        if (String(currentIndex) === String(index)) {
          return { ...data, isNew };
        }
        return { ...item, isNew };
      });
      onSubmit(newLanguages);
      setSelectedPersonalLanguages(newLanguages);
    } else {
      const newLanguages = selectedPersonalLanguages.map((item, currentIndex) => {
        if (String(currentIndex) === String(index)) {
          return { ...data };
        }
        return { ...item };
      });
      onSubmit(newLanguages);
      setSelectedPersonalLanguages(newLanguages);
    }
  };

  const handleMouseMove = () => {
    if (!isMouseInBlock) {
      setIsMouseInBlock(true);
    }
  };

  const onSubmit = list => {
    if (!Object.values(mainErrors).filter(item => Boolean(item)).length) {
      const updatedList = list
        .map(item => {
          const { isNew, title, type, ...rest } = item;
          return { ...rest };
        })
        .filter(item => Object.values(item).some(listItem => listItem));

      const normalizeLanguage = updatedList.map(item => ({ ...item, type: "lawyer" }));
      const source = axios.CancelToken.source();
      setCancelTokenSource(source);

      submitChanges({ languages: normalizeLanguage }, "languages", "save_language", source);
    }
  };

  const handleRemove = index => {
    const newLanguages = selectedPersonalLanguages.filter((item, currentIndex) => {
      return String(currentIndex) !== String(index);
    });

    onSubmit(newLanguages);
    setSelectedPersonalLanguages(newLanguages);
  };

  const selectedLength = selectedLanguages.length;
  const title = `${validation?.custom_name || `Language${selectedLength > 1 ? "s" : ""}`}${selectedLength ? ` (${selectedLength})` : ""}`;

  return (
    <div>
      <Dropdown
        description={validation.custom_description}
        isRequired={isRequired}
        title={title}
        isRequiredText={isRequiredText}
        handleMouseMove={handleMouseMove}
      >
        {selectedPersonalLanguages?.length > 0 ? (
          <div className={styles.content}>
            <div className={styles.languageContent}>
              {selectedPersonalLanguages.map((item, index) => {
                return (
                  <LanguageItem
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${item.language_id}-language-${index}-personal`}
                    handleSubmit={handleSubmit}
                    handleRemove={handleRemove}
                    handleRemoveEmptyLanguage={handleRemove}
                    selected={item}
                    languagesList={languagesList}
                    languagesOptions={normalizeLanguages}
                    index={index}
                    isNotRequired={isNotRequired}
                    isRequired={isRequired}
                    refButton={refButtonPersonal}
                    setIsEdited={setIsEdited}
                    isEdited={isEdited}
                    mainErrors={mainErrors}
                    setMainErrors={setMainErrors}
                    isEvents={isEvents}
                    cancelTokens={cancelTokens}
                  />
                );
              })}
            </div>
          </div>
        ) : null}

        <DropdownActions buttonHandle={handleSetSelectedPersonalLanguage} buttonText="Add languages" />
      </Dropdown>
    </div>
  );
};

const LanguageItem = ({
  languagesList,
  languagesOptions,
  selected,
  handleSubmit: onSubmit,
  index,
  handleRemove,
  isNotRequired,
  isRequired,
  handleRemoveEmptyLanguage,
  refButton,
  isEdited,
  setIsEdited,
  mainErrors,
  setMainErrors,
  isEvents,
  cancelTokens
}) => {
  const { t } = useTranslation();
  const { isNew } = selected;
  const [isMouseInBlock, setIsMouseInBlock] = useState(false);
  const containerRef = useRef();
  const [isTouched, setIsTouched] = useState(false);

  const options = {
    list: languagesList,
    skill: [
      { id: 1, label: t("conversational") },
      { id: 2, label: t("fluent") },
      { id: 3, label: t("native") }
    ]
  };

  const skills = { 1: t("conversational"), 2: t("fluent"), 3: t("native") };

  const validate = values => {
    const { language_id, skill } = values;
    const errors = {};

    const requiredText = "This field is mandatory";

    if (!language_id) {
      errors.language_id = requiredText;
    }

    if (!isEvents && !String(skill)) {
      errors.skill = requiredText;
    }

    const mainErrorsValue = mainErrors;
    mainErrorsValue[index] = Object.keys(errors).length ? errors : null;
    setMainErrors(mainErrorsValue);

    return errors;
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { ...selected, isEdited: false },
    onSubmit: values => {
      const { language_id, skill, type, prkey } = values;

      return onSubmit({ language_id, skill: isEvents ? 3 : skill, type, prkey }, isNew, index, type, cancelTokens);
    },
    validate
  });

  const { values, setFieldValue, setFieldTouched, errors, handleSubmit } = formik;

  const handleChange = (newValue, actionMeta) => {
    if (!values.isEdited) {
      setFieldValue("isEdited", true);
    }

    if (!isEdited) {
      setIsEdited(true);
    }

    setFieldValue(actionMeta.name, newValue.id.toString());

    if (String(values.skill) || isEvents) {
      setTimeout(() => {
        handleSubmit();
      }, 50);
    }
  };

  const handleChangeSkill = newValue => {
    setFieldValue("skill", newValue.id);

    if (!values.isEdited) {
      setFieldValue("isEdited", true);
    }

    if (!isEdited) {
      setIsEdited(true);
    }

    if (String(values.language_id)) {
      setTimeout(() => {
        handleSubmit();
      }, 50);
    }
  };

  const handleBlur = fieldName => {
    setFieldTouched(fieldName, true);
  };

  const onRemove = () => {
    if (selected.isNew) {
      handleRemoveEmptyLanguage(index, values.type);
      const mainErrorsValue = mainErrors;
      if (mainErrorsValue[index]) {
        mainErrorsValue[index] = null;
        setMainErrors(mainErrorsValue);
      }
    } else {
      handleRemove(index, values.type);
    }
  };

  const onHandleSubmit = () => {
    setFieldTouched("language_id");
  };

  const handleMouseLeave = () => {
    if (isMouseInBlock) {
      setIsMouseInBlock(false);
      const isNotRequiredSkill = isEvents ? true : String(values.skill);

      if (values.language_id && isNotRequiredSkill && values.isEdited && !Object.values(errors).some(item => item)) {
        handleSubmit();
        setFieldValue("isEdited", false);
      }
      setIsEdited(false);
    }
  };

  const handleMouseMove = () => {
    if (!isMouseInBlock) {
      setIsMouseInBlock(true);
    }
  };

  const skillValue = useMemo(() => {
    return String(values.skill) ? { id: values.skill, label: skills[values.skill] } : { id: 0, label: "Select" };
  }, [values.skill]);

  const handleTouchStart = () => {
    if (!isTouched) {
      setIsTouched(true);
    }
  };

  useEffect(() => {
    const handleTouch = event => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        if (isTouched) {
          setIsTouched(false);

          if (values.language_id && String(values.skill) && values.isEdited && !Object.values(errors).some(item => item) && isEdited) {
            handleSubmit();
            setIsEdited(false);
          }
        }
      }
    };

    document.addEventListener("touchstart", handleTouch, true);

    return () => {
      document.removeEventListener("touchstart", handleTouch, true);
    };
  }, [isEdited, values.language_id && String(values.skill)]);

  useValidationError(Object.values(mainErrors).some(item => Boolean(item)) ? { languages: "Error" } : "isRemove", "languages");

  return (
    <div
      ref={containerRef}
      className={styles.language}
      onMouseLeave={handleMouseLeave}
      onMouseMove={handleMouseMove}
      onTouchStart={handleTouchStart}
    >
      <div className={styles.inputContent}>
        <span className={styles.languageName}>Language {index + 1}</span>
        <Select
          error={errors.language_id}
          isInvalid={errors.language_id}
          getOptionValue={option => option.id}
          getOptionLabel={option => option.title}
          inputId="language"
          name="language_id"
          options={languagesOptions.sort((a, b) => (a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1))}
          handleBlur={() => handleBlur("language_id")}
          handleChange={handleChange}
          required={isRequired}
          value={getLanguageById(options.list, String(values.language_id))}
          placeholder={t("dashboard_listings_edit_language_selectlanguage")}
          classNameWrap={styles.fullWidth}
        />
      </div>

      <RemoveButton className={styles.removeButton} onClick={onRemove} />

      {!isEvents && (
        <div className={styles.inputContent}>
          <Select
            error={errors.skill}
            isInvalid={errors.skill}
            getOptionValue={option => option.id}
            getOptionLabel={option => option.label}
            inputId="skill"
            name="skill"
            options={options.skill}
            handleChange={handleChangeSkill}
            required={isNotRequired}
            value={skillValue}
            classNameWrap={styles.skillLanguage}
            placeholder={t("dashboard_listings_edit_language_selectflluency")}
          />
        </div>
      )}

      <button ref={refButton} type="button" className={styles.hidden} onClick={onHandleSubmit} />
    </div>
  );
};

export default Languages;
