import React from "react";

import { ReactComponent as LocationIcon } from "../../../media/icons/location.svg";

import styles from "./company.module.css";

const Company = ({ currentJob }) => {
  const {
    location_city: city,
    location_state: state,
    location_country: country,
    company_desc: description,
    company_website: website
  } = currentJob;

  return (
    <div className={styles.content}>
      <h3 className={styles.title}>_Shopify</h3>
      <div className={styles.heading}>
        <a href={`mailto::${website}`} className={styles.website}>
          {website}
        </a>
        <span className={styles.address}>
          <LocationIcon />
          {city ? `${city}, ` : ""}
          {state ? `${state}, ` : ""}
          {country ? `${country}, ` : ""}
        </span>
      </div>
      <span className={styles.description}>{description}</span>
    </div>
  );
};

export default Company;
