import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import { apiKey } from "../../../../../constants/stripe";
import { Modal } from "../../../../common/modal";
import EditCard from "../../../../common/payment/editCard";
import { modals as modalsConstant } from "../../../../../constants/listings";

const stripePromise = loadStripe(apiKey);

export const EditCardComponent = ({
  countries,
  paymentPreloaders,
  paymentSuccess,
  updateCard,
  selectedCard,
  modals,
  closeModal,
  clearAllModals
}) => {
  const handleClose = () => {
    closeModal(modalsConstant.editCard);
  };

  return (
    <Elements stripe={stripePromise}>
      <React.Fragment>
        {selectedCard && modals.editCard && (
          <Modal className="zIndex4" closeModal={handleClose} clearAllModals={clearAllModals}>
            <EditCard
              closeModal={() => closeModal(modalsConstant.editCard)}
              countries={countries}
              currentCardDetails={selectedCard}
              paymentPreloaders={paymentPreloaders}
              paymentSuccess={paymentSuccess}
              updateCard={updateCard}
            />
          </Modal>
        )}
      </React.Fragment>
    </Elements>
  );
};
