import React from "react";
import { Redirect, useLocation } from "react-router-dom";

import MainRoutes from "../mainRoutes";
import { OverlayPreloader } from "../../components/common/preloader";

const PrivateRoutes = ({ isLoggedIn, keycloak }) => {
  const location = useLocation();

  if (isLoggedIn === null) {
    return <OverlayPreloader />;
  }

  return isLoggedIn ? <MainRoutes keycloak={keycloak} location={location} /> : <Redirect to="/login" />;
};

export default PrivateRoutes;
