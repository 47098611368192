import React, { useEffect } from "react";

import styles from "./location.module.css";
import { ReactComponent as LocationIcon } from "../../../media/icons/location.svg";
import { ReactComponent as PhoneIcon } from "../../../media/icons/phone-filled.svg";

const Location = ({ address, phones = [] }) => {
  function initMap() {
    const mapDiv = document.getElementById("map");
    // eslint-disable-next-line
    new window.google.maps.Map(mapDiv, {
      center: { lat: Number(address.latitude), lng: Number(address.longitude) },
      zoom: 13
    });
  }

  useEffect(() => {
    initMap();
  }, []);

  return (
    <div>
      <h5 className={styles.title}>Location</h5>
      <div className={styles.flex}>
        <span className={styles.pin}>
          <LocationIcon />
        </span>
        <div>
          <p className={styles.place}>{`${address.street} ${address.city}, ${address.country}`}</p>
          <p className={styles.address}>{`${address.street} ${address.city}, ${address.country}`}</p>
        </div>
      </div>
      {phones.length ? (
        <div className={styles.flex}>
          <span className={styles.pin}>
            <PhoneIcon />
          </span>
          <div>
            {address.phone}
            {address.fax}
          </div>
        </div>
      ) : null}

      <div className={styles.mapContainer} id="map" />
    </div>
  );
};

export default Location;
