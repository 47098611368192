import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import cn from "classnames";

import { useDispatch, useSelector } from "react-redux";
import { OverlayPreloader } from "../../common/preloader";
import { steps, tabs } from "../../../constants/home";
import { LetsUpHelp } from "../letsUpHelp";
import { AddFreeListing } from "../addFreeListing";
import { Button } from "../../common/button";
import closeIcon from "../../../media/icons/close-grey.svg";
import { actions as mainActions } from "../../../actions/main";
import styles from "./addListing.module.css";

// todo remove
export const AddListing = ({ addListingAssistant, loading, successListingAssistant, removeListingAssistantSuccess }) => {
  const { t } = useTranslation();
  const [step, setStep] = useState(steps.initial);
  const [tab, setTab] = useState(tabs.newListing);
  const { account } = useSelector(state => state);
  const { profile } = account;
  const [isMouseInBlock, setIsMouseInBlock] = useState(false);
  const dispatch = useDispatch();

  const toggleNewListing = useCallback(isActive => dispatch(mainActions.toggleNewListing(isActive)), [dispatch]);

  const handleOpenNewListing = () => {
    toggleNewListing(true);
  };

  const handleMouseMove = () => {
    if (!isMouseInBlock) {
      setIsMouseInBlock(true);
    }
  };

  const handleMouseLeave = () => {
    if (isMouseInBlock) {
      setIsMouseInBlock(false);
    }
  };

  const submittingText = [
    t("dashboard_home_actionblock_tab1_step1"),
    t("dashboard_home_actionblock_tab1_step2"),
    t("dashboard_home_actionblock_tab1_step3"),
    t("dashboard_home_actionblock_tab1_step4"),
    t("dashboard_home_actionblock_tab1_step5")
  ];

  const submittingTextList = submittingText.map((text, index) => {
    return <li className={styles.listItem} key={text}>{`${index + 1}. ${text}`}</li>;
  });

  const handleClose = () => {
    setStep(steps.initial);
    setTab(tabs.newListing);

    handleRemoveListingAssistantSuccess();
  };

  const handleRemoveListingAssistantSuccess = () => {
    if (successListingAssistant) {
      removeListingAssistantSuccess();
    }
  };

  const handleStayInTouch = () => {
    if (window && window.Intercom) {
      window.Intercom("show");
    }
  };

  const activeContainer = isMouseInBlock && tab === tabs.newListing && step === steps.form ? styles.activeContainer : "";

  return (
    <>
      {isMouseInBlock && tab === tabs.newListing && step === steps.form && (
        <OverlayPreloader overlayClassName={styles.overlay} spinnerClassName={styles.spinner} />
      )}

      <div className={cn(styles.container, activeContainer)} onMouseMove={handleMouseMove} onMouseLeave={handleMouseLeave}>
        {loading && <OverlayPreloader />}
        {step === steps.initial && (
          <div className={styles.firstStep}>
            <button type="button" className={styles.addNewListingButton} onClick={handleOpenNewListing}>
              {t("addlisting_start")}
            </button>
            <p className={styles.description}>{t("addlisting_desc")}</p>
          </div>
        )}
        {step === steps.form && (
          <div className={styles.formStep}>
            <Button
              className={cn("button--clear", styles.closeBtn)}
              onClick={() => {
                setStep(steps.initial);
                handleRemoveListingAssistantSuccess();
              }}
            >
              <img src={closeIcon} alt="close" />
            </Button>
            <div className={styles.tabs}>
              <button
                type="button"
                className={cn(styles.tabsItem, tab === tabs.newListing && styles.tabsItemActive)}
                onClick={() => setTab(tabs.newListing)}
              >
                {t("addlisting_tab1")}
              </button>
              <button
                type="button"
                className={cn(styles.tabsItem, tab === tabs.letUpHelp && styles.tabsItemActive)}
                onClick={() => setTab(tabs.letUpHelp)}
              >
                {t("addlisting_tab2")}
              </button>
            </div>
            {tab === tabs.newListing && (
              <div className={styles.content}>
                <AddFreeListing handleClose={handleClose} />
              </div>
            )}
            {tab === tabs.letUpHelp && (
              <>
                {!successListingAssistant ? (
                  <div className={styles.content}>
                    <p className={styles.contentDescription}>{t("dashboard_home_actionblock_tab1_desc")}</p>
                    <LetsUpHelp
                      onClose={handleClose}
                      addListingAssistant={addListingAssistant}
                      professionalName={`${profile.name_f || ""} ${profile.name_l || ""}`}
                    />
                  </div>
                ) : (
                  <div className={styles.content}>
                    <h2 className={styles.contentTitle}>{t("dashboard_home_actionblock_tab1_success_title")}</h2>
                    <ul className={styles.list}>{submittingTextList}</ul>
                    <div className={styles.buttonContainer}>
                      <button type="button" className={styles.close} onClick={handleClose}>
                        {t("dashboard_common_button_close")}
                      </button>
                      <button type="button" className={styles.submit} onClick={handleStayInTouch}>
                        {t("dashboard_home_actionblock_tab1_success_button")}
                      </button>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
};
