import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import cn from "classnames";

import { setDocumentTitle } from "../../helpers/setDocumentTitle";
import { ReactComponent as MainLogo } from "../../media/icons/mainLogo.svg";

import alertIcon from "../../media/icons/watch.svg";
import styles from "./notificationLeadPage.module.css";
import "./index.css";

export const NotificationLeadPage = ({ metaTitle, title, text, buttonText }) => {
  useEffect(() => {
    if (metaTitle) {
      setDocumentTitle(metaTitle);
    }
  }, [metaTitle]);

  return (
    <>
      <header className={cn("forms-header", styles.header)}>
        <MainLogo className={styles.mainLogo} />
        <Link className={cn("header__login-link", styles.headerLink)} to="/login">
          Login
        </Link>
      </header>

      <div className={cn("wrapper", styles.wrapper)}>
        <section className={cn("info-section", styles.info)}>
          <div className="info-section__icon">
            <img className="not-available-icon" src={alertIcon} alt="" />
          </div>
          <div className="info-section__title">
            <p>{title}</p>
          </div>
          <div className="info-section__content">
            <p>{text}</p>
          </div>
        </section>
        <footer className={cn("forms__footer", styles.footer)}>
          <Link to="/login" className={cn("form__button", styles.link)}>
            {buttonText}
          </Link>
        </footer>
      </div>
    </>
  );
};
