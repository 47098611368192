import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { PopupActions } from "../../common/popupActions";
import { ReactComponent as EditIcon } from "../../../media/icons/edit-listing-icon.svg";
import { modals as modalsConstant } from "../../../constants/listings";
import { Button } from "../../common/button";
import { statuses } from "../../../constants/listingDetail";
import styles from "./productControls.module.css";

const ProductControls = ({ isPendingPayment, isPremium, isExpired, paused, openModal, canceledDate, isProfilePage, publishStatus }) => {
  const { t } = useTranslation();
  const [actionPrompt, setActionPrompt] = useState(false);
  const isActiveStatus = publishStatus === statuses.active;

  const closePrompt = () => {
    setActionPrompt(false);
  };

  const handleOpenModal = () => {
    if (isPendingPayment) {
      openModal(modalsConstant.payPending);
    } else if (isExpired) {
      openModal(modalsConstant.renew);
    } else {
      openModal(modalsConstant.upgrade);
    }
  };

  return (
    <PopupActions setActionPrompt={closePrompt} promptRoot={<EditIcon />} promptRootClass={styles.editButton} promptClass={styles.controls}>
      {!actionPrompt && (
        <React.Fragment>
          {isPremium && !canceledDate && (
            <Button className={styles.link} onClick={() => openModal(modalsConstant.downgrade)}>
              {t("downgrade")}
            </Button>
          )}

          {!isPremium && (
            <Button className={styles.link} onClick={handleOpenModal}>
              {t("upgrade")}
            </Button>
          )}

          {isActiveStatus || isPremium || String(paused) === "1" ? (
            <>
              {String(paused) === "1" ? (
                <Button className={styles.link} onClick={() => openModal(modalsConstant.unpause)}>
                  {t("dashboard_single_listing_unpause")}
                </Button>
              ) : (
                <Button className={styles.link} onClick={() => openModal(modalsConstant.pause)}>
                  {t("dashboard_single_listing_pause")}
                </Button>
              )}
            </>
          ) : null}

          {!isProfilePage && (
            <Button className={styles.link} onClick={() => openModal(modalsConstant.delete)}>
              {t("dashboard_listings_table_delete")}
            </Button>
          )}
        </React.Fragment>
      )}
    </PopupActions>
  );
};

export default ProductControls;
