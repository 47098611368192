import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import styles from "./connected.module.css";

const Connected = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h3 className={styles.title}>{t("dashboard_hp_banner_title")}</h3>
        <div className={styles.actions}>
          <Link to="/login" className={styles.signUp}>
            {t("dashboard_hp_banner_button1")}
          </Link>
          <Link to="/login" className={styles.login}>
            {t("dashboard_hp_banner_button2")}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Connected;
