import React from "react";
import { Link } from "react-router-dom";

import styles from "./sectionHeading.module.css";

export const SectionHeading = ({ title, to, linkText, icon, alt = "" }) => {
  return (
    <div className={styles.heading}>
      <div className={styles.wrap}>
        <img className={styles.icon} src={icon} alt={alt} />
        <h2 className={styles.title}>{title}</h2>
      </div>
      <Link className={styles.link} to={to}>
        {linkText}
      </Link>
    </div>
  );
};
