import React from "react";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import { format } from "date-fns";

import { getFormattedDateTimeZone } from "../../../../helpers";
import styles from "./history.module.css";

export const History = ({ history }) => {
  const { t } = useTranslation();

  const renderStatus = status => {
    if (status.includes("pending") || status.includes("received")) {
      return <p className={cn(styles.status, styles.statusYellow)}>{t(status)}</p>;
    }

    if (status.includes("reviewed") || status.includes("morematches")) {
      return <p className={cn(styles.status, styles.statusGreen)}>{t(status)}</p>;
    }

    if (status.includes("notmatch") || status.includes("missinginformation") || status.includes("rejected")) {
      return <p className={cn(styles.status, styles.statusRed)}>{t(status)}</p>;
    }

    return null;
  };

  return (
    <>
      <h3 className={styles.title}>{t("dashboard_referrals_details_change_title")}</h3>
      <div className={styles.historyContent}>
        <div className={styles.row}>
          <div className={styles.itemLeft}>
            <h3 className={styles.label}>{t("dashboard_referrals_details_change_date")}</h3>
          </div>
          <div className={styles.itemRight}>
            <h3 className={styles.label}>{t("dashboard_referrals_details_change_status")}</h3>
          </div>
        </div>

        {history.map(({ added, status }, index) => {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <div className={cn(styles.row, styles.rowContent)} key={`${added}-${status}-${index}`}>
              <div className={styles.itemLeft}>
                <p className={styles.date}>{format(getFormattedDateTimeZone(added), "MMM dd, yyyy' 'HH:mm")}</p>
              </div>
              <div className={styles.itemRight}>{renderStatus(status)}</div>
            </div>
          );
        })}
      </div>
    </>
  );
};
