import React, { useCallback } from "react";
import format from "date-fns/format";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { Status } from "../../home/status";
import styles from "./listItem.module.css";
import { actions as payPerLeadDetailActions } from "../../../actions/payPerLeadDetail";
import { modals as modalsConstant } from "../../../constants/payPerLeadDetail";

const ListItem = ({
  isReferral,
  price,
  added,
  title,
  city,
  state,
  summary,
  practicesTitle,
  leadId,
  purchased,
  setNoInterest,
  setInterest,
  hide,
  lead,
  status,
  matches,
  team
}) => {
  const dispatch = useDispatch();
  const isPurchased = purchased ? "1" : "0";
  const isHide = Number(hide) === 1;
  const checkCouponClear = useCallback(() => dispatch(payPerLeadDetailActions.checkCouponClear()), [dispatch]);
  const toggleModal = useCallback((modal, isActive) => dispatch(payPerLeadDetailActions.toggleModal(modal, isActive)), [dispatch]);
  const setLead = useCallback(() => dispatch(payPerLeadDetailActions.setLead(lead)), [dispatch]);
  const { t } = useTranslation();

  const openModal = () => {
    toggleModal(modalsConstant.payment, true);
    setLead();
    checkCouponClear();
  };

  const handleNoInterest = () => {
    setNoInterest(leadId);
  };

  const handleInterest = () => {
    setInterest(leadId);
  };

  return (
    <div className={styles.item}>
      <div className={styles.content}>
        <h5 className={styles.title}>{title}</h5>
        <p className={styles.description}>{summary}</p>
        <p className={styles.directory}>
          <span>Directory: </span>
          {practicesTitle?.map((item, index) => {
            return <React.Fragment key={item}>{`${item}${index + 1 !== practicesTitle.length ? "," : ""} `}</React.Fragment>;
          })}
        </p>
        <div className={styles.info}>
          <p className={styles.infoItem}>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path
                d="M10.3883 17.0128L10.0201 16.3026L10.0201 16.3026L10.3883 17.0128ZM9.68198 17.0128L9.31379 17.723L9.31379 17.723L9.68198 17.0128ZM15.4852 9.15091C15.4852 11.1015 14.4915 12.7049 13.2642 13.9421C12.0377 15.1786 10.6448 15.9787 10.0201 16.3026L10.7565 17.723C11.453 17.362 13.0086 16.4718 14.4001 15.0689C15.791 13.6668 17.0852 11.6817 17.0852 9.15091H15.4852ZM10.0352 3.70088C13.0451 3.70088 15.4852 6.14094 15.4852 9.15091H17.0852C17.0852 5.25729 13.9288 2.10088 10.0352 2.10088V3.70088ZM4.58516 9.15091C4.58516 6.14094 7.02521 3.70088 10.0352 3.70088V2.10088C6.14155 2.10088 2.98516 5.25729 2.98516 9.15091H4.58516ZM10.0502 16.3026C9.42549 15.9787 8.03265 15.1786 6.8061 13.9421C5.5788 12.7049 4.58516 11.1015 4.58516 9.15091H2.98516C2.98516 11.6817 4.27935 13.6668 5.67017 15.0689C7.06175 16.4718 8.61733 17.362 9.31379 17.723L10.0502 16.3026ZM10.0201 16.3026C10.0197 16.3028 10.021 16.3021 10.024 16.3013C10.027 16.3006 10.0309 16.3 10.0352 16.3C10.0394 16.3 10.0433 16.3006 10.0463 16.3013C10.0493 16.3021 10.0506 16.3028 10.0502 16.3025L9.31379 17.723C9.76908 17.959 10.3012 17.9591 10.7565 17.723L10.0201 16.3026ZM11.9141 9.15083C11.9141 10.1884 11.073 11.0294 10.0355 11.0294V12.6294C11.9567 12.6294 13.5141 11.072 13.5141 9.15083H11.9141ZM10.0355 7.27225C11.073 7.27225 11.9141 8.11332 11.9141 9.15083H13.5141C13.5141 7.22967 11.9567 5.67225 10.0355 5.67225V7.27225ZM8.15693 9.15083C8.15693 8.11332 8.998 7.27225 10.0355 7.27225V5.67225C8.11434 5.67225 6.55693 7.22967 6.55693 9.15083H8.15693ZM10.0355 11.0294C8.998 11.0294 8.15693 10.1884 8.15693 9.15083H6.55693C6.55693 11.072 8.11434 12.6294 10.0355 12.6294V11.0294Z"
                fill="#61616F"
              />
            </svg>
            {city} {state ? `, ${state}` : ""}
          </p>
          <p className={styles.infoItem}>
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
              <rect x="1.5" y="4" width="15" height="12.5" rx="1.66667" stroke="#61616F" strokeWidth="1.66667" />
              <path
                d="M1.5 7.33333C1.5 5.76198 1.5 4.97631 1.98816 4.48816C2.47631 4 3.26198 4 4.83333 4H13.1667C14.738 4 15.5237 4 16.0118 4.48816C16.5 4.97631 16.5 5.76198 16.5 7.33333H1.5Z"
                fill="#61616F"
              />
              <path d="M4.83301 1.5L4.83301 4" stroke="#61616F" strokeWidth="1.66667" strokeLinecap="round" />
              <path d="M13.167 1.5L13.167 4" stroke="#61616F" strokeWidth="1.66667" strokeLinecap="round" />
            </svg>
            {added && <>{format(new Date(added), "MMM do, yyyy")}</>}
          </p>
        </div>
      </div>
      {isReferral ? (
        <div className={styles.actions}>
          <Status caseItem={{ status, matches }} />

          <Link to={`/referral-requests/${leadId}`} className={styles.readMore}>
            See More
          </Link>
        </div>
      ) : (
        <div className={styles.actions}>
          <button type="button" className={styles.purchase} onClick={openModal}>
            {t("dashboard_referrals_buybutton")} ${price}
          </button>

          <Link
            to={`/paid-leads/${leadId}?purchased=${isPurchased}${team?.value ? `&user_id=${team.value}` : ""}`}
            className={styles.readMore}
          >
            {t("dashboard_common_readmore")}
          </Link>

          {isHide ? (
            <button type="button" className={styles.hide} onClick={handleInterest}>
              Unhide
            </button>
          ) : (
            <button type="button" className={styles.hide} onClick={handleNoInterest}>
              Hide
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default ListItem;
