import React from "react";
import { useTranslation } from "react-i18next";
import format from "date-fns/format";

import calendarIcon from "../../../../media/icons/calendar.svg";
import styles from "./comment.module.css";

export const Comment = ({ added_tm, photo, comment, name_f, name_l }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.box}>
      <div className={styles.heading}>
        {photo && (
          <div className={styles.photo}>
            <img src={photo} alt="" />
          </div>
        )}

        <div>
          <p className={styles.name}>
            {name_f} {name_l}
          </p>
        </div>
      </div>
      <div className={styles.infoBox}>
        <img className={styles.calendarIcon} src={calendarIcon} alt="" />
        <p className={styles.date}>
          {t("dashboard_qa_single_answered")} {format(new Date(added_tm.split(" ").join("T")), "MMM do yyyy, h:mm a")}
        </p>
      </div>

      <p className={styles.text}>{comment}</p>
      <div className="down-line mb30" />
    </div>
  );
};
