import { shallowEqual, useDispatch, useSelector } from "react-redux";
import React, { useCallback } from "react";

import { useHistory } from "react-router-dom";
import { modals as modalsConstant, statuses } from "../../../constants/listings";
import { actions as paymentActions } from "../../../actions/payment";
import { actions as listingActions } from "../../../actions/listing";
import { Upgrade } from "../../listings/components/modals/upgrade";
import { Payment } from "../../listings/components/modals/payment";
import { PaymentSuccess } from "../../listings/components/modals/paymentSuccess";
import Confirm from "../../listings/components/modals/confirm";
import { DeleteSuccess } from "../../listings/components/modals/deleteSuccess";
import { Delete } from "../../listings/components/modals/delete";
import { Pause } from "../../listings/components/modals/pause";
import { Unpause } from "../../listings/components/modals/unpause";
import { PauseSuccess } from "../../listings/components/modals/pauseSuccess";
import { UnpauseSuccess } from "../../listings/components/modals/unpauseSuccess";
import { DowngradeSuccess } from "../../listings/components/modals/downgradeSuccess";
import { Downgrade } from "../../listings/components/modals/downgrade";
import { DowngradePeriodSuccess } from "../../listings/components/modals/downgradePeriodSuccess";
import { DowngradePeriod } from "../../listings/components/modals/downgradePeriod";

export const Modals = ({ itemToEdit, openModal, closeModal, setItemToEdit }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const clearPaymentState = useCallback(() => dispatch(paymentActions.clearState()), [dispatch]);
  const cleanUpgradeOptions = useCallback(() => dispatch(listingActions.cleanUpgradeOptions()), [dispatch]);
  const chargeForMembership = useCallback(
    (paymentData, paymentType) => dispatch(paymentActions.chargeForMembership(paymentData, paymentType, false, history)),
    [dispatch]
  );
  const deleteCard = useCallback(cardToDelete => dispatch(paymentActions.deleteCard(cardToDelete)), [dispatch]);
  const getUpgradeOptions = useCallback((id, coupon) => dispatch(listingActions.getUpgradeOptions(id, coupon)), [dispatch]);
  const getPaymentMethods = useCallback(() => dispatch(paymentActions.getPaymentMethods()), [dispatch]);
  const selectCard = useCallback(card => dispatch(paymentActions.selectCard(card)), [dispatch]);
  const updateDefaultSource = useCallback(cardId => dispatch(paymentActions.updateDefaultSource(cardId)), [dispatch]);
  const updateActiveUpgradeOptions = useCallback(activeOption => dispatch(listingActions.updateActiveUpgradeOptions(activeOption)), [
    dispatch
  ]);
  const downgradeMembership = useCallback(ids => dispatch(listingActions.downgradeMembership(ids, false, true, history)), [dispatch]);
  const unDowngradeMembership = useCallback(ids => dispatch(listingActions.unDowngradeMembership(ids, false, true, history)), [dispatch]);
  const downgradeMembershipHow = useCallback(ids => dispatch(listingActions.downgradeMembershipHow(ids, false, true, history)), [dispatch]);

  const deleteMembership = useCallback(memberData => dispatch(listingActions.deleteMembership(memberData, true, history)), [dispatch]);

  const clearSavedPaymentData = useCallback(() => dispatch(listingActions.clearSavedPaymentData()), [dispatch]);
  const setSavedPaymentData = useCallback(() => dispatch(listingActions.setSavedPaymentData()), [dispatch]);
  const pauseMembership = useCallback(id => dispatch(listingActions.pauseListing(id, true, history, false)), [dispatch]);
  const unpauseMembership = useCallback(id => dispatch(listingActions.unPauseListing(id, false, true, history)), [dispatch]);

  const clearAllModals = useCallback(() => dispatch(listingActions.clearAllModals()), [dispatch]);

  const { preloaders } = useSelector(state => state.home);
  const { defaultSource, preloaders: paymentPreloaders, paymentMethods, selectedCard } = useSelector(state => state.payment);
  const {
    upgradeOptions,
    preloaders: listingPreloaders,
    activeUpgradeOptions,
    modals,
    savedPaymentData,
    showUpgradeOptionsError
  } = useSelector(state => state.listing);
  const { dashboardData } = useSelector(state => state.home, shallowEqual);
  const { listings } = dashboardData;

  const handleDelete = id => {
    const target = listings.find(({ listing_id }) => listing_id === id);
    return deleteMembership(target);
  };

  return (
    <>
      {!preloaders.memberships && listings && (
        <React.Fragment>
          {modals.upgrade && (
            <Upgrade
              cleanUpgradeOptions={cleanUpgradeOptions}
              itemToEdit={itemToEdit}
              modals={modals}
              preloaders={listingPreloaders}
              getUpgradeOptions={getUpgradeOptions}
              activeUpgradeOptions={activeUpgradeOptions}
              updateActiveUpgradeOptions={updateActiveUpgradeOptions}
              showUpgradeOptionsError={showUpgradeOptionsError}
              setItemToEdit={setItemToEdit}
              upgradeOptions={upgradeOptions}
              clearAllModals={clearAllModals}
              closeModal={closeModal}
              openModal={openModal}
              savedPaymentData={savedPaymentData}
              clearSavedPaymentData={clearSavedPaymentData}
              setSavedPaymentData={setSavedPaymentData}
            />
          )}

          {modals.payment && (
            <Payment
              deleteCard={deleteCard}
              defaultSource={defaultSource}
              getPaymentMethods={getPaymentMethods}
              paymentMethods={paymentMethods}
              paymentPreloaders={paymentPreloaders}
              selectedCard={selectedCard}
              selectCard={selectCard}
              updateDefaultSource={updateDefaultSource}
              closeModal={() => closeModal(modalsConstant.payment)}
              clearAllModals={clearAllModals}
              openModal={openModal}
              savedPaymentData={savedPaymentData}
              clearSavedPaymentData={clearSavedPaymentData}
              isPendingPayment={statuses.pendingPayment === itemToEdit.status}
            />
          )}

          {modals.confirm && (
            <Confirm
              openModal={openModal}
              closeModal={() => closeModal(modalsConstant.confirm)}
              clearAllModals={clearAllModals}
              activeUpgradeOptions={activeUpgradeOptions}
              modals={modals}
              chargeForMembership={chargeForMembership}
              itemToEdit={itemToEdit}
              selectedCard={selectedCard}
              defaultSource={defaultSource}
              paymentPreloaders={paymentPreloaders}
              clearSavedPaymentData={clearSavedPaymentData}
            />
          )}

          {modals.paymentSuccess && (
            <PaymentSuccess
              closeModal={() => closeModal(modalsConstant.paymentSuccess)}
              clearAllModals={clearAllModals}
              clearPaymentState={clearPaymentState}
              clearSavedPaymentData={clearSavedPaymentData}
              isPendingPayment={statuses.pendingPayment === itemToEdit.status}
            />
          )}

          {modals.delete && (
            <Delete
              closeModal={clearAllModals}
              clearAllModals={clearAllModals}
              itemToEdit={itemToEdit}
              handleDelete={handleDelete}
              preloadersDeleteMembership={listingPreloaders.deleteMembership}
              handlePause={() => openModal(modalsConstant.pause)}
            />
          )}

          {modals.pause && (
            <Pause
              closeModal={clearAllModals}
              clearAllModals={clearAllModals}
              itemToEdit={itemToEdit}
              handlePause={pauseMembership}
              preloadersPauseMembership={listingPreloaders.pause}
            />
          )}

          {modals.unpause && (
            <Unpause
              closeModal={clearAllModals}
              clearAllModals={clearAllModals}
              itemToEdit={itemToEdit}
              handleUnpause={unpauseMembership}
              preloadersUnpauseMembership={listingPreloaders.unpause}
            />
          )}

          {modals.downgrade && (
            <Downgrade
              closeModal={clearAllModals}
              clearAllModals={clearAllModals}
              itemToEdit={itemToEdit}
              downgradeMembership={downgradeMembership}
              preloadersDowngradeMembership={listingPreloaders.downgrade}
            />
          )}
          {modals.deleteSuccess && <DeleteSuccess closeModal={clearAllModals} clearAllModals={clearAllModals} />}
          {modals.pauseSuccess && <PauseSuccess closeModal={clearAllModals} clearAllModals={clearAllModals} />}
          {modals.unpauseSuccess && <UnpauseSuccess closeModal={clearAllModals} clearAllModals={clearAllModals} />}
          {modals.downgradeSuccess && <DowngradeSuccess closeModal={clearAllModals} clearAllModals={clearAllModals} />}
          {modals.downgradePeriodSuccess && <DowngradePeriodSuccess closeModal={clearAllModals} clearAllModals={clearAllModals} />}

          {modals.downgradePeriod && (
            <DowngradePeriod
              closeModal={clearAllModals}
              clearAllModals={clearAllModals}
              itemToEdit={itemToEdit}
              preloaders={listingPreloaders.downgrade}
              unDowngradeMembership={unDowngradeMembership}
              downgradeMembershipHow={downgradeMembershipHow}
            />
          )}
        </React.Fragment>
      )}
    </>
  );
};
