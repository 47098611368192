import React from "react";

import { ReactComponent as HamburgerIcon } from "../../../media/icons/hamburger.svg";
import styles from "./hamburger.module.css";

export const Hamburger = ({ toggleDropdown }) => {
  return (
    <button className={styles.hamburger} type="button" onClick={toggleDropdown}>
      <HamburgerIcon />
    </button>
  );
};
