import React, { useEffect, useState } from "react";
import cn from "classnames";

import { ReactComponent as PinIcon } from "../../../media/icons/pin.svg";
import { Button } from "../../common/button";
import { ReactComponent as SaveIcon } from "../../../media/icons/heart.svg";
import { ReactComponent as ShareIcon } from "../../../media/icons/share.svg";
import styles from "./jobsListing.module.css";

const JobsListing = ({ list, isExpand, addToFavorite, removeFavorite }) => {
  return (
    <div className={cn(styles.flex, isExpand ? styles.flexExpand : "")}>
      {list.map(item => {
        return <Job key={item.title} item={item} isExpand={isExpand} addToFavorite={addToFavorite} removeFavorite={removeFavorite} />;
      })}
    </div>
  );
};

const Job = ({ item, isExpand, removeFavorite, addToFavorite }) => {
  const [isFavorite, setIsFavorite] = useState(false);

  const toggleFavorite = listingId => {
    if (isFavorite) {
      removeFavorite(listingId);
      setIsFavorite(false);
    } else {
      addToFavorite(listingId);
      setIsFavorite(true);
    }
  };

  useEffect(() => {
    if (item.bookmarked === "true") {
      setIsFavorite(true);
    }
  }, []);

  return (
    <div className={cn(styles.tile, isExpand ? styles.tileExpand : "")} key={item.title}>
      <div className={styles.favorites}>
        <Button
          className={cn(styles.favoritesButton, isFavorite ? styles.favoritesButtonActive : "")}
          onClick={() => toggleFavorite(item.listing_id)}
        >
          <SaveIcon />
        </Button>
        <Button className={styles.favoritesButton}>
          <ShareIcon />
        </Button>
      </div>

      <div className={styles.tileImage}>
        <img src={item.listings_image_name} alt="Job" />
      </div>
      <div>
        <div className={styles.titleFlex}>
          <span className={styles.titleDetail}>_c{item.companyName}</span>
          <span className={styles.titleDetail}>
            <PinIcon /> __{item.position}
          </span>
        </div>

        <h6 className={styles.tileTitle}>{item.attorney}</h6>
        <p className={styles.tileDescription}>{item.description}</p>
      </div>
    </div>
  );
};

export default JobsListing;
