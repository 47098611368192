import React from "react";

import { Modal } from "../../../common/modal";
import { ReactComponent as SuccessIcon } from "../../../../media/icons/success.svg";
import ModalControl from "../../../modalControl";
import styles from "./changeEmailSuccess.module.css";

const ChangeEmailSuccess = ({ clearAllModals, closeModal }) => {
  return (
    <Modal classNameMain={styles.modal} clearAllModals={clearAllModals} closeModal={closeModal}>
      <h2 className={styles.subTitle}>ACCOUNT SETTINGS</h2>
      <h1 className={styles.title}>Change Email</h1>

      <div className={styles.content}>
        <SuccessIcon className={styles.icon} />
        <div className={styles.detail}>You have successfully changed your email address.</div>
      </div>

      <ModalControl onExit={closeModal} isHideExitConfirm isClose />
    </Modal>
  );
};

export default ChangeEmailSuccess;
