import getYear from "date-fns/getYear";

const normalizeNewslettersToKeys = newsletters => Object.keys(newsletters).map(item => String(item));

export const renderSelectNewLetter = (newsletters, selectedNewsLetter) => {
  const normalizeSelectedNewLetter = selectedNewsLetter.map(item => String(item));

  return normalizeNewslettersToKeys(newsletters)
    .filter(item => normalizeSelectedNewLetter.includes(String(item)))
    .map(item => {
      return { value: item, label: newsletters[item] };
    });
};

export const renderSelectOptionsNewLetter = (newsletters, selectedNewsLetter) => {
  const activeKeys = selectedNewsLetter.map(item => item.value).map(item => String(item));
  return normalizeNewslettersToKeys(newsletters)
    .filter(item => !activeKeys.includes(item))
    .map(item => {
      return { value: item, label: newsletters[item] };
    });
};

export const renderSelectCountries = (countries = [], shouldReturnFormatted = true) => {
  return countries?.map(({ alpha2, name }) => {
    if (shouldReturnFormatted) {
      return {
        value: alpha2,
        label: name
      };
    }
    return alpha2;
  });
};

export const renderSelectStates = (country, states = [], shouldReturnFormatted = true) => {
  const statesInCountry = states.filter(state => state?.country === country);
  return statesInCountry.map(({ state, title }) => {
    if (shouldReturnFormatted) {
      return {
        value: state,
        label: title
      };
    }
    return state;
  });
};

export const renderSelectProfessions = professions => {
  return professions.map(item => {
    return {
      value: item.listing_dir_profession_id,
      label: item.title
    };
  });
};

export const renderSelectSpecialty = (specialty, professionId, specialtyValue) => {
  return specialty
    .filter(item => {
      return professionId === item.profession_id && !specialtyValue.some(obj => obj.value === item.practice_id);
    })
    .map(elem => {
      return {
        value: elem.practice_id,
        label: elem.title
      };
    });
};

export const getCountryNameFromCode = (countryList, countryCode) => {
  if (countryCode && countryList?.length) {
    const findCountry = countryList.find(({ alpha2 }) => alpha2 === countryCode);
    return findCountry ? findCountry.name : "Select";
  }
  return "Select";
};

export const getStateNameFromCode = (stateList, stateCode) => {
  if (stateCode && stateList?.length) {
    const findState = stateList.find(({ state }) => state === stateCode);
    return findState ? findState.title : "Select";
  }
  return "Select";
};

export const getLanguageById = (languages, id) => {
  if (id !== "") {
    const foundLanguage = languages.find(language => {
      return language.id.toString() === id;
    });

    if (foundLanguage) {
      return { id: foundLanguage.id, title: foundLanguage.title };
    }
  }
  return { id: "", title: "Select" };
};

export const makePerformanceYearOptions = dateOfCreatedUser => {
  const createdYear = dateOfCreatedUser ? new Date(dateOfCreatedUser).getFullYear() : 2016;
  const date = new Date();
  const numOfYears = getYear(date) - createdYear;
  const options = [];

  for (let i = 0; i < numOfYears + 1; i++) {
    options.push({ value: (createdYear + i).toString(), label: (createdYear + i).toString() });
  }

  const sort = (a, b) => {
    return parseInt(a.value, 10) > parseInt(b.value, 10) ? -1 : 1;
  };

  return options.sort(sort);
};
