import React from "react";
import cn from "classnames";

import { Preloader } from "../../../common/preloader";
import { ReactComponent as CloseIcon } from "../../../../media/icons/close-cross-thin.svg";
import { ACTIVE_BLOCK_NAMES } from "../../../../constants/messages";
import DetailClient from "../detailClient";
import Detail from "../detail";
import styles from "./messageDetail.module.css";

const MessageDetail = ({ preloaders, activityBlocks, setActiveBlocks, messageDetail, isClient, messagesLengh }) => {
  const detailActive = activityBlocks.detail ? styles.detailActive : "";
  const detailDisable = !activityBlocks.detailDesktop ? styles.detailDisable : "";
  const isActivePreloaders = Object.values(preloaders).some(item => item);

  const handleDisableMobile = () => {
    setActiveBlocks({ [ACTIVE_BLOCK_NAMES.CONVERSATION]: true, [ACTIVE_BLOCK_NAMES.DETAIL]: false });
  };

  const handleDisableDesktop = () => {
    setActiveBlocks({ [ACTIVE_BLOCK_NAMES.DETAIL]: false, [ACTIVE_BLOCK_NAMES.DETAIL_DESKTOP]: false });
  };

  return (
    <>
      {messagesLengh ? (
        <div className={cn(styles.detail, detailActive, detailDisable)}>
          {preloaders.messageDetail && !preloaders.messages && <Preloader className={styles.preloader} />}

          {!isActivePreloaders && (
            <>
              <button type="button" className={cn("button--clear", styles.closeButton, styles.activeMobile)} onClick={handleDisableMobile}>
                <CloseIcon className={styles.closeIcon} />
              </button>

              <button
                type="button"
                className={cn("button--clear", styles.closeButton, styles.disableMobile)}
                onClick={handleDisableDesktop}
              >
                <CloseIcon className={styles.closeIcon} />
              </button>
            </>
          )}
          {isClient ? <DetailClient messageDetail={messageDetail} /> : <Detail messageDetail={messageDetail} />}
        </div>
      ) : null}
    </>
  );
};

export default MessageDetail;
