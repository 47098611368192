import React from "react";

import testCompany from "../../../media/images/testCompany.png";
import styles from "./insurances.module.css";

const Insurances = ({ insurances = [] }) => {
  return (
    <div className={styles.content}>
      <h3 className={styles.title}>Insurance</h3>
      <div>
        {insurances.map((item, index) => {
          return (
            // eslint-disable-next-line
            <div key={`${item.company_title || item.other_company}-${index}`} className={styles.tile}>
              <div className={styles.flex}>
                <span className={styles.tileLogo}>
                  <img src={testCompany} alt="" />
                </span>
                <div>
                  <h6 className={styles.tileTitle}>{item.company_title || item.other_company}</h6>

                  <p className={styles.tileDescription}>{item.network_title || item.network_id}</p>
                  <p className={styles.tileDescription}>{item.other_plan}</p>
                </div>
              </div>

              <span className={styles.tileStatus}>_Accepting new clients</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Insurances;
