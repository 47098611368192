import { callHistory } from "../constants/actionTypes";

const defaultState = {
  calls: [],
  callHistoryError: false,
  preloader: true
};

const sortByNewest = (a, b) => {
  return new Date(a.date_start) > new Date(b.date_start) ? 1 : -1;
};

export default function(state = defaultState, action) {
  const { type, payload } = action;
  switch (type) {
    case callHistory.GET_CALL_DATA_SUCCESS:
      return {
        ...state,
        calls: payload.calls.sort(sortByNewest),
        preloader: false
      };
    case callHistory.GET_CALL_DATA_FAIL:
      return {
        ...state,
        callHistoryError: payload,
        preloader: false
      };
    case callHistory.SET_CALL_DATA_CLEAN:
      return {
        calls: [],
        callHistoryError: false,
        preloader: false
      };

    default:
      return {
        ...state
      };
  }
}
