import React from "react";

import styles from "./about.module.css";

const About = ({ description = "" }) => {
  return (
    <div className={styles.content}>
      <h6 className={styles.title}>About</h6>
      <p className={styles.description}>{description}</p>
    </div>
  );
};

export default About;
