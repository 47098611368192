import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import PublicHeader from "../publicHeader";
import Footer from "../footer";
import Heading from "./heading";
import Info from "./info";
import Connected from "../features/components/connected";
import { OverlayPreloader } from "../common/preloader";
import { actions } from "../../actions/landing";
import { useDocTitle } from "../../helpers/hooks";

const Landing = () => {
  const { preloaders, cityStateZipList, professions, practices } = useSelector(state => state.landing);
  const dispatch = useDispatch();
  const getCityStateZip = useCallback(cityStateZip => dispatch(actions.getCityStateZip(cityStateZip)), [dispatch]);
  const getPractices = useCallback(professionsId => dispatch(actions.getPractices(professionsId)), [dispatch]);
  const getProfessions = useCallback(() => dispatch(actions.getProfessions()), [dispatch]);
  const { t } = useTranslation();

  useDocTitle(t("dashboard_hp_metatitle"));

  useEffect(() => {
    getProfessions();

    const metaDescription = document.querySelector("meta[name='description']");
    if (metaDescription) {
      metaDescription.setAttribute("content", t("dashboard_hp_metadesc"));
    }
  }, []);

  return (
    <>
      {preloaders.page && <OverlayPreloader overlayClassName="sectionOverlayModal" />}

      <PublicHeader />
      <Heading
        professions={professions}
        getPractices={getPractices}
        getCityStateZip={getCityStateZip}
        cityStateZipList={cityStateZipList}
        practices={practices}
      />
      <Info />
      <Connected />
      <Footer />
    </>
  );
};

export default Landing;
