import React from "react";
import { useSelector } from "react-redux";

import { Hr } from "../hr";
import styles from "./followUpQuestions.module.css";

export const FollowUpQuestions = () => {
  const {
    lead: { followup_questions: followupQuestions }
  } = useSelector(state => state.payPerLeadDetail);

  if (!followupQuestions?.length) {
    return null;
  }

  return (
    <>
      <div className={styles.questions}>
        <h5 className={styles.title}>FOLLOW-UP QUESTIONS ANSWERED BY LEAD</h5>
        {followupQuestions?.map(({ question, answer }, index) => (
          <div className={styles.item} key={`${question}-${answer}`}>
            <p className={styles.question}>
              <span>{`${index + 1}) `}</span>
              {question}
            </p>
            <p className={styles.answer}>{answer}</p>
          </div>
        ))}
      </div>
      <Hr />
    </>
  );
};
