export const modals = {
  importing: "importing",
  importingSuccess: "importingSuccess"
};

export const typeReferrals = {
  "paid-referrals": "purchased",
  referrals: "notpurchased"
};

export const tabs = {
  referrals: "referrals",
  notpurchased: "notpurchased"
};

export const subTabReferrals = {
  pendingReview: "pendingReview",
  matched: "matched",
  notMatched: "notMatched",
  canceled: "canceled "
};

export const subTabLeads = {
  new: "notpurchased",
  purchased: "purchased",
  hidden: "hidden",
  analytics: "analytics"
};
