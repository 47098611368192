import React, { useState } from "react";
import cn from "classnames";

import { ReactComponent as Calendar } from "../../../../media/icons/calendar.svg";
import { ReactComponent as Location } from "../../../../media/icons/location.svg";
import { ReactComponent as ArrowIcon } from "../../../../media/icons/dropdown-arrow.svg";

import styles from "./readOnlyInfo.module.css";

const ReadOnlyInfo = ({ children }) => {
  const [isActiveProfile, setIsActiveProfileLink] = useState(false);

  const toggleProfile = () => {
    setIsActiveProfileLink(prev => !prev);
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div>
          <h6 className={styles.title}>Immigration, US Citizenship, Labor and Employment</h6>

          <div className={styles.flex}>
            <span className={styles.detail}>
              <Location />
              Dallas, TX
            </span>
            <span className={styles.detail}>
              <Calendar />
              ___10 Jan, 2020
            </span>
          </div>
        </div>

        <button type="button" className={styles.buttonText} onClick={toggleProfile}>
          See referral details
        </button>

        <button type="button" className={cn(styles.buttonIcon, isActiveProfile ? styles.buttonIconActive : "")} onClick={toggleProfile}>
          <ArrowIcon />
        </button>
      </div>

      {isActiveProfile ? <>{children}</> : null}
    </div>
  );
};

export default ReadOnlyInfo;
