import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import store from "./reducers";
import "./styles/normalize.css";
import "./styles/index.css";
import "./styles/grid.css";
import "./styles/stripeInputs.css";
import Routes from "./routes";
import "./i18n";
import ErrorBoundary from "./containers/errorBoundary";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Router>
        <ErrorBoundary>
          <Routes />
        </ErrorBoundary>
      </Router>
    </LocalizationProvider>
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
