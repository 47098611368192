import React from "react";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";

import Input from "../../common/input";
import TextArea from "../../common/textarea";
import styles from "./letsUpHelp.module.css";

export const LetsUpHelp = ({ onClose, addListingAssistant, professionalName }) => {
  const { t } = useTranslation();

  const validate = values => {
    const { professional_name, license_state, license_number, link } = values;

    const errors = {};

    if (!professional_name) {
      errors.professional_name = "Required";
    }

    if (!license_state) {
      errors.license_state = "Required";
    }

    if (!license_number) {
      errors.license_number = "Required";
    }

    if (!link) {
      errors.link = "Required";
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      professional_name: professionalName,
      license_state: "",
      license_number: "",
      link: "",
      notes: ""
    },
    validate,
    onSubmit: values => {
      addListingAssistant(values);
    }
  });

  const { errors, touched, values, setFieldValue, setFieldTouched } = formik;

  const handleChange = event => {
    setFieldValue(event.target.name, event.target.value);
  };

  const handleBlur = event => {
    setFieldTouched(event.target.name, true);
  };

  const { professional_name, license_state, license_number, link, notes } = values;

  return (
    <div>
      <Input
        classNameWrap={styles.inputWrap}
        label={t("dashboard_home_actionblock_tab1_field_name")}
        onChange={handleChange}
        name="professional_name"
        value={professional_name}
        error={errors.professional_name}
        isInvalid={errors.professional_name && touched.professional_name}
        onBlur={handleBlur}
        required
      />

      <div className={styles.flexWrap}>
        <Input
          classNameWrap={styles.inputWrap}
          label={t("dashboard_home_actionblock_tab1_field_state")}
          onChange={handleChange}
          name="license_state"
          value={license_state}
          error={errors.license_state}
          isInvalid={errors.license_state && touched.license_state}
          onBlur={handleBlur}
          required
        />

        <Input
          classNameWrap={styles.inputWrap}
          label={t("dashboard_home_actionblock_tab1_field_license")}
          onChange={handleChange}
          name="license_number"
          value={license_number}
          error={errors.license_number}
          isInvalid={errors.license_number && touched.license_number}
          onBlur={handleBlur}
          required
        />
      </div>
      <Input
        classNameWrap={styles.inputWrap}
        label={t("dashboard_home_actionblock_tab1_field_link")}
        onChange={handleChange}
        name="link"
        value={link}
        error={errors.link}
        isInvalid={errors.link && touched.link}
        onBlur={handleBlur}
        required
      />

      <TextArea
        classNameWrap={styles.inputWrap}
        className={styles.textarea}
        label={t("dashboard_home_actionblock_tab1_field_notes")}
        onChange={handleChange}
        name="notes"
        value={notes}
      />

      <div className={styles.buttonContainer}>
        <button type="button" className={styles.close} onClick={onClose}>
          {t("dashboard_common_button_close")}
        </button>
        <button type="button" className={styles.submit} onClick={formik.handleSubmit}>
          {t("dashboard_common_button_submit")}
        </button>
      </div>
    </div>
  );
};
