import React from "react";
import cn from "classnames";

import styles from "./checkbox.module.css";

const Checkbox = ({ className, checked, children, id, disabled, name, onChange, value, unactive, big, isNewStyles }) => {
  const newStyles = isNewStyles ? styles.newCheckbox : "";

  return (
    <label
      className={cn(
        unactive ? styles.checkboxInactive : styles.checkbox,
        big && styles.big,
        newStyles,
        className,
        disabled ? styles.disabled : null
      )}
      htmlFor={name}
    >
      <input type="checkbox" id={id} name={name} disabled={disabled} checked={checked} value={value} onChange={onChange} />
      <i className={cn(styles.icon, big && styles.iconBig)} />

      {children}
    </label>
  );
};

export default Checkbox;
