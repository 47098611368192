import React, { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CreateReferralRequest } from "../../../modalReferalRequest/createReferralRequest";
import { Modal } from "../../../common/modal";
import { actions as referralRequestDetailActions } from "../../../../actions/referralRequestDetail";
import { OverlayPreloader } from "../../../common/preloader";

export const CaseInfo = ({ clearAllModals, onClose }) => {
  const { categoriesProfessions, case: caseData, newsletters, preloader } = useSelector(state => state.referralRequestDetail);

  const dispatch = useDispatch();
  const changeCaseData = useCallback(data => dispatch(referralRequestDetailActions.changeCaseData(data)), [dispatch]);

  const updateNewReferralRequest = useCallback(
    referralRequest => {
      changeCaseData({ ...caseData, ...referralRequest });
    },
    [caseData, changeCaseData]
  );

  const practices = useMemo(
    () =>
      categoriesProfessions.practices
        ? categoriesProfessions.practices.filter(
            item =>
              item.practice_id === caseData.practice1 || item.practice_id === caseData.practice2 || item.practice_id === caseData.practice3
          )
        : [],
    [caseData, categoriesProfessions]
  );

  const professions = useMemo(
    () =>
      categoriesProfessions.professions
        ? categoriesProfessions.professions.find(item => practices[0]?.profession_id === item.profession_id)
        : [],
    [categoriesProfessions.professions, practices]
  );

  const normalizePractices = practices.map(item => {
    const newObject = {};
    newObject.value = item.practice_id;
    newObject.label = item.title;
    return newObject;
  });

  const normalizeProfessions = professions
    ? [professions]?.map(item => {
        const newObject = {};
        newObject.value = item.profession_id;
        newObject.label = item.title;
        return newObject;
      })
    : [];

  return (
    <Modal clearAllModals={clearAllModals} closeModal={onClose}>
      {preloader && <OverlayPreloader />}

      <CreateReferralRequest
        caseData={caseData}
        professions={normalizeProfessions?.length ? normalizeProfessions[0] : {}}
        practices={normalizePractices}
        categoriesProfessions={categoriesProfessions}
        closeModal={onClose}
        submitChanges={updateNewReferralRequest}
        newsletters={newsletters}
      />
    </Modal>
  );
};
