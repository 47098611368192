import React, { useEffect, useState } from "react";
import cn from "classnames";
import { useTranslation } from "react-i18next";

import PublicHeader from "../publicHeader";
import Footer from "../footer";
import { ReactComponent as IconFlash } from "../../media/icons/flash.svg";
import styles from "./pricing.module.css";
import { Switch } from "../common/switch";
import { useDocTitle } from "../../helpers/hooks";
import PricingPlanFree from "./components/pricingPlanFree";
import PricingPlanMonth from "./components/pricingPlanMonth";

const Pricing = () => {
  const [active, setActive] = useState(false);
  const { t } = useTranslation();

  useDocTitle("dashboard_pricing_metatitle");

  useEffect(() => {
    if (window.scrollY) {
      window.scroll(0, 0);
    }
  }, []);

  return (
    <>
      <PublicHeader />

      <div className={styles.section}>
        <div className={styles.wrapper}>
          <div>
            <h1 className={styles.title}>{t("dashboard_pricing_h1")}</h1>
            <div className={styles.pricingToggle}>
              <div className={cn(styles.pricingToggleTitle, active ? styles.pricingToggleTitleUnactive : "")}>
                {t("dashboard_pricing_monthly")}
              </div>

              <Switch selected={active} value="" handleSwitch={() => setActive(prev => !prev)} />

              <div
                className={cn(styles.pricingToggleTitle, styles.pricingToggleTitleAnnually, active ? styles.pricingToggleTitleActive : "")}
              >
                {t("dashboard_pricing_yearly")}
                <span className={styles.pricingToggleLabel}>
                  <IconFlash />
                  {t("dashboard_pricing_discount")}
                </span>
              </div>
            </div>

            <div className={styles.pricing}>
              <div className={styles.pricingContent}>
                <PricingPlanFree />

                <PricingPlanMonth active={active} />
              </div>
            </div>

            <div className={styles.helpContent}>
              <p className={styles.helpText}>{t("dashboard_pricing_knowledgebase_text")}</p>
              <a target="_blank" rel="noreferrer" href="https://help.heritageweb.com/" className={styles.helpLink} type="button">
                {t("dashboard_pricing_knowledgebase_button")}
              </a>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Pricing;
