import React from "react";
import { useTranslation } from "react-i18next";

import { Success } from "../success";

export const DowngradePeriodSuccess = ({ closeModal, clearAllModals }) => {
  const { t } = useTranslation();

  return (
    <Success
      closeModal={closeModal}
      clearAllModals={clearAllModals}
      subTitle="DOWNGRADE LISTING"
      title="Downgrade Canceled"
      message={t("dashboard_listing_downgrade_period_success_canceled")}
    />
  );
};
