import axios from "axios";
import { account } from "../../constants/actionTypes";
import { actions as accountActions } from "../account";

export const actions = {
  sendPhoneCode(phone) {
    return dispatch => {
      dispatch(accountActions.setLoading("verifications", true));

      axios({
        method: "post",
        url: "members/json-api/verify/send-phone-code",
        data: { phone },
        headers: {
          "Content-Type": "application/json"
        }
      })
        .then(() => {
          dispatch({ type: account.PHONE_VERIFY_CODE_SUCCESS });
        })
        .catch(() => dispatch({ type: account.PHONE_VERIFY_CODE_FAIL }));
    };
  },
  checkPhoneCode(code, phone) {
    return dispatch => {
      dispatch(accountActions.setLoading("verifications", true));
      axios({
        method: "post",
        url: "members/json-api/verify/check-phone-code",
        data: { code },
        headers: {
          "Content-Type": "application/json"
        }
      })
        .then(() => {
          dispatch(this.updatePhone(phone));
        })
        .catch(() => {
          dispatch({ type: account.CHECK_PHONE_VERIFY_CODE_FAIL });
        });
    };
  },

  removePhoneNumber() {
    return dispatch => {
      dispatch(accountActions.setLoading("verifications", true));

      axios({
        method: "get",
        url: "members/json-api/profile/delete-phone",
        headers: {
          "Content-Type": "application/json"
        }
      })
        .then(() => {
          dispatch({ type: account.REMOVE_PHONE_SUCCESS });
          dispatch(this.getProfile());
        })
        .catch(() => dispatch({ type: account.REMOVE_PHONE_FAIL }));
    };
  },

  updateEmail(email, keycloak) {
    return dispatch => {
      dispatch(accountActions.setLoading("verifications", true));

      axios({
        method: "post",
        url: "members/json-api/profile/update-email",
        data: { email },
        headers: {
          "Content-Type": "application/json"
        }
      })
        .then(() => {
          keycloak
            .updateToken(60)
            .then(() => {
              dispatch({ type: account.UPDATE_EMAIL_SUCCESS, payload: email });
            })
            .catch(() => {
              dispatch({ type: account.CHECK_EMAIL_VERIFY_CODE_FAIL });
            });
        })
        .catch(error => {
          dispatch({
            type: account.EMAIL_VERIFY_CODE_FAIL,
            payload: {
              isShow: true,
              message: error.response.data.error
            }
          });
        });
    };
  },
  updatePhone(phone) {
    return dispatch => {
      dispatch(accountActions.setLoading("verifications", true));

      axios({
        method: "post",
        url: "members/json-api/profile/update-phone",
        data: { phone },
        headers: {
          "Content-Type": "application/json"
        }
      })
        .then(() => {
          dispatch({ type: account.UPDATE_PHONE_SUCCESS });
          dispatch(this.getProfile());
        })
        .catch(() => {
          dispatch({ type: account.UPDATE_EMAIL_FAIL });
        });
    };
  },
  sendEmailCode(email, callback, isNotCloseModal) {
    return dispatch => {
      dispatch(accountActions.setLoading("verifications", true));

      axios({
        method: "post",
        url: "members/json-api/verify/send-email-code",
        data: { email },
        headers: {
          "Content-Type": "application/json"
        }
      })
        .then(() => {
          if (isNotCloseModal) {
            dispatch({ type: account.EMAIL_SEND_VERIFY_CODE_SUCCESS });
          } else {
            dispatch({ type: account.EMAIL_VERIFY_CODE_SUCCESS });
          }
        })
        .catch(error => {
          if (callback) {
            callback();
          }
          dispatch({
            type: account.EMAIL_VERIFY_CODE_FAIL,
            payload: {
              isShow: true,
              message: error.response.data.error
            }
          });
        });
    };
  },

  checkEmailCode(code, newLoginData, keycloak, isVerified) {
    return dispatch => {
      dispatch(accountActions.setLoading("verifications", true));
      axios({
        method: "post",
        url: "members/json-api/verify/check-email-code",
        data: { code },
        headers: {
          "Content-Type": "application/json"
        }
      })
        .then(() => {
          if (isVerified) {
            dispatch({ type: account.VERIFIED_EMAIL_SUCCESS, payload: newLoginData.email });
          } else {
            dispatch(this.updateEmail(newLoginData.email, keycloak));
          }
        })
        .catch(() => {
          dispatch({ type: account.CHECK_EMAIL_VERIFY_CODE_FAIL });
        });
    };
  },

  passwordVerificationSuccess() {
    return {
      type: account.PASSWORD_VERIFICATION_SUCCESS
    };
  },

  getProfile() {
    return dispatch => {
      dispatch(accountActions.setLoading("credentialSettings", true));
      axios
        .get("/members/json-api/profile")
        .then(res => {
          dispatch({
            type: account.GET_PROFILE,
            payload: res.data
          });
        })
        .catch(err => {
          dispatch({
            type: account.GET_PROFILE_FAIL,
            payload: err
          });
        });
    };
  },

  saveNewEmailPassword(email, password) {
    return {
      type: account.SAVE_NEW_EMAIL_PASSWORD,
      payload: { email, password }
    };
  },

  cleanNewEmailPassword() {
    return {
      type: account.CLEAN_NEW_EMAIL_PASSWORD
    };
  },

  updateContacts(data, callback) {
    return async dispatch => {
      dispatch(accountActions.setLoading("contacts", true));
      axios({
        method: "post",
        responseType: "json",
        url: "/members/json-api/profile/update",
        data
      })
        .then(() => {
          if (callback) {
            callback();
          } else {
            dispatch({
              type: account.UPDATE_CONTACTS_SUCCESS,
              payload: true
            });

            setTimeout(
              () =>
                dispatch({
                  type: account.UPDATE_CONTACTS_SUCCESS,
                  payload: null
                }),
              6000
            );
          }
        })
        .catch(error => {
          dispatch({
            type: account.UPDATE_CONTACTS_FAIL,
            payload: error.response
          });
        })
        .finally(() => {
          dispatch(accountActions.setLoading("contacts", false));
        });
    };
  },

  updateMyAccountInformation(data) {
    return async dispatch => {
      // dispatch(accountActions.setLoading("contacts", true));
      axios({
        method: "post",
        responseType: "json",
        url: "/members/api-v2/my-account",
        data
      })
        .then(() => {
          dispatch({
            type: account.UPDATE_CONTACTS_SUCCESS,
            payload: true
          });

          setTimeout(
            () =>
              dispatch({
                type: account.UPDATE_CONTACTS_SUCCESS,
                payload: null
              }),
            6000
          );
        })
        .catch(error => {
          dispatch({
            type: account.UPDATE_CONTACTS_FAIL,
            payload: error.response
          });
        })
        .finally(() => {
          dispatch(accountActions.setLoading("contacts", false));
        });
    };
  },

  removeNotificationEmail(data) {
    return async dispatch => {
      dispatch(accountActions.setLoading("removeNotificationEmail", true));
      axios({
        method: "post",
        responseType: "json",
        url: "/members/json-api/listing/delete-listing-email",
        data
      })
        .then(() => {
          dispatch({
            type: account.NOTIFICATION_REMOVE_EMAIL_SUCCESS,
            payload: { listingId: data.listing_id, prKey: data.prkey }
          });
        })
        .catch(error => {
          dispatch({
            type: account.NOTIFICATION_REMOVE_EMAIL_FAIL,
            payload: error.response
          });
        });
    };
  },

  removeNotificationPhone(data) {
    return async dispatch => {
      dispatch(accountActions.setLoading("removeNotificationPhone", true));
      axios({
        method: "post",
        responseType: "json",
        url: "/members/json-api/listing/delete-listing-phone",
        data
      })
        .then(() => {
          dispatch({
            type: account.NOTIFICATION_REMOVE_PHONE_SUCCESS,
            payload: { listingId: data.listing_id, prKey: data.prkey }
          });
        })
        .catch(error => {
          dispatch({
            type: account.NOTIFICATION_REMOVE_PHONE_FAIL,
            payload: error.response
          });
        });
    };
  },

  toggleActivityOfModal(modal, isActive) {
    return {
      type: account.TOGGLE_ACTIVITY_OF_MODAL,
      payload: {
        modal,
        isActive
      }
    };
  },

  clearChangeEmailError() {
    return {
      type: account.EMAIL_VERIFY_CODE_CLEAR_ERROR
    };
  },

  clearAllModals() {
    return {
      type: account.CLEAR_ALL_MODALS
    };
  }
};
