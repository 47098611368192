import React from "react";
import { NotificationLeadPage } from "../../components/notificationLeadComponent";

const dataPage = {
  metaTitle: "Lead Already Purchased by Another Professional",
  title: "Lead Sold Exclusively Already",
  text: "This lead has already been purchased by another professional. Each lead is granted exclusively to only one professional.",
  buttonText: "Login to Heritage Web Dashboard"
};

export const LeadAlreadyPurchased = () => {
  const { metaTitle, title, text, buttonText } = dataPage;

  return <NotificationLeadPage metaTitle={metaTitle} title={title} text={text} buttonText={buttonText} />;
};
