import React from "react";

import { Notification } from "../../common/notification";
import { useCallAllHooks } from "../../payPerLeadDetail/hooks";

const PaymentNotification = ({ leadId, match, error, success, isNotGetLeadDetail }) => {
  const { successNotification, errorNotification } = useCallAllHooks({
    success,
    lead_id: leadId,
    error,
    match,
    isNotGetLeadDetail
  });

  return (
    <>
      {successNotification && <Notification success>{successNotification}</Notification>}
      {errorNotification && <Notification error>{errorNotification}</Notification>}
    </>
  );
};

export default PaymentNotification;
