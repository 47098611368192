import React from "react";
import { useTranslation } from "react-i18next";
import cn from "classnames";

import RemoveButton from "../removeButton";
import styles from "../notifications/notifications.module.css";
import { ReactComponent as IconPhone } from "../../../media/icons/notificationPhone.svg";
import { ReactComponent as IconChecked } from "../../../media/icons/checked.svg";
import { ReactComponent as IconEmail } from "../../../media/icons/notificationEmail.svg";

export const NotificationsItem = ({ isEmail, text, handleRemove, isReadOnly }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.notificationsItem}>
      <div className={styles.notificationsElement}>
        {isEmail ? <IconEmail className={styles.icon} /> : <IconPhone className={styles.icon} />}
      </div>
      <div className={cn(styles.notificationsElement, styles.notificationsElementFlex)}>
        <div className={styles.iconContent}>{isEmail ? <IconEmail className={styles.icon} /> : <IconPhone className={styles.icon} />}</div>
        <span className={styles.notificationsText}>{text}</span>
        {!isReadOnly && <RemoveButton className={styles.buttonClose} onClick={handleRemove} />}
      </div>
      <div className={styles.notificationsElement}>
        <span className={styles.notificationsCheckboxText}>{t("dashboard_listing_notifications_table_listing")}</span>
        <IconChecked className={styles.checkedIcon} />
      </div>
      <div className={styles.notificationsElement}>
        <span className={styles.notificationsCheckboxText}>{t("dashboard_listing_notifications_table_inbox")}</span>

        <IconChecked className={styles.checkedIcon} />
      </div>
      <div className={styles.notificationsElement}>
        <span className={styles.notificationsCheckboxText}>{t("dashboard_listing_notifications_table_leads")}</span>

        <IconChecked className={styles.checkedIcon} />
      </div>
      <div className={styles.notificationsElement}>
        <span className={styles.notificationsCheckboxText}>{t("dashboard_listing_notifications_table_qa")}</span>

        <IconChecked className={styles.checkedIcon} />
      </div>
    </div>
  );
};
