import React, { useState } from "react";
import { useSelector } from "react-redux";
import cn from "classnames";

import { ReactComponent as DropdownIcon } from "../../../../media/icons/dropdown-arrow.svg";
import Checkbox from "../../../common/checkbox";
import { FILTERS } from "../../../../constants/questionsAnswers";
import { WEBSITE } from "../../../../constants/regex";
import styles from "./categories.module.css";

const areEqual = (prevProps, nextProps) => {
  return nextProps.activeFilters.categories.length === prevProps.activeFilters.categories.length;
};

export const Categories = React.memo(({ activeFilters, handleFilter, className }) => {
  const questions = useSelector(state => state.questions);
  const { qa, categories } = questions;
  const questionCategories = [...new Set(qa?.map(question => question.website_url.replace(WEBSITE, "").split("/")[0]))];
  const categoriesForFilter = categories?.filter(category => questionCategories.includes(category.title.toLowerCase()) && category);
  const [isActiveDropdown, setIsActiveDropdown] = useState(false);

  if (!categoriesForFilter?.length) {
    return null;
  }

  const toggleDropdown = () => {
    setIsActiveDropdown(prev => !prev);
  };

  return (
    <div className={styles.content}>
      <button type="button" className={styles.dropdownButton} onClick={toggleDropdown}>
        Network
        <DropdownIcon className={cn(styles.icon, isActiveDropdown ? styles.iconActive : "")} />
      </button>

      {isActiveDropdown && (
        <div className={cn(styles.dropdownContent, className)}>
          {categoriesForFilter.map(({ category_id: id, title }) => (
            <span key={id}>
              <Checkbox
                checked={activeFilters.categories.includes(String(id)?.toLowerCase())}
                className={styles.checkbox}
                id={title}
                name={title}
                onChange={() => handleFilter(FILTERS.CATEGORIES, String(id)?.toLowerCase())}
                isNewStyles
                big
              >
                {title}
              </Checkbox>
            </span>
          ))}
        </div>
      )}
    </div>
  );
}, areEqual);
