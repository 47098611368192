import React from "react";
import format from "date-fns/format";
import { useTranslation } from "react-i18next";

import styles from "./subscription.module.css";
import { renderBrandIcon } from "../../../helpers";

const Subscription = ({ item, isExpired, handleActivePaymentModal }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.subscription}>
      <div className={styles.subscriptionItem}>
        <p className={styles.subscriptionName}>{t("dashboard_payments_service")}</p>
        <div>
          <span>{item.membership}</span>
          {item.attorney && <span>({item.attorney})</span>}
        </div>
      </div>
      <div className={styles.subscriptionItem}>
        <p className={styles.subscriptionName}>{t("dashboard_payments_amount")}</p>
        <div>
          <span>{item.terms}</span>
        </div>
      </div>
      <div className={styles.subscriptionItem}>
        <p className={styles.subscriptionName}>{t("dashboard_payments_nextpayment")}</p>
        <div>{isExpired ? <span>Expired</span> : <span>{format(new Date(item.rebill_date), "MMM dd, yyyy")}</span>}</div>
      </div>
      <div className={styles.subscriptionItem}>
        <p className={styles.subscriptionName}>{t("dashboard_payments_paymentmethod")}</p>
        <div>
          <button type="button" onClick={() => handleActivePaymentModal(item)}>
            Update
          </button>
          <div className={styles.cardDetail}>
            <span className={styles.cardBrandImg}>
              <img src={renderBrandIcon(item.brand)} alt="" />
            </span>
            <span className={styles.cardNumber}>{item.last4}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Subscription;
