import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import queryString from "query-string";

import { useLoadAllUseEffects } from "./hooks";
import styles from "../payPerLead/payPerLead.module.css";
import { actions as referralRequestDetailActions } from "../../actions/referralRequestDetail";
import { Search } from "../payPerLead/search";
import { actions as referralRequestsActions } from "../../actions/referralRequests";
import Tabs from "../payPerLead/tabs";
import List from "../payPerLead/list";
import { OverlayPreloader } from "../common/preloader";
import LeadAndReferralsPagination from "../payPerLead/leadAndReferralsPagination";
import Modals from "../payPerLead/modals";
import SelectInput from "../common/selectInput";
import MainTabs from "../payPerLead/mainTabs";
import Checkbox from "../common/checkbox";

const Referrals = () => {
  const dispatch = useDispatch();
  const [activeSort, setActiveSort] = useState("");
  const [activeReferrals, setActiveReferrals] = useState(false);
  const { t } = useTranslation();
  const history = useHistory();
  const { location } = history;

  const searchParams = queryString.parse(location.search);
  const options = [
    { value: "desc", label: t("dashboard_ppl_sortdropdown_recent") },
    { value: "asc", label: t("dashboard_ppl_sortdropdown_oldest") }
  ];

  const { modals, lead, coupon, success, error: errorBuyLead } = useSelector(state => state.payPerLeadDetail);

  const getCases = useCallback(data => dispatch(referralRequestsActions.getCases(data)), [dispatch]);

  const handleRemoveReferrals = useCallback(
    leadId => dispatch(referralRequestDetailActions.handleRemove(leadId, history, true, searchParams)),
    [dispatch, history, searchParams]
  );

  const { filters, loading, leads, pagination, tabsCounter } = useSelector(state => state.payPerLead);
  const { practices } = filters;
  const {
    tabsCounter: tabsCounterReferrals,
    pagination: paginationReferrals,
    preloaders: preloadersReferrals
    // isShowReferralsTab
    // cases: referrals,
    // updateReferralRequest,
    // importReady,
  } = useSelector(state => state.referralRequests);

  const [leadsList, setLeadsList] = useState([]);
  const [referralsList, setReferralsList] = useState([]);
  const [search, setSearch] = useState("");
  const [searchReferrals, setSearchReferrals] = useState("");
  const [perPage, setPerPage] = useState("");
  const [perPageLead, setPerPageLead] = useState("");
  const [listActivePractice, setListActivePractice] = useState([]);
  const [listActiveNetwork, setListActiveNetwork] = useState([]);
  const [notification, setNotification] = useState(null);
  const [error, setError] = useState(null);
  const [activeFilter, setActiveFilter] = useState(false);
  // const [team, setTeam] = useState({ value: "", label: "Unselect " });

  useLoadAllUseEffects({
    setListActiveNetwork,
    setListActivePractice,
    listActiveNetwork,
    setLeadsList,
    setSearch,
    setNotification,
    setError,
    listActivePractice,
    setReferralsList,
    activeSort,
    searchValue: search,
    perPage,
    perPageLead
    // team
  });

  const handleFiltrationSortReferrals = sort => {
    setActiveSort(sort);
    getCases({
      sort: sort.value,
      dirId: listActiveNetwork,
      practiceId: listActivePractice,
      type: searchParams.tab,
      page: searchParams.page,
      search: searchReferrals
    });
  };

  const handleFiltrationSearchReferrals = searchValue => {
    setSearchReferrals(searchValue);
    getCases({
      sort: activeSort?.value,
      dirId: listActiveNetwork,
      practiceId: listActivePractice,
      type: searchParams.tab,
      page: searchParams.page,
      search: searchValue,
      isSelectedFilter: true
    });
  };

  const onDateFiltrationReferrals = item => {
    handleFiltrationSortReferrals(item);
  };

  return (
    <div className={styles.wrapper}>
      <h1 className={styles.mainTitle}>My referral requests</h1>
      {(loading || preloadersReferrals.preloaderCase) && <OverlayPreloader />}

      <MainTabs />

      {/*
      <SelectTeam isShowText handleChange={setTeam} unselectText="My Referrals" />
      */}

      <Search handleFiltrationSearch={handleFiltrationSearchReferrals} search={searchReferrals} isReferrals>
        <SelectInput
          classNameWrap={styles.selectWrap}
          className={styles.selectLatest}
          name="latest"
          placeholder="Most Recent"
          defaultValue={{ value: "", label: t("dashboard_ppl_sortdropdown_default") }}
          onChange={onDateFiltrationReferrals}
          options={options}
          value={activeSort}
        />
      </Search>

      <div className={styles.container}>
        {/* Content and Filter */}
        <div className={styles.tabNavContent}>
          <div>
            <Tabs
              setListActivePractice={setListActivePractice}
              listActivePractice={listActivePractice}
              setListActiveNetwork={setListActiveNetwork}
              listActiveNetwork={listActiveNetwork}
              tabsCounter={tabsCounter}
              tabsCounterReferrals={tabsCounterReferrals}
              setActiveFilter={setActiveFilter}
              activeFilter={activeFilter}
              isShowReferralsTab={false}
              // isShowReferralsTab={isShowReferralsTab}
              isReferrals
            />

            <Checkbox
              isNewStyles
              big
              checked={activeReferrals}
              className={styles.checkbox}
              id="Referrals"
              name="Referrals"
              onChange={() => setActiveReferrals(prev => !prev)}
            >
              <span className={styles.checkboxName}>My Requests</span>
            </Checkbox>

            <div>
              <List
                referralsList={referralsList}
                isReferrals
                leadsList={leadsList}
                practices={practices}
                handleRemoveReferrals={handleRemoveReferrals}
                // team={team}
              />

              <LeadAndReferralsPagination
                setPerPage={setPerPage}
                setPerPageLead={setPerPageLead}
                getCases={getCases}
                activeSort={activeSort}
                listActiveNetwork={listActiveNetwork}
                listActivePractice={listActivePractice}
                searchParams={searchParams}
                searchReferrals={searchReferrals}
                history={history}
                pagination={pagination}
                paginationReferrals={paginationReferrals}
                leads={leads}
                referralsList={referralsList}
                isReferrals
              />
            </div>
          </div>
          {/* modals and notification */}
          <Modals
            error={error}
            notification={notification}
            success={success}
            errorBuyLead={errorBuyLead}
            lead={lead}
            coupon={coupon}
            modals={modals}
            practices={practices}
          />
        </div>
      </div>
    </div>
  );
};

export default Referrals;
