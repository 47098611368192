export const track = (eventName, parameters) => {
  if (window.fbq) {
    window.fbq("track", eventName, { ...parameters });
  }
};

export const trackDataLayer = (event, params) => {
  window.dataLayer = window.dataLayer || [];

  window.dataLayer.push({
    event,
    ...params
  });
};
