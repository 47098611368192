import React from "react";
import { useTranslation } from "react-i18next";

import styles from "./banner.module.css";

const Banner = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.banners}>
      <div className={styles.banner}>
        <span className={styles.text}>{t("dashboard_newaccount_landing_page_message1")}</span>
      </div>
      <div className={styles.secondBanner}>
        <span className={styles.text}>{t("dashboard_newaccount_landing_page_message2")}</span>
      </div>
    </div>
  );
};

export default Banner;
