import axios from "axios";
import { callHistory } from "../constants/actionTypes";

export const actions = {
  getCallHistory() {
    return dispatch => {
      axios
        .get("/members/json-api/index/call-list")
        .then(res => {
          dispatch({
            type: callHistory.GET_CALL_DATA_SUCCESS,
            payload: res.data
          });
        })
        .catch(() => {
          dispatch({
            type: callHistory.GET_CALL_DATA_FAIL,
            payload: true
          });
        });
    };
  },

  setCallHistoryClean() {
    return {
      type: callHistory.SET_CALL_DATA_CLEAN
    };
  }
};
