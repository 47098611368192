import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import { Modal } from "../../../common/modal";
import { modals as modalsConstant } from "../../../../constants/payPerLeadDetail";
import { actions as paymentActions } from "../../../../actions/payment";
import { actions as payPerLeadDetailActions } from "../../../../actions/payPerLeadDetail";
import { AddCard } from "../../../common/payment/addCard";
import { apiKey } from "../../../../constants/stripe";

const stripePromise = loadStripe(apiKey);

export const AddCardComponent = () => {
  const dispatch = useDispatch();
  const addCard = useCallback((cardData, isFirst) => dispatch(paymentActions.addCard(cardData, isFirst)), [dispatch]);
  const clearTokenError = useCallback(() => () => dispatch(paymentActions.clearTokenError()), [dispatch]);
  const clearAllModals = useCallback(() => dispatch(payPerLeadDetailActions.clearAllModals()), [dispatch]);
  const setErrorToken = useCallback(err => dispatch(paymentActions.setErrorToken(err)), [dispatch]);
  const toggleModal = useCallback((modal, isActive) => dispatch(payPerLeadDetailActions.toggleModal(modal, isActive)), [dispatch]);
  const { modals } = useSelector(state => state.payPerLeadDetail);
  const payment = useSelector(state => state.payment);
  const { countries } = useSelector(state => state.main);
  const {
    preloaders: paymentPreloaders,
    success: paymentSuccess,
    paymentMethods,
    error: { stripeTokenError: errorToken, addCard: errorAddCard }
  } = payment;

  const closeModal = () => {
    toggleModal(modalsConstant.addCard, false);
  };

  if (!modals.addCard) {
    return null;
  }

  return (
    <Elements stripe={stripePromise}>
      <Modal className="zIndex4" closeModal={closeModal} clearAllModals={clearAllModals}>
        <AddCard
          errorAddCard={errorAddCard}
          addCard={addCard}
          clearTokenError={clearTokenError}
          countries={countries}
          closeModal={closeModal}
          errorToken={errorToken}
          isFirst={!paymentMethods?.length}
          setErrorToken={setErrorToken}
          paymentPreloaders={paymentPreloaders}
          paymentSuccess={paymentSuccess}
        />
      </Modal>
    </Elements>
  );
};
