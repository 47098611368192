import React from "react";
import cn from "classnames";

import { RedStar } from "../redStar";
import styles from "./title.module.css";

const Title = ({ isRequired, children, className, isRequiredText }) => {
  const requiredText = isRequiredText ? styles.titleRequired : "";

  return (
    <h5 className={cn(styles.title, className, requiredText)}>
      {children} {isRequired && <RedStar isActive />}
    </h5>
  );
};

export default Title;
