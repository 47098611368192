import React from "react";
import { NavLink } from "react-router-dom";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { ReactComponent as FacebookIcon } from "../../media/icons/facebook-active/clicked.svg";
import { ReactComponent as LinkedinIcon } from "../../media/icons/linkedin-active/clicked.svg";
import { ReactComponent as TwitterIcon } from "../../media/icons/twitter-active/clicked.svg";
import { ReactComponent as InstagramIcon } from "../../media/icons/instagram-active/clicked.svg";
import bigLogo from "../../media/images/footer-big-logo.png";
import { track } from "../../helpers/track";
import { eventName } from "../../constants/track";
import styles from "./footer.module.css";

const Footer = () => {
  const { t } = useTranslation();
  const { user } = useSelector(state => state.auth);

  const handleIntercom = () => {
    if (window && window.Intercom) {
      window.Intercom("show");
      window.Intercom("onShow", () => {
        track(eventName.viewContent, { em: user?.email, fn: user?.name_f, ln: user?.name_f });
      });
    }
  };

  const getFullYear = () => new Date().getFullYear();

  return (
    <footer className={styles.footer}>
      <div className={styles.container}>
        <div className={styles.content}>
          <img src={bigLogo} className={styles.bigLogo} alt="big logo" />
          <div className={styles.center}>
            <nav className={styles.nav}>
              <a href="https://www.heritageweb.com/" className={styles.navItem} target="_blank" rel="noopener noreferrer">
                Heritageweb.com
              </a>
              <NavLink to="/features" className={styles.navItem}>
                Features
              </NavLink>
              <NavLink to="/pricing" className={styles.navItem}>
                Pricing
              </NavLink>
              <a href="https://heritageweb.com/about-us" className={styles.navItem} target="_blank" rel="noopener noreferrer">
                About Us
              </a>
              <button type="button" className={cn(styles.navItem, styles.navItemButton)} onClick={handleIntercom}>
                Contact Us
              </button>
            </nav>
            <nav className={styles.nav}>
              <NavLink target="_blank" to="/" className={styles.navItem} rel="noopener noreferrer">
                Dashboard Home
              </NavLink>
              <button type="button" className={cn(styles.navItem, styles.navItemButton)} onClick={handleIntercom}>
                Help
              </button>
              <a href="https://help.heritageweb.com/en/" className={styles.navItem} rel="noopener noreferrer" target="_blank">
                Knowledgebase
              </a>
              <a
                href="https://www.heritageweb.com/privacy-and-security/"
                className={styles.navItem}
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms & Conditions
              </a>

              <a
                href="https://www.heritageweb.com/privacy-and-security/"
                className={styles.navItem}
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy & Security
              </a>
            </nav>
          </div>
        </div>

        <div className={styles.info}>
          <div className={styles.infoFlex}>
            <h1 className={styles.infoTitle}>{t("dashboard_footer_name")}</h1>
            <p className={styles.infoDescription}>{t("dashboard_footer_tagline")}</p>
          </div>
          <div className={styles.infoFlexBottom}>
            <span className={styles.copy}>
              © <strong>Heritage Web LLC</strong> {`2021 - ${getFullYear()}`}.
              <br />
              All Rights Reserved.
            </span>
            <div className={styles.socials}>
              <a href="https://www.facebook.com/HeritageWebLLC/" rel="noopener noreferrer" target="_blank">
                <FacebookIcon />
              </a>
              <a href="https://www.linkedin.com/company/2549836" rel="noopener noreferrer" target="_blank">
                <LinkedinIcon />
              </a>
              <a href="https://twitter.com/heritagewebllc" rel="noopener noreferrer" target="_blank">
                <TwitterIcon />
              </a>
              <a href="https://www.instagram.com/heritageweb/" rel="noopener noreferrer" target="_blank">
                <InstagramIcon />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
