import axios from "axios";
import { signUp } from "../constants/actionTypes";
import { track, trackDataLayer } from "../helpers/track";
import { eventName, eventNameDataLayer } from "../constants/track";

export const actions = {
  signUp({ amember_email, amember_pass, amember_fname, amember_lname }, signupSource = null) {
    const params = new URLSearchParams();
    params.append("amember_email", amember_email);
    params.append("amember_pass", amember_pass);
    params.append("amember_fname", amember_fname);
    params.append("amember_lname", amember_lname);

    if (signupSource) {
      params.append("signup_source", signupSource);
    }

    return dispatch => {
      dispatch({ type: signUp.SET_LOADING, payload: true });

      axios({
        method: "post",
        url: "/members/json-api/login/signup",
        data: params,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
        }
      })
        .then(data => {
          dispatch({ type: signUp.SET_SUCCESS, payload: { ok: true } });
          track(eventName.completeRegistration, { em: amember_email, fn: amember_fname, ln: amember_lname });
          trackDataLayer(eventNameDataLayer.signUp, { user_id: data.data.user_id, signup_page: window.location.href });
        })
        .catch(err => {
          dispatch({ type: signUp.SET_ERRORS, payload: err.response ? err.response.data.error : "Server error" });
        });
    };
  },

  /*
  getProfessions(profession) {
    return dispatch => {
      dispatch({ type: signUp.GET_PROFESSIONS_LOADING });
      axios({
        method: "get",
        url: "/members/json-api/signup/professions"
      })
        .then(result => {
          const findActiveProfession = result.data.find(item => item.title.toLowerCase() === profession);

          dispatch(this.getProfessionList(findActiveProfession.profession_id));
        })
        .catch(error => {
          dispatch({ type: signUp.GET_PROFESSIONS_FAIL, payload: error.response ? error.response.data.error : "Server error" });
        });
    };
  },
  */

  getPublicProfessions(profession, isNotLoadDetail) {
    return dispatch => {
      dispatch({ type: signUp.GET_PROFESSIONS_LOADING });
      axios({
        method: "get",
        url: "/members/json-api/public/professions"
      })
        .then(result => {
          dispatch({ type: signUp.GET_PROFESSIONS_LIST, payload: result.data });
          const findActiveProfession = result.data.find(item => item.title.toLowerCase() === profession);

          if (!isNotLoadDetail) {
            dispatch(this.getProfessionList(findActiveProfession.profession_id));
          }
        })
        .catch(error => {
          dispatch({ type: signUp.GET_PROFESSIONS_FAIL, payload: error.response ? error.response.data.error : "Server error" });
        });
    };
  },

  getCountries(profession) {
    return dispatch => {
      dispatch({ type: signUp.GET_PROFESSIONS_LOADING });

      axios({
        method: "get",
        url: "/members/json-api/public/heritages"
      })
        .then(result => {
          const findActiveProfession = result.data.find(item => item.title.toLowerCase() === profession);

          dispatch({ type: signUp.GET_COUNTRIES_LIST, payload: result.data });
          if (findActiveProfession) {
            dispatch(this.getPublicCountryCommunities(findActiveProfession.heritage_id));
          }
        })
        .catch(error => {
          dispatch({ type: signUp.GET_COUNTRIES_LIST_FAIL, payload: error.response ? error.response.data.error : "Server error" });
        });
    };
  },

  getProfessionList(professionId) {
    return dispatch => {
      axios({
        method: "get",
        url: `/members/json-api/directory/by-profession?profession_id=${professionId}`
      })
        .then(result => {
          dispatch({ type: signUp.GET_PROFESSIONS, payload: result.data });
        })
        .catch(error => {
          dispatch({ type: signUp.GET_PROFESSIONS_FAIL, payload: error.response ? error.response.data.error : "Server error" });
        });
    };
  },

  getPublicCountryCommunities(professionId) {
    return dispatch => {
      axios({
        method: "get",
        url: `/members/json-api/public/communities?heritage_id=${professionId}`
      })
        .then(result => {
          dispatch({ type: signUp.GET_PROFESSIONS, payload: result.data });
        })
        .catch(error => {
          dispatch({ type: signUp.GET_PROFESSIONS_FAIL, payload: error.response ? error.response.data.error : "Server error" });
        });
    };
  },

  cleanProfessional() {
    return dispatch => {
      dispatch({ type: signUp.CLEAN_PROFESSIONAL });
    };
  }
};
