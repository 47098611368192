import React from "react";
import instagram from "../../../media/icons/grayInstagram.svg";
import facebook from "../../../media/icons/grayFacebook.svg";
import twitter from "../../../media/icons/grayTwitter.svg";
import linkedin from "../../../media/icons/grayLinkedin.svg";
import youtube from "../../../media/icons/grayYoutube.svg";
import yelp from "../../../media/icons/grayYelp.svg";
import instagramActive from "../../../media/icons/instagramActive.svg";
import facebookActive from "../../../media/icons/facebookActive.svg";
import twitterActive from "../../../media/icons/twitterActive.svg";
import linkedinActive from "../../../media/icons/linkedinActive.svg";
import youtubeActive from "../../../media/icons/youtubeActive.svg";
import yelpActive from "../../../media/icons/yelpActive.svg";
import styles from "./socials.module.css";

const icons = {
  instagram,
  facebook,
  twitter,
  linkedin,
  youtube,
  yelp
};

const iconsActive = {
  instagram: instagramActive,
  facebook: facebookActive,
  twitter: twitterActive,
  linkedin: linkedinActive,
  "linkedin company page": linkedinActive,
  youtube: youtubeActive,
  yelp: yelpActive
};

const Socials = ({ socials }) => {
  return (
    <div className={styles.content}>
      <h3 className={styles.title}>Social Media</h3>

      <div className={styles.socials}>
        {socials.map(item => {
          return (
            <a href={item.social_url} key={item.social_name} className={styles.socialsItem} target="_blank" rel="noopener noreferrer">
              <img src={item.social_url ? iconsActive[item.social_name] : icons[item.social_name]} alt="" />
            </a>
          );
        })}
      </div>
    </div>
  );
};

export default Socials;
