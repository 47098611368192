import React, { useEffect, useState } from "react";
import cn from "classnames";

import { ReactComponent as ErrorIcon } from "../../../media/icons/error.svg";
import { ReactComponent as SuccessIcon } from "../../../media/icons/notification-success.svg";
import { ReactComponent as CloseIcon } from "../../../media/icons/close-grey.svg";
import styles from "./notification.module.css";

export const Notification = ({ children, error, success, time, close, timeToShow }) => {
  const [isShown, setIsShown] = useState(null);

  useEffect(() => {
    const timeoutShow = setTimeout(() => setIsShown(true), timeToShow || 200);
    const timeoutHide = setTimeout(() => {
      if (close) {
        close();
      }
      setIsShown(false);
    }, time || 7000);

    return () => {
      clearTimeout(timeoutShow);
      clearTimeout(timeoutHide);
    };
  }, []); //eslint-disable-line

  const handleClose = () => {
    if (close) {
      close();
    }
    setIsShown(false);
  };

  return (
    <div role="button" tabIndex="0" className={cn(styles.feedback, isShown ? styles.shown : null)} onClick={handleClose}>
      {error && <ErrorIcon className={styles.icon} />}
      {success && <SuccessIcon className={styles.icon} />} {children}
      <button type="button" onClick={handleClose} className={styles.closeIcon}>
        <CloseIcon />
      </button>
    </div>
  );
};

export const NotificationListing = ({ children, error, success, time, onClose }) => {
  const [isShown, setIsShown] = useState(null);

  useEffect(() => {
    if (error || success) {
      setTimeout(() => setIsShown(true), 200);
      setTimeout(() => {
        setIsShown(false);
        onClose();
      }, time || 7000);
    }
  }, [error, success]); //eslint-disable-line

  return (
    <div role="button" tabIndex="0" className={cn(styles.feedback, isShown ? styles.shown : null)} onClick={onClose}>
      {error && <ErrorIcon className={styles.icon} />}
      {success && <SuccessIcon className={styles.icon} />} {children}
      <button type="button" onClick={() => setIsShown(false)} className={styles.closeIcon}>
        <CloseIcon />
      </button>
    </div>
  );
};

export const NotificationError = ({ text, time, onClose }) => {
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      onClose();
    }, time || 6000);

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, []); //eslint-disable-line

  return (
    <div role="button" tabIndex="0" className={cn(styles.feedback, styles.shown)} onClick={onClose}>
      <ErrorIcon className={styles.icon} /> {text || "Something went wrong"}
      <button type="button" onClick={onClose} className={styles.closeIcon}>
        <CloseIcon />
      </button>
    </div>
  );
};
