import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";

import MainUserInfo from "../mainUserInfo";
import Location from "../location";
import Verifications from "../verifications";
import { modalsName } from "../../../constants/account";
import { track } from "../../../helpers/track";
import { eventName } from "../../../constants/track";
import styles from "./settings.module.css";

const Settings = ({ success, preloaders, profile, openModal, keycloak, updateMyAccountInformation }) => {
  const { name_f, name_l, email_verified, phone_verified, email, phone, state, country, city, zip } = profile;
  const { t } = useTranslation();
  const [isMouseInBlock, setIsMouseInBlock] = useState(false);
  const [isTouched, setIsTouched] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const containerRef = useRef();

  const handleTouchStart = () => {
    if (!isTouched) {
      setIsTouched(true);
    }
  };

  const handleMouseMove = () => {
    if (!isMouseInBlock) {
      setIsMouseInBlock(true);
    }
  };

  const handleMouseLeave = () => {
    if (isMouseInBlock) {
      setIsMouseInBlock(false);

      if (isEdited) {
        handleSubmit();
        setIsEdited(false);
      }
    }
  };

  const validate = values => {
    const { name_f: fullName, name_l: lastName } = values;

    const errors = {};

    if (!fullName) {
      errors.name_f = t("dashboard_common_required");
    }

    if (!lastName) {
      errors.name_l = t("dashboard_common_required");
    }

    return errors;
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name_f: name_f || "",
      name_l: name_l || "",
      state: state || { value: "" },
      country: country ? { value: country } : { value: "" },
      city: city || "",
      zip: zip || ""
    },
    onSubmit: values => {
      const newValues = {
        state: values.state.value,
        name_f: values.name_f,
        name_l: values.name_l,
        city: values.city,
        country: values.country.value,
        zip: values.zip,
        command: "personal_info"
      };
      updateMyAccountInformation(newValues);
    },
    validate
  });

  const { errors, handleSubmit, touched, values, setFieldTouched, setFieldValue } = formik;

  const handleBlur = inputName => {
    setFieldTouched(inputName, true);
  };

  const handleChangeSelect = (newValue, actionMeta) => {
    setFieldValue(actionMeta.name, newValue);

    if (actionMeta.name === "country") {
      setFieldValue("state", "");
    }

    if (!isEdited) {
      setIsEdited(true);
    }
  };

  const handleChange = e => {
    setFieldValue(e.target.name, e.target.value);

    if (!isEdited) {
      setIsEdited(true);
    }
  };

  const handleIntercom = () => {
    if (window && window.Intercom) {
      window.Intercom("show");
      window.Intercom("onShow", () => {
        track(eventName.viewContent, { em: email, fn: name_f, ln: name_l });
      });
    }
  };

  useEffect(() => {
    const handleTouch = event => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        if (isTouched) {
          setIsTouched(false);

          if (isEdited) {
            handleSubmit();
            setIsEdited(false);
          }
        }
      }
    };

    document.addEventListener("touchstart", handleTouch, true);

    return () => {
      document.removeEventListener("touchstart", handleTouch, true);
    };
  }, [isEdited]);

  return (
    <section ref={containerRef} onMouseMove={handleMouseMove} onMouseLeave={handleMouseLeave} onTouchStart={handleTouchStart}>
      <h2 className={styles.title}>{t("dashboard_account_personalinfo")}</h2>

      <MainUserInfo
        preloaders={preloaders}
        success={success}
        errors={errors}
        handleChange={handleChange}
        handleBlur={handleBlur}
        values={values}
        touched={touched}
      />

      <Verifications
        openModal={openModal}
        emailVerified={email_verified}
        phoneVerified={phone_verified}
        phone={phone}
        email={email}
        keycloak={keycloak}
      />

      <h3 className={styles.title}>{t("dashboard_account_location")}</h3>

      <Location handleChangeSelect={handleChangeSelect} values={values} handleChange={handleChange} />

      <div className={styles.password}>
        <p className={styles.passwordName}>Privacy and Security</p>
        <button
          type="button"
          className={styles.passwordButton}
          onClick={() =>
            keycloak.login({
              redirectUri: `${window.location.origin}/account?type=${modalsName.changePasswordSuccess}`,
              action: "UPDATE_PASSWORD"
            })
          }
        >
          {t("dashboard_account_changepassword")}
        </button>
        <button type="button" className={styles.deleteAccount} onClick={handleIntercom}>
          Delete account
        </button>
      </div>
    </section>
  );
};

export default Settings;
