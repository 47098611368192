import React, { useEffect, useState, useRef } from "react";
import cn from "classnames";

import { ReactComponent as OptionIcon } from "../../../media/icons/options.svg";
import styles from "./popupActions.module.css";

export const PopupActions = ({
  arrow,
  buttonClass,
  className,
  close,
  color,
  children,
  options,
  popupClass,
  promptRoot,
  promptClass,
  promptRootClass,
  setActionPrompt
}) => {
  const [isOpen, setOpen] = useState(false);
  const wrapperRef = useRef(null);

  const closeActions = e => {
    if (wrapperRef.current && !wrapperRef.current.contains(e.target)) {
      if (isOpen) {
        setOpen(false);

        if (setActionPrompt) {
          setActionPrompt();
        }
      }
    }
  };

  const handleToggle = () => {
    setOpen(!isOpen);
  };

  useEffect(() => {
    setOpen(false);
  }, [close]);

  useEffect(() => {
    document.addEventListener("mousedown", closeActions);
    return () => {
      document.removeEventListener("mousedown", closeActions);
    };
  });

  return (
    <div className={cn(styles.popupActions, promptRoot ? styles.static : "", className)} ref={wrapperRef}>
      <div className={cn(options?.modal ? styles.modal : null, styles.relWrap)}>
        {promptRoot && (
          <button type="button" onClick={handleToggle} className={promptRootClass}>
            {promptRoot}
          </button>
        )}
        {!promptRoot && (
          <button type="button" onClick={handleToggle} className={cn("button--clear", styles.dots, buttonClass)}>
            <OptionIcon fill={isOpen ? "#5BAFD7" : color} />
          </button>
        )}

        {isOpen && (
          <div
            className={cn(
              options?.responsive ? styles.contentResponsive : styles.content,
              promptRoot ? styles.prompt : null,
              promptClass,
              arrow || null,
              popupClass
            )}
          >
            {children}
          </div>
        )}
      </div>
    </div>
  );
};
