import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import cn from "classnames";
import queryString from "query-string";
import { useHistory } from "react-router-dom";
import qs from "qs";

import { tabs, subTabLeads, subTabReferrals } from "../../../constants/referralRequests";
import { actions as payPerLeadActions } from "../../../actions/payPerLead";
import styles from "./tabs.module.css";

const Tabs = ({
  tabsCounter,
  tabsCounterReferrals,
  setListActiveNetwork,
  setListActivePractice,
  listActivePractice,
  listActiveNetwork,
  activeFilter,
  setActiveFilter,
  isShowReferralsTab,
  isReferrals
}) => {
  const dispatch = useDispatch();
  const { activeTab } = useSelector(state => state.payPerLead);
  const isEmptyFilters = listActivePractice.length || listActiveNetwork.length;

  const setActiveTab = useCallback((tab, subTab) => dispatch(payPerLeadActions.setActiveTab(tab, subTab)), [dispatch]);

  const referralsCount = tabsCounterReferrals.purchased + tabsCounterReferrals.notPurchased;
  const leadsCount = tabsCounter.purchased + tabsCounter.hidden + tabsCounter.new;
  const history = useHistory();
  const searchParams = queryString.parse(history.location.search);

  const handleSetActive = (tab, subTab) => {
    const params = qs.stringify({ ...searchParams, tab });

    if (tab !== activeTab) {
      setActiveTab(tab, subTab);
      history.push(`/paid-leads?${params}`);

      if (isEmptyFilters) {
        setListActivePractice([]);
        setListActiveNetwork([]);
      }

      if (activeFilter) {
        setActiveFilter(false);
      }
    }
  };

  useEffect(() => {
    if (searchParams?.tab && searchParams?.tab !== activeTab) {
      if ([tabs.referrals].includes(searchParams.tab)) {
        // setActiveTab(tabs.referrals, subTabReferrals.pendingReview);
        handleSetActive(tabs.notpurchased, subTabLeads.new);
      } else {
        setActiveTab(tabs.notpurchased, searchParams?.tab || subTabLeads.new);
      }
    }
  }, [searchParams?.tab]);

  /* todo: remove it if we don't need it
  useEffect(() => {
    if (searchParams?.tab && searchParams?.tab === tabs.referrals && !isShowReferralsTab) {
      handleSetActive(tabs.notpurchased, subTabLeads.new);
    }
  }, [isShowReferralsTab]);
  */

  return (
    <>
      {isShowReferralsTab || !isReferrals ? (
        <div className={styles.content}>
          {isShowReferralsTab && (
            <button
              type="button"
              className={cn(styles.tab, styles.tabActive)}
              onClick={() => handleSetActive(tabs.referrals, subTabReferrals.pendingReview)}
            >
              My Requests {referralsCount ? <>{`(${referralsCount})`}</> : ""}
            </button>
          )}
          {!isReferrals && (
            <button
              type="button"
              className={cn(styles.tab, activeTab === tabs.notpurchased ? styles.tabActive : "")}
              onClick={() => handleSetActive(tabs.notpurchased, subTabLeads.new)}
            >
              Leads {leadsCount ? <>{`(${leadsCount})`}</> : ""}
            </button>
          )}
        </div>
      ) : null}
    </>
  );
};

export default Tabs;
