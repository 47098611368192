import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import cn from "classnames";

import { getStateName } from "../../../helpers";
import { Status } from "../status";
import messageIcon from "../../../media/icons/header/messages.svg";
import { MainSection } from "../mainSection";
import styles from "./referralRequests.module.css";

export const ReferralRequests = ({ states }) => {
  const { t } = useTranslation();
  const { dashboardData } = useSelector(state => state.home, shallowEqual);

  if (!dashboardData.cases?.length) {
    return null;
  }

  const referralRequestsList = dashboardData.cases
    .slice(0, 5)
    .map(({ city, lead_id: leadId, practice1, practice2, practice3, state, status, matches }) => {
      return (
        <Link to={`/referral-requests/${leadId}`} className={styles.case} key={leadId}>
          <div className={styles.caseWrap}>
            <div className={cn(styles.caseBox, styles.practice)}>
              <p className={styles.label}>{t("dashboard_referrals_all_table_specialty")}</p>
              <div className={styles.practiceWrp}>
                {practice1 && <span className={styles.practiceText}>{`${practice1}${practice2 || practice3 ? ", " : ""}`}</span>}
                {practice2 && <span className={styles.practiceText}>{`${practice2}${practice3 ? ", " : ""}`}</span>}
                {practice3 && <span className={styles.practiceText}>{practice3}</span>}
              </div>
            </div>
            <div className={cn(styles.caseBox, styles.summary)}>
              <p className={styles.label}>{t("dashboard_referrals_all_table_location")}</p>
              <p className={styles.summary}>
                {city}, {getStateName(states, state)}
              </p>
            </div>
            <div className={cn(styles.caseBox, styles.status)}>
              <p className={styles.label}>{t("dashboard_referrals_all_table_status")}</p>
              <div className={styles.caseBoxContent}>
                <Status caseItem={{ status, matches }} />
              </div>
            </div>
            <div className={styles.readMore}> {t("dashboard_common_readmore")}</div>
          </div>
        </Link>
      );
    });

  return (
    <MainSection
      icon={messageIcon}
      title={t("dashboard_home_referrals_block_title")}
      linkText={t("dashboard_home_viewall")}
      to="/referral-request"
    >
      <div>{referralRequestsList}</div>
    </MainSection>
  );
};
