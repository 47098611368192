import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import { enableScroll, disableScroll } from "../../../../helpers";
import { OverlayPreloader } from "../../../common/preloader";
import { Heading } from "./heading";
import { NotificationList } from "./notificationList";
import styles from "./notification.module.css";

export const PopupNotification = ({ onCloseHandler }) => {
  const { preloaders } = useSelector(state => state.main);
  const wrapperRef = useRef(null);

  useEffect(() => {
    disableScroll();
    return () => {
      enableScroll();
    };
  }, []);

  return (
    <>
      <div ref={wrapperRef} className={styles.notification}>
        <Heading onClose={onCloseHandler} />
        <div className={styles.relative}>
          {preloaders.notifications && <OverlayPreloader />}
          <NotificationList />
        </div>
        <Link to="/notifications" className={styles.buttonNotification} onClick={onCloseHandler}>
          See all Notifications
        </Link>
      </div>
      {/*
      <div className={styles.background} onClick={onCloseHandler} role="button" tabIndex={-1} />
      */}
    </>
  );
};
