export const socialName = {
  facebook: "facebook",
  twitter: "twitter",
  linkedin: "linkedin",
  google: "google",
  instagram: "instagram",
  rss: "rss",
  facebookGroup: "facebook_group",
  linkedinGroup: "linkedin_group"
};
