import React from "react";

import Filter from "../filter";
import styles from "./filters.module.css";

const Filters = ({
  listActiveDirectories = [],
  listActiveListingType = [],
  listActiveSponsorOptions = [],
  filters,
  loading,
  filtersReferrals,
  listActivePractice,
  practices,
  setListActivePractice,
  setListActiveNetwork,
  listActiveNetwork,
  directories,
  isReferrals,
  referralsList,
  leadsList,
  activeFilter,
  setActiveFilter,
  isHideFilters
}) => {
  const appliedCount = listActiveDirectories.length + listActiveListingType.length + listActiveSponsorOptions.length;
  const activeList = isReferrals ? referralsList : leadsList;

  const disableFilters = !activeList?.length && !appliedCount;

  return (
    <>
      {!disableFilters && (
        <div className={styles.mobile}>
          <div className={styles.actions}>
            {activeFilter && (
              <button type="button" className={styles.filterButton} onClick={() => setActiveFilter(true)}>
                Filters
                {appliedCount ? (
                  <span>{appliedCount}</span>
                ) : (
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path d="M1.6665 5.625H18.3332" stroke="#1C1D40" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M4.375 10H15.625" stroke="#1C1D40" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M8.125 14.375H11.875" stroke="#1C1D40" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                )}
              </button>
            )}
          </div>
        </div>
      )}
      {activeFilter && (
        <Filter
          setActiveFilter={setActiveFilter}
          filters={filters}
          loading={loading}
          appliedCount={appliedCount}
          filtersReferrals={filtersReferrals}
          listActivePractice={listActivePractice}
          practices={practices}
          setListActivePractice={setListActivePractice}
          directories={directories}
          listActiveNetwork={listActiveNetwork}
          setListActiveNetwork={setListActiveNetwork}
        />
      )}

      {!disableFilters && (
        <div className={styles.content}>
          {!isHideFilters && (
            <button type="button" className={styles.categoryButton} onClick={() => setActiveFilter(prev => !prev)}>
              Filters
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path d="M5 7.5L10 12.5L15 7.5" stroke="#1C1D40" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </button>
          )}
        </div>
      )}
    </>
  );
};

export default Filters;
