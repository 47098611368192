import React from "react";
import { Link } from "react-router-dom";

import styles from "./professions.module.css";

const Professions = ({ professionalList, isLink, onClick }) => {
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  const professionalListRender = professionalList?.map(({ name, imageUrl, link, Icon }) => {
    return (
      <>
        {isLink ? (
          <Link to={link} className={styles.professionsItem} key={name} onClick={handleClick}>
            <span>
              {imageUrl ? <img src={imageUrl} alt="" /> : null} {Icon ? <Icon /> : null}
            </span>
            <span className={styles.professionsText}>{name}</span>
          </Link>
        ) : (
          <button type="button" className={styles.professionsItem} key={name} onClick={handleClick}>
            <span>
              {imageUrl ? <img src={imageUrl} alt="" /> : null} {Icon ? <Icon /> : null}
            </span>
            <span className={styles.professionsText}>{name}</span>
          </button>
        )}
      </>
    );
  });

  return <div className={styles.professions}>{professionalListRender}</div>;
};

export default Professions;
