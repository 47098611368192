import React from "react";
import { NavLink } from "react-router-dom";
import cn from "classnames";

import { useTranslation } from "react-i18next";
import styles from "./pageNames.module.css";

const PageNames = ({ isListingsPage }) => {
  const { t } = useTranslation();

  return (
    <div className={cn(styles.main, isListingsPage ? styles.mainListings : "")}>
      <NavLink activeClassName={styles.activeLink} className={styles.link} to="/listings">
        {t("dashboard_listings_all_h1")}
      </NavLink>

      <NavLink activeClassName={styles.activeLink} className={styles.link} to="/my-events">
        {t("dashboard_listings_events_h1")}
      </NavLink>

      <NavLink activeClassName={styles.activeLink} className={styles.link} to="/my-organizations">
        {t("dashboard_listings_organizations_h1")}
      </NavLink>

      <NavLink activeClassName={styles.activeLink} className={styles.link} to="/my-jobs">
        {t("dashboard_listings_jobs_h1")}
      </NavLink>

      <NavLink activeClassName={styles.activeLink} className={styles.link} to="/profile">
        My Profile
      </NavLink>
    </div>
  );
};

export default PageNames;
