import React from "react";
import { useTranslation } from "react-i18next";

import styles from "./head.module.css";
import { statusesConstant } from "../../../../constants/referralRequestDetail";
import { ReactComponent as Icon } from "../../../../media/icons/check-green.svg";

export const Head = ({ status }) => {
  const { t } = useTranslation();

  const renderCaseHead = () => {
    let result;
    switch (status) {
      case statusesConstant.pendingReview:
        result = <p className={styles.textPendingReview}>{t("dashboard_referrals_details_top_description_pending")}</p>;
        break;

      case statusesConstant.missingInformation:
        result = (
          <>
            <p className={styles.textMissing}>{t("dashboard_referrals_details_top_description_additionalinfo")}</p>
          </>
        );
        break;

      case statusesConstant.rejected:
        result = (
          <>
            <p className={styles.rejectedDetail}>{t("dashboard_referrals_details_top_description_rejected")}</p>
          </>
        );
        break;

      case statusesConstant.notMatch:
        result = (
          <div className={styles.noMatch}>
            <Icon className={styles.icon} />
            <div>
              <h2 className={styles.noMatchTitle}>No Matches</h2>
              <p className={styles.noMatchDescription}>{t("dashboard_referrals_details_top_description_nomatches")}</p>
            </div>
          </div>
        );
        break;

      default:
        result = null;
    }

    return result;
  };

  return <div className={styles.caseHead}>{renderCaseHead()}</div>;
};
