import React from "react";
import cn from "classnames";

import InputComponent from "../../common/input";
import styles from "./input.module.css";

const Input = ({
  value,
  onChange,
  placeholder,
  className,
  classNameWrap,
  name,
  error,
  id,
  isInvalid,
  label,
  onBlur,
  required,
  isAutoFocus,
  errorClassName
}) => {
  return (
    <InputComponent
      className={cn(styles.input, className)}
      classNameWrap={classNameWrap}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      name={name}
      error={error}
      id={id}
      isInvalid={isInvalid}
      label={label}
      onBlur={onBlur}
      required={required}
      classNameError={cn(styles.errorClassName, errorClassName)}
      classNameInvalid={styles.classNameInvalid}
      isAutoFocus={isAutoFocus}
    />
  );
};

export default Input;
