import React from "react";
import { format, isSameDay } from "date-fns";

import { useTranslation } from "react-i18next";
import { parseDateToYearMonthDay } from "../../../../helpers/parseDate";
import { getNameWebsiteFromUrl, isHaveNumber } from "../../../../helpers";
import { ReactComponent as LetterIcon } from "../../../../media/icons/letter.svg";
import { ReactComponent as PhoneIcom } from "../../../../media/icons/phone.svg";
import { ReactComponent as GoToIcon } from "../../../../media/icons/goTo.svg";
import styles from "./detailClient.module.css";

const DetailClient = ({ messageDetail }) => {
  const {
    name_full: fullName = "",
    attorney = "",
    email,
    phone,
    public_url: publicUrl,
    added,
    listing_status: listingStatus,
    listing_url: listingUrl,
    listings_image_name: listingImageName
  } = messageDetail;
  const dateBetween = added ? parseDateToYearMonthDay(added) : added;
  const isSameDateAdded = added ? isSameDay(new Date(added), new Date()) : false;
  const { t } = useTranslation();

  const renderInitials = name => {
    return name
      .split(" ")
      .slice(0, 2)
      .map(n => n[0])
      .join("");
  };

  return (
    <>
      {fullName && (
        <>
          <div className={styles.nameContent}>
            {listingImageName ? (
              <img className={styles.image} src={listingImageName} alt="Attorney name" />
            ) : (
              <div className={styles.initials}>{renderInitials(fullName)}</div>
            )}
            <div className={styles.name}>{fullName}</div>
          </div>

          <span className={styles.label}>Listing</span>
          {listingUrl && (
            <a href={listingUrl} className={styles.activeStatus} target="_blank" rel="noopener noreferrer">
              {getNameWebsiteFromUrl(listingUrl)}
            </a>
          )}

          <span className={styles.label}>Listing Status</span>

          {listingStatus === "Active" ? (
            <a href={listingUrl} className={styles.listingUrl} target="_blank" rel="noopener noreferrer">
              {t("active")}
            </a>
          ) : (
            <span className={styles.status}>
              {listingStatus}
              <a href={publicUrl} target="_blank" rel="noopener noreferrer" className={styles.publicUrl}>
                <GoToIcon className={styles.icon} />
              </a>
            </span>
          )}

          <hr className={styles.hr} />

          <span className={styles.label}>{t("dashboard_proview_message_first_received")}</span>
          {isSameDateAdded ? <span className={styles.added}>Today</span> : <span className={styles.date}>{dateBetween}</span>}

          {added && <span className={styles.added}>{format(new Date(added), "EEEE MMM dd, yyyy")}</span>}

          <hr className={styles.hr} />

          <span className={styles.label}>Your information</span>
          <span className={styles.professionalName}>{attorney}</span>
          {email && (
            <a href={`mailto::${email}`} className={styles.action}>
              <LetterIcon className={styles.actionIcon} />
              <span className={styles.actionText}>{email}</span>
            </a>
          )}
          {isHaveNumber(phone) && (
            <a href={`tel:${phone}`} className={styles.action}>
              <PhoneIcom className={styles.actionIconPhone} />
              <span className={styles.actionText}>{phone}</span>
            </a>
          )}
        </>
      )}
    </>
  );
};

export default DetailClient;
