import React, { useCallback } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { modals as modalsConstant } from "../../constants/listings";
import { actions as listingActions } from "../../actions/listing";
import { VerificationModal } from "../verification";
import { VerificationSuccessModal } from "../verificationSuccess";
import { VerificationBanner } from "../verificationBanner";

const VerificationEmailFlow = () => {
  const dispatch = useDispatch();
  const { modals } = useSelector(state => state.listing, shallowEqual);
  const clearAllModals = useCallback(() => dispatch(listingActions.clearAllModals()), [dispatch]);
  const toggleActivityOfModal = (modal, isActive) => dispatch(listingActions.toggleActivityOfModal(modal, isActive));

  const openModal = modalName => {
    toggleActivityOfModal(modalName, true);
  };

  const closeModal = modalName => {
    toggleActivityOfModal(modalName, false);
  };

  return (
    <>
      <VerificationBanner openModal={() => openModal(modalsConstant.verification)} />

      {modals.verification && (
        <VerificationModal
          clearAllModals={clearAllModals}
          handleClose={() => closeModal(modalsConstant.verification)}
          openModal={() => openModal(modalsConstant.verificationSuccess)}
        />
      )}

      {modals.verificationSuccess && <VerificationSuccessModal clearAllModals={clearAllModals} />}
    </>
  );
};

export default VerificationEmailFlow;
