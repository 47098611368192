import React from "react";

import imgError from "../../media/icons/404.svg";
import styles from "./errorBoundary.module.css";

const ErrorBoundaryComponent = () => {
  return (
    <div className={styles.main}>
      <div className={styles.imgWrap}>
        <img className={styles.errorImg} src={imgError} alt="" />
      </div>
      <p className={styles.textError}>
        Something went wrong. <br /> We are so sorry
      </p>
      <a className={styles.button} href="/">
        Return to Home
      </a>
    </div>
  );
};

export default ErrorBoundaryComponent;
