import React from "react";
import cn from "classnames";
import format from "date-fns/format";
import { useTranslation } from "react-i18next";

import styles from "./downgradePeriod.module.css";

export const DowngradePeriod = ({ time, handleDowngrade }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.downgrade}>
      <span className={styles.text}>Scheduled to downgrade on {time && format(new Date(time), "MM/dd/yyyy")}:</span>
      <button type="button" className={cn("button--clear", styles.options)} onClick={handleDowngrade}>
        {t("dashboard_listings_table_options")}
      </button>
    </div>
  );
};
