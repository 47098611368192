import React from "react";

import Location from "../../publicListing/location";
import styles from "./location.module.css";

const LocationContent = ({ address }) => {
  return (
    <div className={styles.content}>
      <Location address={address || { street: "street_", city: "_city", country: "_country" }} />
    </div>
  );
};

export default LocationContent;
