import React from "react";

import { ReactComponent as AlarmIcon } from "../../../media/icons/alarm.svg";
import styles from "./alreadyPurchased.module.css";

export const AlreadyPurchased = () => {
  return (
    <div className={styles.content}>
      <AlarmIcon />
      <p className={styles.description}>Sold Exclusively to Another Professional</p>
    </div>
  );
};
