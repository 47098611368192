export const modalsName = {
  invite: "invite",
  inviteSuccess: "inviteSuccess",
  updateInvite: "updateInvite",
  remove: "remove",
  reject: "reject",
  viewAccess: "viewAccess",
  removeSuccess: "removeSuccess",
  rejectSuccess: "rejectSuccess",
  updateInviteSuccess: "updateInviteSuccess",
  accept: "accept"
};
