import { publicListing } from "../constants/actionTypes";

const defaultState = {
  listing: {},
  preloaders: { page: true }
};

export default function(state = defaultState, action) {
  const { type, payload } = action;
  switch (type) {
    case publicListing.SET_LOADING:
      return {
        ...state,
        preloaders: { ...state.preloaders, [payload.section]: payload.isLoading }
      };

    case publicListing.GET_LISTINGS:
      return {
        ...state,
        listing: payload,
        preloaders: {
          ...state.preloaders,
          page: false
        }
      };

    case publicListing.GET_LISTINGS_FAIL:
    case publicListing.ADD_FAVORITES_FAIL:
      return {
        ...state,
        preloaders: {
          ...state.preloaders,
          page: false
        }
      };

    case publicListing.ADD_FAVORITES:
      return {
        ...state,
        listing: {
          ...state.listing,
          bookmarked: true
        },
        preloaders: {
          ...state.preloaders,
          page: false
        }
      };

    case publicListing.REMOVE_FAVORITES:
      return {
        ...state,
        listing: {
          ...state.listing,
          bookmarked: false
        },
        preloaders: {
          ...state.preloaders,
          page: false
        }
      };

    default:
      return {
        ...state
      };
  }
}
