import React, { useCallback, useEffect, useState } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import cn from "classnames";

import { useTranslation } from "react-i18next";
import { Button } from "../common/button";
import Input from "../common/input";
import { Modal } from "../common/modal";
import { actions as mainActions } from "../../actions/main";
import { OverlayPreloader } from "../common/preloader";
import styles from "./verification.module.css";
import Counter from "../counter";
import ModalControl from "../modalControl";

export const VerificationModal = ({ handleClose, clearAllModals, openModal }) => {
  const [disableButton, setDisableButton] = useState(false);
  const [showTimer, setShowTimer] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const validate = ({ code }) => {
    const errors = {};

    if (!code) {
      errors.code = t("dashboard_common_required");
    }

    return errors;
  };

  const sendEmailCode = useCallback(email => dispatch(mainActions.sendEmailCode(email)), [dispatch]);

  const { profile } = useSelector(state => state.account);
  const { preloaders, verifyEmailCode } = useSelector(state => state.main);

  const formik = useFormik({
    initialValues: {
      code: ""
    },
    initialStatus: {
      code: ""
    },
    validate,
    onSubmit: values => {
      checkEmailCode(values.code);
    }
  });

  const checkEmailCode = useCallback(code => dispatch(mainActions.checkEmailCode(code, formik.setStatus)), [dispatch, formik.setStatus]);

  const handleChange = e => {
    formik.setFieldValue(e.target.name, e.target.value);
    if (formik.status?.[e.target.name]) {
      formik.setStatus({ ...formik.status, [e.target.name]: "" });
    }
  };

  const handleSendCode = () => {
    sendEmailCode(profile?.email);
    setDisableButton(true);
    setShowTimer(true);
  };

  const handleResendCode = () => {
    sendEmailCode(profile?.email);
    setShowTimer(true);
  };

  useEffect(() => {
    if (verifyEmailCode.verifyCodeCheck) {
      handleClose();
      openModal();
    }
  }, [verifyEmailCode.verifyCodeCheck]); // eslint-disable-line

  return (
    <Modal closeModal={handleClose} clearAllModals={clearAllModals} classNameMain={styles.modal}>
      <form className={styles.form} onSubmit={formik.handleSubmit}>
        {preloaders.verifyEmailCode && <OverlayPreloader overlayClassName="sectionOverlayModal" />}
        <section>
          <div className={styles.pointer}>{t("dashboard_home_verifyemail_modal_name")}</div>
          <div className={styles.popupHeading}>{t("dashboard_home_verifyemail_modal_title")}</div>
        </section>
        <div className={styles.descText}>{t("dashboard_home_verifyemail_modal_description")}</div>
        <div className={styles.verifyText}>
          {t("dashboard_home_verifyemail_modal_verifybtn")} <span>{profile?.email}</span>
        </div>
        <Button className={cn(styles.verifyEmail, disableButton ? styles.verifyEmailDisabled : "")} onClick={handleSendCode}>
          {disableButton ? <>{t("dashboard_common_button_emailsent")}</> : <>{t("dashboard_home_verifyemail_modal_sendcode")}</>}
        </Button>
        {(disableButton || showTimer) && (
          <Counter showTimer={showTimer} setShowTimer={setShowTimer}>
            <Button className={styles.resendBtn} clearStyles onClick={handleResendCode}>
              {t("dashboard_home_verifyemail_modal_resendbtn")}
            </Button>
          </Counter>
        )}
        <Input
          classNameWrap={styles.codeInput}
          error={formik.status.code || formik.errors.code}
          id="code"
          isInvalid={(formik.status.code || formik.errors.code) && formik.touched.code}
          name="code"
          onBlur={() => formik.setFieldTouched("code", true)}
          label={t("dashboard_home_verifyemail_modal_codeinput")}
          onChange={handleChange}
          value={formik.values.code}
        />
        <Button
          type="submit"
          className={cn(styles.verifyBtn, !formik.values.code && styles.verifyBtnDisabled)}
          disabled={!formik.values.code}
        >
          {t("dashboard_home_verifyemail_modal_verifybtn")}
        </Button>

        <ModalControl onExit={handleClose} isHideExitConfirm isClose />
      </form>
    </Modal>
  );
};
