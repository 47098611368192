import React from "react";
import { ReactComponent as CheckIcon } from "../../../media/icons/create-new-account-profession/check-cycle.svg";

import styles from "./sponsorPlan.module.css";

const listings = ["5X more views", "No ads on listing", "SMS notifications"];

const SponsorPlan = () => {
  const listingsList = listings.map(name => {
    return (
      <div key={name} className={styles.listing}>
        <CheckIcon className={styles.icon} />
        <span className={styles.listingName}>{name}</span>
      </div>
    );
  });

  return (
    <>
      <div className={styles.plan}>
        <div className={styles.content}>
          <div>
            <h2 className={styles.name}>Sponsor Listing</h2>
          </div>

          <div className={styles.priceContent}>
            <span className={styles.priceNumber}>$25</span>
            <span className={styles.priceDescription}>/month</span>
          </div>
        </div>
        <div className={styles.listingDetail}>
          <h5 className={styles.listingType}>Free Listing Benefits +</h5>
          <div className={styles.listings}>{listingsList}</div>
        </div>
      </div>
    </>
  );
};

export default SponsorPlan;
