import React from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import { useTranslation } from "react-i18next";

import professionals from "../../../media/icons/landing/professionals.png";
import client from "../../../media/icons/landing/client.png";
import styles from "./info.module.css";

const Info = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.rightSide}>
            <h2 className={styles.title}>{t("dashboard_hp_benefit1_title")}</h2>
            <h2 className={styles.description}>{t("dashboard_hp_benefit1_desc")}</h2>
            <Link className={styles.link} to="/login">
              {t("dashboard_hp_benefit1_button")}
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M10 6L16 12L10 18" stroke="#5BAFD7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </Link>
          </div>
          <div className={styles.leftSide}>
            <div className={styles.professional}>
              <img src={professionals} alt="Professionals" />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.container}>
        <div className={cn(styles.content, styles.contentAlignItem)}>
          <div className={styles.rightSide}>
            <h2 className={styles.title}>{t("dashboard_hp_benefit2_title")}</h2>
            <h2 className={styles.description}>{t("dashboard_hp_benefit2_desc")}</h2>
            <Link className={styles.link} to="/login">
              {t("dashboard_hp_benefit2_button")}
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M10 6L16 12L10 18" stroke="#5BAFD7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </Link>
          </div>
          <div className={styles.leftSide}>
            <div className={styles.imgClient}>
              <img src={client} alt="client" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Info;
