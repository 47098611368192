import React from "react";
import cn from "classnames";

import styles from "./messageItem.module.css";

const MessageItem = ({ name, attorney, date, message, initials, listingUrl, isRead, onClick, isActive, color, background }) => {
  const messageItemRead = isRead ? styles.messageItemRead : "";
  const messageItemActive = isActive ? styles.messageItemActive : "";

  return (
    <div className={cn(styles.messageItem, messageItemRead, messageItemActive)} role="button" tabIndex="0" onClick={onClick}>
      <div className={styles.content}>
        <div className={styles.initials} style={{ color, background }}>
          {initials}
        </div>
        {!isRead && <span className={styles.unreadCount}>1</span>}
        <div className={styles.tile}>
          <div className={styles.topContent}>
            <span className={styles.name}>{name}</span>
            <span className={styles.date}>{date}</span>
          </div>
          <p className={styles.message}>{message}</p>
          <span className={styles.listing}> {`${listingUrl} (${attorney})`}</span>
        </div>
      </div>
    </div>
  );
};

export default MessageItem;
