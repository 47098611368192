import axios from "axios";
import { isToday, isYesterday } from "date-fns";

// eslint-disable-next-line import/no-cycle
import { main, auth } from "../constants/actionTypes";
// eslint-disable-next-line import/no-cycle
import { actions as homeActions } from "./home";
import { actions as profileActions } from "./account/profile";

export const actions = {
  changeClientView() {
    return dispatch => {
      dispatch({ type: main.CHANGE_CLIENT_VIEW });
    };
  },

  getMainInfo() {
    return dispatch => {
      axios
        .all([
          axios({
            method: "get",
            responseType: "json",
            url: "/members/json-api/index/global-info"
          }),
          axios({
            method: "get",
            responseType: "json",
            url: "/members/json-api/index/countries-states",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
            }
          })
        ])
        .then(
          axios.spread((globalRes, countriesStates) => {
            const notificationsByNewest = globalRes.data.notifications.sort((a, b) => new Date(b.added) - new Date(a.added));
            const isAddedToday = isToday(new Date(globalRes.data.added));
            const isAddedYesterday = isYesterday(new Date(globalRes.data.added));

            dispatch({
              type: main.GET_UTILITY_LISTING,
              payload: {
                countries: countriesStates.data.countries,
                states: countriesStates.data.states,
                globalInfo: globalRes.data,
                notifications: notificationsByNewest
              }
            });

            if (isAddedToday || isAddedYesterday) {
              window.po("customers", "signup", {
                data: {
                  key: "343434", // Heritage Web (aMember) User-ID
                  name: globalRes.data.name,
                  email: globalRes.data.email
                }
              });
            }
          })
        )
        .catch(error => {
          dispatch({
            type: main.GET_UTILITY_LISTING_FAIL,
            payload: error
          });
          console.error("getMainInfo", error);
        });
    };
  },
  getProfileImage() {
    return dispatch => {
      axios
        .get("/members/api-v2/my-account/get?command=picture")
        .then(res => {
          dispatch({
            type: main.GET_PROFILE_IMAGE,
            payload: res.data.data
          });
        })
        .catch(err => {
          dispatch({
            type: main.GET_PROFILE_IMAGE_FAIL,
            payload: err
          });
        });
    };
  },

  getFooterInfo() {
    return dispatch => {
      axios
        .get("/members/api-v2/directory/get?command=links")
        .then(res => {
          dispatch({
            type: main.GET_FOOTER_INFO,
            payload: {
              footerInfo: res.data.data.footer_links,
              socialLinks: res.data.data.social_links
            }
          });
        })
        .catch(err => {
          dispatch({
            type: main.GET_FOOTER_INFO_FAIL,
            payload: err
          });
        });
    };
  },

  setLoading(section, isSectionLoading) {
    return {
      type: main.SET_LOADING,
      payload: { section, isSectionLoading }
    };
  },

  getLoginStatus() {
    return dispatch => {
      axios("/members/json-api/login")
        .then(res => {
          dispatch({ type: main.GET_LOGIN, payload: res.data });
          dispatch({ type: auth.LOGIN_SUCCESS, payload: res.data });
        })
        .catch(error => {
          console.error("error", error.message);
          dispatch({ type: main.GET_LOGIN_FAIL, payload: false });
        });
    };
  },

  sendEmailCode(email) {
    return dispatch => {
      axios({
        method: "post",
        url: "members/json-api/verify/send-email-code",
        data: { email },
        headers: {
          "Content-Type": "application/json"
        }
      })
        .then(() => {
          dispatch({ type: main.SEND_EMAIL_VERIFY_CODE_SUCCESS });
        })
        .catch(() => dispatch({ type: main.SEND_EMAIL_VERIFY_CODE_FAIL }));
    };
  },

  checkEmailCode(code, setStatus) {
    return dispatch => {
      dispatch(this.setLoading("verifyEmailCode", true));
      axios({
        method: "post",
        url: "members/json-api/verify/check-email-code",
        data: { code },
        headers: {
          "Content-Type": "application/json"
        }
      })
        .then(() => {
          dispatch({ type: main.CHECK_EMAIL_VERIFY_CODE_SUCCESS });
          dispatch(profileActions.getProfile());
        })
        .catch(err => {
          if (err?.response?.status === 404) {
            setStatus({ code: "Wrong code" });
          } else {
            setStatus({ code: "Something went wrong" });
          }
          dispatch({ type: main.CHECK_EMAIL_VERIFY_CODE_FAIL });
        });
    };
  },

  logout() {
    return {
      type: auth.LOGOUT
    };
  },

  setViewNotification(ids) {
    return async dispatch => {
      dispatch(this.setLoading("notifications", true));
      axios({
        method: "post",
        responseType: "json",
        url: "/members/json-api/notifications/view",
        data: JSON.stringify(ids),
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
        }
      })
        .then(() => {
          axios({
            method: "get",
            responseType: "json",
            url: "/members/json-api/index/global-info"
          })
            .then(response => {
              dispatch(homeActions.markAsReadNotification(ids));
              dispatch(this.markAsReadNotification(ids));
              dispatch({
                type: main.GET_UTILITY_LISTING,
                payload: {
                  globalInfo: response.data
                }
              });
            })
            .catch(() => {
              dispatch(this.setLoading("notifications", false));
            });
        })
        .catch(() => {
          dispatch(this.setLoading("notifications", false));
        });
    };
  },

  setUnViewNotification(ids) {
    return dispatch => {
      dispatch(this.setLoading("notifications", true));
      axios({
        method: "post",
        responseType: "json",
        url: "/members/json-api/notifications/unview",
        data: JSON.stringify(ids),
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
        }
      })
        .then(() => {
          axios({
            method: "get",
            responseType: "json",
            url: "/members/json-api/index/global-info"
          })
            .then(response => {
              dispatch(homeActions.markAsUnReadNotification(ids));
              dispatch(this.markAsUnReadNotification(ids));
              dispatch({
                type: main.GET_UTILITY_LISTING,
                payload: {
                  globalInfo: response.data
                }
              });
            })
            .catch(() => {
              dispatch(this.setLoading("notifications", false));
            });
        })
        .catch(() => {
          dispatch(this.setLoading("notifications", false));
        });
    };
  },

  markAsReadNotification(ids) {
    return (dispatch, getState) => {
      const {
        main: { notifications }
      } = getState();

      const updatedNotifications = notifications.map(item => {
        return ids.includes(item.notification_id) ? { ...item, is_viewed: "1" } : item;
      });

      dispatch({
        type: main.MARK_READ,
        payload: updatedNotifications
      });
    };
  },

  markAsUnReadNotification(ids) {
    return (dispatch, getState) => {
      const {
        main: { notifications }
      } = getState();

      const updatedNotifications = notifications.map(item => {
        return ids.includes(item.notification_id) ? { ...item, is_viewed: "0" } : item;
      });

      dispatch({
        type: main.MARK_UNREAD,
        payload: updatedNotifications
      });
    };
  },

  setViewAllNotification() {
    return (dispatch, getState) => {
      const {
        main: { notifications }
      } = getState();
      dispatch(this.setLoading("notifications", true));

      const unReadIds = notifications.filter(({ is_viewed }) => is_viewed === "0").map(({ notification_id }) => notification_id);

      axios({
        method: "post",
        responseType: "json",
        url: "/members/json-api/notifications/view-all",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
        }
      })
        .then(() => {
          axios({
            method: "get",
            responseType: "json",
            url: "/members/json-api/index/global-info"
          })
            .then(response => {
              dispatch(homeActions.markAsReadNotification(unReadIds));
              dispatch(this.markAsReadNotification(unReadIds));
              dispatch({
                type: main.GET_UTILITY_LISTING,
                payload: {
                  globalInfo: response.data
                }
              });
            })
            .catch(() => {
              dispatch(this.setLoading("notifications", false));
            });
        })
        .catch(() => {
          dispatch(this.setLoading("notifications", false));
        });
    };
  },

  togglePaymentError(isActive, errorMessage) {
    return {
      type: main.TOGGLE_PAYMENT_ERROR,
      payload: {
        isActive,
        errorMessage
      }
    };
  },

  toggleNewListing(isActive, listingType = "") {
    return {
      type: main.TOGGLE_NEW_LISTING,
      payload: {
        isActive,
        listingType
      }
    };
  },

  toggleMobileMenu(isActive) {
    return {
      type: main.TOGGLE_MOBILE_MENU,
      payload: {
        isActive
      }
    };
  }
};
