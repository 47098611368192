import React from "react";
import { useTranslation } from "react-i18next";
import cn from "classnames";

import { ReactComponent as IconProfile } from "../../../media/icons/see-profile.svg";
import { modals as modalsConstant } from "../../../constants/listings";
import { statuses } from "../../../constants/listingDetail";
import { Button } from "../../common/button";
import styles from "./productDetails.module.css";

const ProductDetails = ({ title, url, paused, openModal, children, isPremium, publishStatus, changeStatus, isDraft }) => {
  const isPaused = paused === "1" && publishStatus === statuses.paused;
  const isActiveStatus = publishStatus === statuses.active;
  const isExpired = publishStatus === statuses.expired;
  const isPendingReview = publishStatus === statuses.pending;
  const isNeverPublished = publishStatus === statuses.neverPublished;
  const { t } = useTranslation();

  const renderText = () => {
    if (isPendingReview) {
      return <span className={styles.statusLabel}>Pending review</span>;
    }

    if (isNeverPublished) {
      return <span className={styles.statusLabel}>{publishStatus}</span>;
    }

    if (isPaused) {
      return (
        <Button className={styles.unpause} onClick={() => openModal(modalsConstant.unpause)}>
          {t("dashboard_single_listing_unpause")}
        </Button>
      );
    }

    if (isExpired) {
      return (
        <>
          <span className={styles.expired}>{publishStatus} </span>
          <Button
            className={cn("button--clear", styles.renew)}
            onClick={() => openModal(isPremium ? modalsConstant.renew : modalsConstant.upgrade)}
          >
            - {t("clicktorenew")}
          </Button>
        </>
      );
    }

    if (isActiveStatus) {
      return (
        <a href={url} className={styles.active} target="_blank" rel="noopener noreferrer">
          {t("active")} <IconProfile className={styles.iconSmall} />
        </a>
      );
    }

    return <span className={styles.defaultStatus}>{publishStatus}</span>;
  };

  return (
    <div className={styles.productDetails}>
      <div className={styles.flexTop}>
        {isNeverPublished ? (
          <p className={cn(styles.title, styles.titleText)}>{title}</p>
        ) : (
          <a href={url} className={styles.title} target="_blank" rel="noopener noreferrer">
            {title} <IconProfile className={styles.icon} />
          </a>
        )}
        {children}
      </div>
      <div className={styles.flexContent}>
        <p className={styles.info}>{isDraft ? t("dashboard_listings_status_unpublishedchanges") : <>Changes {changeStatus}</>}</p>

        <div className={styles.statusContent}>
          <p className={styles.statusLabel}>{t("status")}:</p>
          {renderText()}
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;
