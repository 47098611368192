import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { modals as modalsConstant } from "../../../../constants/listings";
import { Modal } from "../../../common/modal";
import ModalControl from "../../../modalControl";
import styles from "./confirm.module.css";
import { Button } from "../../../common/button";
import { priceFormatter, renderBrandIcon } from "../../../../helpers";
import { OverlayPreloader } from "../../../common/preloader";
import modalStyles from "../../../common/modal/modal.module.css";
import { actions as payPerLeadDetailActions } from "../../../../actions/payPerLeadDetail";
import { useKeyDownEnter } from "../../../../helpers/hooks";

export const Confirm = ({ leadId, price, description, currentPrice, lead }) => {
  const { t } = useTranslation();

  const payment = useSelector(state => state.payment);
  const { defaultSource, preloaders: paymentPreloaders, selectedCard } = payment;
  const { brand, last4, id } = selectedCard || { brand: "", last4: "", id: "" };
  const isDefault = defaultSource?.id === id;
  const location = useLocation();

  const dispatch = useDispatch();
  const clearAllModals = useCallback(() => dispatch(payPerLeadDetailActions.clearAllModals()), [dispatch]);
  const toggleModal = useCallback((modal, isActive) => dispatch(payPerLeadDetailActions.toggleModal(modal, isActive)), [dispatch]);
  const { loading } = useSelector(state => state.payPerLeadDetail);
  const {
    coupon: { code, price: updatedPrice }
  } = useSelector(state => state.payPerLeadDetail);
  const isFree = code && updatedPrice === 0;
  const activePrice = code ? styles.priceActive : "";
  const buyLead = useCallback(
    (leadIds, token, isPurchased) => dispatch(payPerLeadDetailActions.buyLead(leadIds, token, isPurchased, lead)),
    [dispatch]
  );
  const isPurchased = queryString.parse(location.search).purchased === "0" ? "notpurchased" : "purchased";

  const closeModal = () => {
    toggleModal(modalsConstant.confirm, false);
  };

  const openModal = modal => {
    toggleModal(modal, true);
  };

  const handleCloseModal = () => {
    closeModal();
  };

  const onExit = () => {
    closeModal();
    openModal(modalsConstant.payment);
  };

  const onEditPaymentMethod = () => {
    closeModal();
    openModal(modalsConstant.payment);
  };

  const onConfirm = () => {
    if (isFree) {
      buyLead(leadId, "", isPurchased);
    } else {
      buyLead(leadId, selectedCard.id, isPurchased);
    }
  };

  useKeyDownEnter(onConfirm);

  return (
    <Modal closeModal={handleCloseModal} clearAllModals={clearAllModals}>
      {paymentPreloaders.chargeForMembership && (
        <OverlayPreloader overlayClassName="sectionOverlayModal zIndex4" spinnerClassName={`zIndex4 ${modalStyles.spinnerOverlay}`} />
      )}

      {(paymentPreloaders.paymentMethods || paymentPreloaders.updateCard || paymentPreloaders.deleteCard || loading) && (
        <OverlayPreloader overlayClassName="sectionOverlayModal zIndex4" spinnerClassName="zIndex4" />
      )}

      <h3 className={styles.subTitle}>PURCHASE LEAD</h3>
      <h1 className={styles.title}>Confirm and Pay</h1>

      <h2 className={styles.key}>Lead ID</h2>
      <span className={styles.value}>{leadId}</span>

      <h2 className={styles.key}>Lead Price (Exclusive)</h2>
      <span className={cn(styles.value, activePrice)}>{price}</span>
      {code && <span className={styles.updatedPrice}>{priceFormatter.format(updatedPrice)}</span>}

      <h2 className={styles.key}>Lead Description</h2>
      <div className={styles.description}>
        <span>{description}</span>
      </div>

      <div className={styles.method}>
        <div className={styles.methodContent}>
          <p className={styles.methodTitle}>{t("dashboard_listings_modal_upgrade_confirm_h4")}</p>
          {!isFree && (
            <Button className={styles.buttonEdit} onClick={onEditPaymentMethod}>
              {t("dashboard_common_link_edit")}
            </Button>
          )}
        </div>
        <div className={styles.card}>
          {isFree ? (
            <span className={styles.cardNoPayment}>{t("dashboard_listings_modal_upgrade_confirm_zeropay")}</span>
          ) : (
            <>
              <div className={styles.cardImg}>
                <img src={renderBrandIcon(brand)} alt={`${brand} card`} />
              </div>
              <div>
                <span className={styles.cardNumber}>**** - **** - **** - {last4}</span>
                {isDefault && <p className={styles.cardDefault}>{t("dashboard_payment_methods_default_card")}</p>}
              </div>
            </>
          )}
        </div>
      </div>
      <ModalControl isExit onExit={onExit} onConfirm={onConfirm}>
        Pay {currentPrice}
      </ModalControl>
    </Modal>
  );
};
