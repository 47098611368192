import axios from "axios";

import { home, main } from "../constants/actionTypes";
// eslint-disable-next-line import/no-cycle
import { actions as mainActions } from "./main";
import { track, trackDataLayer } from "../helpers/track";
import { eventName, eventNameDataLayer } from "../constants/track";

export const actions = {
  getDashboardInfo() {
    return dispatch => {
      dispatch(this.setLoading("dashboard", true));

      axios
        .get("/members/json-api/index/home")
        .then(res => {
          dispatch({
            type: home.GET_DASHBOARD_DATA_SUCCESS,
            payload: res.data
          });
        })
        .catch(err =>
          dispatch({
            type: home.GET_DASHBOARD_DATA_FAIL,
            payload: err
          })
        );
    };
  },

  setLoading(section, isSectionLoading) {
    return {
      type: home.SET_LOADING,
      payload: { section, isSectionLoading }
    };
  },

  clearDashboardData() {
    return {
      type: home.SET_DASHBOARD_DATA_CLEAR
    };
  },

  markAsReadNotification(ids) {
    return (dispatch, getState) => {
      const {
        home: {
          dashboardData: { notifications }
        }
      } = getState();

      const updatedNotifications = notifications.map(item => {
        return ids.includes(item.notification_id) ? { ...item, is_viewed: "1" } : item;
      });

      dispatch({
        type: home.MARK_READ,
        payload: updatedNotifications
      });
    };
  },

  markAsUnReadNotification(ids) {
    return (dispatch, getState) => {
      const {
        home: {
          dashboardData: { notifications }
        }
      } = getState();

      const updatedNotifications = notifications.map(item => {
        return ids.includes(item.notification_id) ? { ...item, is_viewed: "0" } : item;
      });

      dispatch({
        type: home.MARK_UNREAD,
        payload: updatedNotifications
      });
    };
  },

  setViewNotification(ids) {
    return dispatch => {
      axios({
        method: "post",
        responseType: "json",
        url: "/members/json-api/notifications/view",
        data: ids,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
        }
      }).then(() => {
        dispatch(this.markAsReadNotification(ids));
        dispatch(mainActions.markAsReadNotification(ids));

        axios({
          method: "get",
          responseType: "json",
          url: "/members/json-api/index/global-info"
        }).then(result => {
          dispatch({
            type: main.GET_UTILITY_LISTING,
            payload: {
              globalInfo: result.data
            }
          });
        });
      });
    };
  },

  setUnViewNotification(ids) {
    return async dispatch => {
      axios({
        method: "post",
        responseType: "json",
        url: "/members/json-api/notifications/unview",
        data: ids,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
        }
      }).then(() => {
        dispatch(this.markAsUnReadNotification(ids));
        dispatch(mainActions.markAsUnReadNotification(ids));

        axios({
          method: "get",
          responseType: "json",
          url: "/members/json-api/index/global-info"
        }).then(result => {
          dispatch({
            type: main.GET_UTILITY_LISTING,
            payload: {
              globalInfo: result.data
            }
          });
        });
      });
    };
  },

  addListingAssistant(data) {
    return dispatch => {
      dispatch(this.setLoading("listingAssistant", true));

      axios({
        method: "post",
        responseType: "json",
        url: "/members/json-api/listing-assistant/add",
        data
      })
        .then(() => {
          dispatch({
            type: home.ADD_LISTING_ASSISTANT
          });
        })
        .catch(() => {
          dispatch({
            type: home.ADD_LISTING_ASSISTANT_FAIL
          });
        });
    };
  },

  removeListingAssistantSuccess() {
    return {
      type: home.ADD_LISTING_ASSISTANT_REMOVE_SUCCESS
    };
  },

  getAddNewListingData(isModal) {
    return dispatch => {
      axios({ method: "get", responseType: "json", url: "/members/json-api/signup/listings-categories-professions" })
        .then(response => {
          const priority = isModal ? ["Agents", "Doctors", "Lawyers"] : ["Accountants", "Agents", "Doctors", "Lawyers"];
          const professionsSort = response.data.professions
            .filter(item => !item.title.includes("Profile"))
            .sort((a, b) => (priority.includes(a.title) ? (priority.includes(b.title) ? (a.title < b.title ? -1 : 0) : -1) : 1));

          dispatch({
            type: home.GET_ADD_NEW_LISTING_DATA,
            payload: {
              ...response?.data,
              professions: professionsSort
            }
          });
        })
        .catch(() => {
          dispatch({
            type: home.GET_ADD_NEW_LISTING_DATA_FAIL
          });
        });
    };
  },

  addNewListing(data, history, listingCreatePage, listingType) {
    return (dispatch, getState) => {
      const {
        account: { profile }
      } = getState();

      dispatch(this.setLoading("listingAssistant", true));

      const isEvensOrJobsPage =
        ["/my-events", "/my-jobs", "/my-organizations"].some(item => history.location.pathname.includes(item)) ||
        ["Events", "Jobs", "Organizations"].some(item => listingType.includes(item));
      const pattern = /jobs|events|organizations/;
      const listingName = history.location.pathname.match(pattern) || listingType.toLowerCase().match(pattern);

      const url = isEvensOrJobsPage ? `/members/api-v2/${listingName}` : "/members/json-api/signup/copy-or-create-listing";

      axios({
        method: "post",
        responseType: "json",
        url,
        data,
        headers: { "Content-Type": "application/json" }
      })
        .then(res => {
          dispatch({
            type: home.ADD_NEW_LISTING,
            payload: res.data
          });

          dispatch(mainActions.toggleNewListing(false));

          const pathname = isEvensOrJobsPage ? `/my-${listingName}` : "/listings";

          const listingId = isEvensOrJobsPage ? res.data.data.listing_id : res.data.listing_id;

          history.push(`${pathname}/${listingId}`);

          track(eventName.addToCart, { em: profile.email, fn: profile.name_f, ln: profile.name_f });
          trackDataLayer(eventNameDataLayer.listingCreated, { listing_status: "in review", listing_create_page: listingCreatePage });
        })
        .catch(() => {
          dispatch({
            type: home.ADD_NEW_LISTING_FAIL
          });
        });
    };
  },

  getSaved(command = "latest") {
    return dispatch => {
      dispatch(this.setLoading("saved", true));

      axios({ method: "get", url: `/members/api-v2/bookmarks?command=${command}&expand=lawfirms,practices,parent_company` })
        .then(response => {
          const payload = command !== "latest" ? { [command]: response.data.data.records } : response.data.data.records;

          dispatch({
            type: home.GET_SAVED,
            payload: {
              list: payload,
              count: response.data.data.counting
            }
          });
        })
        .catch(() => {
          dispatch({
            type: home.GET_SAVED_FAIL
          });
        });
    };
  },

  getMyFeed(command = "all", search) {
    return dispatch => {
      dispatch(this.setLoading("feed", true));

      axios({
        method: "get",
        url: `/members/api-v2/my-feed?command=${command}&expand=lawfirms,practices,parent_company${
          search ? `&filters[0][field]=name&filters[0][value]=${search}` : ""
        }`
      })
        .then(response => {
          dispatch({
            type: home.GET_MY_FEED,
            payload: {
              list: response.data.data.records,
              count: response.data.data.counting
            }
          });
        })
        .catch(() => {
          dispatch({
            type: home.GET_MY_FEED_FAIL
          });
        });
    };
  },

  getFollowing() {
    return dispatch => {
      dispatch(this.setLoading("feed", true));

      axios({ method: "get", url: "/members/api-v2/user-following" })
        .then(response => {
          dispatch({
            type: home.GET_FOLLOWING,
            payload: response.data.data
          });
        })
        .catch(() => {
          dispatch({
            type: home.GET_FOLLOWING_FAIL
          });
        });
    };
  },

  removeFollowing(id) {
    return dispatch => {
      dispatch(this.setLoading("removeFollowing", true));

      axios({ method: "DELETE", url: `/members/api-v2/user-following?id=${id}` })
        .then(() => {
          dispatch({
            type: home.REMOVE_FOLLOWING,
            payload: id
          });
        })
        .catch(() => {
          dispatch({
            type: home.REMOVE_FOLLOWING_FAIL
          });
        });
    };
  },

  addToFavorite(listingId) {
    return dispatch => {
      dispatch(this.setLoading("page", true));

      axios({
        method: "post",
        responseType: "json",
        url: "/members/api-v2/bookmarks",
        params: {
          content_id: listingId,
          type: "listing"
        }
      })
        .then(() => {
          dispatch({
            type: home.ADD_FAVORITES,
            payload: listingId
          });
        })
        .catch(error => {
          dispatch({
            type: home.ADD_FAVORITES_FAIL,
            payload: error?.response?.data?.error
          });
        });
    };
  },

  removeFavorite(listingId) {
    return dispatch => {
      dispatch(this.setLoading("page", true));

      axios({
        method: "delete",
        responseType: "json",
        url: "/members/api-v2/bookmarks",
        params: {
          content_id: listingId,
          type: "listing"
        }
      })
        .then(() => {
          dispatch({
            type: home.REMOVE_FAVORITES,
            payload: listingId
          });
        })
        .catch(error => {
          dispatch({
            type: home.REMOVE_FAVORITES_FAIL,
            payload: error?.response?.data?.error
          });
        });
    };
  }
};
