import React from "react";

import { Select } from "../select";
import { AnswerCounter } from "../answerCounter";
import { Answers } from "../answers";
import styles from "./answersBox.module.css";

export const AnswersBox = ({ onChangeSelect, answersLength, answers }) => {
  if (!answersLength) {
    return null;
  }

  return (
    <div className={styles.answersBox}>
      <div className={styles.selectContainer}>
        {answersLength > 1 && <Select onChangeSelect={onChangeSelect} />}
        <AnswerCounter answersLength={answersLength} />
      </div>

      <Answers answers={answers} />
    </div>
  );
};
