import { ReactComponent as LawyerIcon } from "../media/icons/create-new-account-profession/directory-lawyer.svg";
import { ReactComponent as DoctorIcon } from "../media/icons/create-new-account-profession/directory-doctor.svg";
import { ReactComponent as NursesIcon } from "../media/icons/create-new-account-profession/directory-nurses.svg";
import { ReactComponent as TherapistIcon } from "../media/icons/create-new-account-profession/directory-therapist.svg";
import { ReactComponent as AccountIcon } from "../media/icons/create-new-account-profession/directory-account.svg";
import { ReactComponent as AgentIcon } from "../media/icons/create-new-account-profession/directory-agent.svg";
import { ReactComponent as BabysitterIcon } from "../media/icons/create-new-account-profession/directory-babysitters.svg";
import { ReactComponent as HousekeepersIcon } from "../media/icons/create-new-account-profession/directory-housekeepers.svg";
import { ReactComponent as TutorsIcon } from "../media/icons/create-new-account-profession/directory-tutors.svg";
import { ReactComponent as ArtistsIcon } from "../media/icons/create-new-account-profession/directory-artists.svg";
import { ReactComponent as DJsIcon } from "../media/icons/create-new-account-profession/directory-djs.svg";

export const professionNames = {
  lawyers: "lawyers",
  doctors: "doctors",
  nurses: "nurses",
  therapists: "therapists",
  agents: "agents",
  accountants: "accountants",
  djs: "djs",
  artists: "artists",
  tutors: "tutors",
  housekeepers: "housekeepers",
  babysitters: "babysitters"
};

export const images = {
  lawyers: LawyerIcon,
  doctors: DoctorIcon,
  nurses: NursesIcon,
  therapists: TherapistIcon,
  accountants: AccountIcon,
  agents: AgentIcon,
  babysitters: BabysitterIcon,
  housekeepers: HousekeepersIcon,
  tutors: TutorsIcon,
  artists: ArtistsIcon,
  djs: DJsIcon
};
