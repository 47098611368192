import React from "react";
import cn from "classnames";
import { useTranslation } from "react-i18next";

import { ReactComponent as GoToIcon } from "../../../../media/icons/goTo.svg";
import { statuses, redStatuses } from "../../../../constants/listings";
import styles from "./status.module.css";

export const Status = ({ status, listingUrl, isActiveStatus, isHomePage, handleRenew }) => {
  const { t } = useTranslation();
  const membershipNameActive = isActiveStatus ? styles.membershipNameActive : "";
  const redTextStatus = redStatuses.includes(status) ? styles.redStatus : "";
  const greyText = isHomePage ? styles.statusTexHomePage : "";
  const statusContainerHome = isHomePage ? styles.statusContainerHome : "";
  const isExpired = status === statuses.expired;

  return (
    <>
      <div className={cn(styles.statusContainer, statusContainerHome)}>
        <span className={cn(styles.statusText, greyText)}>{t("status")}: </span>
        {status === statuses.active ? (
          <a className={styles.membershipNameBlue} href={listingUrl} target="_blank" rel="noopener noreferrer">
            {status}
            <GoToIcon className={styles.icon} />
          </a>
        ) : (
          <>
            <div className={cn(styles.membershipName, membershipNameActive, redTextStatus)}>{status}</div>
            {isExpired && (
              <button type="button" className={cn("button--clear", styles.options)} onClick={handleRenew}>
                - {t("clicktorenew")}
              </button>
            )}
          </>
        )}
      </div>
    </>
  );
};
