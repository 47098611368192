import React, { useEffect, useRef, useMemo, useState } from "react";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import format from "date-fns/format";
// eslint-disable-next-line
import { useTimezoneSelect, allTimezones } from "react-timezone-select";

import Dropdown from "../dropdown";
import Input from "../input";
import Select from "../select";
import { customStyles as selectStyles } from "../../../constants/select";
import DatePickerInput from "../../common/datePickerInput";
import { isLastDayOfMonth, getOccurrenceOfMonth } from "../../../helpers";
import { daysWithNumbers, daysOfWeek } from "../../../constants/listingDetail";
import Checkbox from "../../common/checkbox";
import TimePickerInput from "../../common/timePickerInput";
import { Radio } from "../../common/radio";
import CalendarWithInputMask from "../calendarWithInputMask";
import styles from "./eventsSchedules.module.css";
import { useValidationError } from "../../../helpers/hooks";

const dayOptions = [
  { id: "doesnt_repeat", title: "Does not repeat" },
  { id: "custom", title: "Custom" }
];

const repeatOptions = [
  { id: "day", title: "Day" },
  { id: "week", title: "Week" },
  { id: "month", title: "Month" },
  { id: "year", title: "Year" }
];

const repeatOnOptions = [
  { id: "1", title: "S" },
  { id: "2", title: "M" },
  { id: "3", title: "T" },
  { id: "4", title: "W" },
  { id: "5", title: "T" },
  { id: "6", title: "F" },
  { id: "7", title: "S" }
];

const EventsSchedules = ({ validation, selectedEvents, submitChanges, handleRemove, showRequiredText, isHideRepeat }) => {
  if (Object.keys(validation).length && !validation.enabled) {
    return null;
  }

  const refButton = useRef();
  const isRequired = Object.keys(validation).length ? validation.required : true;
  const isRequiredText = validation.required && !selectedEvents?.length && showRequiredText;
  const [selectedList, setSelectedList] = useState([]);
  const [mainErrors, setMainErrors] = useState({});
  const [isEdited, setIsEdited] = useState(false);

  const selectedEventsList = !selectedEvents.length
    ? [
        {
          start_date_time: new Date(),
          end_date_time: new Date(),
          timezone: "",
          allday: 0,
          is_repeat: 0,
          repeat_count: 0,
          repeat_period: "",
          repeat_days: "",
          repeat_end_type: null,
          repeat_end_date: new Date(),
          repeat_end_count: 0,
          activeRadio: "",
          repeatValue: "",
          repeat_month_type: "",
          prkey: "",
          isNew: true
        }
      ]
    : selectedEvents;

  useEffect(() => {
    if (!selectedList.length) {
      setSelectedList(selectedEventsList);
    } else {
      const newList = selectedList.map((item, index) => {
        return selectedEvents[index] ? selectedEvents[index] : item;
      });
      setSelectedList(newList);
    }
  }, [selectedEvents.length]);

  const onSubmit = list => {
    if (!Object.values(mainErrors).filter(item => Boolean(item)).length) {
      const updatedList = list
        .map(item => {
          const { isNew, ...rest } = item;
          return { ...rest };
        })
        .filter(item => Object.values(item).some(listItem => listItem));

      submitChanges({ event_schedules: updatedList }, "event_schedules", "save_event_schedule");
      setIsEdited(false);
    }
  };

  const handleSubmit = (data, isNew, index) => {
    if (isNew) {
      submitChanges(
        {
          event_schedules: [...selectedEvents, data]
        },
        "event_schedules",
        "save_event_schedule"
      );
      onSubmit([...selectedEvents, data]);
    } else {
      const newEvents = selectedEvents.map((item, currentIndex) => {
        if (String(currentIndex) === String(index)) {
          return {
            ...data
          };
        }
        return {
          ...item
        };
      });

      setSelectedList(newEvents);
      onSubmit(newEvents);
    }
  };

  const title = "Events Schedule";
  return (
    <Dropdown description={validation.custom_description} isRequired={isRequired} title={title} isRequiredText={isRequiredText}>
      <div>
        {selectedList.map((item, index) => {
          return (
            <EventsSchedulesItem
              // eslint-disable-next-line react/no-array-index-key
              key={`${item.percentage}-events-schedule-${item.radius}-index-${index}`}
              handleSubmit={handleSubmit}
              handleRemove={handleRemove}
              selected={item}
              index={index}
              isRequired={isRequired}
              refButton={refButton}
              setIsEdited={setIsEdited}
              isEdited={isEdited}
              setMainErrors={setMainErrors}
              mainErrors={mainErrors}
              isHideRepeat={isHideRepeat}
            />
          );
        })}
      </div>
    </Dropdown>
  );
};

const EventsSchedulesItem = ({
  selected,
  handleSubmit: onSubmit,
  index,
  isRequired,
  refButton,
  isEdited,
  setIsEdited,
  setMainErrors,
  mainErrors,
  isHideRepeat
}) => {
  const { isNew } = selected;
  const { t } = useTranslation();
  const [isMouseInBlock, setIsMouseInBlock] = useState(false);
  const containerRef = useRef();
  const [isTouched, setIsTouched] = useState(false);

  const { options } = useTimezoneSelect({ labelStyle: "abbrev", timezones: allTimezones });

  const timeZones = options.map(item => ({
    id: item.value,
    title: item.label
  }));

  const handleTouchStart = () => {
    if (!isTouched) {
      setIsTouched(true);
    }
  };

  const validate = values => {
    const {
      start_date_time,
      end_date_time,
      timezone,
      is_repeat,
      repeat_count,
      repeat_period,
      repeat_days,
      repeat_end_type,
      activeRadio,
      repeat_end_date,
      repeat_end_count,
      repeat_month_type
    } = values;
    const errors = {};

    const requiredText = t("dashboard_common_required");

    if (!end_date_time) {
      errors.end_date_time = requiredText;
    } else if (end_date_time === "Invalid date") {
      errors.end_date_time = "Invalid date format";
    }

    if (!start_date_time) {
      errors.start_date_time = requiredText;
    } else if (end_date_time === "Invalid date") {
      errors.start_date_time = "Invalid date format";
    }

    if (!timezone) {
      errors.timezone = requiredText;
    }

    if (is_repeat && !isHideRepeat) {
      if (!repeat_count) {
        errors.repeat_count = requiredText;
      }

      if (repeat_period && repeat_period === "week" && (!repeat_days || repeat_days?.length === 1)) {
        errors.repeat_period = requiredText;
      }

      if (repeat_period && repeat_period === "month" && !repeat_month_type) {
        errors.repeat_month_type = requiredText;
      }

      if (!repeat_period) {
        errors.repeat_period = requiredText;
      }

      if (!repeat_end_type) {
        errors.repeat_end_type = requiredText;
      }

      if (repeat_end_type && activeRadio === "repeat_end_type_count" && !repeat_end_count) {
        errors.repeat_end_count = requiredText;
      }

      if (repeat_end_type && activeRadio === "repeat_end_type_date" && (!repeat_end_date || repeat_end_date === "0000-00-00 00:00:00")) {
        errors.repeat_end_date = requiredText;
      }
    }

    const mainErrorsValue = mainErrors;
    mainErrorsValue[index] = Object.keys(errors).length ? errors : null;
    setMainErrors(mainErrorsValue);

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      ...selected,
      activeRadio: selected.repeat_end_type && selected.repeat_end_type === "count" ? "repeat_end_type_count" : "repeat_end_type_date",
      repeat_days: selected.repeat_days.split(","),
      start_date_time: selected.start_date_time === "0000-00-00 00:00:00" ? new Date() : selected.start_date_time,
      end_date_time: selected.end_date_time === "0000-00-00 00:00:00" ? new Date() : selected.end_date_time,
      isEdited: false
    },
    onSubmit: values => {
      const startDateTime = format(new Date(values.start_date_time), "yyyy-MM-dd HH:mm:ss");
      const endDateTime = format(new Date(values.end_date_time), "yyyy-MM-dd HH:mm:ss");

      const normalizedData = {
        start_date_time: startDateTime,
        end_date_time: endDateTime,
        repeat_month_type: values.repeat_month_type,
        timezone: values.timezone ? timeZones.find(item => item.id === values.timezone || item.title === values.timezone)?.id : "",
        allday: values.allday,
        is_repeat: values.repeatValue && values.repeatValue.id === "doesnt_repeat" ? 0 : 1,
        repeat_count: values.repeat_count,
        repeat_period: values.repeat_period,
        repeat_days: values.repeat_days && Array.isArray(values.repeat_days) ? values.repeat_days.join(",") : values.repeat_days,
        repeat_end_type: values.activeRadio && values.activeRadio === "repeat_end_type_count" ? "count" : "date",
        repeat_end_date: values.repeat_end_date,
        repeat_end_count: values.repeat_end_count,
        prkey: values.prkey
      };

      return onSubmit(normalizedData, isNew, index);
    },
    validate
  });

  const { values, setFieldValue, setFieldTouched, handleSubmit, errors, touched, setErrors } = formik;

  const handleChange = e => {
    setFieldValue(e.target.name, e.target.value);

    if (!values.isEdited) {
      setFieldValue("isEdited", true);
    }

    if (!isEdited) {
      setIsEdited(true);
    }
  };

  const handleStartDateError = reason => {
    if (reason && errors.start_date_time !== reason) {
      formik.setFieldError("start_date_time", reason);

      if (!values.isEdited) {
        setFieldValue("isEdited", true);
      }

      if (!isEdited) {
        setIsEdited(true);
      }
    }
  };

  const handleRepeatEndDateError = reason => {
    if (reason && errors.repeat_end_count !== reason) {
      formik.setFieldError("repeat_end_count", reason);

      if (!values.isEdited) {
        setFieldValue("isEdited", true);
      }

      if (!isEdited) {
        setIsEdited(true);
      }
    }
  };

  const handleChangeRadio = event => {
    formik.setFieldValue("activeRadio", event.target.name);

    if (values.repeat_end_type !== event.target.name) {
      formik.setFieldValue("repeat_end_type", event.target.name === "repeat_end_type_count" ? "count" : "date");
    }

    if (!values.isEdited) {
      setFieldValue("isEdited", true);
    }

    if (!isEdited) {
      setIsEdited(true);
    }
  };

  const handleBlur = fieldName => {
    setFieldTouched(fieldName, true);
  };

  const onHandleSubmit = () => {
    if (
      (values.start_date_time ||
        values.end_date_time ||
        values.timezone ||
        values.allday ||
        values.is_repeat ||
        values.repeat_count ||
        values.repeat_days ||
        values.repeat_days.length ||
        values.repeat_end_type ||
        values.repeat_end_date ||
        values.repeat_end_count ||
        values.repeat_period) &&
      values.isEdited
    ) {
      handleSubmit();
    }
  };

  const handleChangeStartDate = date => {
    /*
    if (values.end_date_time && date && new Date(date) > new Date(values.end_date_time)) {
      // setErrors({ start_date_time: "Start time cannot be after end time" });
      formik.setFieldValue("start_date_time", date);
      formik.setFieldValue("end_date_time", date);
    } else {
    */

    setFieldValue("start_date_time", date);
    setFieldValue("end_date_time", date);

    if (!values.isEdited) {
      setFieldValue("isEdited", true);
    }

    if (!isEdited) {
      setIsEdited(true);
    }
    // }
  };

  const handleChangeInputStartDate = value => {
    if (!values.isEdited) {
      setFieldValue("isEdited", true);
    }
    if (!isEdited) {
      setIsEdited(true);
    }

    setFieldValue("start_date_time", value);
  };

  const handleChangeInputEndDate = value => {
    if (!values.isEdited) {
      setFieldValue("isEdited", true);
    }
    if (!isEdited) {
      setIsEdited(true);
    }

    setFieldValue("end_date_time", value);
  };

  const handleChangeEndDate = date => {
    if (values.start_date_time && date && new Date(date) < new Date(values.start_date_time)) {
      setErrors({ end_date_time: "End time cannot be before start time." });
    } else {
      formik.setFieldValue("end_date_time", date);
      if (!values.isEdited) {
        setFieldValue("isEdited", true);
      }

      if (!isEdited) {
        setIsEdited(true);
      }
    }
  };

  const handleChangeRepeatEnd = date => {
    formik.setFieldValue("repeat_end_date", date);

    if (!values.isEdited) {
      setFieldValue("isEdited", true);
    }

    if (!isEdited) {
      setIsEdited(true);
    }
  };

  const handleSelectChange = (newValue, actionMeta) => {
    if (actionMeta.name === "repeatValue") {
      setFieldValue("is_repeat", newValue.id === "doesnt_repeat" ? 0 : 1);
    }

    setFieldValue(actionMeta.name, newValue);

    if (!values.isEdited) {
      setFieldValue("isEdited", true);
    }

    if (!isEdited) {
      setIsEdited(true);
    }
  };

  const handleSelectChangeRepeat = (newValue, actionMeta) => {
    if (!values.isEdited) {
      setFieldValue("isEdited", true);
    }

    setFieldValue(actionMeta.name, newValue.id);
  };

  const handleSelectChangeTimeZone = (newValue, actionMeta) => {
    setFieldValue(actionMeta.name, newValue.title);

    if (!values.isEdited) {
      setFieldValue("isEdited", true);
    }

    if (!isEdited) {
      setIsEdited(true);
    }
  };

  const handleChangeAllDay = () => {
    setFieldValue("allday", String(values.allday) === "1" ? 0 : 1);

    if (!values.isEdited) {
      setFieldValue("isEdited", true);
    }

    if (!isEdited) {
      setIsEdited(true);
    }
  };

  const formatPracticeOption = ({ title, bold }) => <div>{bold ? <strong>{title}</strong> : title}</div>;

  const toggleActiveDay = day => {
    const newActiveDays = values.repeat_days.includes(day) ? values.repeat_days.filter(item => item !== day) : [...values.repeat_days, day];

    setFieldValue("repeat_days", newActiveDays);

    if (!values.isEdited) {
      setFieldValue("isEdited", true);
    }

    if (!isEdited) {
      setIsEdited(true);
    }
  };

  const repeatMonthOptions = useMemo(() => {
    const startDate = new Date(values.start_date_time);
    const getStartDay = new Date(values.start_date_time).getDay();
    const nameOfWeek = daysOfWeek[getStartDay];
    const dayOfMonth = startDate.getDate();

    const occurrenceOfMonth = getOccurrenceOfMonth(values.start_date_time);
    const secondValueGetDate = daysWithNumbers[occurrenceOfMonth] || "";

    const firstOptions = `Monthly on day ${dayOfMonth}`;
    const secondOption = `Monthly on the ${secondValueGetDate.toLowerCase()} ${nameOfWeek}`;
    const thirdOptions = isLastDayOfMonth(values.start_date_time, dayOfMonth) ? "Monthly on the last day of the month" : null;

    return [
      { id: "0", title: firstOptions },
      { id: "1", title: secondOption },
      isLastDayOfMonth(values.start_date_time, dayOfMonth) ? { id: "2", title: thirdOptions } : null
    ];
  }, [values.start_date_time]);

  const handleMouseLeave = () => {
    if (isMouseInBlock) {
      setIsMouseInBlock(false);
      if (
        (values.start_date_time ||
          values.end_date_time ||
          values.timezone ||
          values.allday ||
          values.is_repeat ||
          values.repeat_days.length) &&
        values.isEdited &&
        !Object.values(errors).some(item => item)
      ) {
        handleSubmit();
        setIsEdited(false);
        setFieldValue("isEdited", false);
      }
    }
  };

  const handleMouseMove = () => {
    if (!isMouseInBlock) {
      setIsMouseInBlock(true);
    }
  };

  useEffect(() => {
    if (values.is_repeat) {
      setFieldValue("repeatValue", dayOptions[1]);
    }
  }, []);

  useEffect(() => {
    const handleTouch = event => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        if (isTouched) {
          setIsTouched(false);

          if (
            (values.start_date_time ||
              values.end_date_time ||
              values.timezone ||
              values.allday ||
              values.is_repeat ||
              values.repeat_days.length) &&
            values.isEdited &&
            !Object.values(errors).some(item => item)
          ) {
            handleSubmit();
            setIsEdited(false);
            setFieldValue("isEdited", false);
          }
        }
      }
    };

    document.addEventListener("touchstart", handleTouch, true);

    return () => {
      document.removeEventListener("touchstart", handleTouch, true);
    };
  }, [isEdited, values.start_date_time, values.end_date_time, values.timezone, values.allday, values.is_repeat, values.repeat_days.length]);

  useValidationError(Object.values(mainErrors).some(item => Boolean(item)) ? { eventsSchedules: "Error" } : "isRemove", "eventsSchedules");

  return (
    <div
      ref={containerRef}
      className={styles.item}
      onMouseLeave={handleMouseLeave}
      onMouseMove={handleMouseMove}
      onTouchStart={handleTouchStart}
    >
      <span className={styles.label}>Date</span>
      <div className={styles.itemFlex}>
        <div className={styles.dateContent}>
          <CalendarWithInputMask
            error={errors.start_date_time}
            isInvalid={errors.start_date_time}
            value={
              values.start_date_time && values.start_date_time !== "0000-00-00 00:00:00" ? new Date(values.start_date_time) : new Date()
            }
            onChange={date => handleChangeStartDate(date)}
            handleChangeInput={handleChangeInputStartDate}
            placeholder={t("acquired")}
            defaultMaxDate={null}
            minDate={new Date()}
            name="start_date_time"
            id="start_date_time"
          />

          {!values.allday && (
            <TimePickerInput
              isInvalid={errors.start_date_time && touched.start_date_time}
              name="start_date_time"
              id="start_date_time"
              value={values.start_date_time ? new Date(values.start_date_time) : null}
              onBlur={() => handleBlur("start_date_time")}
              onChange={date => handleChangeStartDate(date)}
              onError={handleStartDateError}
              views={["day"]}
              classNameWrap={styles.dateFullDate}
              classNameError={styles.errorText}
              isDisableMaxDay
              isGrey
            />
          )}
        </div>

        <span className={styles.startTo}>to</span>

        <div className={styles.dateContent}>
          <CalendarWithInputMask
            error={errors.end_date_time}
            isInvalid={errors.end_date_time}
            value={values.end_date_time && values.end_date_time !== "0000-00-00 00:00:00" ? new Date(values.end_date_time) : new Date()}
            onChange={date => handleChangeEndDate(date)}
            handleChangeInput={handleChangeInputEndDate}
            // required={isNotRequired}
            placeholder={t("acquired")}
            defaultMaxDate={null}
            minDate={new Date()}
            name="end_date_time"
            id="end_date_time"
          />

          {!values.allday && (
            <TimePickerInput
              isInvalid={errors.end_date_time && touched.end_date_time}
              name="end_date_time"
              id="end_date_time"
              value={values.end_date_time ? new Date(values.end_date_time) : null}
              onBlur={() => handleBlur("end_date_time")}
              onChange={date => handleChangeEndDate(date)}
              onError={handleRepeatEndDateError}
              views={["day"]}
              classNameWrap={styles.dateFullDate}
              classNameError={styles.errorText}
              isDisableMaxDay
              isGrey
            />
          )}
        </div>

        <Select
          error={errors.timezone}
          isInvalid={errors.timezone}
          getOptionValue={option => option.id}
          getOptionLabel={option => option.title}
          inputId="timezone"
          name="timezone"
          options={timeZones}
          onBlur={() => handleBlur("timezone")}
          handleChange={handleSelectChangeTimeZone}
          styles={selectStyles}
          required={isRequired}
          value={values.timezone ? timeZones.find(item => item.id === values.timezone) : []}
          placeholder="GMT-04:00) Eastern Time - New York"
          classNameWrap={styles.dateTimeZone}
          classNameError={styles.errorText}
        />
      </div>

      <div className={styles.dateAllDay}>
        <Checkbox isNewStyles checked={values.allday} className={styles.checkbox} id="allday" name="allday" onChange={handleChangeAllDay} />
        All day
      </div>

      {!isHideRepeat && (
        <>
          <Select
            error={errors.repeatValue}
            isInvalid={errors.repeatValue}
            getOptionValue={option => option.id}
            getOptionLabel={option => option.title}
            formatOptionLabel={formatPracticeOption}
            inputId="repeatValue"
            name="repeatValue"
            options={dayOptions}
            onBlur={() => handleBlur("repeatValue")}
            handleChange={handleSelectChange}
            styles={selectStyles}
            value={values.repeatValue}
            placeholder="Does not repeat"
            classNameWrap={styles.dateCustom}
          />

          {values.repeatValue && values.repeatValue.id !== "doesnt_repeat" && (
            <>
              <span className={styles.label}>Repeat every</span>

              <div className={styles.repeat}>
                <Input
                  className={styles.repeatInput}
                  classNameWrap={styles.dateInputWrap}
                  error={errors.repeat_count}
                  id="repeat_count"
                  isInvalid={errors.repeat_count}
                  name="repeat_count"
                  onBlur={() => handleBlur("repeat_count")}
                  onChange={handleChange}
                  value={values.repeat_count}
                  errorClassName={styles.errorText}
                  placeholder="0"
                />
                <Select
                  error={errors.repeat_period}
                  isInvalid={errors.repeat_period && touched.repeat_period}
                  getOptionValue={option => option.id}
                  getOptionLabel={option => option.title}
                  formatOptionLabel={formatPracticeOption}
                  inputId="repeat_period"
                  name="repeat_period"
                  options={repeatOptions}
                  onBlur={() => handleBlur("repeat_period")}
                  handleChange={handleSelectChangeRepeat}
                  styles={selectStyles}
                  value={values.repeat_period ? repeatOptions.find(item => item.id === values.repeat_period) : []}
                  placeholder="Day"
                  classNameWrap={styles.repeatPeriod}
                  classNameError={styles.errorText}
                />
              </div>
              {values.repeat_period === "month" && (
                <>
                  <span className={styles.label}>Repeat on</span>
                  <Select
                    error={errors.repeat_month_type}
                    isInvalid={errors.repeat_month_type && touched.repeat_month_type}
                    getOptionValue={option => option.id}
                    getOptionLabel={option => option.title}
                    inputId="repeat_month_type"
                    name="repeat_month_type"
                    options={repeatMonthOptions.filter(Boolean)}
                    onBlur={() => handleBlur("repeat_month_type")}
                    handleChange={handleSelectChangeRepeat}
                    styles={selectStyles}
                    value={
                      values.repeat_month_type
                        ? repeatMonthOptions.filter(Boolean).find(item => String(item.id) === String(values.repeat_month_type))
                        : []
                    }
                    placeholder="Day"
                    classNameWrap={styles.repeatMonth}
                  />
                </>
              )}

              {values.repeat_period === "week" && (
                <>
                  <span className={styles.label}>Repeat on</span>
                  <div className={styles.repeatOn}>
                    {repeatOnOptions.map(item => {
                      return (
                        <button
                          type="button"
                          onClick={() => toggleActiveDay(item.id)}
                          className={cn(styles.repeatOnItem, values.repeat_days.includes(item.id) ? styles.repeatOnItemActive : "")}
                          key={item.id}
                          name="repeat_days"
                        >
                          {item.title}
                        </button>
                      );
                    })}
                  </div>
                </>
              )}

              <span className={styles.label}>Ends</span>

              <div className={styles.endsContent}>
                <Radio
                  className={cn("button--clear", styles.endsRadio)}
                  iconClass={styles.endsIcon}
                  name="repeat_end_type_date"
                  checked={values.activeRadio === "repeat_end_type_date"}
                  onChange={handleChangeRadio}
                >
                  On
                </Radio>
                <DatePickerInput
                  format="MM/dd/yyyy"
                  error={errors.repeat_end_date}
                  isInvalid={errors.repeat_end_date && touched.repeat_end_date}
                  name="repeat_end_date"
                  id="repeat_end_date"
                  value={values.repeat_end_date ? new Date(values.repeat_end_date) : null}
                  onBlur={() => handleBlur("repeat_end_date")}
                  onChange={date => handleChangeRepeatEnd(date)}
                  onError={handleRepeatEndDateError}
                  views={["day"]}
                  classNameWrap={styles.dateFullDate}
                  classNameError={styles.errorText}
                  isDisableMaxDay
                  isGrey
                />
              </div>

              <div className={styles.endsContent}>
                <Radio
                  className={cn("button--clear", styles.endsRadio)}
                  iconClass={styles.endsIcon}
                  name="repeat_end_type_count"
                  checked={values.activeRadio === "repeat_end_type_count"}
                  onChange={handleChangeRadio}
                >
                  After
                </Radio>
                <Input
                  className={styles.endsInput}
                  error={errors.repeat_end_count}
                  id="repeat_end_count"
                  isInvalid={errors.repeat_end_count && touched.repeat_end_count}
                  name="repeat_end_count"
                  onBlur={() => handleBlur("repeat_end_count")}
                  onChange={handleChange}
                  value={values.repeat_end_count}
                  errorClassName={styles.errorText}
                  placeholder="0"
                />
                <span className={styles.endsText}>occurrences</span>
              </div>
            </>
          )}
        </>
      )}
      <button ref={refButton} type="button" className={styles.hidden} onClick={onHandleSubmit} />
    </div>
  );
};

export default EventsSchedules;
