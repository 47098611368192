import React from "react";
import cn from "classnames";

import styles from "./switch.module.css";

export const Switch = ({ activeAll, className, handleSwitch, selected, red, value, disabled }) => {
  const active = red ? styles.switchActiveRed : styles.switchActive;
  const isActive = selected ? active : null;

  return (
    <button
      type="button"
      value={value}
      className={cn(styles.switch, activeAll ? styles.switchBlue : styles.switchWhite, "button--clear", className, isActive)}
      onClick={() => {
        handleSwitch();
      }}
      disabled={disabled}
    >
      <span className={styles.switchCircle} />
    </button>
  );
};
