import React, { useEffect } from "react";

import styles from "./video.module.css";

const Video = ({ videoId }) => {
  const onYouTubeIframeAPIReady = () => {
    // eslint-disable-next-line no-new
    new window.YT.Player("player", {
      height: "360",
      width: "640",
      videoId,
      playerVars: {
        autoplay: 0,
        controls: 1
      },
      events: {
        onReady: onPlayerReady
      }
    });
  };

  useEffect(() => {
    onYouTubeIframeAPIReady();
  }, [videoId]);

  const onPlayerReady = event => {
    event.target.playVideo();
  };

  return (
    <div className={styles.content}>
      <h3 className={styles.title}>Video about Company</h3>

      <div className={styles.video} id="player" />
    </div>
  );
};

export default Video;
