import React from "react";

import styles from "./tags.module.css";

const Tags = ({ tags, title }) => {
  if (!tags.length) {
    return null;
  }

  return (
    <div className={styles.content}>
      <h3 className={styles.title}>{title}</h3>
      <div className={styles.tags}>
        {tags.map(item => (
          <span key={item} className={styles.tag}>
            {item}
          </span>
        ))}
      </div>
    </div>
  );
};

export default Tags;
