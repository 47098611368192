import React, { useCallback, useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import { useHistory } from "react-router-dom";
import cn from "classnames";
import { useTranslation } from "react-i18next";

import { actions } from "../../actions/messages";
import { TABS } from "../../constants/messages";
import { Notification } from "../common/notification";
import { OverlayPreloader } from "../common/preloader";
import MessagesList from "./components/messagesList";
import Filtration from "./components/filtration";
import MessagesContent from "./components/messagesContent";
import MessageDetail from "./components/messageDetail";
// import Menu from "./components/menu";
import FilterMessage from "./components/filterMessage";
import styles from "./messages.module.css";
import { useDocTitle } from "../../helpers/hooks";
import SelectTeam from "../selectTeam";

const Messages = () => {
  const { t } = useTranslation();

  const [isFirstLoading, setIsFirstLoading] = useState(true);
  const [filteredMessages, setFilteredMessages] = useState([]);
  const dispatch = useDispatch();
  const setActivityMenu = useCallback(isActive => dispatch(actions.setActivityMenu(isActive)), [dispatch]);
  const setActiveBlocks = useCallback(blocks => dispatch(actions.setActiveBlocks(blocks)), [dispatch]);
  const disableSuccess = useCallback(() => dispatch(actions.disableSuccess()), [dispatch]);
  const sendMessage = useCallback((messageData, team) => dispatch(actions.sendMessage(messageData, team)), [dispatch]);
  const getMessages = useCallback(
    (messageId, isArchive, team, isUnreadMessages, sort) =>
      dispatch(actions.getMessages(messageId, isArchive, team, isUnreadMessages, sort)),
    [dispatch]
  );
  const unreadMessage = useCallback((emailConversationId, team) => dispatch(actions.unreadMessage(emailConversationId, team)), [dispatch]);
  const archiveMessage = useCallback((emailConversationId, messageId) => dispatch(actions.archiveMessage(emailConversationId, messageId)), [
    dispatch
  ]);
  const unArchiveMessage = useCallback(
    (emailConversationId, messageId) => dispatch(actions.unArchiveMessage(emailConversationId, messageId)),
    [dispatch]
  );
  const setActivityFilter = useCallback(emailConversationId => dispatch(actions.setActivityFilter(emailConversationId)), [dispatch]);
  const clearMessageDetail = useCallback(() => dispatch(actions.clearMessageDetail()), [dispatch]);
  const setFilter = useCallback(filterData => dispatch(actions.setFilter(filterData)), [dispatch]);
  const getMessageDetail = useCallback(
    (listingMessageId, emailConversationId, isNotRead, team) =>
      dispatch(actions.getMessageDetail(listingMessageId, emailConversationId, isNotRead, team)),
    [dispatch]
  );
  const { user } = useSelector(state => state.auth);
  const {
    messages,
    messagesStateCount,
    preloaders,
    error,
    messageDetail,
    messageDetailMessages,
    activityBlocks,
    success,
    isActiveMenu,
    isActiveFilterModal,
    activeListings,
    filter
  } = useSelector(state => state.messages);
  const isClient = user?.user_id && String(messageDetail?.user_id) === String(user?.user_id);
  const { importReady = {} } = useSelector(state => state.referralRequests);
  const history = useHistory();
  const searchParams = queryString.parse(history.location.search);
  const isEmptyMessages = messages.length === 0;
  const activeSearchMessageId = searchParams.messageId;
  const findActiveMessage = useMemo(() => {
    return messages.find(item => String(item.listing_message_id) === String(activeSearchMessageId));
  }, [activeSearchMessageId]);

  const leftSideDisabled = !activityBlocks.messages ? styles.leftSideDisabled : "";
  const leftSideDisabledTablet = !activityBlocks.messages && activityBlocks.detail ? styles.leftSideDisabledTablet : "";
  const isSuccess = Object.values(success).some(item => item);
  const [team, setTeam] = useState({ value: "", label: "Unselect " });
  const [sort, setSort] = useState("");

  const handleChangeUrl = activeMessageId => {
    history.push({
      pathname: "/inbox",
      search: queryString.stringify({ tab: searchParams.tab || "all", messageId: activeMessageId }),
      state: {
        update: true
      }
    });
  };

  const handleClearAll = () => {
    history.push({
      pathname: "/inbox",
      search: queryString.stringify({ tab: "all" }),
      state: {
        update: true
      }
    });

    getMessages(searchParams.messageId, false, team, false, "");
    setIsFirstLoading(true);
    setActivityFilter(false);
  };

  useDocTitle(t("dashboard_inbox_metatitle"));

  const handleUnread = () => {
    unreadMessage(findActiveMessage.email_conversation_id);
  };

  const handleArchive = () => {
    archiveMessage(findActiveMessage.email_conversation_id, activeSearchMessageId);
  };

  const handleUnnArchive = () => {
    unArchiveMessage(findActiveMessage.email_conversation_id, activeSearchMessageId);
  };

  useEffect(() => {
    getMessages(searchParams.messageId, searchParams.tab === TABS.ARCHIVED);
  }, [getMessages]); // eslint-disable-line

  useEffect(() => {
    if (messages?.length && messages) {
      setFilteredMessages(messages);
    }
  }, [messages?.length, sort, messages, messagesStateCount]); // eslint-disable-line

  useEffect(() => {
    if ((!Object.keys(searchParams).length || !findActiveMessage) && !isEmptyMessages && !activeSearchMessageId) {
      const activeMessageId = messages[0].listing_message_id;
      handleChangeUrl(activeMessageId);
    }
  }, [searchParams, messages, history, activeSearchMessageId]); // eslint-disable-line

  useEffect(() => {
    if (activeSearchMessageId && findActiveMessage?.email_conversation_id && Object.keys(messageDetail).length === 0 && isFirstLoading) {
      setIsFirstLoading(false);
      getMessageDetail(activeSearchMessageId, findActiveMessage.email_conversation_id, String(findActiveMessage.is_read) === "0", team);
    }
  }, [findActiveMessage?.email_conversation_id, messageDetail, activeSearchMessageId]); // eslint-disable-line

  const handleSelectMessageDetail = currentTeam => {
    setTeam(currentTeam);
    getMessages(searchParams.messageId, searchParams.tab === TABS.ARCHIVED, currentTeam);
  };

  const renderSuccessMessage = () => {
    if (success.unArchive) {
      return "Conversation Unarchived";
    }

    if (success.unread) {
      return "Conversation marked as unread";
    }

    if (success.archive) {
      return "Conversation archived";
    }
    return "";
  };

  const setFilterByNameAndText = name => {
    if (!isFirstLoading) {
      const messagesListFiltered = name.length
        ? messages.filter(
            item => item.name_full.toLowerCase().includes(name.toLowerCase()) || item.message.toLowerCase().includes(name.toLowerCase())
          )
        : messages;
      setFilteredMessages(messagesListFiltered);
    }
  };

  const handleFilter = filterData => {
    const filterListing = filterData.activeListings.length
      ? messages.filter(item => filterData.activeListings.includes(String(item.listing_id)))
      : messages;

    const normalizedMessages = filterListing.filter(item => String(item.is_read) === (filterData.isUnread ? "0" : "1"));

    setFilteredMessages(normalizedMessages);

    if (normalizedMessages.length) {
      const firstElement = normalizedMessages[0];
      getMessageDetail(
        firstElement.listing_message_id,
        firstElement.email_conversation_id,
        String(findActiveMessage?.is_read) === "0",
        team
      );
      handleChangeUrl(firstElement.listing_message_id);
    } else {
      clearMessageDetail();
    }
  };

  const isActiveReferralRequestsBanner =
    String(importReady.requests) !== "0" || (String(importReady.messages) !== "0" && String(importReady.questions) !== "0");

  return (
    <>
      {activityBlocks.detail && <OverlayPreloader spinnerClassName={styles.spinner} overlayClassName={styles.overlayMobile} />}

      <div className={cn(styles.wrapper, isActiveReferralRequestsBanner ? styles.wrapperReferral : "")}>
        {error && (
          <Notification error tyme="5000">
            {error}
          </Notification>
        )}

        {isSuccess && (
          <Notification success tyme="5000" close={disableSuccess}>
            {renderSuccessMessage()}
          </Notification>
        )}

        {preloaders.messages && <OverlayPreloader overlayClassName={styles.overlay} spinnerClassName={styles.spinnerClassName} />}

        <div className={styles.heading}>
          {/* <h1 className={styles.title}>{searchParams.tab === "archived" ? "Archived messages" : "All messages"}</h1> */}
          <h1 className={styles.title}>{t("dashboard_inbox_title")}</h1>
          <div>
            <SelectTeam
              className={styles.selectTeam}
              isShowText
              text={t("dashboard_inbox_team_title")}
              handleChange={handleSelectMessageDetail}
              unselectText="My Messages"
            />
          </div>
        </div>

        <div className={styles.content}>
          <div className={cn(styles.leftSide, leftSideDisabled, leftSideDisabledTablet)}>
            <Filtration
              toggleFilter={() => setActivityFilter(!isActiveFilterModal)}
              setActivityMenu={setActivityMenu}
              isArchived={searchParams.tab === TABS.ARCHIVED}
              setFilterByNameAndText={setFilterByNameAndText}
              isActiveFilterModal={isActiveFilterModal}
            />
            <MessagesList
              messages={filteredMessages}
              getMessageDetail={getMessageDetail}
              activeSearchMessageId={activeSearchMessageId}
              handleChangeUrl={handleChangeUrl}
              setActiveBlocks={setActiveBlocks}
              messagesLength={messages.length}
              team={team}
            />

            {isActiveFilterModal && (
              <FilterMessage
                filter={filter}
                setFilter={setFilter}
                onClose={() => setActivityFilter(false)}
                handleClearAll={handleClearAll}
                handleFilter={handleFilter}
                activeListings={activeListings}
                isActiveMenu={isActiveMenu}
                setIsFirstLoading={setIsFirstLoading}
                history={history}
                team={team}
                getMessages={getMessages}
                sort={sort}
                setSort={setSort}
              >
                {/*
                <Menu setIsFirstLoading={setIsFirstLoading} history={history} getMessages={getMessages} team={team} />
                */}
              </FilterMessage>
            )}
          </div>

          <MessagesContent
            messageDetail={messageDetail}
            messageDetailMessages={messageDetailMessages}
            preloaders={preloaders}
            sendMessage={sendMessage}
            isClient={isClient}
            setActiveBlocks={setActiveBlocks}
            activityBlocks={activityBlocks}
            handleUnread={handleUnread}
            handleArchive={handleArchive}
            handleUnnArchive={handleUnnArchive}
            isArchived={searchParams.tab === TABS.ARCHIVED}
            isActiveFilterModal={isActiveFilterModal}
            messagesLength={messages.length}
            team={team}
          />

          <MessageDetail
            messageDetail={messageDetail}
            preloaders={preloaders}
            setActiveBlocks={setActiveBlocks}
            activityBlocks={activityBlocks}
            isClient={isClient}
            messagesLengh={messages.length}
          />
        </div>
      </div>
    </>
  );
};

export default Messages;
