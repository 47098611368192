import { landing } from "../constants/actionTypes";

const defaultState = {
  countries: [],
  states: [],
  practices: [],
  cityStateZipList: [],
  professions: [],
  preloaders: {
    page: false
  },
  articles: [],
  events: [],
  freeListings: [],
  jobs: [],
  questions: [],
  sponsorListings: []
};

export default function(state = defaultState, action) {
  const { type, payload } = action;
  switch (type) {
    case landing.SET_LOADING:
      return {
        ...state,
        preloaders: { ...state.preloaders, [payload.section]: payload.isLoading }
      };

    case landing.GET_COUNTRIES:
      return {
        ...state,
        preloaders: { ...state.preloaders, page: false },
        countries: payload
      };

    case landing.GET_CITY_STATE:
      return {
        ...state,
        preloaders: { ...state.preloaders, page: false },
        cityStateZipList: payload
      };

    case landing.GET_CITY_STATE_FAIL:
      return {
        ...state,
        preloaders: { ...state.preloaders, page: false }
      };

    case landing.GET_PRACTICES:
      return {
        ...state,
        preloaders: { ...state.preloaders, page: false },
        practices: payload
      };

    case landing.GET_PRACTICES_FAIL:
      return {
        ...state,
        preloaders: { ...state.preloaders, page: false }
      };

    case landing.GET_PROFESSIONS:
      return {
        ...state,
        preloaders: { ...state.preloaders, page: false },
        professions: payload
      };

    case landing.GET_PROFESSIONS_FAIL:
      return {
        ...state,
        preloaders: { ...state.preloaders, page: false }
      };

    default:
      return {
        ...state
      };
  }
}
