export const modals = {
  matchesInfo: "matchesInfo",
  missingInfo: "missingInfo",
  question: "question",
  message: "message",
  successMessage: "successMessage",
  questionOpenToOther: "questionOpenToOther",
  contactInfo: "contactInfo"
};

export const statusesConstant = {
  missingInformation: "missingInformation",
  matches: "matches",
  match: "match",
  notMatch: "notMatch",
  reviewed: "reviewed",
  rejected: "rejected",
  pendingReview: "pendingReview",
  requestReceived: "pending"
};

export const statusesConstantKeys = {
  missingInformation: "dashboard_referrals_details_status_missinginformation",
  matches: "dashboard_referrals_details_status_morematches",
  match: "dashboard_referrals_details_status_1match",
  notMatch: "dashboard_referrals_details_status_notmatch",
  reviewed: "dashboard_referrals_details_status_reviewed",
  rejected: "dashboard_referrals_details_status_rejected",
  pendingReview: "dashboard_referrals_details_status_pending",
  requestReceived: "dashboard_referrals_details_status_received"
};
