import React from "react";
import { formatDistanceToNow, format } from "date-fns";

import styles from "./licenses.module.css";

const Licenses = ({ licenses }) => {
  return (
    <div className={styles.content}>
      <h3 className={styles.title}>Licenses</h3>
      <div>
        {licenses.map(item => {
          return (
            <div className={styles.tile} key={`${item.prkey}-${item.acquired_date}`}>
              <h6 className={styles.tileTitle}>
                {item.location_state_title}, {item.location_title}
              </h6>

              <p className={styles.tileDescription}>Licensed for {formatDistanceToNow(new Date(item.acquired_date))}</p>
              <p className={styles.tileYear}>Since {format(new Date(item.acquired_date), "MMM dd, yyyy")}</p>

              <div className={styles.footer}>
                {item.status && <p className={styles.tileStatus}>{item.status}</p>}

                {item.update_date && item.update_date !== "0000-00-00" ? (
                  <p className={styles.tileLastUpdated}>Last verified in {format(new Date(item.update_date), "MMM dd, yyyy")}</p>
                ) : null}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Licenses;
