import { listingDetail } from "../constants/actionTypes";

const defaultModals = {
  addCard: false
};

// todo remove
const defaultState = {
  preloaders: {
    page: false
  },
  modals: {
    ...defaultModals
  },
  listingDetail: {}
};

export default function(state = defaultState, action) {
  const { payload, type } = action;
  switch (type) {
    case listingDetail.SET_LOADING:
      return {
        ...state,
        preloaders: {
          ...state.preloaders,
          [payload.name]: payload.isLoading
        }
      };

    case listingDetail.GET_LISTING:
      return {
        ...state,
        listingDetail: payload.listingDetail,
        preloaders: {
          ...state.preloaders,
          page: false
        }
      };

    case listingDetail.TOGGLE_MODAL:
      return {
        ...state,
        modals: {
          ...state.modals,
          [payload.modal]: payload.isActive
        }
      };

    default:
      return {
        ...state
      };
  }
}
