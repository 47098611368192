import React from "react";

import { Modal } from "../../common/modal";
import ModalControl from "../../modalControl";
import { OverlayPreloader } from "../../common/preloader";
import modalStyles from "../../common/modal/modal.module.css";
import styles from "./deleteNotification.module.css";

export const DeleteNotification = ({
  closeModal,
  clearAllModals,
  preloaders,
  subtitle,
  title,
  text,
  value,
  keyValue,
  listing,
  listingType,
  listingName,
  handleConfirm
}) => {
  const handleCloseModal = () => {
    closeModal();
  };

  const onExit = () => {
    closeModal();
  };

  const onConfirm = () => {
    handleConfirm();
  };

  return (
    <Modal closeModal={handleCloseModal} clearAllModals={clearAllModals} className={styles.confirm}>
      {preloaders && (
        <OverlayPreloader overlayClassName="sectionOverlayModal zIndex4" spinnerClassName={`zIndex4 ${modalStyles.spinnerOverlay}`} />
      )}

      <h3 className={styles.subTitle}>{subtitle}</h3>
      <h1 className={styles.title}>{title}</h1>
      <p className={styles.text}>{text}</p>

      <p className={styles.value}>{value}</p>
      <p className={styles.key}>{keyValue}</p>

      <p className={styles.value}>{listing}</p>
      <p className={styles.listing}>{listingType}</p>
      <p className={styles.listingName}>{listingName}</p>

      <ModalControl isExit onExit={onExit} onConfirm={onConfirm}>
        Delete Notification
      </ModalControl>
    </Modal>
  );
};
