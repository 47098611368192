import React from "react";
import { useTranslation } from "react-i18next";
import cn from "classnames";

import { Button } from "../common/button";
import styles from "./modalControl.module.css";

const ModalControl = ({
  onExit,
  onConfirm,
  isExit,
  isClose,
  children,
  isHideExit,
  className,
  isExitReject,
  confirmClassName,
  isHideExitConfirm,
  confirmationType,
  confirmDisabled,
  exitDisabled,
  isAddHelpButton
}) => {
  const { t } = useTranslation();

  const renderExitButton = () => {
    if (isExit) {
      return t("dashboard_common_button_back");
    }

    if (isClose) {
      return t("close");
    }

    if (isExitReject) {
      return "Reject";
    }

    return t("dashboard_common_button_cancel");
  };

  const handleIntercom = () => {
    if (window && window.Intercom) {
      window.Intercom("show");
    }
  };

  return (
    <div className={cn(styles.modalControl, className)}>
      {isAddHelpButton && (
        <Button onClick={handleIntercom} className={styles.exitButton}>
          Help
        </Button>
      )}

      {!isHideExit && (
        <Button onClick={onExit} className={styles.exitButton} disabled={exitDisabled}>
          {renderExitButton()}
        </Button>
      )}
      {!isHideExitConfirm && (
        <Button
          onClick={onConfirm}
          className={cn(styles.confirmButton, confirmClassName)}
          type={confirmationType}
          disabled={confirmDisabled}
        >
          {children}
        </Button>
      )}
    </div>
  );
};

export default ModalControl;
