import React, { useState } from "react";
import cn from "classnames";

import { Preloader } from "../../../common/preloader";
import { Button } from "../../../common/button";
import sendIcon from "../../../../media/icons/send-message.svg";
import styles from "./messageInput.module.css";

const MessageInput = ({ handleSend, preloaders, isActiveFilterModal }) => {
  const [message, setMessage] = useState("");
  const isSomePreloader = Object.values(preloaders).some(item => item);
  const messageInputLoading = isSomePreloader || isActiveFilterModal ? styles.messageInputLoading : "";
  const isSendMessagePreloader = preloaders.sendMessage;
  const disableInput = isSendMessagePreloader ? styles.inputFieldDisable : "";
  const disableSendButton = message === "";

  const onSendMessage = () => {
    if (message && !isSendMessagePreloader) {
      handleSend(message);
      setMessage("");
    }
  };

  const onEnterPress = e => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      onSendMessage();
    }
  };

  const handleChangeText = text => {
    if (!isSomePreloader) {
      setMessage(text);
    }
  };

  return (
    <div className={cn(styles.messageInput, messageInputLoading)}>
      <input
        placeholder="Start typing your message here..."
        className={cn(styles.inputField, disableInput)}
        value={message}
        onChange={e => handleChangeText(e.target.value)}
        onKeyDown={onEnterPress}
      />

      <Button className={cn(styles.sendBtn, "button--clear")} disabled={disableSendButton} onClick={onSendMessage}>
        {isSendMessagePreloader ? (
          <Preloader className={styles.preloader} />
        ) : (
          <img src={sendIcon} alt="send" className={styles.sendIcon} />
        )}
      </Button>
    </div>
  );
};

export default MessageInput;
