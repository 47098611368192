import React from "react";

import { ReactComponent as PinIcon } from "../../../media/icons/pin.svg";
import styles from "./questionsAndAnswers.module.css";

const QuestionsAndAnswers = ({ questions }) => {
  const renderQuestions = questions.map(item => {
    const { city, state, title, description, url, practice1, practice2, practice3 } = item;

    return (
      <div className={styles.item} key={item.question_id}>
        <a rel="noopener noreferrer" href={url} target="_blank" className={styles.itemName}>
          {title}
        </a>
        <p className={styles.itemDescription}>{description}</p>
        <div className={styles.itemPractices}>
          <span className={styles.itemPracticesItem}>{`${practice1}${practice2 ? `, ${practice2}` : ""} ${
            practice3 ? `and ${practice3}` : ""
          }`}</span>
        </div>
        <span className={styles.itemAddress}>
          <PinIcon />
          {city ? `${city}, ` : ""}
          {state}
        </span>
      </div>
    );
  });

  return (
    <div className={styles.content}>
      <h4 className={styles.title}>Latest Q&A’s</h4>
      <div className={styles.questions}>{renderQuestions}</div>

      <a href="/" className={styles.blue}>
        Ask Your Question for Free
      </a>
      <a href="/" className={styles.bordered}>
        Read More Q&A’s
      </a>
    </div>
  );
};

export default QuestionsAndAnswers;
