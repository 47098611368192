import React from "react";
import cn from "classnames";

import styles from "./button.module.css";

export const Button = ({ className, clearStyles, children, form, onClick, type = "button", disabled = false }) => {
  return (
    <button
      disabled={disabled}
      className={cn("button", className, { [styles.clear]: clearStyles })}
      form={form}
      // eslint-disable-next-line react/button-has-type
      type={type}
      onClick={onClick}
    >
      {children}
    </button>
  );
};
