import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import img from "../../../../media/images/testImg.png";
import { Button } from "../../../common/button";
import { getNameWebsiteFromUrl } from "../../../../helpers";
import styles from "./matches.module.css";

export const Matches = ({ matches, onOpenModal }) => {
  if (!matches.length) {
    return null;
  }

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>{matches.length} Matches founded</h1>

      {matches.map(match => (
        <Match key={match.attorney} match={match} onOpenModal={onOpenModal} />
      ))}
    </div>
  );
};

const Match = ({ match, onOpenModal }) => {
  const { t } = useTranslation();
  const [isActiveProfile, setIsActiveProfileLink] = useState(false);
  const { attorney, city, state, listing_url } = match;

  const toggleProfile = () => {
    setIsActiveProfileLink(prev => !prev);
  };

  return (
    <div className={styles.matches} key={attorney}>
      <div className={styles.flex}>
        <img className={styles.photo} src={img} alt="profile imf" />
        <div className={styles.info}>
          <span className={styles.name}>{attorney}</span>
          <span className={styles.address}>{`${city}, ${state}`}</span>
        </div>
        <span className={styles.website}>{getNameWebsiteFromUrl(listing_url)}</span>

        <div className={styles.rightSide}>
          <button type="button" className={styles.profile} onClick={toggleProfile}>
            {t("dashboard_common_button_public_profile")}
          </button>
          <Button className={styles.button} onClick={() => onOpenModal(true, attorney)}>
            {t("dashboard_common_button_message")}
          </Button>
        </div>
      </div>

      {isActiveProfile && (
        <>
          <span className={styles.indent} />
          <div className={styles.detail}>
            <div className={styles.detailItem}>
              <span className={styles.label}>NAME</span>
              <span className={styles.value}>Firstname Lastname</span>
            </div>
            <div className={styles.detailItem}>
              <span className={styles.label}>E-MAIL ADDRESS</span>
              <span className={styles.value}>gabe.lewis23@gmail.com</span>
            </div>
            <div className={styles.detailItem}>
              <span className={styles.label}>PHONE</span>
              <span className={styles.value}>+1-202-555-0126</span>
            </div>
            <div className={styles.detailItem}>
              <span className={styles.label}>RECEIVED ON</span>
              <span className={styles.value}>12/09/19 - 12:52 AM</span>
            </div>
            <div className={styles.detailItem}>
              <span className={styles.label}>REQUEST ID</span>
              <span className={styles.value}>4293</span>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
