import React, { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation, useHistory } from "react-router-dom";
import cn from "classnames";
import { useTranslation } from "react-i18next";

import ContactInfo from "./modals/contactInfo";
import { useDocTitle } from "../../helpers/hooks";
import { getActiveStatuses, getQueryParams } from "../../helpers";
// remove
// import { Title } from "./components/title";
import Breadcrumbs from "./components/breadcrumbs";
import { OverlayPreloader } from "../common/preloader";
import ContentComponent from "./components/contentComponent";
import { CaseInfo } from "./modals/caseInfo";
import { Questions } from "./modals/questions";
import { QuestionOpenToOther } from "./modals/questionOpenToOther";
import { Head as HeadComponent } from "./components/head";
import Info from "./components/info";
import { FollowUpQuestions } from "./components/followUpQuestions";
import QuestionsForm from "./components/questionsForm";
import OpenToOtherProfessionals from "./components/openToOtherProfessionals";
import ReadOnlyInfo from "./components/readOnlyInfo";
import { History } from "./components/history";
import { Matches } from "./components/matches";
import { Statuses } from "./components/statuses";
import { Message } from "./modals/message";
import { SuccessMessage } from "./modals/successMessage";
import { actions as referralRequestDetailActions } from "../../actions/referralRequestDetail";
import { modals as modalConstant } from "../../constants/referralRequestDetail";
import { Remove } from "./components/remove";
import styles from "./referralRequestDetail.module.css";
import emptyLead from "../../media/icons/search-empty.svg";
// import { Sidebar } from "../common/sidebar";
/* remove 01/05/2024 */
// import ModalReferralRequest from "../modalReferalRequest/modalReferalRequest" ;

export const ReferralRequestDetailComponent = ({ client }) => {
  const params = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const handleRemove = useCallback((leadId, history) => dispatch(referralRequestDetailActions.handleRemove(leadId, history)), [dispatch]);
  const getCase = useCallback(id => dispatch(referralRequestDetailActions.getCase(id)), [dispatch]);
  const toggleModal = useCallback((modal, isActive) => dispatch(referralRequestDetailActions.toggleModal(modal, isActive)), [dispatch]);
  const sendMatchMessage = useCallback((leadId, message) => dispatch(referralRequestDetailActions.sendMatchMessage(leadId, message)), [
    dispatch
  ]);
  const toggleMessageModal = useCallback((isActive, name) => dispatch(referralRequestDetailActions.toggleMessageModal(isActive, name)), [
    dispatch
  ]);
  const clearAllModals = useCallback(() => dispatch(referralRequestDetailActions.clearAllModals()), [dispatch]);
  const getPractices = useCallback(() => dispatch(referralRequestDetailActions.getPractices()), [dispatch]);
  const setContactInfoData = useCallback(data => dispatch(referralRequestDetailActions.setContactInfoData(data)), [dispatch]);
  const changeContactInfo = useCallback(data => dispatch(referralRequestDetailActions.changeContactInfo(data)), [dispatch]);
  const queryParams = useMemo(() => getQueryParams(location.search), [location]);
  const {
    case: caseData,
    followups,
    categoriesProfessions,
    preloader,
    matches,
    history,
    modals,
    isFailedEmailVerification,
    practices,
    contactInfoData
  } = useSelector(state => state.referralRequestDetail);
  const { t } = useTranslation();

  const historyHook = useHistory();
  const isEmpty = Object.keys(caseData).length === 0;

  const { states } = useSelector(state => state.main);
  const {
    account: { profile }
  } = useSelector(state => state);

  useEffect(() => {
    if (profile?.email) {
      getCase(params.id);
      getPractices(queryParams.dir_id);
    }
  }, [client, getCase, queryParams, params.id, profile?.email]); //eslint-disable-line

  useDocTitle("dashboard_referrals_details_meta_title");

  const handleActiveModal = modal => {
    toggleModal(modal, true);
  };

  const handleActiveMessageModal = (isActive, name) => {
    toggleMessageModal(isActive, name);
  };

  const handleCloseModal = modal => {
    toggleModal(modal, false);
  };

  const handleOpenContactInfo = () => {
    const { name_f, name_l, email, phone, lead_id: leadId } = caseData;
    setContactInfoData({ firstName: name_f || "", lastName: name_l || "", email, phone, leadId });
    handleActiveModal(modalConstant.contactInfo);
  };

  const emptyState = (
    <div className={cn("empty-state", styles.empty)}>
      <img src={emptyLead} alt="no_leads" />

      <p className={styles.emptyText}>Referral request will be displayed here</p>
    </div>
  );

  return (
    <>
      {preloader && <OverlayPreloader />}

      <div className={styles.content}>
        <div className={styles.case}>
          <h2 className={styles.title}>{t("dashboard_referrals_editor_details_title")}</h2>
          <Breadcrumbs />
          {isEmpty ? (
            <>{emptyState}</>
          ) : (
            <>
              <Statuses status={caseData.status} />
              <Matches matches={matches} onOpenModal={handleActiveMessageModal} />
              <div className={styles.caseWrp}>
                <HeadComponent status={getActiveStatuses(caseData.status)} />
                <Info
                  caseData={caseData}
                  categoriesProfessions={categoriesProfessions}
                  states={states}
                  status={getActiveStatuses(caseData.status)}
                  onOpenPopupDescription={() => handleActiveModal(modalConstant.missingInfo)}
                  isFailedEmailVerification={isFailedEmailVerification}
                  handleOpenContactInfo={handleOpenContactInfo}
                  practicesList={practices}
                />

                {/* {modals.createReferralRequest && <ModalReferralRequest />} */}

                <QuestionsForm leadId={caseData.lead_id} />

                <OpenToOtherProfessionals
                  onOpenModal={() => handleActiveModal(modalConstant.questionOpenToOther)}
                  openToOtherProfessionals={caseData?.open_to_other_professionals}
                  status={getActiveStatuses(caseData.status)}
                />
                <History history={history} />
                <Remove handleRemove={() => handleRemove(caseData.lead_id, historyHook)} />
              </div>
              <ReadOnlyInfo>
                <ContentComponent
                  caseData={caseData}
                  categoriesProfessions={categoriesProfessions}
                  states={states}
                  status={getActiveStatuses(caseData.status)}
                  onOpenPopupDescription={() => handleActiveModal(modalConstant.missingInfo)}
                  isFailedEmailVerification={isFailedEmailVerification}
                  handleOpenContactInfo={handleOpenContactInfo}
                />
                <FollowUpQuestions
                  followups={followups}
                  onOpenModal={() => handleActiveModal(modalConstant.question)}
                  status={getActiveStatuses(caseData.status)}
                  leadId={caseData.lead_id}
                />
              </ReadOnlyInfo>
            </>
          )}
        </div>
        {/*
        <Sidebar className={styles.sidebar} />
        */}
      </div>

      {modals.contactInfo && (
        <ContactInfo
          closeModal={() => handleCloseModal(modalConstant.contactInfo)}
          clearAllModals={clearAllModals}
          contactInfoData={contactInfoData}
          setContactInfoData={setContactInfoData}
          preloader={preloader}
          changeContactInfo={changeContactInfo}
        />
      )}

      {modals.missingInfo && <CaseInfo onClose={() => handleCloseModal(modalConstant.missingInfo)} clearAllModals={clearAllModals} />}

      {modals.question && (
        <Questions onClose={() => handleCloseModal(modalConstant.question)} clearAllModals={clearAllModals} leadId={caseData.lead_id} />
      )}

      {modals.questionOpenToOther && (
        <QuestionOpenToOther
          onClose={() => handleCloseModal(modalConstant.questionOpenToOther)}
          clearAllModals={clearAllModals}
          leadId={caseData.lead_id}
        />
      )}

      {modals.message && (
        <Message
          sendMatchMessage={sendMatchMessage}
          leadId={caseData.lead_id}
          onClose={() => handleActiveMessageModal(false, "")}
          clearAllModals={clearAllModals}
          preloader={preloader}
        />
      )}

      {modals.successMessage && (
        <SuccessMessage onClose={() => handleCloseModal(modalConstant.successMessage)} clearAllModals={clearAllModals} />
      )}
    </>
  );
};
