import React, { useCallback, useEffect, useMemo } from "react";
import { useLocation, useHistory } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import Settings from "./settings";

import { actions as profileActions } from "../../actions/account/profile";
import { modalsName } from "../../constants/account";
import ChangeEmail from "./modals/changeEmail";
import ChangeEmailNew from "./modals/changeEmailNew";
import ChangeEmailVerification from "./modals/changeEmailVerification";
import ChangeEmailSuccess from "./modals/changeEmailSuccess";
// import VerifiedEmailSuccess from "./modals/verifiedEmailSuccess";
import RemovePhone from "./modals/removePhone";
// import VerifyUserEmail from "./modals/verifyUserEmail";
import { AddNotifications } from "../addNotifications";
import { NotificationSuccess } from "../notificationSuccess";
import styles from "./account.module.css";
import { getQueryParams } from "../../helpers";
import { useDocTitle } from "../../helpers/hooks";
import { Notification } from "../common/notification";
import Tabs from "./tabs";

const Account = ({ keycloak }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const updateContacts = useCallback(data => dispatch(profileActions.updateContacts(data)), [dispatch]);
  const updateMyAccountInformation = useCallback(data => dispatch(profileActions.updateMyAccountInformation(data)), [dispatch]);
  const getProfile = useCallback(() => dispatch(profileActions.getProfile()), [dispatch]);
  const sendEmailCode = useCallback(
    (email, callback, isNotCloseModal) => dispatch(profileActions.sendEmailCode(email, callback, isNotCloseModal)),
    [dispatch]
  );
  const saveNewEmailPassword = useCallback((email, password) => dispatch(profileActions.saveNewEmailPassword(email, password)), [dispatch]);
  const checkEmailCode = useCallback(
    (code, newloginData, keycloackData, isVerified) =>
      dispatch(profileActions.checkEmailCode(code, newloginData, keycloackData, isVerified)),
    [dispatch]
  );
  const updateEmail = useCallback(email => dispatch(profileActions.updateEmail(email)), [dispatch]);
  const sendPhoneCode = useCallback(phone => dispatch(profileActions.sendPhoneCode(phone)), [dispatch]);
  const checkPhoneCode = useCallback((phone, newPhoneData) => dispatch(profileActions.checkPhoneCode(phone, newPhoneData)), [dispatch]);
  const removePhoneNumber = useCallback(() => dispatch(profileActions.removePhoneNumber()), [dispatch]);
  const passwordVerificationSuccess = useCallback(() => dispatch(profileActions.passwordVerificationSuccess()), [dispatch]);
  const { account } = useSelector(state => state);
  const toggleActivityOfModal = (modal, isActive) => dispatch(profileActions.toggleActivityOfModal(modal, isActive));
  const clearChangeEmailError = () => dispatch(profileActions.clearChangeEmailError());
  const cleanNewEmailPassword = () => dispatch(profileActions.cleanNewEmailPassword());
  const clearAllModals = () => dispatch(profileActions.clearAllModals());

  const location = useLocation();
  const history = useHistory();
  const queryParams = useMemo(() => getQueryParams(location.search), [location]);

  const { error, preloaders, profile, success, modals, verificationError, newLoginData } = account;

  const openModal = modalName => {
    toggleActivityOfModal(modalName, true);
  };

  const closeModal = modalName => {
    toggleActivityOfModal(modalName, false);
  };

  useEffect(() => {
    if (!profile?.length) {
      getProfile();
    }
  }, []);

  useEffect(() => {
    if (queryParams.code && profile.email) {
      if (queryParams.type === "password") {
        passwordVerificationSuccess();
      }
      history.replace({
        pathname: location.pathname,
        search: ""
      });
    }
  }, [queryParams.code, profile.email, queryParams.type]); // eslint-disable-line

  useDocTitle(t("dashboard_account_metatitle"));

  return (
    <>
      {error?.changeEmail?.isShow && (
        <Notification error close={clearChangeEmailError}>
          {error?.changeEmail.message}
        </Notification>
      )}
      <div className={styles.wrapper}>
        <h1 className={styles.mainTitle}>{t("dashboard_account_h1_title")}</h1>

        <Tabs />

        <div className={styles.content}>
          <Settings
            preloaders={preloaders}
            profile={profile}
            success={success}
            updateContacts={updateContacts}
            updateMyAccountInformation={updateMyAccountInformation}
            openModal={openModal}
            keycloak={keycloak}
          />
        </div>
        {/* Modals */}
        {modals.changeEmail && (
          <ChangeEmail
            clearAllModals={clearAllModals}
            closeModal={() => closeModal(modalsName.changeEmail)}
            preloaders={preloaders}
            verificationError={verificationError}
            sendEmailCode={sendEmailCode}
            saveNewEmailPassword={saveNewEmailPassword}
            email={newLoginData.email}
            password={newLoginData.password}
            cleanNewEmailPassword={cleanNewEmailPassword}
          />
        )}
        {modals.changeEmailNew && (
          <ChangeEmailNew
            clearAllModals={clearAllModals}
            closeModal={() => closeModal(modalsName.changeEmailNew)}
            preloaders={preloaders}
            verificationError={verificationError}
            sendEmailCode={sendEmailCode}
            saveNewEmailPassword={saveNewEmailPassword}
            email={newLoginData.email}
            cleanNewEmailPassword={cleanNewEmailPassword}
            updateEmail={updateEmail}
          />
        )}
        {modals.changeEmailVerification && (
          <ChangeEmailVerification
            clearAllModals={clearAllModals}
            closeModal={() => closeModal(modalsName.changeEmailVerification)}
            handleResendCode={() => sendEmailCode(newLoginData.email)}
            openModal={() => openModal(modalsName.changeEmail)}
            preloaders={preloaders}
            checkEmailCode={checkEmailCode}
            newLoginData={newLoginData}
            updateEmail={updateEmail}
            cleanNewEmailPassword={cleanNewEmailPassword}
            verificationError={verificationError}
            keycloak={keycloak}
          />
        )}

        {modals.changeEmailSuccess && (
          <ChangeEmailSuccess clearAllModals={clearAllModals} closeModal={() => closeModal(modalsName.changeEmailSuccess)} />
        )}

        {(modals.addPhone || modals.changePhone) && (
          <AddNotifications
            subTitle="ACCOUNT SETTINGS"
            title={modals.addPhone ? "Add Phone Number" : t("dashboard_account_phone_change_title")}
            text={modals.addPhone ? t("dashboard_newaccount_addphone") : t("dashboard_account_phone_change_msg")}
            label={t("dashboard_account_phone_change_numbertitle")}
            buttonText={t("dashboard_account_phone_change_sendcodebutton")}
            buttonTextSent={t("dashboard_account_phone_change_codesent")}
            clearAllModals={clearAllModals}
            closeModal={clearAllModals}
            handleNotify={sendPhoneCode}
            handleVerify={checkPhoneCode}
            preloaders={preloaders.verifications}
            isErrorVerification={verificationError.checkPhoneCode}
          />
        )}

        {(modals.addPhoneSuccess || modals.changePhoneSuccess) && (
          <NotificationSuccess
            subtitle="ACCOUNT SETTINGS"
            title={t("dashboard_account_phone_verify_success_title")}
            text={t("dashboard_account_phone_verify_success_msg")}
            clearAllModals={clearAllModals}
            closeModal={clearAllModals}
          />
        )}

        {modals.changePasswordSuccess && (
          <NotificationSuccess
            subtitle="ACCOUNT SETTINGS"
            title="Successfully"
            text="You have successfully changed your password"
            clearAllModals={clearAllModals}
            closeModal={clearAllModals}
          />
        )}

        {modals.removePhone && (
          <RemovePhone
            phone={profile.phone}
            clearAllModals={clearAllModals}
            closeModal={() => closeModal(modalsName.removePhone)}
            preloaders={preloaders}
            removePhoneNumber={removePhoneNumber}
          />
        )}

        {modals.removePhoneSuccess && (
          <NotificationSuccess
            subtitle="ACCOUNT SETTINGS"
            title={t("dashboard_account_phone_remove_success_title")}
            text={t("dashboard_account_phone_remove_success_msg")}
            clearAllModals={clearAllModals}
            closeModal={clearAllModals}
          />
        )}

        {/* todo: Remove this component
        {modals.verifyEmail && (
          <VerifyUserEmail
            sendEmailCode={sendEmailCode}
            userEmail={profile?.email}
            clearAllModals={clearAllModals}
            handleClose={clearAllModals}
            checkEmailCode={checkEmailCode}
            preloaders={preloaders}
            keycloak={keycloak}
          />
        )}

        {modals.verifyEmailSuccess && <VerifiedEmailSuccess clearAllModals={clearAllModals} closeModal={clearAllModals} />}
        */}
      </div>
    </>
  );
};

export default Account;
