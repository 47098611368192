import React from "react";
import { Link } from "react-router-dom";
import format from "date-fns/format";

import { ReactComponent as CalendarIcon } from "../../../media/icons/calendarNew.svg";
import { ReactComponent as TagIcon } from "../../../media/icons/tag.svg";
// import eventsImage from "../../../media/images/defaultEvents.jpg";
import styles from "./events.module.css";

const Events = ({ events }) => {
  const renderEvents = events.map(item => {
    const { attorney, listings_image_name: listingsImageName, description, listing_id: listingId } = item;
    const startDateTime = item.event_schedules && item.event_schedules.length && item.event_schedules[0].start_date_time;

    return (
      <div className={styles.item} key={`${listingId}-events`}>
        <div className={styles.itemImg}>
          <img src={listingsImageName} alt="Events default" />
        </div>
        <div className={styles.itemContent}>
          <Link to={`/public-listing/${listingId}`} className={styles.itemName}>
            {attorney}
          </Link>
          <p className={styles.itemDescription}>{description}</p>
          <div className={styles.detail}>
            <span className={styles.detailItem}>
              <CalendarIcon />
              {startDateTime && format(new Date(startDateTime), "dd MMM - h:mm a")}
            </span>
            <span className={styles.detailLine} />
            <span className={styles.detailItem}>
              <TagIcon />
              $25
            </span>
          </div>
        </div>
      </div>
    );
  });
  return (
    <div className={styles.content}>
      <h4 className={styles.title}>Latest Events</h4>
      <div className={styles.events}>{renderEvents}</div>
    </div>
  );
};

export default Events;
