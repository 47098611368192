import React, { useRef } from "react";
import format from "date-fns/format";
import { useTranslation } from "react-i18next";

import { ACTIVE_BLOCK_NAMES } from "../../../../constants/messages";
import MessageItem from "../messageItem";
import { getNameWebsiteFromUrl } from "../../../../helpers";
import styles from "./messagesList.module.css";

const MessagesList = ({ messages, getMessageDetail, activeSearchMessageId, handleChangeUrl, setActiveBlocks, messagesLength, team }) => {
  const { t } = useTranslation();

  const messageListElement = useRef();

  const onClick = (listingMessageId, emailConversationId, isRead) => {
    getMessageDetail(listingMessageId, emailConversationId, String(isRead) === "0", team);
    handleChangeUrl(listingMessageId);
    setActiveBlocks({ [ACTIVE_BLOCK_NAMES.CONVERSATION]: true, [ACTIVE_BLOCK_NAMES.MESSAGES]: false });
  };

  const messagesList = messages.map((item, index) => {
    return (
      <MessageItem
        key={`${item.listing_message_id}-date-${item.sent}-${index}`} // eslint-disable-line
        isActive={String(activeSearchMessageId) === String(item.listing_message_id)}
        message={item.message}
        date={format(new Date(item.sent), "MMM dd")}
        attorney={item.attorney}
        name={item.name_full}
        initials={item.client_initials}
        listingUrl={getNameWebsiteFromUrl(item.listing_url || "")}
        isRead={String(item.is_read) === "1"}
        onClick={() => onClick(item.listing_message_id, item.email_conversation_id, item.is_read)}
        color={item.color}
        background={item.background}
      />
    );
  });

  return (
    <div className={styles.messagesList} ref={messageListElement}>
      {messagesLength ? (
        <> {messagesList}</>
      ) : (
        <div className={styles.emptyState}>
          <div className={styles.emptyIcon}>
            <svg xmlns="http://www.w3.org/2000/svg" width="33" height="32" viewBox="0 0 33 32" fill="none">
              <path
                d="M26.8032 27.2628C26.1955 25.5617 24.8563 24.0586 22.9934 22.9865C21.1306 21.9144 18.8481 21.3333 16.5 21.3333C14.1519 21.3333 11.8694 21.9144 10.0065 22.9865C8.14365 24.0586 6.8045 25.5617 6.19677 27.2628"
                stroke="#ABAFB9"
                strokeWidth="2.66667"
                strokeLinecap="round"
              />
              <circle cx="16.5" cy="10.6667" r="5.33333" stroke="#ABAFB9" strokeWidth="2.66667" strokeLinecap="round" />
            </svg>
          </div>
          <h5 className={styles.emptyTitle}>{t("dashboard_inbox_nohistory_title")}</h5>
          <p className={styles.emptyText}>{t("dashboard_inbox_nohistory_desc")}</p>
        </div>
      )}
    </div>
  );
};

export default MessagesList;
