import React from "react";

import { useTranslation } from "react-i18next";
import { NotificationsItem } from "../notificationsItem";
import { modals as modalsConstant } from "../../../constants/listings";
import styles from "./notifications.module.css";

const Notifications = ({ emails, phones, handleRemoveNotification, isPremium, name, userEmail, listingId }) => {
  const { t } = useTranslation();
  const handleRemoveVerifiedPhone = (data, prKey) => {
    handleRemoveNotification(modalsConstant.deletePhone, { keyValue: data, attorney: name, prKey, listingId });
  };

  const handleRemoveVerifiedEmail = (data, prKey) => {
    handleRemoveNotification(modalsConstant.deleteEmail, { keyValue: data, attorney: name, prKey, listingId });
  };

  const updatedEmail = userEmail ? [{ email: userEmail, isReadOnly: true }, ...emails] : emails;

  const emailsList = updatedEmail
    .filter(item => Boolean(item.email))
    .map(item => (
      <NotificationsItem
        isReadOnly={item.isReadOnly}
        key={item.email}
        isEmail
        text={item.email}
        handleRemove={() => handleRemoveVerifiedEmail(item.email, item.prkey)}
      />
    ));

  const phoneList = phones
    .filter(item => Boolean(item.phone))
    .map(item => (
      <NotificationsItem
        isReadOnly={item.isReadOnly}
        key={item.phone}
        text={item.phone}
        handleRemove={() => handleRemoveVerifiedPhone(item.phone, item.prkey)}
      />
    ));

  return (
    <div className={styles.notifications}>
      <div className={styles.notificationsHead}>
        <span className={styles.notificationsTitle}>{t("dashboard_listing_notifications_table_type")}</span>
        <span className={styles.notificationsTitle}>{t("dashboard_listing_notifications_table_to")}</span>
        <span className={styles.notificationsTitle}>{t("dashboard_listing_notifications_table_listing")}</span>
        <span className={styles.notificationsTitle}>{t("dashboard_listing_notifications_table_inbox")}</span>
        <span className={styles.notificationsTitle}>{t("dashboard_listing_notifications_table_leads")}</span>
        <span className={styles.notificationsTitle}>{t("dashboard_listing_notifications_table_qa")}</span>
      </div>
      {emailsList}
      {isPremium && <>{phoneList}</>}
    </div>
  );
};

export default Notifications;
