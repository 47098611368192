import React from "react";
import styles from "./redStar.module.css";

export const RedStar = ({ isActive }) => {
  if (!isActive) {
    return null;
  }

  return <span className={styles.redStar}>*</span>;
};
