import React from "react";
import cn from "classnames";

import { ReactComponent as CloseIcon } from "../../../media/icons/close-grey.svg";
import { ReactComponent as RemoveIcon } from "../../../media/icons/remove.svg";
import styles from "./removeButton.module.css";

const RemoveButton = ({ onClick, isHide, className, isCrossIcon }) => {
  const buttonHide = isHide ? styles.buttonHide : "";
  const buttonCrossIcon = isCrossIcon ? styles.buttonCrossIcon : "";

  return (
    <button className={cn(styles.button, buttonHide, className, buttonCrossIcon)} type="button" onClick={onClick}>
      {isCrossIcon ? <CloseIcon /> : <RemoveIcon />}
    </button>
  );
};

export default RemoveButton;
