import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import queryString from "query-string";

import { QUESTION_TYPES } from "../../../../constants/questionsAnswers";
import { ReactComponent as EmptyIcon } from "../../../../media/icons/search-empty.svg";
import { OverlayPreloader } from "../../../common/preloader";
import { TabNav } from "../../../common/tabs";
import { QuestionBox } from "../questionBox";
import { actions as questionsActions } from "../../../../actions/questions";
import styles from "./tabsContent.module.css";
import { Pagination } from "../../../common/pagination";

export const TabsContent = ({ questionsToRender, listingsCount, isQaExist, children }) => {
  const dispatch = useDispatch();
  const { preloader, pagination } = useSelector(state => state.questions);
  const saveQuestion = questionId => dispatch(questionsActions.saveQuestion(questionId));
  const unSaveQuestion = questionId => dispatch(questionsActions.unSaveQuestion(questionId));

  const navigationTabs = useMemo(() => {
    const navigations = [];

    if (isQaExist || !listingsCount) {
      navigations.push({
        text: "My Questions",
        param: "my-questions"
      });
    }

    if (listingsCount) {
      navigations.push({
        text: "My Answers",
        param: "my-answers"
      });
    }

    return navigations;
  }, [isQaExist, listingsCount]);

  const history = useHistory();
  const { location } = history;
  const searchParams = queryString.parse(location.search);

  const makePaginationLinks = () => {
    const navLinks = [];
    const numberOfPages = pagination.lastPageNum || 0;

    for (let i = 0; i <= numberOfPages; i++) {
      navLinks.push({ pathname: "/question-answer", search: queryString.stringify({ ...searchParams, page: i }), number: i });
    }

    return navLinks;
  };

  const isShowPagination = useMemo(() => {
    if (searchParams.tab === "my-questions") {
      return (
        (questionsToRender.qa.length && questionsToRender.qa.length >= pagination.perPage && pagination.lastPageNum !== 0) ||
        Number(searchParams.page) >= 1
      );
    }
    return (
      (questionsToRender.answers.length && questionsToRender.answers.length >= pagination.perPage && pagination.lastPageNum !== 0) ||
      Number(searchParams.page) >= 1
    );
  }, [questionsToRender.qa, questionsToRender.answers]);

  const renderQuestions = (array, questionType) => {
    if (!array.length) {
      return (
        <div className={styles.empty}>
          <EmptyIcon />
          <p className={styles.text}>Your questions and answers will appear here</p>
        </div>
      );
    }

    return array.map(({ added, city, question_answer, question_id: questionId, saved, state, summary, title, answers }) => {
      return (
        <QuestionBox
          added={added}
          answer={question_answer}
          answerTimes={answers}
          city={city}
          classNames={styles.box}
          key={questionId}
          questionId={questionId}
          saved={Boolean(Number(saved))}
          saveQuestion={saveQuestion}
          state={state}
          summary={summary}
          tab={questionType}
          textClass={styles.boxTitle}
          title={title}
          unSaveQuestion={unSaveQuestion}
        />
      );
    });
  };

  const questionTab =
    isQaExist || !listingsCount
      ? [<React.Fragment key="1">{renderQuestions(questionsToRender.qa, QUESTION_TYPES.QUESTIONS)}</React.Fragment>]
      : null;

  return (
    <div className={styles.content}>
      {children}
      {preloader && <OverlayPreloader />}
      <TabNav
        page="question-answer"
        nav={navigationTabs}
        content={[
          questionTab,
          listingsCount
            ? [<React.Fragment key="2">{renderQuestions(questionsToRender.answers, QUESTION_TYPES.MY_ANSWER)}</React.Fragment>]
            : null
        ].filter(item => Boolean(item))}
      />
      {isShowPagination && (
        <Pagination
          className={styles.pagination}
          links={makePaginationLinks()}
          currentPage={parseInt(searchParams.page, 10)}
          lastPage={pagination.lastPageNum}
        />
      )}
    </div>
  );
};
