import React from "react";

import { useTranslation } from "react-i18next";
import { NotificationsItem } from "../notificationsItem";
import { modals as modalsConstant } from "../../../constants/listings";
import styles from "./notifications.module.css";

export const Notifications = ({ emails, phones, openModal }) => {
  const { t } = useTranslation();

  const handleRemoveVerifiedPhone = data => {
    openModal(modalsConstant.deletePhone, data);
  };

  const handleRemoveVerifiedEmail = data => {
    openModal(modalsConstant.deleteEmail, data);
  };

  const emailsList = emails.map(item => (
    <NotificationsItem key={item.email} isEmail text={item.email} handleRemove={() => handleRemoveVerifiedEmail(item.email)} />
  ));

  const phoneList = phones.map(item => (
    <NotificationsItem key={item.phone} text={item.phone} handleRemove={() => handleRemoveVerifiedPhone(item.phone)} />
  ));

  return (
    <div className={styles.notifications}>
      <div className={styles.notificationsHead}>
        <span className={styles.notificationsTitle}>{t("dashboard_listing_notifications_table_type")}</span>
        <span className={styles.notificationsTitle}>{t("dashboard_listing_notifications_table_to")}</span>
        <span className={styles.notificationsTitle}>{t("dashboard_listing_notifications_table_listing")}</span>
        <span className={styles.notificationsTitle}>{t("dashboard_listing_notifications_table_inbox")}</span>
        <span className={styles.notificationsTitle}>{t("dashboard_listing_notifications_table_leads")}</span>
        <span className={styles.notificationsTitle}>{t("dashboard_listing_notifications_table_qa")}</span>
      </div>
      {emailsList}
      {phoneList}
    </div>
  );
};
