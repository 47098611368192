import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import cn from "classnames";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import { useTranslation } from "react-i18next";

import { actions as questionsActions } from "../../actions/questions";
import { ACTIVE_FILTERS_DEFAULT_VALUE, TABS } from "../../constants/questionsAnswers";
import { Categories } from "./components/categories";
import { CategoriesMyAnswers } from "./components/categoriesMyAnswers";
// import { CategoriesMobile } from "./components/categoriesMobile";
import { Practices } from "./components/practices";
import { Professions } from "./components/professions";
// import { PracticesMobile } from "./components/practicesMobile";
// import { ProfessionsMobile } from "./components/professionsMobile";
import { Search } from "./components/search";
// import { ResetAllFilters } from "./components/resetAllFilters";
// import { Bookmarked } from "./components/bookmarked";
// import { Separator } from "./components/separator";
import { TabsContent } from "./components/tabsContent";
import { Notification } from "../common/notification";
import { useFiltrationQAndAPage, useDocTitle } from "../../helpers/hooks";
import { filterBySearchValue, filterBookmarked } from "../../helpers";
import styles from "./questionsAnswers.module.css";
import MainTabs from "../payPerLead/mainTabs";

export const QuestionsAnswers = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const getQuestions = useCallback(page => dispatch(questionsActions.getQuestions(page)), [dispatch]);
  const getQuestionsList = useCallback(
    ({ page, dirId, professionId, practiceId, categoryId, search }) =>
      dispatch(questionsActions.getQuestionsList({ page, dirId, professionId, practiceId, categoryId, search })),
    [dispatch]
  );
  const cleanQuestions = useCallback(() => dispatch(questionsActions.cleanQuestions()), [dispatch]);
  const getMyQuestions = useCallback(
    ({ page, dirId, professionId, practiceId, categoryId, search }) =>
      dispatch(questionsActions.getMyQuestions({ page, dirId, professionId, practiceId, categoryId, search })),
    [dispatch]
  );
  const { qa, answers, error, pagination } = useSelector(state => state.questions);
  const location = useLocation();
  const queryParamsObj = queryString.parse(location.search);
  const [searchValue, setSearchValue] = useState("");
  const [searchValueMyAnswer, setSearchValueMyAnswer] = useState("");
  const bookmarked = false;
  // const [bookmarked, setBookmarked] = useState(false);
  const [activeFilters, setActiveFilters] = useState(ACTIVE_FILTERS_DEFAULT_VALUE);
  const [questionsToRender, setQuestionsToRender] = useState({ qa: [], answers: [] });
  const searchParams = queryString.parse(history.location.search);
  const {
    globalInfo: { listings_count: listingsCount }
  } = useSelector(state => state.main);

  const isMyQuestionsTab = queryParamsObj.tab === TABS.MY_QUESTIONS || Object.keys(queryParamsObj).length === 0;
  // const isEmptyQuestions = !qa.length;
  // const isEmptyAnswers = !answers.length;
  // const isHideBookmark = (isMyQuestionsTab && isEmptyQuestions) || (isEmptyAnswers && !isMyQuestionsTab);
  const { t } = useTranslation();

  /*
  const isActiveFilter =
    Boolean(activeFilters.categories.length) ||
    Boolean(activeFilters.professions.length) ||
    Boolean(activeFilters.practices.length) ||
    bookmarked;
  */

  const toggleActive = (arrName, item) => (arrName.includes(item) ? arrName.filter(i => i !== item) : [...arrName, item]);

  /*
  const handleFilter = (filterName, id) => {
    const toggleValue = toggleActive(activeFilters[filterName], id);
    setActiveFilters(prevState => ({ ...prevState, [filterName]: toggleValue, isFirstRender: false }));

    getMyQuestions({ page: searchParams.page, practiceId: id, search: searchValue });
  };
  */

  const handleSearchValue = text => {
    setSearchValue(text);

    getMyQuestions({
      page: searchParams.page,
      professionId: activeFilters.professions,
      categoryId: activeFilters.categories,
      search: text
    });
  };

  const handleSearchValueMyAnswer = text => {
    setSearchValueMyAnswer(text);

    getQuestionsList({
      page: searchParams.page,
      practiceId: activeFilters.practices,
      categoryId: activeFilters.categoriesMyAnswers,
      search: text
    });
  };

  const handleFilterProfession = (filterName, id) => {
    const toggleValue = toggleActive(activeFilters[filterName], id);
    setActiveFilters(prevState => ({ ...prevState, [filterName]: toggleValue, isFirstRender: false }));

    getMyQuestions({ page: searchParams.page, professionId: toggleValue, search: searchValue });
  };

  const handleFilterCategories = (filterName, id) => {
    const toggleValue = toggleActive(activeFilters[filterName], id);
    setActiveFilters(prevState => ({ ...prevState, [filterName]: toggleValue, isFirstRender: false }));

    getMyQuestions({ page: searchParams.page, categoryId: toggleValue, search: searchValue });
  };

  const handleFilterCategoriesMyAnswers = (filterName, id) => {
    const toggleValue = toggleActive(activeFilters[filterName], id);
    setActiveFilters(prevState => ({ ...prevState, [filterName]: toggleValue, isFirstRender: false }));

    getQuestionsList({ page: searchParams.page, practiceId: activeFilters.practices, categoryId: toggleValue, search: searchValue });
  };

  const handleFilterMyQuestions = (filterName, id) => {
    const toggleValue = toggleActive(activeFilters[filterName], id);
    setActiveFilters(prevState => ({ ...prevState, [filterName]: toggleValue, isFirstRender: false }));

    getQuestionsList({
      page: searchParams.page,
      practiceId: toggleValue,
      categoryId: activeFilters.categoriesMyAnswers,
      search: searchValue
    });
  };

  /*
  const handleResetAllFilters = () => {
    setActiveFilters({ ...ACTIVE_FILTERS_DEFAULT_VALUE, isFirstRender: false });
    setBookmarked(false);
    if (isMyQuestionsTab) {
      getMyQuestions({ page: searchParams.page });
    } else {
      getQuestions(0);
    }
  };
  */

  useDocTitle(t("dashboard_qa_metatitle"));

  useFiltrationQAndAPage(activeFilters, searchValue, bookmarked, setQuestionsToRender);

  useEffect(() => {
    setQuestionsToRender({
      qa: filterBookmarked(bookmarked, filterBySearchValue(qa, searchValue)),
      answers: filterBookmarked(bookmarked, filterBySearchValue(answers, searchValue))
    });
  }, [qa, answers, searchValue, bookmarked]);

  useEffect(() => {
    if (searchParams.tab !== "my-questions") {
      getMyQuestions({ page: searchParams.page });
    } else {
      getQuestionsList({ page: searchParams.page });
    }
  }, [searchParams.page]); // eslint-disable-line

  useEffect(() => {
    if (pagination.currentPageNum !== undefined) {
      const currentTab =
        Boolean(listingsCount) && questionsToRender.answers.length && !questionsToRender.qa.length ? TABS.MY_ANSWER : TABS.MY_QUESTIONS;

      history.push(`/question-answer?page=${pagination.currentPageNum}&tab=${currentTab}`);
    }
  }, [questionsToRender.answers.length, questionsToRender.qa.length]); // eslint-disable-line

  useEffect(() => {
    getQuestions(searchParams.page);

    return () => {
      cleanQuestions();
    };
  }, []); // eslint-disable-line

  /*
  useEffect(() => {
    if (
      activeFilters.isFirstRender &&
      (questionsToRender.qa.length || questionsToRender.answers.length) &&
      questionsToRender.qa.length < questionsToRender.answers.length
    ) {
      history.push(`?tab=${TABS.MY_ANSWER}`);
    }
  }, [questionsToRender.qa, questionsToRender.answers]); // eslint-disable-line
  */

  return (
    <>
      {error && <Notification error>{error}</Notification>}
      <div className={styles.wrapper}>
        <h1 className={styles.title}>{isMyQuestionsTab ? <>{t("dashboard_qa_all_h1")}</> : <>{t("dashboard_qa_myanswers_h1")}</>}</h1>

        <MainTabs />

        {(questionsToRender.qa.length && questionsToRender.answers.length) || searchValue ? (
          <>
            {isMyQuestionsTab ? (
              <Search key="isMyQuestionsTab" searchValue={searchValue} setSearchValue={handleSearchValue} />
            ) : (
              <Search key="isMyQuestionsTabNoo" searchValue={searchValueMyAnswer} setSearchValue={handleSearchValueMyAnswer} />
            )}
          </>
        ) : null}

        <TabsContent
          questionsToRender={questionsToRender}
          isQaExist={Boolean(questionsToRender.qa.length)}
          listingsCount={Boolean(listingsCount) || questionsToRender.qa.length}
        >
          {isMyQuestionsTab ? (
            <div className={styles.filterFlex}>
              <Professions activeFilters={activeFilters} handleFilter={handleFilterProfession} />
              <Categories className={styles.activeDropdown} activeFilters={activeFilters} handleFilter={handleFilterCategories} />
            </div>
          ) : (
            <div className={styles.filterFlex}>
              <Practices activeFilters={activeFilters} handleFilter={handleFilterMyQuestions} />
              <CategoriesMyAnswers
                className={styles.activeDropdown}
                activeFilters={activeFilters}
                handleFilter={handleFilterCategoriesMyAnswers}
              />
            </div>
          )}
        </TabsContent>
      </div>
    </>
  );
};
