import React, { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";

import { useTranslation } from "react-i18next";
import Dropdown from "../dropdown";
import Checkbox from "../../common/checkbox";
import DropdownActions from "../dropdownActions";
import styles from "./consultation.module.css";

const Consultation = ({ consultation, submitChanges, validation, showRequiredText }) => {
  if (Object.keys(validation).length && !validation.enabled) {
    return null;
  }

  const [isShowEmpty, setIsShowEmpty] = useState(false);
  const isRequired = Object.keys(validation).length ? validation.required : true;
  const isRequiredText = validation.required && showRequiredText;
  const [isMouseInBlock, setIsMouseInBlock] = useState(false);
  const [isTouched, setIsTouched] = useState(false);
  const containerRef = useRef();

  const [isEdited, setIsEdited] = useState(false);
  const [consultationValue, setConsultationValue] = useState(0);

  useEffect(() => {
    setConsultationValue(consultation);
  }, [consultation]);

  const handleTouchStart = () => {
    if (!isTouched) {
      setIsTouched(true);
    }
  };

  const handleMouseMove = () => {
    if (!isMouseInBlock) {
      setIsMouseInBlock(true);
    }
  };

  const handleMouseLeave = () => {
    if (isMouseInBlock) {
      setIsMouseInBlock(false);

      if (isEdited) {
        submitChanges({ consultation: consultationValue }, "consultation", "save_consultation");
        setIsEdited(false);
      }
    }
  };

  const handleSubmit = value => {
    setConsultationValue(value ? 1 : 0);
  };

  const handleShowEmpty = () => {
    if (!isShowEmpty) {
      setIsShowEmpty(true);
    }
  };

  useEffect(() => {
    const handleTouch = event => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        if (isTouched) {
          setIsTouched(false);

          if (isEdited) {
            submitChanges({ consultation: consultationValue }, "consultation", "save_consultation");
            setIsEdited(false);
          }
        }
      }
    };

    document.addEventListener("touchstart", handleTouch, true);

    return () => {
      document.removeEventListener("touchstart", handleTouch, true);
    };
  }, [isEdited, isEdited, consultationValue]);

  return (
    <div ref={containerRef}>
      <Dropdown
        description={validation.custom_description}
        isRequired={isRequired}
        title="Free Consultations"
        isRequiredText={isRequiredText}
        handleMouseMove={handleMouseMove}
        handleMouseLeave={handleMouseLeave}
        handleTouchStart={handleTouchStart}
      >
        <ConsultationItem
          selected={{ consultation: Boolean(consultation) }}
          handleSubmit={handleSubmit}
          isEdited={isEdited}
          setIsEdited={setIsEdited}
        />
        <DropdownActions buttonHandle={handleShowEmpty} />
      </Dropdown>
    </div>
  );
};

const ConsultationItem = ({ selected, handleSubmit: onSubmit, setIsEdited, isEdited }) => {
  const formik = useFormik({
    initialValues: { ...selected, isEdited: false },
    onSubmit: values => {
      return onSubmit(values.consultation);
    }
  });
  const { t } = useTranslation();

  const { values, handleSubmit, setFieldValue } = formik;

  const handleChange = value => {
    setFieldValue("consultation", value);
    handleSubmit(value);

    if (!values.isEdited) {
      setFieldValue("isEdited", true);
    }

    if (!isEdited) {
      setIsEdited(true);
    }
  };

  return (
    <div className={styles.item}>
      <div className={styles.flexItem}>
        <div className={styles.content}>
          <span className={styles.name}>{t("dashboard_listings_edit_consultation_checkbox")}</span>

          <div className={styles.flex}>
            <Checkbox isNewStyles checked={values.consultation === true} onChange={() => handleChange(true)}>
              Yes
            </Checkbox>
            <Checkbox isNewStyles checked={values.consultation === false} onChange={() => handleChange(false)}>
              No
            </Checkbox>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Consultation;
