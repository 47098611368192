import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import cn from "classnames";

import { actions as referralRequestsActions } from "../../actions/referralRequests";
import { modals } from "../../constants/referralRequests";
import styles from "./bannerReferralRequests.module.css";

export const BannerReferralRequests = ({ importReady }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const toggleActivityOfModal = useCallback(
    (modal, isActive) =>
      dispatch(
        referralRequestsActions.toggleActivityOfModal({
          modal,
          isActive
        })
      ),
    [dispatch]
  );

  if (
    typeof importReady.requests !== "number" ||
    (String(importReady.requests) === "0" && String(importReady.messages) === "0" && String(importReady.questions) === "0")
  ) {
    return null;
  }

  return (
    <div className={styles.banner}>
      <p className={styles.bannerText}>
        <span> Import your requests </span>
        (You can import your submitted messages, referral requests, or questions through our directories)
      </p>
      <button className={cn("button", styles.buttonImport)} type="button" onClick={() => toggleActivityOfModal(modals.importing, true)}>
        {t("dashboard_referrals_import_confirmation_import_button")}
      </button>
    </div>
  );
};
