import React from "react";

import styles from "./profileAside.module.css";

const ProfileAside = () => {
  return (
    <div className={styles.aside}>
      <h4 className={styles.asideTitle}>Articles (Ryan Howard)</h4>

      <div className={styles.content}>
        <a href="/" className={styles.link}>
          CFPB Issues First Version of FAQS Addressing Consumer Reporting in the COVID-19 Pandemic
        </a>
        <a href="/" className={styles.link}>
          Judge Jackson Explains the Basis for Her Invalidation of the Board’s Election Regulations
        </a>
      </div>

      <h4 className={styles.asideTitle}>Q&As (Ryan Howard)</h4>

      <div className={styles.content}>
        <a href="/" className={styles.link}>
          CFPB Issues First Version of FAQS Addressing Consumer Reporting in the COVID-19 Pandemic
        </a>
        <a href="/" className={styles.link}>
          Judge Jackson Explains the Basis for Her Invalidation of the Board’s Election Regulations
        </a>
        <a href="/" className={styles.link}>
          Judge Jackson Explains the Basis for Her Invalidation of the Board’s Election Regulations
        </a>
        <a href="/" className={styles.link}>
          CFPB Issues First Version of FAQS Addressing Consumer Reporting in the COVID-19 Pandemic
        </a>
        <a href="/" className={styles.link}>
          CFPB Issues First Version of FAQS Addressing Consumer Reporting in the COVID-19 Pandemic
        </a>
      </div>
    </div>
  );
};

export default ProfileAside;
