import { signUp } from "../constants/actionTypes";

const defaultState = {
  error: "",
  loading: null,
  success: null,
  professions: [],
  professionsList: [],
  countriesList: [],
  otherProfessions: [],
  professionsLoading: false
};

export default function(state = defaultState, action) {
  const { type, payload } = action;
  switch (type) {
    case signUp.SET_LOADING:
      return {
        ...state,
        loading: payload
      };

    case signUp.SET_SUCCESS:
      return {
        ...state,
        success: payload.ok,
        loading: false
      };
    case signUp.SET_ERRORS:
      return {
        ...state,
        error: payload,
        loading: false
      };

    case signUp.GET_PROFESSIONS:
      return {
        ...state,
        professions: payload,
        professionsLoading: false
      };

    case signUp.CLEAN_PROFESSIONAL:
      return {
        ...state,
        professions: [],
        professionsLoading: false
      };

    case signUp.GET_PROFESSIONS_LOADING:
      return {
        ...state,
        professionsLoading: true
      };

    case signUp.GET_PROFESSIONS_LIST:
      return {
        ...state,
        professionsLoading: false,
        professionsList: payload,
        otherProfessions: payload
      };

    case signUp.GET_COUNTRIES_LIST:
      return {
        ...state,
        professionsLoading: false,
        countriesList: payload
      };

    case signUp.GET_PROFESSIONS_FAIL:
      return {
        ...state,
        professionsLoading: false
      };

    case signUp.GET_COUNTRIES_LIST_FAIL:
      return {
        ...state,
        professionsLoading: false
      };

    default:
      return {
        ...state
      };
  }
}
