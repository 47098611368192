import React from "react";
import { useTranslation } from "react-i18next";

import styles from "./answerCounter.module.css";

export const AnswerCounter = React.memo(({ answersLength }) => {
  const { t } = useTranslation();

  return (
    <p className={styles.answersCounter}>
      {t("dashboard_qa_single_publishedanswers")} (<span className={styles.answersGrey}>{answersLength || ""}</span>)
    </p>
  );
});
